import { useEffect, useState } from 'react'
import { useLocalStorage as useLocalStorageHook } from 'usehooks-ts'

export function useLocalStorage() {
  const [localStorage, setLocalStorage] = useState<Storage | undefined>(undefined)

  useEffect(() => {
    setLocalStorage(window.localStorage)
  }, [])

  return localStorage
}

export function useLocalStorageKey(key: string, initialValue: string | (() => string)) {
  return useLocalStorageHook<string>(key, initialValue, {
    serializer: (i) => i,
    deserializer: (i) => i,
  })
}
