'use client'

import { Flex } from '@chakra-ui/react'
import { PwaInstaller } from 'components/common/InstallPwa'
import { RoundButton } from 'components/common/RoundButton'
import { NCSendGTMEvent } from 'components/common/nextJs/components/nextCompatibleGoogleTags'
import { NCImage } from 'components/common/nextJs/components/nextCompatibleImage'
import { useCallback } from 'react'

export function InstallAppTile({ pwaInstaller }: { pwaInstaller: PwaInstaller | undefined }) {
  const handleInstallTheAppClicked = useCallback(() => {
    if (pwaInstaller?.installPwa) {
      NCSendGTMEvent({
        event: 'reviews_list_install_app_clicked',
        category: 'app_install',
      })
      pwaInstaller.installPwa()
    }
  }, [pwaInstaller])
  return (
    <>
      {pwaInstaller?.installPwa && !pwaInstaller?.appInstallState && (
        <Flex
          direction={'column'}
          alignSelf={'center'}
          style={{
            backgroundColor: '#282c34',
            maxWidth: '200px',
            width: '100%',
            textAlign: 'center',
            padding: '12px',
            borderRadius: '8px',
            color: '#b0b0b0',
            fontFamily: 'LeagueSpartan, sans-serif',
            lineHeight: '1.2em',
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
          }}>
          Install the PlayBack App
          <br />
          <NCImage
            style={{
              alignSelf: 'center',
              marginTop: '20px',
              width: '80px',
              filter: 'drop-shadow(0 0 10px rgb(255,255,255,0.2))',
            }}
            src={'/android-chrome-192x192.png'}
            alt='Install PlayBack App'
            onClick={handleInstallTheAppClicked}
          />
          <br />
          <RoundButton
            alt={'Install Playback'}
            className='m-[8px] justify-center'
            backgroundColor='#DD3D4E'
            color='white'
            onClick={handleInstallTheAppClicked}>
            Install
          </RoundButton>
        </Flex>
      )}
    </>
  )
}
