import { ChevronLeftIcon, CopyIcon } from '@chakra-ui/icons'
import { PlaybackHomeButton } from 'NavigationBar'
import {
  CollapsableTopBar,
  NavButton,
  NavItemContainer,
  VerticalSubMenu,
} from 'components/CollapsableTopBar'
import { BackgroundIcon } from 'components/common/BackgroundIcon'
import { useNCNavigate } from 'components/common/nextJs/components/nextCompatibleRouter'
import { useQueryString } from 'components/common/utils/QueryString'
import { cn } from 'components/common/utils/tailwindUtils'
import { showCopyReviewDialog } from 'data/CopyReview'
import { VideoSourceData } from 'data/ReviewSelectionInit'
import { DocumentPermissions } from 'data/common'
import { useCallback, useMemo } from 'react'
import { firebaseConfig } from 'services/FirebaseConfig'
import { DodgeballTemplate } from 'templates/dodgeball/DodgeballTemplate'
import { ReviewActivityType } from 'ui/ReviewMetaStore'
import { showSignInToEditDialog } from 'util/dialogUtils'
import { useWindowDimensions } from './UseWindowDimensions'
import { ShareDialogOptions, showShareReviewDialog } from './components/ShareReviewDialog'
import { VideoController } from './components/VideoController'
import { FirebaseDb, WrappedAuth, useFirebase } from './components/common/Firebase'
import { useUser } from './components/common/UseUser'
import graphIcon from './icons/graph_icon.png'
import shareIcon from './icons/share_button.png'
import { HighlightsButtons, HighlightsMode, ViewModeButtonProps } from './ui/HighlightsButtons'
import { HighlightPlayer, HighlightTypes } from './ui/HighlightsPlayer'
import { useCopyLinkToClipBoard } from './util/links'
import { NCSendGTMEvent } from 'components/common/nextJs/components/nextCompatibleGoogleTags'

export const NAVBAR_HEIGHT = 48

export function NavigationBarEditor({
  canExitWatchMode,
  onFullscreenClicked,
  highlightPlayer,
  highlightsMode,
  isFullScreen,
  isPlayingHighlights,
  onShowAnalysisClicked,
  onWatchModeClicked,
  documentPermissions,
  auth,
  shareDialogOptions,
  videoController,
  watchMode,
  hasPlayersOrFilters,
  showCreateButton = true,
  height = NAVBAR_HEIGHT,
  trackingProgress = 0,
  viewingModeButtonProps,
  firebaseDb,
  reviewFileProps,
  statsTemplateKey: chosenStatsLevel,
  onStatsLevelChosen,
  activityType,
}: {
  activityType?: ReviewActivityType | undefined
  statsTemplateKey?: string
  onStatsLevelChosen?: (level: DodgeballTemplate.DodgeballStatsLevel) => void
  height?: number
  showCreateButton?: boolean
  documentPermissions: DocumentPermissions | undefined
  videoController: VideoController | undefined
  isPlayingHighlights: false | HighlightTypes
  highlightPlayer: HighlightPlayer | undefined
  auth: WrappedAuth
  highlightsMode: HighlightsMode
  hasPlayersOrFilters: boolean
  onFullscreenClicked: () => void
  watchMode: boolean
  onWatchModeClicked: () => void
  canExitWatchMode: boolean
  isFullScreen: boolean
  onShowAnalysisClicked?: (show: boolean) => void
  shareDialogOptions: ShareDialogOptions | undefined
  trackingProgress: number
  viewingModeButtonProps?: ViewModeButtonProps
  firebaseDb: FirebaseDb
  reviewFileProps: {
    reviewId?: string
    videoSourceData?: VideoSourceData
  }
}) {
  const clipboardCopier = useCopyLinkToClipBoard()
  const windowDimensions = useWindowDimensions()
  const navigate = useNCNavigate()
  const { SignIn, user, navigateToSignIn } = useUser({
    auth,
  })
  const compactMode = windowDimensions.width <= 900
  const extremeCompact = windowDimensions.width <= 500

  const handleShareButtonClicked = useCallback((shareDialogOptions: ShareDialogOptions) => {
    showShareReviewDialog(shareDialogOptions)
  }, [])

  const [backbuttonDestination] = useQueryString('referrer_url', undefined)

  const handleCopy = useCallback(
    (reviewId: string) => () => {
      if (!user) {
        showSignInToEditDialog({
          action: { title: 'copy', message: 'make a copy' },
          navigateToSignIn,
        })
        return
      }
      showCopyReviewDialog(firebaseDb, user, reviewId, navigate)
    },
    [firebaseDb, navigate, navigateToSignIn, user],
  )

  const memoizedFullscreenClicked = useCallback(() => {
    onFullscreenClicked()
  }, [onFullscreenClicked])

  const memoizedViewingModeButtonProps = useMemo(
    () => viewingModeButtonProps,
    [viewingModeButtonProps],
  )

  const leftMainButton = useMemo(() => {
    return () => [
      backbuttonDestination ?
        <NavButton
          href={backbuttonDestination}
          className='bg-transparent text-white font-semibold'
          alt={'Go to back to the previous page'}>
          <ChevronLeftIcon fontSize={30}></ChevronLeftIcon>
          Back
        </NavButton>
      : <PlaybackHomeButton
          documentPermissions={documentPermissions}
          showCreateButton={showCreateButton}
          compactMode={compactMode}
        />,
    ]
  }, [backbuttonDestination, documentPermissions, showCreateButton, compactMode])

  const rightFixedChildren = useMemo(() => {
    return [
      <NavItemContainer
        showOnlyInVerticalMenu={true}
        key='signin'>
        <SignIn />
      </NavItemContainer>,
      onShowAnalysisClicked && (
        <NavButton
          key='analysis'
          icon={graphIcon}
          onClick={() => {
            NCSendGTMEvent({ event: 'analysis_clicked' })
            onShowAnalysisClicked(true)
            videoController?.pause()
          }}
          className={cn(
            'bg-white text-black',
            trackingProgress >= 0.8 && 'animate-rainbowBorder border-[3px]',
          )}
          alt={'View statistics'}
          squashedChildrenArray={['']}>
          Insights
        </NavButton>
      ),
      clipboardCopier.copy && shareDialogOptions && !watchMode && (
        <NavButton
          key='share'
          onClick={() => handleShareButtonClicked(shareDialogOptions)}
          icon={shareIcon}
          className='bg-[#1c1c1c] text-white'
          disabled={clipboardCopier.isCopying}
          squashedChildrenArray={['Share', '']}>
          {highlightPlayer?.isPlayingHighlights ? `Share ${highlightsMode}` : 'Share Review'}
        </NavButton>
      ),
    ]
  }, [
    SignIn,
    handleShareButtonClicked,
    clipboardCopier.isCopying,
    shareDialogOptions,
    watchMode,
    onShowAnalysisClicked,
    videoController,
    clipboardCopier.copy,
    highlightPlayer?.isPlayingHighlights,
    highlightsMode,
    trackingProgress,
  ])

  return (
    <CollapsableTopBar
      // hamburgerButtonClassName='bg-[rgba(189,32,38,0.54)]'
      leftMainButton={leftMainButton}
      rightFixedChildren={rightFixedChildren}>
      {() => [
        <>
          {videoController && highlightPlayer && (
            <NavItemContainer>
              <HighlightsButtons
                viewingModeButtonProps={memoizedViewingModeButtonProps}
                height={height - 8}
                hasPlayersOrFilters={hasPlayersOrFilters}
                videoController={videoController}
                isPlayingHighlights={isPlayingHighlights}
                highlightPlayer={highlightPlayer}
                displayStyle={
                  extremeCompact ? 'smallest'
                  : compactMode ?
                    'small'
                  : 'large'
                }
                highlightsMode={highlightsMode}
                fullscreenClicked={memoizedFullscreenClicked}
                watchMode={watchMode}
                onWatchModeClicked={onWatchModeClicked}
                canExitWatchMode={canExitWatchMode}
                isFullScreen={isFullScreen}
              />
            </NavItemContainer>
          )}
        </>,
        <VerticalSubMenu
          showOnlyInVerticalMenu={true}
          key='File'
          label='File'>
          {reviewFileProps.reviewId && (
            <NavButton
              icon={<CopyIcon />}
              onClick={handleCopy(reviewFileProps.reviewId)}>
              Make a copy
            </NavButton>
          )}
        </VerticalSubMenu>,
        (
          onStatsLevelChosen &&
          activityType === 'dodgeball' &&
          viewingModeButtonProps?.viewingMode === 'edit'
        ) ?
          <NavItemContainer
            key='level'
            showOnlyInVerticalMenu={false}>
            <DodgeballStatsLevelDropdown
              className='h-full w-full'
              statsTemplateKey={chosenStatsLevel}
              onChosen={onStatsLevelChosen}
            />
          </NavItemContainer>
        : undefined,
      ]}
    </CollapsableTopBar>
  )
}

/** for offline testing */
export function StoryBook() {
  const firebase = useFirebase(firebaseConfig)
  return (
    <div className='flex h-svh w-svw flex-col'>
      <NavigationBarEditor
        trackingProgress={100}
        auth={firebase.auth}
        canExitWatchMode={true}
        documentPermissions='view'
        onFullscreenClicked={() => ''}
        hasPlayersOrFilters={false}
        highlightPlayer={undefined}
        highlightsMode='Highlights'
        isFullScreen={false}
        isPlayingHighlights={'highlights'}
        onWatchModeClicked={() => ''}
        shareDialogOptions={undefined}
        videoController={undefined}
        watchMode={false}
        firebaseDb={firebase.firebaseDb}
        reviewFileProps={{
          reviewId: 'reviewId',
          videoSourceData: { id: 'id', source: 'Youtube' },
        }}
        activityType='dodgeball'
      />
    </div>
  )
}

export function DodgeballStatsLevelDropdown({
  statsTemplateKey = 'basic',
  onChosen,
  className,
}: {
  statsTemplateKey: string | undefined
  onChosen: (level: DodgeballTemplate.DodgeballStatsLevel) => void
  className?: string
}) {
  const chosen = DodgeballTemplate.parseDodgeballStatsLevel(statsTemplateKey ?? 'basic')

  return (
    <div
      className={cn(
        'relative flex flex-row items-center justify-center gap-1 bg-blue-grey px-2 py-1',
        className,
      )}>
      <BackgroundIcon
        src={graphIcon}
        className='h-[1.2em] w-[1.2em]'
      />
      <select
        defaultValue={chosen}
        onChange={(e) => {
          onChosen(e.target.value as DodgeballTemplate.DodgeballStatsLevel)
        }}
        className='h-full w-full border-none bg-blue-grey font-montserrat text-md text-white'>
        <option value='basic'>Basic Stats</option>
        <option value='default'>Advanced Stats</option>
        <option value='kdr'>Live Stats</option>
      </select>
    </div>
  )
}
