'use client'

import * as React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { EventDefinition, resolveStringOrFn } from 'templates/TemplateConfig'
import { ReviewActivityType } from 'ui/ReviewMetaStore'
import { TimelineStore } from 'ui/TimelineStore'
import { useWindowDimensions } from '../UseWindowDimensions'
import { Player } from '../data/Player'
import { isNoteDefinition } from '../data/common'
import { TeamStore } from '../hooks/UseTeamRepo'
import { ChooserPlayerPanel, PlayerState } from '../ui/PlayerPanel'
import { PlayerStore } from '../ui/PlayerStore'
import { PlayerTile } from '../ui/PlayerTile'
import { getRoundAtTime, playersDeadMap } from './RoundStartPlayersPanel'
import { EditableStringDiv } from './common/EditableStringDiv'

type ChooserReason =
  | { type: 'AssignToTimelineEvent'; eventDefinition: EventDefinition; time: number }
  | { type: 'timeline-marker'; eventDefinitionKey: string; time: number }

export type PlayerChooserProps = {
  team: number
  location?: Rect
  displayStyle?: 'above' | 'below'
  onChoose: (player: Player | undefined, nameChange?: string, updateState?: boolean) => void

  reason: ChooserReason
} & ({ matchNumber: number; time?: undefined } | { time: number; matchNumber?: undefined })

export interface Rect {
  left: number
  right: number
  top: number
  bottom: number
}

let globalShowPlayerChooser: ShowPlayerChooser = () => {
  throw Error("Player chooser hasn't been rendered")
}

export const showPlayerChooser = (props: PlayerChooserProps) => {
  globalShowPlayerChooser(props)
}

export type ShowPlayerChooser = (props: PlayerChooserProps) => void
export const PlayerChooser = ({
  activityType,
  teamStore,
  playerStore,
  matchNumberAtTime,
  definitions,
  timelineStore,
}: {
  activityType: ReviewActivityType | undefined
  definitions: EventDefinition[]
  teamStore: TeamStore
  playerStore: PlayerStore
  timelineStore: TimelineStore
  matchNumberAtTime: (time: number) => number
}) => {
  const [props, setProps] = useState<PlayerChooserProps | undefined>()
  const [position, setPosition] = useState<Rect | undefined>()
  const [isCreating, markCreating] = useState<boolean>(false)
  const chooserRef = useRef<ChooserPlayerPanel>(null)
  const dialogRef = useRef<HTMLDivElement>(null)
  const displayStyle = props?.displayStyle || 'below'
  const chooserHeight = 60 // approximate
  const windowDimensions = useWindowDimensions()
  useEffect(() => {
    if (props && position) {
      const onWindowKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          event.stopImmediatePropagation()
          props.onChoose?.(undefined)
          setProps(undefined)
          setPosition(undefined)
          markCreating(false)
        }
      }
      window.addEventListener('keydown', onWindowKeyDown)
      return () => {
        window.removeEventListener('keydown', onWindowKeyDown)
      }
    }
  }, [props, position])

  globalShowPlayerChooser = useCallback(
    async (props: PlayerChooserProps) => {
      const matchNumber = props.matchNumber ?? matchNumberAtTime(props.time)
      if (
        playerStore.players.filter(
          (player) => player.team === props.team && player.matchNumber === matchNumber,
        ).length === 0
      ) {
        // markCreating(true)
        // await playerStore.createPlayer(props.team, matchNumber)
        // props.onChoose(await playerStore.createPlayer(props.team, matchNumber))
        // return
        markCreating(false)
      }
      const rect = props.location ?? {
        top: 0,
        left: windowDimensions.width / 2,
        right: windowDimensions.width / 2,
        bottom: windowDimensions.height / 3,
      }

      setPosition(rect)
      setProps((oldProps) => {
        if (oldProps) {
          oldProps.onChoose(undefined, undefined, false)
        }
        return {
          ...props,
          matchNumber: matchNumber,
          time: undefined,
          onChoose: (player: Player | undefined, nameChange, updateState) => {
            if (player && nameChange) playerStore.updatePlayerName(player, nameChange)
            if (updateState || updateState === undefined) {
              markCreating(false)
              setPosition(undefined)
              setProps(undefined)
            }
            props.onChoose(player)
          },
        }
      })
    },
    [playerStore, matchNumberAtTime, windowDimensions.height, windowDimensions.width],
  )

  const horizontalOffsetRef = useRef({
    horizontalOffset: 0,
    verticalOffset: 0,
  })

  useEffect(() => {
    horizontalOffsetRef.current.horizontalOffset = 0
    horizontalOffsetRef.current.verticalOffset = 0
  }, [position])
  const rect = dialogRef.current?.getBoundingClientRect()
  const isOverRight = (rect?.right ?? 0) > windowDimensions.width
  const isOverLeft = (rect?.left ?? 0) < 0
  const isOverWidth = (rect?.width ?? 0) >= windowDimensions.width - 3 // buffer to allow it to resize

  const isTooCloseToBottom = (rect?.bottom ?? 0) > windowDimensions.height - 100
  const isTooCloseToTop = (rect?.top ?? 0) < 100
  let offsetX: (offset: number) => string = (offset: number) =>
    `calc(${position?.left || 0}px - 50% - ${offset}px)`
  const offsetY: (offset: number) => string = (offset: number) =>
    `calc(${displayStyle === 'below' ? position?.bottom || 0 : (position?.top || 0) - chooserHeight}px - 50% - ${offset}px)`

  //horizontal
  if (isOverWidth) offsetX = () => '0'
  else if (isOverLeft) horizontalOffsetRef.current.horizontalOffset -= 1
  else if (isOverRight) horizontalOffsetRef.current.horizontalOffset += 15

  // vertical
  if (isTooCloseToTop) horizontalOffsetRef.current.verticalOffset -= 1
  else if (isTooCloseToBottom) horizontalOffsetRef.current.verticalOffset += 15

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 10000,
      }}>
      <div
        ref={dialogRef}
        className={'fixed left-0 top-0 z-[1000]'}
        style={{
          translate: `${offsetX(horizontalOffsetRef.current.horizontalOffset)} ${offsetY(horizontalOffsetRef.current.verticalOffset)}`,
        }}>
        {position && props && isCreating && playerStore.players.length >= 1 && (
          <PlayerTile
            focusOnMountIfExpanded={true}
            editMode={true}
            canEnableEditing={true}
            player={playerStore.players[0]}
            displayStyle={'name+icon'}
            size={45}
            onPlayerNameUpdate={() => {
              // do nothing
            }}
            onPlayerDelete={(player) => {
              playerStore.deletePlayer(player.id)
              markCreating(false)
            }}
            onPlayerSubmit={props.onChoose}
            expandedWidth={'200px'}
          />
        )}
        {position && props && !isCreating && (
          <ChooserPlayerPanel
            key='player-chooser-panel'
            title={
              <>
                {props.reason.type === 'AssignToTimelineEvent' && (
                  <>
                    Who on{' '}
                    <TeamName
                      team={props.team}
                      teamStore={teamStore}
                    />{' '}
                    {isNoteDefinition(props.reason.eventDefinition.key) ?
                      <>should read this</>
                    : <b>{resolveStringOrFn(props.reason.eventDefinition.title)}</b>}
                    ?
                  </>
                )}
                {props.reason.type === 'timeline-marker' && (
                  <>
                    Who on{' '}
                    <TeamName
                      team={props.team}
                      teamStore={teamStore}
                    />{' '}
                    {isNoteDefinition(props.reason.eventDefinitionKey) ?
                      <>should read this</>
                    : <b>
                        {resolveStringOrFn(
                          definitions.find(
                            (it) =>
                              props.reason.type === 'timeline-marker' &&
                              it.key === props.reason.eventDefinitionKey,
                          )?.title,
                        ) ?? 'should be assigned'}
                      </b>
                    }
                    ?
                  </>
                )}
              </>
            }
            matchNumber={props.matchNumber ?? matchNumberAtTime(props.time)}
            team={props.team}
            canEditNames={true}
            canEnableEditingNames={true}
            canAddPlayers={true}
            ref={chooserRef}
            playerStore={playerStore}
            onPlayerClick={props.onChoose}
            onPlayerSubmit={props.onChoose}
            handleShortcuts={true}
            onClose={() => {
              markCreating(false)
              props.onChoose?.(undefined)
              setPosition(undefined)
              setProps(undefined)
            }}
            overridePlayerState={
              activityType === 'dodgeball' ?
                (players) => {
                  const roundEvents = getRoundAtTime(
                    timelineStore.sortedEvents.filter((it) => !isNoteDefinition(it.definition_key)),
                    props.reason.time,
                  )
                  const roundPlayerIds = roundEvents
                    .filter((it) => (it.team ?? 0) === props.team)
                    .reduce((acc, event) => {
                      event.who?.forEach((it) => acc.add(it.id))
                      return acc
                    }, new Set<string>())

                  return playersDeadMap(
                    activityType,
                    players,
                    roundEvents.slice(
                      0,
                      roundEvents.findLastIndex((it) => it.time < props.reason.time) + 1,
                    ),
                  )
                    ?.entriesArray()
                    .map<[Player, PlayerState]>(([player, isDead]) => {
                      return [
                        player,
                        isDead ? 'dead'
                        : roundPlayerIds.size >= 6 && !roundPlayerIds.has(player.id) ? 'not-subbed'
                        : undefined,
                      ]
                    })
                    .toMap()
                }
              : undefined
            }
          />
        )}
      </div>
    </div>
  )
}

function TeamName({ team, teamStore }: { teamStore: TeamStore; team: number }) {
  return (
    <>
      {teamStore.teamNames?.[team] ?
        <>
          [<b>{teamStore.teamNames[team]}</b>]
        </>
      : team === teamStore.mainTeam ?
        'your team'
      : 'the other team'}
    </>
  )
}

export interface CommentBoxProps {
  comment?: string
  onChange: (comment?: string) => void
}

export type ShowCommentBox = (element: HTMLElement, props: CommentBoxProps) => void
export const useCommentor = () => {
  const [commentProps, setProps] = useState<CommentBoxProps | undefined>()
  const [position, setPosition] = useState<{ left: number; top: number } | undefined>()
  const ref = useRef<EditableStringDiv>(null)

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === 'NumpadEnter') {
      e.preventDefault()
      ref.current?.blur()
    }
  }

  const onChange = (text: string) => {
    commentProps?.onChange?.(text)
  }

  const onBlur = () => {
    setPosition(undefined)
    setProps(undefined)
  }

  const CommentBox = () => {
    const top = position?.top ?? 0
    const left = position?.left ?? 0
    return (
      <>
        {commentProps && position && (
          <EditableStringDiv
            ref={ref}
            translate={'no'}
            className={'fixed -translate-x-1/2 translate-y-[20px] transform'}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            style={{
              top: position?.top || 0,
              left: position?.left || 0,
              position: 'fixed',
              transform: 'translate(-50%, 20px)',
            }}>
            {commentProps.comment}
          </EditableStringDiv>
        )}
      </>
    )
  }

  const showCommentBox: ShowCommentBox = async (element: HTMLElement, props: CommentBoxProps) => {
    const rect = element.getBoundingClientRect()
    setPosition({ top: rect.top, left: rect.left })
    setProps(() => ({
      comment: props.comment,
      onChange: (comment?: string) => {
        setPosition(undefined)
        setProps(undefined)
        props.onChange(comment)
      },
    }))
  }

  return { CommentBox, showCommentBox }
}
