'use client'

import { GroupSelectionStore, useGroupMembersStore } from 'UseGroupSelectionStore'
import { getBrowserId } from 'components/common/UseUser'
import { useNCNavigate } from 'components/common/nextJs/components/nextCompatibleRouter'
import { showProcessExistingReviewsForVideoSourceDialog } from 'components/showAddReviewsToGroupDialog'
import { User } from 'firebase/auth'
import { parseStatsTemplate, useStatsTemplateStore } from 'hooks/UseStatsTemplateStore'
import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react'
import { NavigateOptions } from 'react-router'
import { toast } from 'react-toastify'
import { showAssignTeamDialog } from 'ui/ShowAssignTeamDialog'
import { useGroupPermissionsStore } from 'useGroupPermissionsStore'
import { CreateReviewFirebaseEntry } from '../AppDataRepository'
import { ReviewsStore } from '../UseRecentsReviewStore'
import { showDialog } from '../components/common/Dialog'
import { FirebaseDb, FirebaseDbReference } from '../components/common/Firebase'
import { useQueryString } from '../components/common/utils/QueryString'
import { randomUUID } from '../components/common/utils/randomUUID'
import {
  combineStatRecordRules,
  getParentStatRecordEntry,
  getParentStatRecordId,
  getStatRecord,
  getStatRecordAlias,
} from '../hooks/UseStatRecordReviewId'
import { showActivityTypeChooseDialog } from '../ui/ChooseActivityType'
import {
  ActivityStatsTemplate,
  ReviewActivityType,
  mapFirebaseFromActivityType,
  mapFirebaseToActivityType,
  useReviewMetaStore,
} from '../ui/ReviewMetaStore'
import { SetViewingModeProps, useDocumentPermissionsStore } from '../ui/UseDocumentPermissionsStore'
import { useReviewSelectionState } from './UseReviewSelectionState'
import { ViewingMode } from './ViewingMode'
import {
  DocumentPermissions,
  FirebaseEventMetaStatsEntry,
  FirebaseGroupEntry,
  FirebaseUserEntry,
  FirebaseVideoSource,
  MetaStatsCache,
  RecentsFirebaseEntry,
  ReviewFirebaseEntry,
  VideoSource,
} from './common'
import { findExactAlias } from './statrecordtypes'
import { NCSendGTMEvent } from 'components/common/nextJs/components/nextCompatibleGoogleTags'

export interface ReviewSelection {
  videoId: string
  reviewId: string
  groupId: string | undefined
  source: VideoSource
  eventId?: string
  initTime: number
}

export type LoadNewVideoProps =
  | {
      reviewId: string
      permissions: DocumentPermissions
      type: 'reviewId'
    }
  | { videoSourceData: VideoSourceData; type: 'videoSourceData' }

interface ReviewSelectionInit {
  selection?: ReviewSelection
  activityType: ReviewActivityType | undefined
  isLocalReview: boolean
  owner: string | undefined
  ownerName: string | undefined
  isCreatedByStaff: boolean | undefined
  videoTitle: string | undefined
  setInitialVideoTitle: (title: string) => void
  viewingMode: ViewingMode
  documentPermissions?: DocumentPermissions
  setViewingMode: (props: SetViewingModeProps) => boolean
  showAnalysis: string | undefined
  setShowAnalysis: (showAnalysis: boolean) => void
  statsTemplate: ActivityStatsTemplate | undefined
}

export interface BroadcastFirebaseEntry {
  reviewId: string | null
  ownerUID: string | null
  ownerName: string | null
  isCreatedByStaff: boolean | null
  videoId: string | null
  source: FirebaseVideoSource | null
  createdTime: number
  title: string | null
  mode: ViewingMode | null
  scheduled: number
  isPublicStatsRecord: boolean | null
  isAttachedToStatsRecord: boolean | null
}

export interface FirebaseUserReviews {
  owned: { [id: string]: RecentsFirebaseEntry & MetaStatsCache } | null
  visited: { [id: string]: RecentsFirebaseEntry & MetaStatsCache } | null
}

export interface FirebaseBroadCastReviews {
  global: { [id: string]: BroadcastFirebaseEntry } | null
}

export type VideoSourceData = {
  id: string
  source: VideoSource
}

export type StatRecordSource = { id: string; source: 'StatRecord' }

export function fromVideoSourceBackToUrl(videoSource: VideoSourceData | StatRecordSource): string {
  switch (videoSource.source) {
    case 'Facebook_url':
      return videoSource.id
    case 'Youtube_url':
      return videoSource.id
    case 'Youtube':
      return `https://www.youtube.com/watch?v=${videoSource.id}`
    case 'StatRecord':
      return `/statsrecord?id=${encodeURIComponent(videoSource.id)}`
  }
}

export async function createNewReview({
  statsTemplate,
  firebaseDb,
  user,
  statRecordSourceData,
  videoSourceData,
  assignedGroupId,
  groupSelectionStore,
}: {
  statsTemplate: ActivityStatsTemplate
  statRecordSourceData: StatRecordSource | undefined
  videoSourceData: VideoSourceData
  user: User | undefined
  firebaseDb: FirebaseDb
  assignedGroupId?: string | undefined
  groupSelectionStore?: GroupSelectionStore | undefined
}) {
  const reviewId = randomUUID()
  await firebaseDb.getRef(`reviews/${reviewId}`).set({
    videoId: videoSourceData.id,
    source: videoSourceData.source,
    activityType: mapFirebaseFromActivityType(statsTemplate.activityType),
    parentStatRecord: statRecordSourceData?.id || null,
    statsTemplateKey: statsTemplate.templateId || null,
  } satisfies CreateReviewFirebaseEntry)

  localStorage.setItem(`videoId:${videoSourceData.id}:${videoSourceData.source}`, reviewId)

  await claimCreateDate(reviewId, firebaseDb)

  NCSendGTMEvent({
    event: 'review_created',
    activity: statsTemplate.activityType,
  })

  if (user) {
    await claimOwnership(reviewId, user, firebaseDb)
  }
  if (assignedGroupId && groupSelectionStore) {
    await groupSelectionStore.addReview(reviewId, assignedGroupId, 'edit')
  }
  return reviewId
}

/**
 * Maps stat record source data to video source data if source is StatRecord.
 *
 * returns null if stat record source data doesn't map to a valid video source
 * @param firebase
 * @param videoSourceData
 * @param activityType
 *
 */
async function mapVideoSourceInfo(
  firebase: FirebaseDb,
  videoSourceData: VideoSourceData | StatRecordSource,
  activityType?: ReviewActivityType,
): Promise<{ videoSourceData: VideoSourceData; activityType?: ReviewActivityType } | undefined> {
  if (videoSourceData.source !== 'StatRecord') {
    return videoSourceData && { videoSourceData: videoSourceData, activityType }
  }

  const entry = await getStatRecord({
    firebase,
    statsRecordId: videoSourceData.id,
  })
  if (!entry) return undefined

  const mappedVideoSource: VideoSourceData = {
    id: entry.videoId,
    source: entry.source,
  }

  return (
    mappedVideoSource && {
      videoSourceData: mappedVideoSource,
      activityType: mapFirebaseToActivityType(entry.activityType),
    }
  )
}
export type ByPassProcessVideoSteps = undefined | false | 'createReview' | 'createReviewANDTeam'

export function parseByPassProcessVideoSteps(s: string | undefined): ByPassProcessVideoSteps {
  if (s === 'createReviewANDTeam') return 'createReviewANDTeam'
  if (s === 'createReview') return 'createReview'
  return undefined
}

export async function processVideoSourceData({
  firebaseDb,
  user,
  videoSourceData,
  activityType,
  reviewStore,
  navigate,
  createNewTeamEnabled,
  getSignInToCreateGroupRedirect,
  byPassCreateReviewSteps,
  existingGroupIds,
}: {
  user: User | undefined
  firebaseDb: FirebaseDb
  activityType?: ReviewActivityType
  reviewStore: ReviewsStore
  navigate: (url: string, opts?: NavigateOptions) => void
  createNewTeamEnabled: boolean
  videoSourceData: StatRecordSource | VideoSourceData
  byPassCreateReviewSteps?: ByPassProcessVideoSteps
  existingGroupIds: string[]
  /** Will automatically confirm and show create review flow
   *
   * @returns a relative url starting with /
   */
  getSignInToCreateGroupRedirect: (
    videoSource: VideoSourceData,
    activityType: ReviewActivityType,
  ) => string
}) {
  const storedReviewId =
    localStorage.getItem(`videoId:${videoSourceData.id}:${videoSourceData.source}`) ||
    // backwards compatibility
    localStorage.getItem(`videoId:${videoSourceData.id}`)

  const mappedVideoSourceData = await mapVideoSourceInfo(firebaseDb, videoSourceData, activityType)

  if (!mappedVideoSourceData) {
    await showDialog({
      title: `Stat Record not found`,
      children: (Red) => {
        return (
          <>
            We couldn&apos;t find that Stat Record. Ask the owner of this Record to create a new
            link for you.
          </>
        )
      },
      positiveButtonProps: 'OK',
      user_dismissable: false,
    })
    return false
  }

  const recent = await reviewStore.findMatchingReview(
    mappedVideoSourceData.videoSourceData,
    existingGroupIds,
  )

  // TODO: Check if the user's team already has a review [PREMIUM]
  const hasExisting =
    (storedReviewId &&
      // There was a momentary bug (couple days) where creating reviews failed but the id's were still stored locally
      (await firebaseDb.getRef(`reviews/${storedReviewId}`).get()).exists()) ||
    recent.length

  if (hasExisting && !byPassCreateReviewSteps) {
    if (storedReviewId && recent.none((it) => it.reviewId === storedReviewId)) {
      const title = await firebaseDb.getVal<string>(`reviews/${storedReviewId}/title`)
      const isCreatedByStaff =
        user && (await firebaseDb.getVal<string>(`flags/users/${user.uid}/staff`))
      const entry = await createRecentReviewEntry({
        reviewOwner: user?.uid,
        reviewOwnerName: user?.displayName ?? undefined,
        videoSourceData: mappedVideoSourceData.videoSourceData,
        title: title,
        reviewId: storedReviewId,
        createdTime: undefined,
        documentPermissions: 'edit',
        firebaseDb,
        isCreatedByStaff: !!isCreatedByStaff,
      })
      recent.push(entry)
    }

    const result = await showProcessExistingReviewsForVideoSourceDialog({
      reviews: recent,
      statsRecordId: videoSourceData.source === 'StatRecord' ? videoSourceData.id : undefined,
      firebaseDb,
      user,
    })

    if (!result.selection) {
      return undefined
    }

    switch (result?.selection?.strategy) {
      case 'open':
        return result.selection.reviewId
      case 'link':
      case 'replace':
        if (videoSourceData.source !== 'StatRecord') return undefined
        await firebaseDb
          .getRef(`reviews/${result.selection.reviewId}/parentStatRecord`)
          .set(videoSourceData.id)
        return result.selection.reviewId
      case 'upgrade_link':
        if (videoSourceData.source !== 'StatRecord') return undefined
        const proposedStatRecord = await getStatRecord({
          firebase: firebaseDb,
          statsRecordId: videoSourceData.id,
        })
        const existingStateRecord = await getParentStatRecordEntry({
          firebase: firebaseDb,
          reviewId: result.selection.reviewId,
        })
        if (!proposedStatRecord || !existingStateRecord) return undefined

        const alias = await getStatRecordAlias({
          firebase: firebaseDb,
          reviewId: existingStateRecord.reviewId,
        })
        if (!alias) return undefined
        const match = findExactAlias(
          alias,
          combineStatRecordRules(proposedStatRecord.rules, existingStateRecord.rules),
        )
        if (!match) return undefined
        await firebaseDb
          .getRef(`reviews/${result.selection.reviewId}/parentStatRecord`)
          .set(match.statRecordId)
        return result.selection.reviewId
      case 'create_new':
        // continue to create new review
        break
    }
  }
  /** CREATE A NEW REVIEW FROM HERE */

  const chosenActivityType =
    mappedVideoSourceData.activityType ?? (await showActivityTypeChooseDialog())

  if (chosenActivityType) {
    const createNewReviewOptions = {
      statsTemplate: parseStatsTemplate(
        chosenActivityType,
        videoSourceData.source === 'StatRecord' ?
          await getStatRecord({
            firebase: firebaseDb,
            statsRecordId: videoSourceData.id,
          }).then((it) => it?.statsTemplateKey ?? null)
        : null,
      ),
      firebaseDb,
      user,
      statRecordSourceData: videoSourceData.source === 'StatRecord' ? videoSourceData : undefined,
      videoSourceData: mappedVideoSourceData.videoSourceData,
    }

    return createNewTeamEnabled || existingGroupIds.length > 0 ?
        await showAssignTeamDialog({
          firebaseDb,
          addGroupAuth: {
            user,
            signInToCreateGroupRedirect: getSignInToCreateGroupRedirect(
              mappedVideoSourceData.videoSourceData,
              chosenActivityType,
            ),
          },
          navigate,
          onTeamAssigned: async (
            assignedGroupId: string | undefined,
            groupSelectionStore: GroupSelectionStore | undefined,
          ) => {
            const reviewId = await createNewReview({
              ...createNewReviewOptions,
              assignedGroupId,
              groupSelectionStore,
            })
            return reviewId
          },
          createTeamOnRender: byPassCreateReviewSteps === 'createReviewANDTeam',
        })
      : await createNewReview(createNewReviewOptions)
  }
  return undefined
}

export function useReviewSelectionInitialisation(
  firebaseDb: FirebaseDb,
  user: User | undefined,
): ReviewSelectionInit {
  const navigate = useNCNavigate()
  const { reviewId, groupId, isNewReview, videoSourceData, isLocalReview } =
    useReviewSelectionState(firebaseDb)
  const [showAnalysis, setShowAnalysis] = useQueryString('showanalysis', undefined)
  const [eventId] = useQueryString('eventid', undefined)
  const [timeParam] = useQueryString('t', undefined)
  const visitCountRecordedRef = useRef(false)
  const reviewMetaStore = useReviewMetaStore(
    firebaseDb,
    user,
    reviewId,
    undefined,
    undefined,
    undefined,
  )
  const {
    viewingMode,
    setViewingMode,
    documentPermissions,
    setDocumentPermissions: setDocumentPermissionsInternal,
    owner,
    ownerName,
    isCreatedByStaff,
  } = useDocumentPermissionsStore({
    activityType: reviewMetaStore.activityType,
    reviewId: reviewId,
    firebaseDb: firebaseDb,
    user: user,
    isLocalReview: isLocalReview,
  })
  const { isMember, blockExternalAccessToReviews } = useGroupPermissionsStore(
    firebaseDb,
    user?.uid,
    groupId,
  )
  const { sendInviteRequest } = useGroupMembersStore(firebaseDb, groupId)

  const storedTitle = reviewMetaStore.title

  const setInitialVideoTitle = useCallback(
    (title: string) => {
      if (reviewId && !storedTitle && title && documentPermissions) {
        reviewMetaStore.changeTitle?.(title, documentPermissions)
      }
    },
    [reviewId, storedTitle, reviewMetaStore, documentPermissions],
  )

  useEffect(() => {
    if (reviewId && isNewReview && user) {
      // NEW REVIEW AND LOGGED IN
      claimOwnership(reviewId, user, firebaseDb)
    }
  }, [reviewId, isNewReview, user, firebaseDb])

  useEffect(() => {
    if (reviewId && isNewReview) {
      // NEW REVIEW AND LOGGED IN
      claimCreateDate(reviewId, firebaseDb)
    }
  }, [reviewId, isNewReview, firebaseDb])

  const { regenerateMeta } = reviewMetaStore

  const showBlockReviewDialog = useCallback(() => {
    showDialog({
      title: `Warning`,
      children: () => {
        return (
          <>
            This review has been locked to a team. Please request an invite to the Playback Team to
            open this review.
          </>
        )
      },
      positiveButtonProps: {
        text: 'OK',
        onClicked: () => {
          navigate('/')
          return true
        },
      },
      negativeButtonProps: {
        text: 'Request Access',
        onClicked: async () => {
          if (!user) {
            toast(`You'll need to sign in first`, { position: 'bottom-right' })
            return false
          }
          await sendInviteRequest(user)
          navigate('/')
          return true
        },
      },
      user_dismissable: false,
    })
  }, [navigate, sendInviteRequest, user])

  useEffect(() => {
    if (!reviewId) return
    firebaseDb.get(`reviews/${reviewId}`).then((reviewSnapshot) => {
      if (
        videoSourceData &&
        (!reviewSnapshot.exists() || !reviewSnapshot.child('videoId').exists())
      ) {
        // set videoId
        firebaseDb.getRef(`reviews/${reviewId}/videoId`).set(videoSourceData.id)
        firebaseDb.getRef(`reviews/${reviewId}/source`).set(videoSourceData.source)
      }

      if (!reviewSnapshot.exists()) return
      const review: ReviewFirebaseEntry = reviewSnapshot.val() as ReviewFirebaseEntry

      if (blockExternalAccessToReviews && !isMember) {
        showBlockReviewDialog()
        return
      }

      repairReviewMetaData({
        review,
        reviewId,
        user,
        videoSourceData,
        firebaseDb,
        title: storedTitle,
        documentPermissions,
        isLocalReview,
      }).then((it) => {
        if (!(reviewId && videoSourceData)) return
        return recordReviewVisit(
          documentPermissions,
          it?.reviewOwner,
          it?.reviewOwnerName,
          videoSourceData,
          it?.actualTitle,
          reviewId,
          it?.createTime,
          user,
          firebaseDb,
          it?.isCreatedByStaff,
          visitCountRecordedRef,
          regenerateMeta,
        )
      })
    })
  }, [
    blockExternalAccessToReviews,
    documentPermissions,
    firebaseDb,
    isLocalReview,
    isMember,
    regenerateMeta,
    reviewId,
    showBlockReviewDialog,
    storedTitle,
    user,
    videoSourceData,
  ])

  const selection: ReviewSelection | undefined = useMemo(() => {
    if (blockExternalAccessToReviews && !isMember) {
      return undefined
    }

    let time: number
    try {
      time = parseInt(timeParam ?? '0')
    } catch (e) {
      time = 0
    }
    return videoSourceData && reviewId ?
        {
          videoId: videoSourceData.id,
          source: videoSourceData.source,
          reviewId,
          groupId,
          eventId,
          initTime: time,
        }
      : undefined
  }, [
    blockExternalAccessToReviews,
    isMember,
    videoSourceData,
    reviewId,
    groupId,
    eventId,
    timeParam,
  ])
  const [statsTemplate] = useStatsTemplateStore(firebaseDb, reviewId)
  return useMemo(
    () => ({
      isLocalReview,
      selection,
      videoTitle: storedTitle,
      activityType: reviewMetaStore.activityType,
      setInitialVideoTitle,
      viewingMode: viewingMode,
      owner,
      ownerName,
      isCreatedByStaff,
      documentPermissions,
      setViewingMode,
      statsTemplate,
      showAnalysis: showAnalysis,
      setShowAnalysis: (showAnalysis) => setShowAnalysis(showAnalysis ? 'true' : undefined),
    }),
    [
      isLocalReview,
      selection,
      storedTitle,
      statsTemplate,
      reviewMetaStore.activityType,
      setInitialVideoTitle,
      viewingMode,
      owner,
      ownerName,
      isCreatedByStaff,
      documentPermissions,
      setViewingMode,
      showAnalysis,
      setShowAnalysis,
    ],
  )
}

async function recordReviewVisit(
  documentPermissions: DocumentPermissions | undefined,
  reviewOwner: string | undefined,
  reviewOwnerName: string | undefined,
  videoSourceData: VideoSourceData,
  title: string | undefined,
  reviewId: string,
  createTime: number | undefined,
  user: User | undefined,
  firebaseDb: FirebaseDb,
  isCreatedByStaff: boolean | undefined,
  visitCountRecorded: MutableRefObject<boolean>,
  regenMeta:
    | (() => Promise<FirebaseEventMetaStatsEntry & { visits: ReviewFirebaseEntry['visits'] }>)
    | undefined,
) {
  if (documentPermissions && user) {
    await recordRecentReview({
      reviewOwner,
      reviewOwnerName,
      videoSourceData,
      title,
      reviewId,
      createdTime: createTime,
      user,
      documentPermissions,
      firebaseDb,
      isCreatedByStaff,
      regenMeta,
    })
  }
  if (!visitCountRecorded.current) {
    visitCountRecorded.current = true
    await addReviewViewedEntry({
      reviewId: reviewId,
      user: user,
      firebaseDb: firebaseDb,
    })
  }
}

async function repairReviewMetaData({
  review,
  reviewId,
  user,
  videoSourceData,
  firebaseDb,
  title,
  isLocalReview,
}: {
  review: ReviewFirebaseEntry
  reviewId: string
  user: User | undefined
  videoSourceData: VideoSourceData | undefined
  firebaseDb: FirebaseDb
  title: string | undefined
  documentPermissions?: DocumentPermissions
  isLocalReview: boolean
}) {
  repairMissingPlayers(review, reviewId, firebaseDb)

  if (!(user && reviewId && videoSourceData)) return
  const { reviewOwner, reviewOwnerName, isCreatedByStaff } = await repairMissingOwner(
    review,
    reviewId,
    user,
    isLocalReview,
    firebaseDb,
  )
  const createTime = await repairMissingCreateTime(review, reviewId, reviewOwner, user, firebaseDb)
  const actualTitle = await repairMissingTitle(
    reviewOwner,
    reviewId,
    title,
    review,
    user,
    firebaseDb,
  )
  await repairMissingVideoSource(
    reviewOwner,
    reviewId,
    videoSourceData.source,
    review,
    user,
    firebaseDb,
  )
  return { reviewOwner, reviewOwnerName, createTime, actualTitle, isCreatedByStaff }
}

async function repairMissingPlayers(
  review: ReviewFirebaseEntry,
  reviewId: string,
  firebaseDb: FirebaseDb,
) {
  if (!review.events) return
  const eventPlayerDictionary = Object.values(review.events)
    .flatMap((event) => event.who || [])
    .reduce(
      (dictionary, currentValue) => {
        if (!dictionary[currentValue.id]) {
          dictionary[currentValue.id] = currentValue
        }
        return dictionary
      },
      { ...review.players },
    )

  if (
    JSON.stringify(Object.keys(review.players || {})) !==
    JSON.stringify(Object.keys(eventPlayerDictionary))
  ) {
    await firebaseDb.getRef(`reviews/${reviewId}/players`).set(eventPlayerDictionary)
  }
}

async function claimOwnership(reviewId: string, user: User, firebaseDb: FirebaseDb) {
  await firebaseDb.getRef(`reviews/${reviewId}/owner`).set(user.uid)
}

async function claimCreateDate(reviewId: string, firebaseDb: FirebaseDb) {
  await firebaseDb.getRef(`reviews/${reviewId}/createdTime`).set(Date.now())
}

async function repairMissingOwner(
  review: ReviewFirebaseEntry,
  reviewId: string,
  user: User,
  isLocalReview: boolean,
  firebaseDb: FirebaseDb,
): Promise<{
  reviewOwner: string | undefined
  reviewOwnerName: string | undefined
  isCreatedByStaff: boolean | undefined
}> {
  const updates: { [key: string]: string | boolean | undefined } = {}

  let reviewOwner = review.owner
  if (!reviewOwner && isLocalReview) {
    reviewOwner = user.uid
    updates['owner'] = user.uid
  }

  let reviewOwnerName = review.ownerName
  if (!reviewOwnerName) {
    reviewOwnerName = user.displayName ?? undefined
    updates['ownerName'] = user.displayName ?? undefined
  }

  let isCreatedByStaff = review.isCreatedByStaff
  if (!isCreatedByStaff) {
    const createdByStaff = await firebaseDb.getVal<string>(`flags/users/${user?.uid}/staff`)
    isCreatedByStaff = !!createdByStaff
    updates['isCreatedByStaff'] = !!createdByStaff
  }

  if (Object.keys(updates).length) {
    await firebaseDb.getRef(`reviews/${reviewId}`).update(updates)
  }

  return { reviewOwner, reviewOwnerName, isCreatedByStaff }
}

async function repairMissingCreateTime(
  review: ReviewFirebaseEntry,
  reviewId: string,
  reviewOwner: string | undefined,
  user: User,
  firebaseDb: FirebaseDb,
): Promise<number | undefined> {
  if (reviewOwner === user.uid && review.createdTime === undefined) {
    const createdTime = Date.now()
    await firebaseDb.getRef(`reviews/${reviewId}/createdTime`).set(createdTime)
    return createdTime
  }
  return review.createdTime ?? undefined
}

async function repairMissingTitle(
  reviewOwner: string | undefined,
  reviewId: string,
  title: string | undefined,
  review: ReviewFirebaseEntry,
  user: User,
  firebaseDb: FirebaseDb,
): Promise<string | undefined> {
  if (reviewOwner === user.uid && title && !review.title) {
    await firebaseDb.getRef(`reviews/${reviewId}/title`).set(title)
    return title
  }
  return review.title ?? undefined
}

async function repairMissingVideoSource(
  reviewOwner: string | undefined,
  reviewId: string,
  source: FirebaseVideoSource | undefined,
  review: ReviewFirebaseEntry,
  user: User,
  firebaseDb: FirebaseDb,
) {
  if (reviewOwner === user.uid && !review.source) {
    await firebaseDb.getRef(`reviews/${reviewId}/source`).set(source ?? null)
  }
}

export async function createRecentReviewEntry({
  reviewOwner,
  reviewOwnerName,
  videoSourceData,
  title,
  reviewId,
  createdTime,
  documentPermissions,
  firebaseDb,
  isCreatedByStaff,
}: {
  reviewOwner: string | undefined
  reviewOwnerName: string | undefined
  videoSourceData: VideoSourceData
  title: string | undefined
  reviewId: string
  createdTime: number | undefined
  documentPermissions: DocumentPermissions
  firebaseDb: FirebaseDb
  isCreatedByStaff: boolean | undefined
}): Promise<RecentsFirebaseEntry> {
  return {
    reviewId: reviewId,
    lastVisited: Date.now(),
    videoId: videoSourceData.id,
    source: videoSourceData.source,
    createdTime: createdTime ?? null,
    ownerUID: reviewOwner || null,
    ownerName: reviewOwnerName || null,
    title: title || null,
    mode: documentPermissions || null,
    isCreatedByStaff: isCreatedByStaff || null,
    isPublicStatsRecord: !!(await getStatRecordAlias({
      firebase: firebaseDb,
      reviewId,
    })),
    isAttachedToStatsRecord:
      (await getParentStatRecordId({
        firebase: firebaseDb,
        reviewId,
      })) ?? null,
  }
}
export async function getReviewEntryRefs({
  reviewOwner,
  user,
  reviewId,
  firebaseDb,
  includeUserRef = true,
}: {
  reviewOwner: string | undefined
  user: User
  reviewId: string
  firebaseDb: FirebaseDb
  includeUserRef?: boolean
}) {
  const refs: FirebaseDbReference<RecentsFirebaseEntry & MetaStatsCache>[] = []

  const groupsMap =
    (await firebaseDb.getVal<ReviewFirebaseEntry['groups']>(`reviews/${reviewId}/groups`)) ?? {}

  Object.keys(groupsMap).map(async (groupId) => {
    refs.push(
      firebaseDb.getRef<RecentsFirebaseEntry & MetaStatsCache>(
        `groups/${groupId}/reviews/${reviewId}`,
      ),
    )
  })

  if (includeUserRef) {
    if (reviewOwner !== user.uid) {
      refs.push(firebaseDb.getRef(`users/${user.uid}/reviews/visited/${reviewId}`))
    } else {
      refs.push(firebaseDb.getRef(`users/${user.uid}/reviews/owned/${reviewId}`))
    }
  }

  return refs
}

async function getReviewGroupsThatImMemberOf(
  firebaseDb: FirebaseDb,
  reviewId: string,
  user: User,
): Promise<string[]> {
  const groupsMap =
    (await firebaseDb.getVal<ReviewFirebaseEntry['groups']>(`reviews/${reviewId}/groups`)) ?? {}
  const myGroups = new Set(
    Object.keys(
      (await firebaseDb
        .getRef<FirebaseUserEntry>(`users/${user.uid}`)
        .childFromKey('groups')
        .getVal()) ?? {},
    ),
  )

  const matchingGroupsPromises = Object.keys(groupsMap).map(async (groupId) => {
    if (!myGroups.has(groupId)) {
      return null
    }
    const editingUsers = await firebaseDb
      .getRef<FirebaseGroupEntry>(`groups/${groupId}`)
      .childFromKey('editingUsers')
      .getVal()
    const members = await firebaseDb
      .getRef<FirebaseGroupEntry>(`groups/${groupId}`)
      .childFromKey('members')
      .getVal()
    const uid = await firebaseDb
      .getRef<FirebaseGroupEntry>(`groups/${groupId}`)
      .childFromKey('owner')
      .getVal()

    const groupMembers = new Set(Object.keys(Object.assign(editingUsers ?? {}, members ?? {})))
    if (uid) groupMembers.add(uid)

    if (!groupMembers.has(user.uid)) return null

    return groupId
  })

  return (await Promise.all(matchingGroupsPromises)).filterNotNull()
}
async function recordRecentReview({
  reviewOwner,
  reviewOwnerName,
  videoSourceData,
  title,
  reviewId,
  createdTime,
  user,
  documentPermissions,
  firebaseDb,
  isCreatedByStaff,
  regenMeta: regenMeta,
}: {
  reviewOwner: string | undefined
  reviewOwnerName: string | undefined
  videoSourceData: VideoSourceData
  title: string | undefined
  reviewId: string
  createdTime: number | undefined
  user: User
  documentPermissions: DocumentPermissions
  firebaseDb: FirebaseDb
  isCreatedByStaff: boolean | undefined
  regenMeta:
    | (() => Promise<FirebaseEventMetaStatsEntry & { visits: ReviewFirebaseEntry['visits'] }>)
    | undefined
}) {
  const refs = await getReviewEntryRefs({
    reviewOwner,
    user,
    reviewId,
    firebaseDb,
    // If the review is in a group, we should not record it in the user's recent reviews.
    includeUserRef: (await getReviewGroupsThatImMemberOf(firebaseDb, reviewId, user)).length === 0,
  })

  const recentsEntry: RecentsFirebaseEntry = await createRecentReviewEntry({
    reviewOwner,
    reviewOwnerName,
    videoSourceData,
    title,
    reviewId,
    createdTime,
    documentPermissions,
    firebaseDb,
    isCreatedByStaff,
  })

  const meta = (await regenMeta?.()) ?? null
  await Promise.all(
    refs.map((ref) =>
      ref
        .getVal()
        .then((it) => {
          const newentry = {
            ...(it ?? {}),
            ...recentsEntry,
            metaStatsUserCache: {
              ...(it?.metaStatsUserCache ?? {}),
              [user.uid]: { commentCount: meta?.commentCount ?? 0 },
            } satisfies MetaStatsCache['metaStatsUserCache'],
            metaStats: meta,
          } satisfies RecentsFirebaseEntry & MetaStatsCache
          return ref.set(newentry)
        })
        .catch((e) => {
          console.warn('Error recording recent review', e)
        }),
    ),
  )
}

async function addReviewViewedEntry({
  reviewId,
  user,
  firebaseDb,
}: {
  reviewId: string
  user: User | undefined
  firebaseDb: FirebaseDb
}) {
  await firebaseDb
    .getRef(`reviews/${reviewId}/visits/${user ? user.uid : `anon_${getBrowserId()}`}`)
    .runTransaction((it) => {
      const views = it as number | null
      return (views ?? 0) + 1
    })
}
