import '@stripe/stripe-js'
import { DialogServer } from 'components/common/Dialog'
import { PwaContextProvider } from 'components/common/InstallPwa'
import NCRouter from 'components/common/nextJs/components/nextCompatibleRouter'
import { ReactNode, StrictMode } from 'react'
import { DefaultFlagContextClientProvider } from './DefaultFlagContextProvider'
import ToastContainer from './ToastContainer'
import { DndProviderDynamic } from './dndProviderDynamic'
import TourProviderClient from './tourProvider'
import VhProvider from './vhProvider'

export interface NextLayoutProps {
  children: ReactNode
  slug?: string[]
}

export default function AppBaseWrappers({ children }: NextLayoutProps) {
  return (
    <StrictMode>
      <NCRouter>
        <DefaultFlagContextClientProvider>
          <DndProviderDynamic>
            <PwaContextProvider>
              <VhProvider>
                <TourProviderClient>{children}</TourProviderClient>
              </VhProvider>
              <DialogServer />
              <ToastContainer />
            </PwaContextProvider>
          </DndProviderDynamic>
        </DefaultFlagContextClientProvider>
      </NCRouter>
    </StrictMode>
  )
}

export function FullPageContainer({ children }: NextLayoutProps) {
  return (
    <div className='app-container h-svh w-svw touch-none overflow-clip overscroll-x-none'>
      {children}
    </div>
  )
}

export function BodyScrollingContainer({ children }: NextLayoutProps) {
  return (
    <div className='relative h-svh min-h-fit'>
      {children}
    </div>
  )
}
