import { StatRecord } from 'data/statrecordtypes'
import { User } from 'firebase/auth'
import { Dispatch, useCallback, useEffect, useMemo, useState } from 'react'
import {
  EventDefinition,
  POINT_LOSE_DEFINITION,
  POINT_WIN_DEFINITION,
} from 'templates/TemplateConfig'
import { v4 as randomUUID } from 'uuid'
import { TimelineStorage } from '../AppDataRepository'
import { LOCAL_USER } from '../components/Timeline'
import {
  FirebaseDb,
  FirebaseDbReference,
  useDatabaseRefLiveValue,
} from '../components/common/Firebase'
import { PropType, mergeSortedLists } from '../components/common/utils/typescriptUtils'
import { Player } from '../data/Player'
import { POINT_DEFINITION, ROUND_END } from '../data/StatsStore'
import { BaseTimelineEvent, TimelineEvent, TimelineNote } from '../data/TimelineEvent'
import {
  TimelineMarkerFirebaseDictionary,
  TimelineMarkerFirebaseEntry,
  TimelineMarkerNoteFirebaseEntry,
  TimelineReplyFirebaseEntry,
  isNoteDefinition,
} from '../data/common'
import { useParentStatRecord } from '../hooks/UseStatRecordReviewId'
import { PlayerStore } from './PlayerStore'

export interface TimelineRepo {
  updateEvent: (
    changing: TimelineEvent<EventDefinition>,
    isLocalReview: boolean,
    user?: User,
  ) => Promise<void>
  updateEventSeenDate: (eventId: TimelineEvent<EventDefinition>['id'], user: User) => Promise<void>
  addReply: (
    eventId: TimelineEvent<EventDefinition>['id'],
    reply: string,
    user: User,
  ) => Promise<void>
  removeReplies: (
    eventId: TimelineEvent<EventDefinition>['id'],
    replyIds: string[],
    user: User,
  ) => Promise<void>
  addEvent: (
    time: number,
    definition: EventDefinition,
    team: number,
    isLocalReview: boolean,
    user?: User,
    who?: Player,
    parentNoteId?: string,
  ) => Promise<TimelineEvent<EventDefinition>>
  getEvent: (
    id: PropType<TimelineEvent<EventDefinition>, 'id'>,
  ) => TimelineEvent<EventDefinition> | undefined
  // range must be less than 3 seconds
  getEventsAtTime: (startTime: number, endTime: number) => TimelineEvent<EventDefinition>[]
  deleteEvent: (
    id: PropType<TimelineEvent<EventDefinition>, 'id'>,
  ) => Promise<TimelineEvent<EventDefinition> | undefined>
  removePlayer: (id: PropType<Player, 'id'>) => Promise<void>
  sortedEvents: TimelineEvent<EventDefinition>[]
  eventsCreatedThisSession: PropType<TimelineEvent<EventDefinition>, 'id'>[]
}

export type TimelineStore = TimelineRepo & {
  selectedEvents: SelectedEventState[]
  setSelectedEvents: SetSelectedEvents
  sortedReviewEvents: TimelineEvent<EventDefinition>[]
  sortedStatRecordEvents: TimelineEvent<EventDefinition>[]
}

export type SelectedEventState = {
  id: PropType<TimelineEvent<EventDefinition>, 'id'>
  hovered: boolean
}

export type SetSelectedEvents = Dispatch<
  SelectedEventState[] | ((selectedEvents: SelectedEventState[]) => SelectedEventState[])
>

const timeChunkSize = 3 //seconds
function calculateTimeChunk(time: number) {
  return Math.floor(Math.ceil(time / timeChunkSize) * timeChunkSize * 100) / 100
}

export const FILTER_STAT_RECORD_EVENTS =
  (statRecord: StatRecord | undefined) => (it: TimelineEvent<EventDefinition>) => {
    return (
      !isNoteDefinition(it.definition_key) &&
      (statRecord === undefined ||
        (statRecord.rules[it.team] && statRecord.rules[it.team] !== 'hidden') ||
        isRoundEndDefinition(it.definition_key))
    )
  }
export const FILTER_STAT_RECORD_PLAYERS =
  (statRecord: StatRecord | undefined) => (player: Player) => {
    return (
      statRecord?.rules === undefined ||
      (statRecord.rules[player.team] && statRecord.rules[player.team] !== 'hidden')
    )
  }

export function useFirebaseWatcherTimelineStore({
  statRecord,
  initEventId,
  firebase,
  playerState,
}: {
  statRecord: StatRecord | undefined
  initEventId: string | undefined
  firebase: FirebaseDb
  playerState: PlayerStore
}): TimelineStore {
  const statsRecordRepo = useFirebaseTimelineRepo({
    eventsRef: useMemo(
      () => (statRecord && firebase.getRef(`reviews/${statRecord.reviewId}/events`)) || undefined,
      [statRecord, firebase],
    ),
    playerState,
    source: 'stat_record',
  })

  const {
    addEvent: statsRecordAddEvent,
    addReply: statsRecordAddReply,
    deleteEvent: statsRecordDeleteEvent,
    eventsCreatedThisSession: statsRecordEventsCreatedThisSession,
    getEvent: statsRecordGetEvent,
    getEventsAtTime: statsRecordGetEventsAtTime,
    removePlayer: statsRecordRemovePlayer,
    removeReplies: statsRecordRemoveReplies,
    sortedEvents: statsRecordSortedEvents,
    updateEvent: statsRecordUpdateEvent,
    updateEventSeenDate: statsRecordUpdateEventSeenDate,
  } = statsRecordRepo

  const [selectedEvents, setSelectedEvents] = useState<SelectedEventState[]>([])

  useEffect(() => {
    if (initEventId) setSelectedEvents([{ id: initEventId, hovered: false }])
  }, [initEventId])

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code !== 'Escape') {
        return
      }
      if (
        selectedEvents?.length &&
        (document.activeElement?.tagName?.toLowerCase() === 'body' ||
          document.activeElement?.tagName === undefined)
      ) {
        setSelectedEvents([])
      }
    }
    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  }, [selectedEvents])

  const filteredStatRecordSortedEvents = useMemo(
    () => statsRecordSortedEvents.filter(FILTER_STAT_RECORD_EVENTS(statRecord)),
    [statRecord, statsRecordSortedEvents],
  )
  const sortedEvents = filteredStatRecordSortedEvents

  return useMemo(() => {
    return {
      selectedEvents: selectedEvents,
      setSelectedEvents: setSelectedEvents,
      sortedEvents,
      sortedReviewEvents: [],
      sortedStatRecordEvents: filteredStatRecordSortedEvents,
      addEvent: () => {
        return Promise.reject()
      },
      addReply: () => {
        return Promise.resolve()
      },
      deleteEvent: () => {
        return Promise.reject()
      },
      eventsCreatedThisSession: [],
      getEvent: () => {
        return undefined
      },
      getEventsAtTime: () => {
        return []
      },
      removePlayer: () => {
        return Promise.resolve()
      },
      removeReplies: () => {
        return Promise.resolve()
      },
      updateEvent: () => {
        return Promise.resolve()
      },
      updateEventSeenDate: () => {
        return Promise.resolve()
      },
    } satisfies TimelineStore
  }, [selectedEvents, sortedEvents, filteredStatRecordSortedEvents])
}

export function useFirebaseTimelineStore({
  reviewId,
  initEventId,
  firebase,
  playerState,
}: {
  reviewId: string | undefined
  initEventId: string | undefined
  firebase: FirebaseDb
  playerState: PlayerStore
}): TimelineStore {
  const statRecord = useParentStatRecord({ firebase, reviewId })

  const statsRecordRepo = useFirebaseTimelineRepo({
    eventsRef: useMemo(
      () => (statRecord && firebase.getRef(`reviews/${statRecord.reviewId}/events`)) || undefined,
      [statRecord, firebase],
    ),
    playerState,
    source: 'stat_record',
  })

  const {
    addEvent: statsRecordAddEvent,
    addReply: statsRecordAddReply,
    deleteEvent: statsRecordDeleteEvent,
    eventsCreatedThisSession: statsRecordEventsCreatedThisSession,
    getEvent: statsRecordGetEvent,
    getEventsAtTime: statsRecordGetEventsAtTime,
    removePlayer: statsRecordRemovePlayer,
    removeReplies: statsRecordRemoveReplies,
    sortedEvents: statsRecordSortedEvents,
    updateEvent: statsRecordUpdateEvent,
    updateEventSeenDate: statsRecordUpdateEventSeenDate,
  } = statsRecordRepo

  const [selectedEvents, setSelectedEvents] = useState<SelectedEventState[]>([])
  const reviewRepo = useFirebaseTimelineRepo({
    eventsRef: useMemo(
      () => (reviewId && firebase.getRef(`reviews/${reviewId}/events`)) || undefined,
      [reviewId, firebase],
    ),
    source: 'local',
    playerState,
  })
  const {
    addEvent: reviewAddEvent,
    addReply: reviewAddReply,
    deleteEvent: reviewDeleteEvent,
    eventsCreatedThisSession: reviewEventsCreatedThisSession,
    getEvent: reviewGetEvent,
    getEventsAtTime: reviewGetEventsAtTime,
    removePlayer: reviewRemovePlayer,
    removeReplies: reviewRemoveReplies,
    sortedEvents: reviewSortedEvents,
    updateEvent: reviewUpdateEvent,
    updateEventSeenDate: reviewUpdateEventSeenDate,
  } = reviewRepo

  useEffect(() => {
    if (initEventId) setSelectedEvents([{ id: initEventId, hovered: false }])
  }, [initEventId])

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code !== 'Escape') {
        return
      }
      if (
        selectedEvents?.length &&
        (document.activeElement?.tagName?.toLowerCase() === 'body' ||
          document.activeElement?.tagName === undefined)
      ) {
        setSelectedEvents([])
      }
    }
    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  }, [selectedEvents])

  const filteredStatRecordSortedEvents = useMemo(
    () => statsRecordSortedEvents.filter(FILTER_STAT_RECORD_EVENTS(statRecord)),
    [statRecord, statsRecordSortedEvents],
  )

  const sortedEvents = useMemo(() => {
    const result = mergeSortedLists(
      reviewSortedEvents,
      filteredStatRecordSortedEvents,
      (it) => it.time,
    ).distinctBy((it) => it.id)
    return result
  }, [reviewSortedEvents, filteredStatRecordSortedEvents])

  return useMemo(() => {
    return {
      addEvent: async (time, definition, team, isLocalReview, user, who, parentNoteId) => {
        const newEvent = await reviewAddEvent(
          time,
          definition,
          team,
          isLocalReview,
          user,
          who,
          parentNoteId,
        )
        setSelectedEvents([{ id: newEvent.id, hovered: false }])
        return newEvent
      },
      addReply: reviewAddReply,
      deleteEvent: reviewDeleteEvent,
      eventsCreatedThisSession: reviewEventsCreatedThisSession,
      getEvent: reviewGetEvent,
      getEventsAtTime: reviewGetEventsAtTime,
      removePlayer: reviewRemovePlayer,
      removeReplies: reviewRemoveReplies,
      selectedEvents: selectedEvents,
      setSelectedEvents: setSelectedEvents,
      sortedReviewEvents: reviewSortedEvents,
      sortedEvents,
      sortedStatRecordEvents: filteredStatRecordSortedEvents,
      updateEvent: reviewUpdateEvent,
      updateEventSeenDate: reviewUpdateEventSeenDate,
    }
  }, [
    reviewAddEvent,
    reviewAddReply,
    reviewDeleteEvent,
    reviewEventsCreatedThisSession,
    reviewGetEvent,
    reviewGetEventsAtTime,
    reviewRemovePlayer,
    reviewRemoveReplies,
    reviewSortedEvents,
    reviewUpdateEvent,
    reviewUpdateEventSeenDate,
    selectedEvents,
    sortedEvents,
    filteredStatRecordSortedEvents,
  ])
}

export function isRoundEndDefinition(key: string) {
  return ROUND_END.includes(key)
}

export function useFirebaseTimelineRepo({
  eventsRef,
  playerState,
  source,
}: {
  source: TimelineEvent<EventDefinition>['source']
  eventsRef: FirebaseDbReference | undefined
  playerState: PlayerStore
}): TimelineRepo {
  const timelineMarkerEntries = useDatabaseRefLiveValue({
    ref: eventsRef,
  }) as TimelineMarkerFirebaseDictionary | undefined

  const [eventIdsAddedThisSession, setEventIdsAddedThisSession] = useState<
    PropType<TimelineEvent<EventDefinition>, 'id'>[]
  >([])

  const mapToTimelineEvent = useCallback(
    (entry: TimelineMarkerFirebaseEntry): TimelineEvent<EventDefinition> => {
      const event = TimelineStorage.mapFirebaseToTimelineEvent(source)(entry)
      const players =
        event.who?.length ?
          (event.who.map((player) => playerState.getPlayer(player.id)).filter((i) => i) as Player[])
        : undefined
      return {
        ...event,
        who: (players?.length && players) || undefined,
      }
    },
    [playerState, source],
  )
  const events: TimelineEvent<EventDefinition>[] = useMemo(() => {
    if (!timelineMarkerEntries) return []
    return Object.values(timelineMarkerEntries)
      .orderBy((it) => it.time)
      .map(mapToTimelineEvent)
  }, [timelineMarkerEntries, mapToTimelineEvent])

  const timeChunkedEvents: Map<number, TimelineEvent<EventDefinition>[]> = useMemo(() => {
    return events.reduce((map, event) => {
      const alignedTime = calculateTimeChunk(event.time)
      const alignedEvents = map.get(alignedTime)
      if (!alignedEvents) {
        map.set(alignedTime, [event])
        return map
      }

      alignedEvents.push(event)
      return map
    }, new Map<number, TimelineEvent<EventDefinition>[]>())
  }, [events])

  const spreadTimeCollisions = useCallback(
    async (
      team: number,
      cachedEvents?: {
        [id: string]: TimelineMarkerFirebaseEntry
      },
    ) => {
      if (!eventsRef) return
      const map: { [time: number]: TimelineMarkerFirebaseEntry[] } = {}
      const moveBackIncrementSeconds = 0.1875
      let events: { [id: string]: TimelineMarkerFirebaseEntry }
      if (!cachedEvents) {
        const snapshot = await eventsRef.get()
        events = snapshot.val() as TimelineMarkerFirebaseDictionary
      } else {
        events = cachedEvents
      }

      function alignTime(time: number) {
        return (
          Math.floor(Math.ceil(time / moveBackIncrementSeconds) * moveBackIncrementSeconds * 100) /
          100
        )
      }

      Object.values(events)
        .filter((e) => (e.team ?? 0) === team)
        .orderBy((it) => it.time)
        .forEach((i) => {
          const alignedTime = alignTime(i.time)
          if (!map[alignedTime]) {
            map[alignedTime] = []
          }
          map[alignedTime].push(i)
        })
      let anyUpdate = false
      Object.values(map)
        .filter((i) => i.length > 1)
        .forEach((collisions) => {
          collisions
            .orderBy((it) => it.time)
            .forEach((event, index, array) => {
              anyUpdate = true
              const moveBackSteps = array.length - index - 1
              const moveBackTime = moveBackSteps * moveBackIncrementSeconds
              const finalTime = alignTime(event.time) - moveBackTime
              eventsRef.child(event.id).runTransaction((event) => ({
                ...event,
                time: finalTime,
              }))
              events[event.id].time = finalTime
            })
        })
      if (anyUpdate) {
        await spreadTimeCollisions(team, events)
      }
    },
    [eventsRef],
  )

  const addEvent = useCallback(
    async (
      time: number,
      definition: EventDefinition,
      team: number,
      isLocalReview: boolean,
      user?: User,
      who?: Player,
      parentNoteId?: string,
    ) => {
      if (!isLocalReview && !user) throw new Error('Not signed in on a non-local review')

      let mappedDefinition: EventDefinition = definition
      let mappedTeam: number = team
      if (POINT_DEFINITION.includes(definition.key) && team === 1) {
        mappedTeam = 0
        switch (definition.key) {
          case POINT_WIN_DEFINITION.key:
            mappedDefinition = POINT_LOSE_DEFINITION
            break
          case POINT_LOSE_DEFINITION.key:
            mappedDefinition = POINT_WIN_DEFINITION
            break
        }
      }

      const baseProps: BaseTimelineEvent<EventDefinition> = {
        ...mappedDefinition,
        id: randomUUID(),
        time,
        tag: mappedDefinition,
        who: who && [who],
        definition_key: mappedDefinition.key,
        team: mappedTeam,
        createdDate: Date.now(),
        modifiedDate: Date.now(),
        seenBy: user ? { [user.uid]: Date.now() } : {},
        createBy: user ? { uid: user.uid, displayName: user.displayName } : { ...LOCAL_USER },
        specialType: undefined,
        modifiedBy: [],
        source,
      }

      let newEvent: TimelineEvent<EventDefinition>
      if (mappedDefinition.key === 'note' || mappedDefinition.key === 'note_sketch') {
        newEvent = {
          ...baseProps,
          specialType: 'note',
          reactedDate: undefined,
          replies: {},
          definition_key: mappedDefinition.key,
          parentNoteId: parentNoteId,
        } satisfies TimelineNote<EventDefinition>
      } else {
        newEvent = baseProps
      }

      setEventIdsAddedThisSession((array) => array.concat([newEvent.id]))
      eventsRef
        ?.child(newEvent.id)
        .set(TimelineStorage.mapFirebaseFromTimelineEvent(newEvent))
        .then(() => spreadTimeCollisions(0))
        .then(() => spreadTimeCollisions(1))
      return newEvent
    },
    [source, spreadTimeCollisions, eventsRef],
  )
  const updateEvent = useCallback(
    async (changing: TimelineEvent<EventDefinition>, isLocalReview: boolean, user?: User) => {
      if (!((isLocalReview || user) && changing.source === source)) return
      const modifiedEvent: TimelineEvent<EventDefinition> = {
        ...changing,
        modifiedDate: Date.now(),
        modifiedBy: changing.modifiedBy
          .concat(
            user ?
              {
                uid: user.uid,
                displayName: user.displayName,
              }
            : { ...LOCAL_USER },
          )
          .distinctBy((it) => it.uid),
        seenBy: {
          ...changing.seenBy,
          ...(user?.uid && { [user.uid]: Date.now() }),
        },
        who: changing.who?.length ? changing.who : undefined,
      }
      if (!eventsRef && process.env.NODE_ENV === 'development') {
        console.error('eventsRef is undefined')
      }
      if (eventsRef) {
        await eventsRef.child(changing.id).runTransaction(() => ({
          ...TimelineStorage.mapFirebaseFromTimelineEvent(modifiedEvent),
        }))

        await spreadTimeCollisions(0)
        await spreadTimeCollisions(1)
      }
    },
    [eventsRef, spreadTimeCollisions, source],
  )
  const updateEventSeenDate = useCallback(
    async (eventId: TimelineEvent<EventDefinition>['id'], user: User) => {
      if (!eventsRef && process.env.NODE_ENV === 'development') {
        console.error('eventsRef is undefined')
      }
      await eventsRef?.child(eventId).runTransaction(
        (it) =>
          it &&
          ({
            ...it,
            seenBy: { ...it.seenBy, [user.uid]: Date.now() },
          } satisfies TimelineMarkerFirebaseEntry),
      )
    },
    [eventsRef],
  )
  const addReply = useCallback(
    async (eventId: TimelineEvent<EventDefinition>['id'], message: string, user: User) => {
      const newReplyEntry: TimelineReplyFirebaseEntry = {
        id: randomUUID(),
        message: message,
        createBy: { uid: user.uid, displayName: user.displayName },
        createdDate: Date.now(),
        modifiedBy: null,
        modifiedDate: Date.now(),
      }
      if (!eventsRef && process.env.NODE_ENV === 'development') {
        console.error('eventsRef is undefined')
      }
      await eventsRef?.child(eventId).runTransaction(
        (it) =>
          it &&
          ({
            ...it,
            replies: { ...it.replies, [newReplyEntry.id]: newReplyEntry },
            reactedDate: Date.now(),
            seenBy: { ...it.seenBy, [user.uid]: Date.now() },
          } satisfies TimelineMarkerFirebaseEntry),
      )
    },
    [eventsRef],
  )

  const removeReplies = useCallback(
    async (eventId: TimelineEvent<EventDefinition>['id'], replyIds: string[]) => {
      if (!eventsRef && process.env.NODE_ENV === 'development') {
        console.error('eventsRef is undefined')
      }
      await eventsRef?.child(eventId).runTransaction((it) => {
        if (!it) return it
        const modifiedReplies = { ...it.replies }
        replyIds.forEach((id) => delete modifiedReplies[id])
        return {
          ...it,
          replies: { ...modifiedReplies },
        } satisfies TimelineMarkerFirebaseEntry
      })
    },
    [eventsRef],
  )
  const deleteEvent = useCallback(
    async (id: PropType<TimelineEvent<any>, 'id'>) => {
      if (!eventsRef || !timelineMarkerEntries) return undefined
      const hasChildren = (
        events: TimelineEvent<EventDefinition>[],
        id: PropType<TimelineEvent<any>, 'id'>,
      ) => {
        return events.some((e) => e.specialType === 'note' && e.parentNoteId === id)
      }
      const handleDelete = async (
        id: PropType<TimelineEvent<any>, 'id'>,
        events: TimelineEvent<EventDefinition>[],
      ) => {
        const deletedEvent = events.find((e) => e.id === id)
        if (!deletedEvent) return undefined
        if (deletedEvent.specialType !== 'note') {
          await eventsRef.child(id).remove()
          return deletedEvent
        }
        const hasChild = hasChildren(events, id)
        const updatedEvent = { extra: null, isDeleted: hasChild }
        if (hasChild) {
          await eventsRef.child(`${id}`).update(updatedEvent)
        } else {
          await eventsRef.child(`${id}`).remove()
          const restEvents = events.filter((e) => e.id !== id)
          if (deletedEvent?.parentNoteId) {
            const parentNote = restEvents.find((e) => e.id === deletedEvent.parentNoteId)
            if (parentNote && parentNote?.isDeleted && !hasChildren(restEvents, parentNote.id)) {
              await handleDelete(parentNote.id, restEvents)
            }
          }
        }
        return deletedEvent
      }
      return handleDelete(id, events)
    },
    [events, eventsRef, timelineMarkerEntries],
  )

  const getEvent = useCallback(
    (id: PropType<TimelineEvent<any>, 'id'>) =>
      timelineMarkerEntries && timelineMarkerEntries[id] ?
        mapToTimelineEvent(timelineMarkerEntries[id])
      : undefined,
    [timelineMarkerEntries, mapToTimelineEvent],
  )
  const getEventsAtTime = useCallback(
    (startTime: number, endTime: number) => {
      const events = (timeChunkedEvents.get(calculateTimeChunk(startTime)) ?? []).concat(
        timeChunkedEvents.get(calculateTimeChunk(endTime)) ?? [],
      )

      return events
        .distinctBy((it) => it.id)
        .orderBy((it) => it.time)
        .filter((it) => it.time < endTime && it.time >= startTime)
    },
    [timeChunkedEvents],
  )

  const removePlayer = useCallback(
    async (id: PropType<Player, 'id'>) => {
      if (!timelineMarkerEntries) return
      const playersEvents: TimelineMarkerFirebaseEntry[] = Object.values(
        timelineMarkerEntries,
      ).filter((i) => i.who?.filter((i) => i.id === id)?.length)
      if (!eventsRef && process.env.NODE_ENV === 'development') {
        console.error('eventsRef is undefined')
      }
      playersEvents.forEach((event) => {
        const newWho = event.who?.filter((p) => p.id !== id)
        eventsRef?.child(`${event.id}`).set({
          ...event,
          who: newWho,
        })
      })
    },
    [eventsRef, timelineMarkerEntries],
  )
  return useMemo(
    () => ({
      addEvent,
      updateEvent,
      updateEventSeenDate,
      addReply,
      removeReplies,
      deleteEvent,
      getEvent,
      getEventsAtTime,
      sortedEvents: events,
      removePlayer,
      eventsCreatedThisSession: eventIdsAddedThisSession,
    }),
    [
      addEvent,
      updateEvent,
      updateEventSeenDate,
      deleteEvent,
      getEvent,
      getEventsAtTime,
      events,
      removePlayer,
      eventIdsAddedThisSession,
      addReply,
      removeReplies,
    ],
  )
}

export function mergeDistinctWithOverride<T>({
  overrider,
  base,
  overrideBy,
}: {
  overrider: T[]
  base: T[]
  overrideBy: (item: T) => any
}) {
  return [...base, ...overrider].reduce((acc, item) => {
    const key = overrideBy(item)
    const index = acc.findIndex((i) => overrideBy(i) === key)
    if (index !== -1) {
      acc[index] = item
    } else {
      acc.push(item)
    }
    return acc
  }, [] as T[])
}
