'use client'

import dynamic from 'components/common/nextJs/components/nextCompatibleDynamic'

const ToastContainerComponent = dynamic(
  () => import('react-toastify').then((mod) => mod.ToastContainer),
  {
    ssr: true,
  },
)

export default function ToastContainer() {
  return <ToastContainerComponent style={{ zIndex: 100000 }} />
}
