'use client'

import { DocumentPermissions, FirebaseNameChangeEntry, ReviewFirebaseEntry } from 'data/common'
import { matchingStatRecordTeamRules } from 'data/statrecordtypes'
import { User } from 'firebase/auth'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import {
  FirebaseComponents,
  FirebaseDbReference,
  useDatabaseRef,
  useDatabaseRefLiveValue,
  useDatabaseRefLiveValueMemo,
} from '../components/common/Firebase'
import { PlayerStore } from '../ui/PlayerStore'
import { TimelineStore, isRoundEndDefinition } from '../ui/TimelineStore'
import { useProFeaturesStore } from './UseProFeaturesStore'
import { useParentStatRecord } from './UseStatRecordReviewId'

export type TeamStore = Omit<TeamRepo, 'teamToHide'> & {
  setBothTeamAutoTrackingEnabled: (value: ((prevState: boolean) => boolean) | boolean) => void
  bothTeamAutoTrackingEnabled: boolean
  teamsToShow: number[]
  teamsAvailable: number[]
  mainTeam: number
  otherTeam: number
}

export type TeamRepo = {
  setTeamName: (
    team: number,
    teamName: string,
    request?: boolean,
    approve?: boolean,
  ) => Promise<void>
  teamNames:
    | {
        [id: number]: string | undefined
      }
    | undefined
  requestedTeamNameChanges:
    | {
        [id: string]: FirebaseNameChangeEntry
      }
    | undefined
  teamToHide: number | undefined
  setTeamToHide: (team: number | undefined) => Promise<void> | undefined
  enableMultipleTeams: (enable: boolean) => Promise<void> | undefined
  /**
   * multiple teams are present in the stats
   */
  hasMultipleTeams: boolean
  /**
   * either user chose to have multiple teams or multiple teams are present in the stats
   */
  multipleTeamsEnabled: boolean
}

export function useTeamStore(props: {
  documentPermissions: DocumentPermissions | undefined
  firebase: FirebaseComponents
  reviewId: string | undefined
  timelineStore: TimelineStore
  playerStore: PlayerStore
  user: User | undefined
}): TeamStore {
  const [bothTeamAutoTrackingEnabled, setBothTeamAutoTrackingEnabled] = useState(false)

  const { user, reviewId, firebase } = props
  const reviewRef = useDatabaseRef<ReviewFirebaseEntry>(firebase.firebaseDb, `reviews/${reviewId}`)
  const parentStatRecord = useParentStatRecord({
    firebase: firebase.firebaseDb,
    reviewId: reviewId,
  })
  const parentStatReviewRef = useDatabaseRef<ReviewFirebaseEntry>(
    firebase.firebaseDb,
    parentStatRecord?.reviewId && `reviews/${parentStatRecord?.reviewId}`,
  )
  const {
    hasMultipleTeams: reviewHasMultipleTeams,
    teamNames: reviewTeamNames,
    requestedTeamNameChanges: reviewRequestedTeamNameChanges,
    setTeamName: reviewSetTeamName,
    teamToHide,
    setTeamToHide,
    enableMultipleTeams,
    multipleTeamsEnabled: reviewMultipleTeamsEnabled,
  } = useTeamRepo({ ...props, reviewRef })

  const {
    hasMultipleTeams: parentHasMultipleTeams,
    teamNames: parentTeamNames,
    requestedTeamNameChanges: parentRequestedTeamNameChanges,
    multipleTeamsEnabled: parentMultipleTeamsEnabled,
  } = useTeamRepo({
    ...props,
    reviewRef: parentStatReviewRef,
  })

  const filteredParentTeamsAvailable = useMemo(() => {
    const teams: number[] | undefined =
      parentStatRecord && matchingStatRecordTeamRules(parentStatRecord, 'visible')

    if (!teams?.length) return undefined
    return teams
  }, [parentStatRecord])

  const filteredParentMultipleTeamsEnabled =
    parentMultipleTeamsEnabled &&
    !!filteredParentTeamsAvailable &&
    filteredParentTeamsAvailable.length > 1

  const filteredParentHasMultipleTeams =
    parentHasMultipleTeams && !!filteredParentTeamsAvailable?.length

  const teamsAvailable: number[] = useMemo(
    () =>
      filteredParentMultipleTeamsEnabled || reviewMultipleTeamsEnabled ?
        [0, 1]
      : (filteredParentTeamsAvailable ?? [0]),
    [filteredParentMultipleTeamsEnabled, filteredParentTeamsAvailable, reviewMultipleTeamsEnabled],
  )

  const teamsToShow: number[] = useMemo(
    () => teamsAvailable.filter((it) => it !== teamToHide),
    [teamToHide, teamsAvailable],
  )

  const {
    featureFlags: { autoTrackBothTeamsEnabled },
  } = useProFeaturesStore({
    user,
    reviewId,
  })

  const mainTeam = useMemo(
    () =>
      teamsToShow.length === 1 ? teamsToShow[0]
      : parentStatRecord ?
        (matchingStatRecordTeamRules(parentStatRecord, 'visible').firstOrNull() ?? 0)
      : 0,
    [parentStatRecord, teamsToShow],
  )

  const otherTeam = Math.abs(1 - mainTeam)
  return useMemo(
    () => ({
      teamNames: { ...parentTeamNames, ...reviewTeamNames },
      requestedTeamNameChanges: {
        ...parentRequestedTeamNameChanges,
        ...reviewRequestedTeamNameChanges,
      },
      setTeamName: reviewSetTeamName,
      teamsToShow,
      setTeamToHide,
      mainTeam,
      otherTeam,
      hasMultipleTeams: reviewHasMultipleTeams || filteredParentHasMultipleTeams,
      teamsAvailable: teamsAvailable,
      bothTeamAutoTrackingEnabled:
        reviewMultipleTeamsEnabled || filteredParentMultipleTeamsEnabled ?
          bothTeamAutoTrackingEnabled
        : false,
      enableMultipleTeams,
      multipleTeamsEnabled: reviewMultipleTeamsEnabled || filteredParentMultipleTeamsEnabled,
      setBothTeamAutoTrackingEnabled:
        autoTrackBothTeamsEnabled ?
          setBothTeamAutoTrackingEnabled
        : () => {
            toast("You'll need assign this review to a 'PRO' League to access this feature", {
              position: 'top-center',
            })
          },
    }),
    [
      parentTeamNames,
      reviewTeamNames,
      parentRequestedTeamNameChanges,
      reviewRequestedTeamNameChanges,
      reviewSetTeamName,
      teamsToShow,
      setTeamToHide,
      mainTeam,
      otherTeam,
      reviewHasMultipleTeams,
      filteredParentHasMultipleTeams,
      teamsAvailable,
      reviewMultipleTeamsEnabled,
      filteredParentMultipleTeamsEnabled,
      bothTeamAutoTrackingEnabled,
      enableMultipleTeams,
      autoTrackBothTeamsEnabled,
    ],
  )
}

export function useTeamRepo({
  user,
  reviewRef,
  playerStore,
  timelineStore,
  documentPermissions,
}: {
  user: User | undefined
  documentPermissions: DocumentPermissions | undefined
  reviewRef: FirebaseDbReference<ReviewFirebaseEntry> | undefined
  timelineStore: TimelineStore
  playerStore: PlayerStore
}): TeamRepo {
  const teamNamesRef = useMemo(() => reviewRef?.childFromKey('teamNames'), [reviewRef])
  const requestedTeamNameChangesRef = useMemo(
    () => reviewRef?.childFromKey('requestedTeamNameChanges'),
    [reviewRef],
  )
  const bothTeamEnabledRef = useMemo(() => reviewRef?.childFromKey('bothTeams'), [reviewRef])
  const teamToHideRef = useMemo(() => reviewRef?.childFromKey('teamToHide'), [reviewRef])
  const [userSelectedTeamToHide, setUserSelectedTeamToHide] = useState<number | undefined>(
    undefined,
  )

  const hasBothTeams = useMemo(() => {
    return (
      (playerStore.players.any((it) => it.team === 1) &&
        playerStore.players.any((it) => it.team === 0)) ||
      (timelineStore.sortedEvents.any(
        (event) => event.team === 1 && !isRoundEndDefinition(event.definition_key),
      ) &&
        timelineStore.sortedEvents.any(
          (event) => event.team === 0 && !isRoundEndDefinition(event.definition_key),
        ))
    )
  }, [timelineStore, playerStore])

  const bothTeamsEnabled = useDatabaseRefLiveValue({ ref: bothTeamEnabledRef }) ?? hasBothTeams
  const teamToHide = useDatabaseRefLiveValue({ ref: teamToHideRef }) ?? undefined

  const teamNamesString = useDatabaseRefLiveValue({
    ref: teamNamesRef,
  })

  const requestedTeamNameChanges =
    useDatabaseRefLiveValue({
      ref: requestedTeamNameChangesRef,
    }) ?? undefined

  useEffect(() => {
    setUserSelectedTeamToHide(teamToHide ?? undefined)
  }, [teamToHide])

  const teamNames = useMemo(() => {
    return (
      (teamNamesString &&
        Object.entries(teamNamesString).reduce(
          (previousValue, [team, name]) => {
            previousValue[parseInt(team)] = name ?? undefined
            return previousValue
          },
          {} as { [id: number]: string | undefined },
        )) ??
      undefined
    )
  }, [teamNamesString])

  const setTeamName = useCallback(
    async (team: number, teamName: string, request?: boolean, approve?: boolean) => {
      if (!teamNamesRef || !requestedTeamNameChangesRef) {
        if (process.env.NODE_ENV === 'development') {
          console.error(
            `Error: ${!teamNamesRef ? 'teamNamesRef' : 'requestedTeamNameChangesRef'} is undefined`,
          )
        }
        return
      }

      if (!request || typeof approve === 'boolean') {
        const teamId = team.toString()

        await Promise.all([
          requestedTeamNameChangesRef.child(teamId).remove(),
          teamNamesRef.child(teamId).set(
            typeof approve === 'boolean' ?
              approve ? teamName
              : (teamNames?.[team] ?? null)
            : (teamName ?? null),
          ),
        ])
      } else if (user) {
        await requestedTeamNameChangesRef.child(team.toString()).set({
          changes: teamName,
          dateSubmitted: Date.now(),
          ownerEmail: user.email,
          ownerName: user.displayName,
          ownerUid: user.uid,
        } satisfies FirebaseNameChangeEntry)
      }
    },
    [teamNames, teamNamesRef, requestedTeamNameChangesRef, user],
  )
  const enableMultipleTeams = useCallback(
    (enable: boolean) => {
      return bothTeamEnabledRef?.set(enable)
    },
    [bothTeamEnabledRef],
  )

  const setTeamToHide = useCallback(
    (team: number | undefined) => {
      if (documentPermissions === 'edit') return teamToHideRef?.set(team ?? null)
      else {
        setUserSelectedTeamToHide(team)
        return Promise.resolve()
      }
    },
    [teamToHideRef, documentPermissions],
  )

  return useMemo(
    () => ({
      setTeamName,
      teamNames,
      requestedTeamNameChanges,
      teamToHide: documentPermissions === 'edit' ? teamToHide : userSelectedTeamToHide,
      setTeamToHide,
      enableMultipleTeams,
      multipleTeamsEnabled: bothTeamsEnabled,
      hasMultipleTeams: hasBothTeams,
    }),
    [
      setTeamName,
      teamNames,
      requestedTeamNameChanges,
      documentPermissions,
      teamToHide,
      userSelectedTeamToHide,
      setTeamToHide,
      enableMultipleTeams,
      bothTeamsEnabled,
      hasBothTeams,
    ],
  )
}

export function getShortTeamName(teamStore: TeamStore, team: number) {
  return (
    teamStore.teamNames?.[team]?.trim()?.split(/\s+/).firstOrNull() ??
    (team === teamStore.mainTeam ? 'Home' : 'Away')
  )
}
