'use client'

import { CheckCircleIcon } from '@chakra-ui/icons'
import { CloseButton } from '@chakra-ui/react'
import { FirebaseNameChangeEntry } from 'data/common'
import { CSSProperties, PropsWithChildren, useCallback, useMemo } from 'react'
import { TeamStore } from '../hooks/UseTeamRepo'
import { EditableStringDiv } from './common/EditableStringDiv'
import { cn } from './common/utils/tailwindUtils'

export function LiveTeamEntry({
  teamStore,
  team,
  editable,
  canEnableEditing,
  placeholder,
  groupTeamNames,
  isGroupMember,
  canRequestNameChange,
  enableRequestNameChange,
}: {
  placeholder?: string
  teamStore: TeamStore
  editable: boolean
  canEnableEditing: boolean
  team: number
  groupTeamNames?: string[] | undefined
  isGroupMember?: boolean | undefined
  canRequestNameChange?: boolean | undefined
  enableRequestNameChange?: boolean | undefined
}) {
  const handleTeamNameUpdate = useCallback(
    (nameChange: string, approve?: boolean) => {
      enableRequestNameChange ?
        teamStore.setTeamName(team, nameChange, canRequestNameChange && !isGroupMember, approve)
      : teamStore.setTeamName(team, nameChange)
    },
    [canRequestNameChange, enableRequestNameChange, isGroupMember, team, teamStore],
  )

  return (
    <TeamEntry
      canEnableEditing={canEnableEditing}
      hideRequestChangeActions={!isGroupMember}
      editable={editable}
      teamName={teamStore.teamNames?.[team]}
      requestedTeamNameChange={teamStore.requestedTeamNameChanges?.[team]?.changes}
      placeholder={placeholder}
      groupTeamNames={groupTeamNames}
      onChange={() => {
        // do nothing
      }}
      onSubmit={handleTeamNameUpdate}
    />
  )
}

export function TeamEntry(
  props: InlinePillProps & {
    teamName: string | undefined
    requestedTeamNameChange?: string | undefined
    hideRequestChangeActions?: boolean
  },
) {
  return (
    <>
      {props.requestedTeamNameChange ?
        <div
          translate={'no'}
          className='text-[#FF4136] line-through'>
          {props.teamName}
        </div>
      : undefined}
      <InlinePill {...props}>{props.requestedTeamNameChange ?? props.teamName}</InlinePill>
      {props.requestedTeamNameChange && !props.hideRequestChangeActions && (
        <>
          <CheckCircleIcon
            color={'white'}
            className='hover:text-[#2ECC40]'
            onClick={(e) => {
              e.stopPropagation()
              props.onSubmit &&
                props.requestedTeamNameChange &&
                props.onSubmit(props.requestedTeamNameChange, true)
            }}
          />
          <CloseButton
            className='hover:text-[#2ECC40]'
            onClick={(e) => {
              e.stopPropagation()
              props.onSubmit &&
                props.requestedTeamNameChange &&
                props.onSubmit(props.requestedTeamNameChange, false)
            }}
          />
        </>
      )}
    </>
  )
}

export type InlinePillProps = {
  backgroundColor?: CSSProperties['backgroundColor']
  placeholder?: string
  className?: string
  groupTeamNames?: string[] | undefined
} & (
  | {
      canEnableEditing: true
      editable: boolean
      onChange: (changed: string, approve?: boolean) => void
      onSubmit?: (changed: string, approve?: boolean) => void
    }
  | {
      canEnableEditing?: false | undefined
      editable?: boolean
      onChange?: (changed: string, approve?: boolean) => void
      onSubmit?: (changed: string, approve?: boolean) => void
    }
)

export function InlinePill({
  editable,
  canEnableEditing,
  placeholder,
  groupTeamNames,
  children,
  onChange,
  onSubmit,
  className,
  backgroundColor = '#284277',
}: PropsWithChildren<InlinePillProps>) {
  return (
    <div className={cn('flex flex-row items-center gap-1 font-league-spartan', className)}>
      {(children || canEnableEditing) && (
        <div className='hover:brightness-125'>
          <EditableStringDiv
            disabled={!editable || (!onChange && !onSubmit)}
            placeholderStyle={{
              color: 'grey',
              border: '1px solid grey',
              backgroundColor: '#353A44',
            }}
            aria-multiline={false}
            blurOnEnter={true}
            placeholder={placeholder}
            onSubmit={onSubmit}
            onChange={onChange}
            suggestions={groupTeamNames}
            style={{
              backgroundColor,
              padding: '2px 8px',
              borderRadius: '10px',
              color: 'white',
              whiteSpace: 'nowrap',
            }}>
            {children}
          </EditableStringDiv>
        </div>
      )}
    </div>
  )
}
