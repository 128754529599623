'use client'

import { ignoreMultiKeyedResultErrors } from 'components/RankingsByDiv'
import { FirebaseGroupEntry, MetaStatsCache, RecentsFirebaseEntry } from 'data/common'
import { User } from 'firebase/auth'
import { limitToFirst, limitToLast, orderByChild } from 'firebase/database'
import getVideoId from 'get-video-id'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  FirebaseDb,
  useDatabaseMultiRefLiveValue,
  useNullableDatabaseRefLiveValueMemo,
} from './components/common/Firebase'
import {
  BroadcastFirebaseEntry,
  FirebaseBroadCastReviews,
  FirebaseUserReviews,
  VideoSourceData,
} from './data/ReviewSelectionInit'

export interface ReviewsStore {
  orderedReviews: (RecentsFirebaseEntry & MetaStatsCache)[] | undefined | null
  setFilterByMe: (filterByMe: boolean) => void
  deleteReview: (entry: RecentsFirebaseEntry) => Promise<void>
  findMatchingReview: (
    videoSource: VideoSourceData,
    includeGroupIds?: string[],
  ) => Promise<(RecentsFirebaseEntry & MetaStatsCache)[]>
  showMoreReviews: () => void
  isShowingMoreReviews: boolean
}

export function useRecentsReviewStore(
  firebaseDb: FirebaseDb,
  user: User | undefined,
  groupId?: string,
): ReviewsStore {
  const [showMoreReviews, setShowMoreReviews] = useState(false)
  useEffect(() => {
    setShowMoreReviews(false)
  }, [groupId])
  const userReviews = useDatabaseMultiRefLiveValue(
    () =>
      user?.uid && !groupId ? getUserReviewMultiKeyRefs({ firebaseDb, user, showMoreReviews }) : [],
    [user?.uid, groupId, showMoreReviews],
  )
  const groupReviews = useNullableDatabaseRefLiveValueMemo(
    () =>
      groupId ?
        firebaseDb.getRef<FirebaseGroupEntry['reviews']>(
          `groups/${groupId}/reviews`,
          orderByChild('lastVisited'),
          showMoreReviews ? undefined : limitToLast(10),
        )
      : undefined,
    [groupId, showMoreReviews],
  )

  const reviewEntries = useMemo(() => {
    if (groupId) {
      if (groupReviews instanceof Error) return null
      return groupReviews
    }
    const reviews = ignoreMultiKeyedResultErrors(userReviews).results
    return { ...reviews.owned, ...reviews.visited }
  }, [groupId, groupReviews, userReviews])

  const deleteReview = useCallback(
    async (entry: RecentsFirebaseEntry) => {
      if (!user) return
      if (groupId) {
        await firebaseDb.getRef(`groups/${groupId}/reviews/${entry.reviewId}`).remove()

        await firebaseDb.getRef(`reviews/${entry.reviewId}/groups/${groupId}`).remove()
        return
      }
      await firebaseDb.getRef(`users/${user.uid}/reviews/owned/${entry.reviewId}`).remove()
      await firebaseDb.getRef(`users/${user.uid}/reviews/visited/${entry.reviewId}`).remove()
    },
    [firebaseDb, user, groupId],
  )
  const reviewStore = useReviewStore(firebaseDb, user, groupId, reviewEntries, deleteReview)
  return useMemo(
    () => ({
      ...reviewStore,
      showMoreReviews: () => setShowMoreReviews(true),
      isShowingMoreReviews: showMoreReviews,
    }),
    [reviewStore, setShowMoreReviews, showMoreReviews],
  )
}

function useReviewStore(
  firebaseDb: FirebaseDb,
  user: User | undefined,
  groupId: string | undefined,
  reviewEntries: { [p: string]: RecentsFirebaseEntry & MetaStatsCache } | undefined | null,
  deleteReview: (entry: RecentsFirebaseEntry) => Promise<void>,
): Omit<ReviewsStore, 'showMoreReviews' | 'isShowingMoreReviews'> {
  const [filterByMe, setFilterByMe] = useState(false)

  const orderedReviews = useMemo(() => {
    return !reviewEntries ? reviewEntries : (
        Object.entries(reviewEntries)
          .filter(([reviewId, entry]) => (filterByMe ? entry.ownerUID === user?.uid : true))
          // Some review entries became broken
          .mapNotNull(([reviewId, entry]) => ({ ...entry, reviewId: reviewId }))
          .orderByDesc((entry) => entry.lastVisited)
      )
  }, [reviewEntries, filterByMe, user])

  const findMatchingReview = useCallback(
    async (videoSource: VideoSourceData, includeGroupIds?: string[]) => {
      const userReviews = (
        await Promise.all(
          getUserReviewMultiKeyRefs({ firebaseDb, user, showMoreReviews: true }).map(async (it) => {
            try {
              return (await it.ref?.getVal()) ?? {}
            } catch (e) {
              if (process.env.NODE_ENV === 'development') console.error(e)
              return {}
            }
          }),
        )
      ).reduce((acc, curr) => ({ ...acc, ...curr }), {})

      let allReviews = { ...userReviews }

      const searchableGroupIds = [groupId, ...(includeGroupIds ?? [])].filterNotNull()
      if (searchableGroupIds.length > 0) {
        const groupReviews = await Promise.all(
          searchableGroupIds.map(async (groupId) => {
            try {
              const reviews = await firebaseDb.getVal<FirebaseGroupEntry['reviews']>(
                `groups/${groupId}/reviews`,
              )
              return reviews ?? {}
            } catch (e) {
              if (process.env.NODE_ENV === 'development') console.error(e)
              return {}
            }
          }),
        )
        groupReviews.forEach((groupReview) => {
          allReviews = { ...allReviews, ...groupReview }
        })
      }

      return Object.values(allReviews).filter(
        (entry) =>
          entry.videoId &&
          ((entry.source === videoSource.source && entry.videoId === videoSource.id) ||
            (entry.source === 'Youtube_url' &&
              videoSource.source === 'Youtube_url' &&
              getVideoId(videoSource.id).id === getVideoId(entry.videoId).id)),
      )
    },
    [firebaseDb, groupId, user],
  )

  return useMemo(
    () => ({
      orderedReviews,
      setFilterByMe,
      findMatchingReview,
      deleteReview,
    }),
    [orderedReviews, setFilterByMe, findMatchingReview, deleteReview],
  )
}

export function useBroadcastedReviewStore(firebaseDb: FirebaseDb) {
  const [reviewEntries, setReviewEntries] = useState<{
    [id: string]: BroadcastFirebaseEntry & MetaStatsCache
  }>({})
  useEffect(() => {
    const unsubscribe = firebaseDb.getRef(`broadcasts`, limitToFirst(2))?.onValue(
      (snapshot) => {
        if (!snapshot.exists()) {
          setReviewEntries({})
          return
        }
        const broadCastReviews: FirebaseBroadCastReviews = snapshot.val()
        setReviewEntries(broadCastReviews.global || {})
      },
      (error) => console.log(error),
      {},
    )
    return () => unsubscribe?.()
  }, [firebaseDb])
  const orderedReviews = useMemo(
    () => Object.values(reviewEntries).orderByDesc((entry) => entry.createdTime),
    [reviewEntries],
  )
  return useMemo(() => ({ broadcastedReviews: orderedReviews }), [orderedReviews])
}

function getUserReviewMultiKeyRefs({
  firebaseDb,
  user,
  showMoreReviews,
}: {
  firebaseDb: FirebaseDb
  user: User | undefined
  showMoreReviews: boolean
}) {
  const results = []
  try {
    results.push({
      ref:
        user?.uid ?
          firebaseDb.getRef<FirebaseUserReviews['owned']>(
            `users/${user.uid}/reviews/owned`,
            orderByChild('lastVisited'),
            showMoreReviews ? undefined : limitToLast(10),
          )
        : undefined,
      key: 'owned',
    })
  } catch (e) {
    if (process.env.NODE_ENV === 'development') console.error(e)
  }
  try {
    results.push({
      ref:
        user?.uid ?
          firebaseDb.getRef<FirebaseUserReviews['visited']>(
            `users/${user.uid}/reviews/visited`,
            orderByChild('lastVisited'),
            showMoreReviews ? undefined : limitToLast(10),
          )
        : undefined,
      key: 'visited',
    })
  } catch (e) {
    if (process.env.NODE_ENV === 'development') console.error(e)
  }
  return results
}
