'use client'

import { StatRecord, StatRecordAlias, findExactAlias } from 'data/statrecordtypes'
import { useCallback, useMemo } from 'react'
import { FirebaseDb } from '../components/common/Firebase'
import { getStatRecordAlias, setStatRecordAlias } from './UseStatRecordReviewId'

export const publishNewBaseRecordAlias = async (
  firebase: FirebaseDb,
  record: Omit<StatRecord, 'rules'>,
): Promise<StatRecordAlias> => {
  const newAllTeamsRecord = {
    ...record,
    rules: {
      0: 'stats_unlocked',
      1: 'stats_unlocked',
    },
  } satisfies StatRecord

  const newWatchOnlyRecord = {
    ...record,
    rules: {
      0: 'stats_locked',
      1: 'stats_locked',
    },
  } satisfies StatRecord

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const allTeamsStatsRecordId = (await firebase.getRef(`stat_records`).push(newAllTeamsRecord)).key!
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const watchOnlyStatsRecordId = (await firebase.getRef(`stat_records`).push(newWatchOnlyRecord))
    .key!

  const newStatRecordAlias = {
    allTeams: allTeamsStatsRecordId,
    watcherOnly: watchOnlyStatsRecordId,
    allLinks: {
      [allTeamsStatsRecordId]: newAllTeamsRecord.rules,
      [watchOnlyStatsRecordId]: newWatchOnlyRecord.rules,
    },
  } satisfies StatRecordAlias

  await setStatRecordAlias({
    firebase,
    reviewId: record.reviewId,
    statRecordAlias: newStatRecordAlias,
  })
  return newStatRecordAlias
}

export const publishAsRecord = async (
  firebase: FirebaseDb,
  record: StatRecord,
): Promise<{ alias: StatRecordAlias; matchingStatRecordId: string }> => {
  const existingStatRecordAlias =
    (await getStatRecordAlias({
      firebase,
      reviewId: record.reviewId,
    })) ?? (await publishNewBaseRecordAlias(firebase, record))

  const existingLink = findExactAlias(existingStatRecordAlias, record.rules)

  if (existingLink)
    return { alias: existingStatRecordAlias, matchingStatRecordId: existingLink.statRecordId }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const newStatsRecordId = (await firebase.getRef(`stat_records`).push(record)).key!

  const newStatRecordAlias = {
    ...existingStatRecordAlias,
    allLinks: {
      ...existingStatRecordAlias.allLinks,
      [newStatsRecordId]: record.rules,
    },
  } satisfies StatRecordAlias

  await setStatRecordAlias({
    firebase,
    reviewId: record.reviewId,
    statRecordAlias: newStatRecordAlias,
  })
  return { alias: newStatRecordAlias, matchingStatRecordId: newStatsRecordId }
}

export function useStatsRecordStore(firebase: FirebaseDb) {
  const publishAsRecordHook = useCallback(
    async (record: StatRecord) => {
      return publishAsRecord(firebase, record)
    },
    [firebase],
  )

  return useMemo(() => ({ publishAsRecord: publishAsRecordHook }), [publishAsRecordHook])
}
