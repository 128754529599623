'use client'

import { useCallback, useMemo, useRef } from 'react'
import { VideoController } from './VideoController'
import { DEFAULT_VIDEO_PLAYER_STATUS, VideoPlayerStatus } from './VideoPlayerStatus'
import { useMutableRef } from './common/utils/reactUtils'

type Resolver<T> = (value: T | PromiseLike<T>) => void

export function useQueuedVideoController() {
  const [videoControllerRef, setInternalVideoController] = useMutableRef<VideoController>()
  const videoControllerReadyResolver = useRef<{ resolve?: Resolver<void> }>({})
  const videoControllerReadyPromiseState = useMemo(
    () => ({
      promise: new Promise<void>((resolve, reject) => {
        videoControllerReadyResolver.current.resolve = resolve
      }),
    }),
    [],
  )
  const queuedCommandsRef = useRef<{
    play?: 'play' | 'pause'
    time?: number
    rate?: number
    promise: Promise<void>
  }>({
    promise: videoControllerReadyPromiseState.promise,
  })
  const queuedVideoController: VideoController = useMemo(() => {
    return {
      async seekTo(time: number): Promise<void> {
        await videoControllerRef.current?.seekTo(time)
        queuedCommandsRef.current.time = time
        return queuedCommandsRef.current.promise
      },
      async scrubTo(time: number): Promise<void> {
        await videoControllerRef.current?.scrubTo(time)
        queuedCommandsRef.current.time = time
        return queuedCommandsRef.current.promise
      },
      async pause(): Promise<void> {
        try {
          await videoControllerRef.current?.pause()
        } catch (e) {
          console.error(e)
        }
        queuedCommandsRef.current.play = 'pause'
        return queuedCommandsRef.current.promise
      },
      async play(): Promise<void> {
        await videoControllerRef.current?.play()
        queuedCommandsRef.current.play = 'play'
        return queuedCommandsRef.current.promise
      },
      async seekRelative(time: number): Promise<void> {
        await videoControllerRef.current?.seekRelative(time)
        queuedCommandsRef.current.time = Math.max(time + (queuedCommandsRef.current.time ?? 0), 0)
        return queuedCommandsRef.current.promise
      },
      async setPlaybackRate(suggestedRate: number): Promise<void> {
        await videoControllerRef.current?.setPlaybackRate(suggestedRate)
        queuedCommandsRef.current.rate = suggestedRate
        return queuedCommandsRef.current.promise
      },
      get currentStatus(): VideoPlayerStatus {
        return videoControllerRef.current?.currentStatus ?? DEFAULT_VIDEO_PLAYER_STATUS
      },
    }
  }, [videoControllerRef])

  const applyQueuedCommands = useCallback(async (player: VideoController) => {
    const queuedControls = queuedCommandsRef.current
    if (queuedControls.play === 'play') {
      await player.play()
    }
    if (queuedControls.play === 'pause') {
      await player.pause()
    }
    if (queuedControls.time !== undefined) {
      await player.seekTo(queuedControls.time)
    }
    if (queuedControls.rate !== undefined) {
      await player.setPlaybackRate(queuedControls.rate)
    }
    videoControllerReadyResolver.current.resolve?.()
  }, [])

  const setVideoController = useCallback(
    async (videoController: VideoController) => {
      setInternalVideoController(videoController)
      await applyQueuedCommands(videoController)
    },
    [applyQueuedCommands, setInternalVideoController],
  )
  return useMemo(
    () => ({
      videoController: queuedVideoController,
      setVideoController,
    }),
    [queuedVideoController, setVideoController],
  )
}
