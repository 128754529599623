'use client'

import { CheckCircleIcon, ChevronDownIcon, ChevronRightIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { CloseButton, Flex } from '@chakra-ui/react'
import { Colors } from 'Colors'
import { FirebaseNameChangeEntry } from 'data/common'
import {
  FirebaseBaseLeaderboardEntry,
  FirebaseGameResult,
  FirebaseGroupLeaderboardEntry,
  FirebaseMeasureResult,
  LeaderboardEntry,
  LeaderboardGameResults,
  NswdlDivFilter,
  ResultDividers,
  RoundInfo,
} from 'data/leaderboardtypes'
import { User } from 'firebase/auth'
import { getReviewWatcherStatRecordId } from 'hooks/UseStatRecordReviewId'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { InView } from 'react-intersection-observer'
import { createNameChangeRequest } from 'ui/PlayerStore'
import { normaliseName } from '../data/NormaliseName'
import { RankedItem, useRankingsResults } from '../hooks/UseRankingsResults'
import searchIcon from '../icons/magnify_graph.png'
import penEditIcon from '../icons/penEditIcon.png'
import playIcon from '../icons/play.png'
import { DocumentTitle } from './DocumentTitle'
import { DraggableScrollBox } from './DraggableScrollBox'
import { PageLoader } from './PageLoader'
import { Measure, MeasureResult } from './RankingsByDiv'
import { useProfileSearchBehaviour } from './RankingsTableFilterStrategy'
import { SearchInput } from './SearchInput'
import { InlinePill, TeamEntry } from './TeamEntry'
import { tryOpenNewTab } from './TryOpenNewTab'
import { EditableStringDiv } from './common/EditableStringDiv'
import { FirebaseComponents, FirebaseDbReference, useDatabaseRef } from './common/Firebase'
import { RoundButton } from './common/RoundButton'
import { useFeatureFlag } from './common/hooks/useFeatureFlags'
import dynamic from './common/nextJs/components/nextCompatibleDynamic'
import { NCImage } from './common/nextJs/components/nextCompatibleImage'
import { useNCNavigate } from './common/nextJs/components/nextCompatibleRouter'
import { cn } from './common/utils/tailwindUtils'
import { NCSendGTMEvent } from './common/nextJs/components/nextCompatibleGoogleTags'

export const MAX_RANKING = 20

const PlayerStatProfileCarousel = dynamic(() => import('./PlayerStatProfileCarousel'))

export function RankingsTable({
  firebase,
  user,
  results,
  isGroupMember,
  measure,
  round: selectedRound,
  division: selectedDivision,
  referrerUrl,
  scrollingParentRef,
  backgroundColor,
  groupId,
  publicLeaderboardId,
  includeForFun,
  leaderboardRef,
  leaderboardTitle,
  onMeasureSelected,
  onDivisionSelected,
  byTeam,
  nameChanges,
}: {
  includeForFun?: boolean
  firebase: FirebaseComponents
  user: User | undefined
  isGroupMember?: boolean
  measure: Measure
  round: RoundInfo | undefined
  division: NswdlDivFilter
  referrerUrl: string
  results: LeaderboardGameResults
  scrollingParentRef?: React.RefObject<Element>
  backgroundColor: string
  onMeasureSelected: (measure: Measure) => void
  onDivisionSelected: (division: NswdlDivFilter) => void
  byTeam?: boolean
  nameChanges: FirebaseBaseLeaderboardEntry['nameChanges']
} & (
  | {
      leaderboardRef: FirebaseDbReference<FirebaseBaseLeaderboardEntry>
      groupId: string | undefined
      publicLeaderboardId: string | undefined
      leaderboardTitle: string | undefined
    }
  | {
      leaderboardRef?: undefined
      groupId?: undefined
      publicLeaderboardId?: undefined
      leaderboardTitle?: undefined
    }
)) {
  const { value: enablePrivateNameChange } = useFeatureFlag('enablePrivateNameChange', true, true)
  const { value: enablePublicNameChange } = useFeatureFlag('enablePublicNameChange', false, true)
  const [searchPlayerTerm, setSearchPlayerTerm] = useState<string>()
  const [searchTeamsTerm, setSearchTeamsTerm] = useState<string>()

  const {
    rankings,
    rankingsByMeasure,
    filterdCarouselRankingsByMeasure,
    previousRankings,
    top3Record,
    filteredTableRankings,
  } = useRankingsResults(
    results,
    selectedRound,
    selectedDivision,
    measure,
    searchPlayerTerm,
    searchTeamsTerm,
    byTeam,
  )

  const {
    selectedPlayerProfile,
    setSelectedPlayerProfile,
    selectedTeamProfile,
    setSelectedTeamProfile,
  } = useProfileSearchBehaviour({
    searchPlayerTerm,
    searchTeamsTerm,
    rawRankings: rankings,
    byTeam,
  })

  const rowheight = isMobile ? 48 : 60

  const [topGameRecordsToggle, setShowTopGameRecords] = useState(false)

  const privateLeaderboardRef = useDatabaseRef<FirebaseGroupLeaderboardEntry>(
    firebase.firebaseDb,
    `groups/${groupId}/leaderboard`,
  )
  const publicLeaderboardRef = useDatabaseRef<FirebaseBaseLeaderboardEntry>(
    firebase.firebaseDb,
    `public_leaderboards/${publicLeaderboardId}`,
  )

  const handleChangePlayerName = useCallback(
    async (
      reviews: (FirebaseGameResult & { dividers: ResultDividers })[],
      existingPlayerName: string,
      name: string,
      approve?: boolean,
    ) => {
      if (name === existingPlayerName) return

      if (!privateLeaderboardRef) {
        process.env.NODE_ENV === 'development' &&
          console.error('privateLeaderboardRef is undefined')
        return
      }

      if (isGroupMember || typeof approve === 'boolean') {
        const newName =
          typeof approve === 'boolean' ?
            approve ? name
            : existingPlayerName
          : name

        reviews.forEach(async ({ reviewId, playerId }) => {
          const reviewPlayerRef = firebase.firebaseDb.getRef(
            `reviews/${reviewId}/players/${playerId}`,
          )
          if (!reviewPlayerRef) {
            process.env.NODE_ENV === 'development' && console.error('reviewPlayerRef is undefined')
            return
          }

          await privateLeaderboardRef
            .child('nameChanges')
            .child(existingPlayerName)
            .update({
              ...createNameChangeRequest(newName, user),
              accepted: true,
            })
          await reviewPlayerRef.child('name').set(newName)
          await reviewPlayerRef.child('requestedChanges').child('name').remove()
        })
      } else if (user) {
        if (!publicLeaderboardRef) {
          process.env.NODE_ENV === 'development' &&
            console.error('publicLeaderboardRef is undefined')
          return
        }

        const nameChangeRequest = createNameChangeRequest(name, user)

        await publicLeaderboardRef
          .child('nameChanges')
          .child(existingPlayerName)
          .set({ ...nameChangeRequest })
        await privateLeaderboardRef
          .child('nameChanges')
          .child(existingPlayerName)
          .set({ ...nameChangeRequest })
      }
    },
    [firebase.firebaseDb, isGroupMember, privateLeaderboardRef, publicLeaderboardRef, user],
  )

  return (
    <Flex
      direction={'column'}
      alignItems={'stretch'}
      style={{
        color: 'white',
        fontFamily: 'LeagueSpartan, sans-serif',
        width: '100%',
      }}>
      <InView
        threshold={0}
        rootMargin='-200px'
        root={scrollingParentRef?.current}>
        {({ inView, ref, entry }) => (
          <div
            ref={ref}
            className='flex w-full flex-row justify-center'>
            {entry?.boundingClientRect.top &&
              (entry?.rootBounds?.top ?? 0) > entry?.boundingClientRect.top && (
                <MeasureDividersTitle
                  className='fixed top-10 z-10'
                  measure={measure}
                  selectedDivision={selectedDivision}
                  selectedRound={selectedRound}
                />
              )}
          </div>
        )}
      </InView>
      {groupId && selectedPlayerProfile && !byTeam && (
        <DocumentTitle title={`Player Profile - ${selectedPlayerProfile} | ${leaderboardTitle}`}>
          <Suspense fallback={<PageLoader />}>
            <PlayerStatProfileCarousel
              MAX_RANKING={MAX_RANKING}
              firebase={firebase}
              user={user}
              groupId={groupId}
              leaderboardTitle={leaderboardTitle}
              selectedDivision={selectedDivision}
              selectedPlayer={selectedPlayerProfile}
              selectedMeasure={measure}
              selectedRound={selectedRound}
              includeForFun={includeForFun}
              rankingsByMeasure={filterdCarouselRankingsByMeasure.mapValues((it) =>
                it
                  .filter((it) => it.player !== undefined)
                  .map((it) => ({
                    ...it,
                    item: it.player,
                  })),
              )}
              onClose={() => setSelectedPlayerProfile(undefined)}
              isGroupMember={isGroupMember}
              leaderboardRef={leaderboardRef}
              onMeasureSelected={onMeasureSelected}
              onDivisionSelected={onDivisionSelected}
              onPlayerSelected={(player) => setSelectedPlayerProfile(player)}
            />
          </Suspense>
        </DocumentTitle>
      )}
      {top3Record && !measure.disableTopGameRecords && !searchPlayerTerm && !searchTeamsTerm && (
        <div className='flex flex-col items-center gap-6'>
          <span className='flex flex-row items-center gap-2'>
            <RoundButton
              className='h-fit w-fit rounded-none bg-slate-800 py-1 text-white'
              alt={''}
              onClick={() => setShowTopGameRecords((it) => !it)}>
              {topGameRecordsToggle ? 'Hide 1 Game Records' : 'Show 1 Game Records'}
            </RoundButton>
          </span>
          {topGameRecordsToggle && (
            <>
              <span className='self-center opacity-50'>
                The tops results achieved within one game
              </span>
              <Flex
                direction={'column'}
                w={'100%'}
                maxW={500}
                gap={rowheight / 6}>
                {top3Record.map((record, index) => (
                  <RankRow
                    height={rowheight}
                    prefix={<div className='w-12' />}
                    resultBackgroundColor={backgroundColor}
                    key={index}
                    displayName={record.displayName}
                    rank={record.displayRank.toString()}
                    result={record.total?.displayValue ?? record.total?.value?.toString() ?? '--'}
                    subtitle={index === 0 ? 'Top 1 game records' : undefined}
                    resultLabel={index === 0 ? measure.title : undefined}
                    user={user}
                    className='w-[calc(100%-20px)] pl-5'
                    isGroupMember={isGroupMember}
                    expandedContent={
                      <div className='flex flex-col gap-6 pl-8'>
                        {record.player && (
                          <div className='flex w-full flex-row items-center justify-center'>
                            <RoundButton
                              className='w-fit rounded-md bg-slate-200 bg-gradient-to-r from-slate-600 to-slate-800 text-white'
                              onClick={() => {
                                NCSendGTMEvent({
                                  event: 'leaderboard_view_player_profile_clicked',
                                  category: 'leaderboard',
                                })
                                setSelectedPlayerProfile(record.player)
                              }}>
                              See Player Profile
                            </RoundButton>
                          </div>
                        )}
                        <ScrollableReviewResultsList
                          referrerUrl={referrerUrl}
                          measure={measure}
                          firebase={firebase}
                          reviews={record.reviews}
                          enableReviewNavigation={!!isGroupMember}
                          className='pl-[60px]'
                        />
                      </div>
                    }
                    enablePrivateNameChange={false}
                    enablePublicNameChange={false}
                  />
                ))}
              </Flex>
            </>
          )}
        </div>
      )}

      <Flex
        direction={'column'}
        marginBottom={60}
        gap={rowheight / 3}>
        {filteredTableRankings(rankingsByMeasure.get(measure))?.map((ranking, index) => {
          const previousRanking = previousRankings?.find(
            (it) => it.player === ranking.player,
          )?.displayRank
          return (
            <RankRow
              height={rowheight}
              playerLabel='Player'
              subtitle={index === 0 ? 'Rank' : undefined}
              resultLabel={index === 0 ? measure.title : undefined}
              displayName={ranking.displayName}
              user={user}
              isGroupMember={isGroupMember}
              resultBackgroundColor={backgroundColor}
              nameChanges={nameChanges}
              onChange={(name, approve) =>
                handleChangePlayerName(ranking.reviews, ranking.displayName, name, approve)
              }
              prefix={
                ((ranking.displayRank <= MAX_RANKING ||
                  (previousRanking && previousRanking <= MAX_RANKING)) && (
                  <RankingChangeIndicator
                    oldRanking={previousRanking ?? undefined}
                    ranking={ranking.displayRank}
                  />
                )) || <div style={{ width: 20, minWidth: 20 }} />
              }
              className='w-[calc(100%-20px)] pl-5'
              rank={ranking.displayRank <= MAX_RANKING ? ranking.displayRank.toString() : '--'}
              result={ranking.total?.displayValue ?? ranking.total?.value?.toString() ?? '--'}
              expandedContent={
                <div className='flex flex-col gap-6'>
                  {ranking.player && (
                    <div className='flex w-full flex-row items-center justify-center'>
                      <RoundButton
                        className='w-fit rounded-md bg-slate-200 bg-gradient-to-r from-slate-600 to-slate-800 text-white'
                        onClick={() => setSelectedPlayerProfile(ranking.player)}>
                        See Player Profile
                      </RoundButton>
                    </div>
                  )}
                  <ScrollableReviewResultsList
                    referrerUrl={referrerUrl}
                    measure={measure}
                    firebase={firebase}
                    reviews={ranking.reviews}
                    enableReviewNavigation={!!isGroupMember}
                    className='pl-[60px]'
                  />
                </div>
              }
              key={ranking.player}
              enablePrivateNameChange={enablePrivateNameChange}
              enablePublicNameChange={enablePublicNameChange}
            />
          )
        })}
      </Flex>
      <div
        className={
          'pointer-events-none fixed bottom-0 left-0 z-10 flex w-[100svw] flex-row justify-center gap-2 p-2'
        }>
        <div className='pointer-events-auto flex w-fit max-w-72 flex-row justify-center'>
          <TogglableSearchInput
            onSubmit={setSearchPlayerTerm}
            buttonLabel='Search players'
            placeholder='Enter a player name...'
          />
        </div>
        <div className='pointer-events-auto flex w-fit max-w-72 flex-row justify-center'>
          <TogglableSearchInput
            onSubmit={setSearchTeamsTerm}
            buttonLabel='Search teams'
            placeholder='Enter a team name...'
          />
        </div>
      </div>
    </Flex>
  )
}

function TogglableSearchInput(props: {
  onSubmit: (value: string | undefined) => void
  className?: string
  buttonLabel?: string
  placeholder?: string
}) {
  const [showSearch, setShowSearch] = useState(false)

  return (
    <>
      {!showSearch && (
        <RoundButton
          className={cn('w-fit', props.className)}
          icon={searchIcon}
          alt={''}
          backgroundColor={Colors.color_playback_crimson}
          color={'white'}
          iconInvert={true}
          onClick={() => setShowSearch(true)}>
          {props.buttonLabel ?? 'Filter'}
        </RoundButton>
      )}
      {showSearch && (
        <SearchInput
          className='w-full'
          autoFocus={true}
          onChange={(e) => props.onSubmit(e.currentTarget.value)}
          placeholder={props.placeholder ?? 'Enter a search term...'}
          submitText={'Filter'}
          onSubmit={(e) => props.onSubmit(e)}
          onClose={() => {
            setShowSearch(false)
            props.onSubmit(undefined)
          }}
        />
      )}
    </>
  )
}
export function MeasureDividersTitle({
  measure,
  selectedDivision,
  selectedRound,
  className,
}: {
  measure: Measure
  selectedDivision: NswdlDivFilter
  selectedRound: RoundInfo | undefined
  className?: string
}) {
  return (
    <div
      className={cn(
        'rounded-full bg-slate-800 bg-opacity-80 px-4 text-2xl shadow-sm font-bold *:text-playback-crimson',
        className,
      )}
      style={{ maskImage: '' }}>
      {measure.title} <span>|</span> {selectedDivision.title} <span>|</span> {selectedRound?.name}
    </div>
  )
}
export function RankingChangeIndicator({
  ranking,
  oldRanking,
}: {
  ranking: number
  oldRanking: number | undefined
}) {
  const upBy = oldRanking && ranking < oldRanking ? oldRanking - ranking : 0
  const downBy = oldRanking && ranking > oldRanking ? ranking - oldRanking : 0
  const marginY = -8
  return (
    (oldRanking &&
      (ranking < oldRanking ?
        <Flex
          direction={'column'}
          className='items-center'>
          {upBy > 2 && (
            <ChevronUpIcon
              color={Colors.color_green}
              width={20}
              height={20}
              marginY={marginY}
            />
          )}
          {upBy > 1 && (
            <ChevronUpIcon
              color={Colors.color_green}
              width={20}
              height={20}
              marginY={marginY}
            />
          )}
          {upBy > 0 && (
            <ChevronUpIcon
              color={Colors.color_green}
              width={20}
              height={20}
              marginY={marginY}
            />
          )}
          {upBy > 3 ? upBy : ''}
        </Flex>
      : ranking > oldRanking ?
        <Flex
          direction={'column'}
          className='items-center'>
          {downBy > 3 ? downBy : ''}
          {downBy > 2 && (
            <ChevronDownIcon
              color={Colors.color_red}
              width={20}
              height={20}
              marginY={marginY}
            />
          )}
          {downBy > 1 && (
            <ChevronDownIcon
              color={Colors.color_red}
              width={20}
              height={20}
              marginY={marginY}
            />
          )}
          {downBy > 0 && (
            <ChevronDownIcon
              color={Colors.color_red}
              width={20}
              height={20}
              marginY={marginY}
            />
          )}
        </Flex>
      : undefined)) || <div style={{ width: 20, minWidth: 20 }} />
  )
}

export function gameResultsFilters(
  roundCategory: string,
  division: string,
  maxRound: number,
): (it: FirebaseGameResult & { dividers: ResultDividers }) => boolean {
  return (it: FirebaseGameResult & { dividers: ResultDividers }) =>
    it.dividers.round.category == roundCategory &&
    !!it.dividers.matchingDivisions?.any((matchedDivision) =>
      typeof matchedDivision === 'string' ?
        matchedDivision === division
      : matchedDivision.key === division,
    ) &&
    it.dividers.round.number <= maxRound
}

export function filterResults(
  results: LeaderboardGameResults,
  predicate: (it: FirebaseGameResult & { dividers: ResultDividers }) => boolean,
): LeaderboardGameResults {
  return Object.entries(results).reduce((acc, [measureKey, playerResults]) => {
    if (!playerResults) return acc
    const filteredResults = playerResults.filter(predicate)
    return {
      ...acc,
      [measureKey]: filteredResults,
    }
  }, {})
}

type EditableNameChangeRequestDivProps = {
  playerName: string
  user: User | undefined
  isGroupMember: boolean | undefined
  height: number
  nameChange?:
    | (FirebaseNameChangeEntry & {
        accepted: boolean
      })
    | undefined
  onRequestChange?: (name: string) => void
  onApproval?: (approve: boolean) => void
}

const EditableNameChangeRequestDiv = ({
  playerName,
  user,
  height,
  isGroupMember,
  nameChange,
  onRequestChange,
  onApproval,
}: EditableNameChangeRequestDivProps) => {
  const editableRef = React.createRef<EditableStringDiv>()
  const [isEditing, setIsEditing] = useState(false)
  useEffect(() => {
    isEditing && editableRef.current?.focus()
  }, [editableRef, isEditing])

  const handleEditClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setIsEditing(true)
  }, [])

  return (
    <div
      style={{
        fontSize: height / 3,
        letterSpacing: '-1',
      }}
      className='flex h-full min-w-0 max-w-[75%] shrink grow basis-0 flex-row items-center whitespace-nowrap
        text-start font-montserrat uppercase font-bold'
      translate={'no'}>
      <div className='flex items-center gap-2'>
        {nameChange && !nameChange?.accepted ?
          <div
            translate={'no'}
            className='text-[#FF4136] line-through'>
            {playerName.trim()}
          </div>
        : undefined}
        <EditableStringDiv
          ref={editableRef}
          className={cn('font-leagueSpartan whitespace-nowrap')}
          disabled={!isEditing || !onRequestChange}
          onSubmit={onRequestChange}
          onBlur={() => {
            setIsEditing(false)
          }}
          placeholder={''}>
          {nameChange ? nameChange?.changes : playerName.trim()}
        </EditableStringDiv>
        {nameChange?.changes && !nameChange?.accepted && isGroupMember && onApproval && (
          <>
            <CheckCircleIcon
              className='hover:text-[#2ECC40]'
              onClick={(e) => {
                e.stopPropagation()
                onApproval(true)
              }}
            />
            <CloseButton
              className='hover:text-[#2ECC40]'
              onClick={(e) => {
                e.stopPropagation()
                onApproval(false)
              }}
            />
          </>
        )}
        {user && !isEditing && onRequestChange && (
          <NCImage
            className='h-5 w-5 cursor-pointer'
            src={penEditIcon}
            onClick={handleEditClick}
          />
        )}
      </div>
    </div>
  )
}

export function RankRow({
  prefix,
  expand,
  onClick,
  onChange,
  nameChanges,
  expandedContent,
  rank,
  result,
  subtitle,
  resultLabel,
  displayName: playerName,
  playerLabel,
  user,
  isGroupMember,
  resultBackgroundColor,
  height = isMobile ? 48 : 60,
  className,
  enablePrivateNameChange,
  enablePublicNameChange,
}: {
  rank?: string
  expandedContent?: React.ReactNode
  displayName: string
  playerLabel?: React.ReactNode
  prefix?: React.ReactNode
  onClick?: () => void
  onChange?: (name: string, approve?: boolean) => void
  nameChanges?: FirebaseBaseLeaderboardEntry['nameChanges']
  expand?: boolean
  result: string
  subtitle?: string
  resultLabel?: string
  user: User | undefined
  isGroupMember: boolean | undefined
  resultBackgroundColor: string
  height?: number
  className?: string
  enablePrivateNameChange: boolean | undefined
  enablePublicNameChange: boolean | undefined
}) {
  const [expanded, setExpanded] = useState(expand)

  useEffect(() => {
    setExpanded(expand)
  }, [expand])

  const handleOnClick = useCallback(() => {
    onClick && onClick()
    setExpanded((it) => !it)
  }, [onClick])

  return (
    <>
      <div
        className={cn(
          'relative flex w-full flex-row items-end overflow-clip font-montserrat',
          className,
        )}>
        <div
          className='flex flex-row items-center justify-center'
          style={{ height }}>
          {prefix}
        </div>
        <div className='flex w-full flex-col content-center gap-1'>
          {(subtitle || resultLabel) && (
            <div className='z-[9] flex w-full flex-row items-end justify-stretch'>
              <div
                style={{
                  textAlign: 'start',
                  width: '80px',
                  fontFamily: 'Montserrat, sans-serif',
                  textTransform: 'uppercase',
                  fontVariationSettings: '"wght" 300',
                  whiteSpace: 'nowrap',
                }}>
                {subtitle}
              </div>
              <div
                style={{
                  textAlign: 'start',
                  fontFamily: 'Montserrat, sans-serif',
                  textTransform: 'uppercase',
                  flexGrow: 1,
                  flexShrink: 1,
                  fontVariationSettings: '"wght" 300',
                }}>
                {playerLabel}
              </div>
              <div
                className='w-[80px] pr-[50px] text-end font-montserrat uppercase'
                style={{
                  direction: 'rtl',
                  fontVariationSettings: '"wght" 300',
                }}>
                {resultLabel}
              </div>
            </div>
          )}

          <button
            className='flex w-full cursor-pointer flex-row items-center justify-stretch overflow-clip border-none
              text-white'
            style={{
              height: height,
              background: 'linear-gradient(to right, #97B4F168 20%,#97B4F134 60%, #97B4F100)',
            }}
            onClick={handleOnClick}>
            <div className='flex w-full flex-row items-center justify-stretch overflow-clip'>
              {rank !== undefined ?
                <div
                  style={{
                    fontVariationSettings: '"wght" 700',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: (height * 2) / 3,
                    minWidth: (height * 4) / 3,
                    textAlign: 'center',
                  }}>
                  {rank}
                </div>
              : <div style={{ width: 20 }} />}
              <EditableNameChangeRequestDiv
                playerName={playerName}
                user={user}
                isGroupMember={isGroupMember}
                height={height}
                nameChange={nameChanges?.[playerName.trim()]}
                onRequestChange={
                  (enablePublicNameChange || isGroupMember) && enablePrivateNameChange && onChange ?
                    (name) => onChange(name, isGroupMember)
                  : undefined
                }
                onApproval={
                  isGroupMember && onChange && nameChanges?.[playerName.trim()] ?
                    (approve) => onChange(nameChanges?.[playerName.trim()].changes, approve)
                  : undefined
                }
              />
              <div
                className='absolute right-0 flex flex-row items-center justify-end'
                style={{
                  height: height,
                  boxShadow: `0 0 20px 30px ${toRgba(resultBackgroundColor, 0.4)}`,
                  background: toRgba(resultBackgroundColor, 0.4),
                }}>
                <div
                  className='basis-auto text-end font-montserrat text-playback-crimson'
                  style={{
                    fontSize: (height * 4) / 10,
                    fontVariationSettings: '"wght" 700',
                    textShadow: `0 0 5px ${toRgba(resultBackgroundColor, 1.0)}`,
                  }}>
                  {result}
                </div>
                <div className='w-[50px] flex-shrink-0 basis-[50px]'>
                  {expanded ?
                    <ChevronDownIcon
                      width={50}
                      height={50}
                    />
                  : <ChevronRightIcon
                      width={50}
                      height={50}
                    />
                  }
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
      {expanded && expandedContent}
    </>
  )
}
export function ScrollableReviewResultsList({
  className,
  ...props
}: Parameters<typeof ReviewResultsList>[0] & { className?: string }) {
  return (
    <DraggableScrollBox
      direction='horizontal'
      className={cn('scrollbar-none', className)}>
      <ReviewResultsList {...props} />
    </DraggableScrollBox>
  )
}

export function ReviewResultsItem({
  review,
  enableReviewNavigation,
  handleReviewClick,
}: {
  review: FirebaseGameResult & {
    dividers: ResultDividers
  }
  enableReviewNavigation: boolean
  handleReviewClick: (reviewId: string) => Promise<void>
}) {
  return (
    <>
      <div className='flex w-full flex-row items-center justify-start gap-2'>
        <div className='inline-block'>
          <RoundButton
            alt='Watch'
            className='h-[24px] w-fit'
            color={Colors.color_white}
            backgroundColor={Colors.color_playback_crimson}
            icon={playIcon}
            onClick={() => handleReviewClick(review.reviewId)}>
            Watch
          </RoundButton>
        </div>
        <div
          className='flex flex-row items-center justify-start gap-1'
          style={{
            whiteSpace: 'nowrap',
            userSelect: 'text',
          }}>
          {review.displayBreakdown ?? review.displayValue ?? review.value ?? '--'}
          <TeamEntry
            canEnableEditing={false}
            editable={false}
            teamName={review.teamName ?? `Team ${(review.team ?? 0) + 1}`}
            className={'inline-flex w-fit'}
          />
          {review.dividers.division ?
            <InlinePill backgroundColor={Colors.color_green}>
              {review.dividers.division.title}
            </InlinePill>
          : undefined}
          :
          {review.dividers.round ?
            <InlinePill backgroundColor={Colors.color_black}>
              {review.dividers.round.name}
            </InlinePill>
          : undefined}
          {review.opponentName && (
            <TeamEntry
              canEnableEditing={false}
              editable={false}
              backgroundColor={Colors.color_grey}
              teamName={review.opponentName}
              className={'inline-flex w-fit'}
            />
          )}
          {(
            enableReviewNavigation // assuming isGroupMember is always true for demonstration
          ) ?
            <a
              href={`editor?id=${review.reviewId}&mode=edit`}
              style={{
                color: '#888888',
                userSelect: 'text',
              }}
              draggable={false}>
              {review.title}
            </a>
          : <span
              style={{
                color: '#888888',
                userSelect: 'text',
              }}>
              {review.title}
            </span>
          }
        </div>
      </div>
    </>
  )
}

export function ReviewResultsList({
  firebase,
  enableReviewNavigation,
  reviews,
  measure,
  referrerUrl,
}: {
  firebase: FirebaseComponents
  enableReviewNavigation: boolean
  reviews: (FirebaseGameResult & { dividers: ResultDividers })[]
  measure: Measure
  referrerUrl: string
}) {
  const navigate = useNCNavigate()
  const handleReviewClick = useCallback(
    async (reviewId: string) => {
      // Open a new tab immediately on user click
      const newTab = tryOpenNewTab('about:blank', '_blank')

      NCSendGTMEvent({
        event: 'leaderboard_watch_player_highlight_clicked',
        category: 'leaderboard',
        measure: measure.key,
      })
      const watcherRecordId = await getReviewWatcherStatRecordId({
        firebase: firebase.firebaseDb,
        reviewId,
      })

      // TODO(DAvey): For teams that aren't signed up for stat records. Link this to the normal review with highlights
      const url = `/watch?statrecordid=${watcherRecordId}&highlights=true${measure.highlightEventKeys.map((key) => `&event=${key}`).join('')}&player=${reviews.find((review) => review.reviewId === reviewId)?.playerId}&referrer_url=${encodeURIComponent(referrerUrl)}`

      if (newTab) {
        newTab.location.href = url
      } else {
        const newTab = tryOpenNewTab(url, '_blank')

        if (!newTab) {
          navigate(url)
        } else {
          newTab.focus()
        }
      }
    },
    [measure.key, measure.highlightEventKeys, firebase, reviews, referrerUrl, navigate],
  )

  return (
    <Flex
      style={{
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'flex-start',
        gap: 10,
      }}>
      {reviews
        .orderByDesc((it) => it.dividers.round.number)
        .map((review) => {
          return (
            <ReviewResultsItem
              key={review.reviewId}
              review={review}
              enableReviewNavigation={enableReviewNavigation}
              handleReviewClick={handleReviewClick}
            />
          )
        })}
    </Flex>
  )
}

export function gameResultsToLeagueResults(
  measure: Measure,
  filteredResults: LeaderboardGameResults,
  predicate: (gameResult: FirebaseGameResult & { dividers: ResultDividers }) => boolean,
  reviews: (FirebaseGameResult & { dividers: ResultDividers })[] = filteredResults[
    measure.key
  ]?.filter(predicate) ?? [],
): {
  reviews: (FirebaseGameResult & { dividers: ResultDividers })[]
  total: FirebaseMeasureResult | undefined
} {
  const medianGamesPerPlayer =
    Object.values(filteredResults[measure.key] ?? {})
      .groupBy((r) => r.player)
      .mapValues((g) => g.length)
      .valuesArray()
      .medianOrNull() ?? 0

  let total: MeasureResult | undefined
  if (measure.valueByLeagueResult) {
    total = measure.valueByLeagueResult(filteredResults, predicate, medianGamesPerPlayer)
  } else if (measure.mode === 'sum') {
    total = { value: reviews.mapProp('value').filterNotNull().sum() }
  } else {
    const average = reviews.mapProp('value').filterNotNull().avgOrNull()
    total = { value: average ? Math.round(average * 10) / 10 : null }
  }

  return {
    reviews,
    total: {
      value: total?.value ?? null,
      displayValue: total?.displayValue ?? null,
      displayBreakdown: total?.displayBreakdown ?? null,
    },
  }
}

export type GenericDisplayRankedResults = RankedPlayerMeasureResults | RankedTeamMeasureResults

export type RankedPlayerMeasureResults = {
  displayRank: number
  player: string
  team?: undefined
  displayName: string
  reviews: (FirebaseGameResult & { dividers: ResultDividers })[]
  total: FirebaseMeasureResult | undefined
}

export type RankedTeamMeasureResults = {
  displayRank: number
  team: string
  player?: undefined
  displayName: string
  reviews: (FirebaseGameResult & { dividers: ResultDividers })[]
  total: FirebaseMeasureResult | undefined
}

export function calculateRankedPlayerResults(
  filteredResults: LeaderboardGameResults,
  measure: Measure,
): RankedPlayerMeasureResults[] {
  const playerResults =
    filteredResults[measure.key]
      ?.groupBy(({ player }) => normaliseName(player))
      ?.entriesArray()
      ?.mapNotNull(([normalisedPlayer, reviews]) => {
        const result = gameResultsToLeagueResults(
          measure,
          filteredResults,
          (it) => (it.normalisedPlayer || normaliseName(it.player)) === normalisedPlayer,
          reviews,
        )
        return { ...result, player: normalisedPlayer }
      }) ?? []

  return rankBy(
    playerResults.filter((it) => it.player),
    (it) => it.total?.value ?? -1,
  ).map((it) => ({ ...it.item, displayName: it.item.player, displayRank: it.rank }))
}

export function calculateRankedTeamResults(
  filteredResults: LeaderboardGameResults,
  measure: Measure,
): RankedTeamMeasureResults[] {
  const teamResults =
    filteredResults[measure.key]
      ?.groupBy(
        ({ teamName, dividers, normalisedTeamName }) =>
          `${normalisedTeamName || normaliseName(teamName)}${dividers.division?.title ? ` | ${dividers.division?.title}` : ''}`,
      )
      ?.entriesArray()
      ?.mapNotNull(([normalisedTeamWithDiv, reviews]) => {
        const result = gameResultsToLeagueResults(
          measure,
          filteredResults,
          (it) =>
            `${it.normalisedTeamName || normaliseName(it.teamName)}${it.dividers.division?.title ? ` | ${it.dividers.division?.title}` : ''}` ===
            normalisedTeamWithDiv,
          reviews,
        )
        return {
          ...result,
          team: normalisedTeamWithDiv.split('|')[0].trim(),
          teamWithDiv: normalisedTeamWithDiv,
        }
      }) ?? []

  return rankBy(
    teamResults.filter((it) => it.team),
    (it) => it.total?.value ?? -1,
  ).map((it) => ({
    ...it.item,
    displayName: it.item.teamWithDiv,
    displayRank: it.rank,
    team: it.item.team,
  }))
}

export function rankBy<T>(array: T[], by: (it: T) => number): RankedItem<T>[] {
  const grouppedByValue = array.orderByDesc((it) => by(it)).groupByArray((it) => by(it))

  const rankedResults = grouppedByValue.reduce(
    (acc, [score, group], index) => {
      const updatedPlayers = group.map((item) => {
        if (score !== acc.previousScore) {
          acc.previousScore = score ?? null
          acc.curentDisplayRanking = acc.flattenedResults.length + 1
        }
        return {
          item: item,
          hiddenRank: index + 1,
          rank: acc.curentDisplayRanking ?? 1, // Use display rank which handles ties correctly
        }
      })

      acc.flattenedResults.push(...updatedPlayers)
      return acc
    },
    {
      flattenedResults: [] as {
        item: T
        hiddenRank: number
        rank: number
      }[],
      previousScore: null as number | null,
      curentDisplayRanking: null as number | null,
    },
  ).flattenedResults
  return rankedResults
}

// Utility function to convert various CSS color formats to RGBA
const toRgba = (color: string, alpha: number): string => {
  let r = 0,
    g = 0,
    b = 0

  if (color.startsWith('#')) {
    // Handle hex color
    if (color.length === 4) {
      r = parseInt(color[1] + color[1], 16)
      g = parseInt(color[2] + color[2], 16)
      b = parseInt(color[3] + color[3], 16)
    } else if (color.length === 7) {
      r = parseInt(color[1] + color[2], 16)
      g = parseInt(color[3] + color[4], 16)
      b = parseInt(color[5] + color[6], 16)
    }
  } else if (color.startsWith('rgb')) {
    // Handle rgb and rgba colors
    const rgbValues = color.match(/\d+/g)
    if (rgbValues) {
      r = parseInt(rgbValues[0], 10)
      g = parseInt(rgbValues[1], 10)
      b = parseInt(rgbValues[2], 10)
      if (color.startsWith('rgba') && rgbValues.length === 4) {
        alpha = parseFloat(rgbValues[3])
      }
    }
  } else if (color.startsWith('hsl')) {
    // Handle hsl and hsla colors
    const hslValues = color.match(/\d+/g)
    if (hslValues) {
      const h = parseInt(hslValues[0], 10)
      const s = parseInt(hslValues[1], 10) / 100
      const l = parseInt(hslValues[2], 10) / 100

      const a = s * Math.min(l, 1 - l)
      const f = (n: number) => {
        const k = (n + h / 30) % 12
        return l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
      }

      r = Math.round(f(0) * 255)
      g = Math.round(f(8) * 255)
      b = Math.round(f(4) * 255)

      if (color.startsWith('hsla') && hslValues.length === 4) {
        alpha = parseFloat(hslValues[3])
      }
    }
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
