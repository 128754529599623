import { DodgeballMeasureKeys } from '../templates/dodgeball/MeasureKeys'
import { FirebaseNameChangeEntry } from './common'

export const NswdlDivs = [
  {
    title: 'Mens 1',
    key: 'mens1',
    match: (title: string | undefined) =>
      (title?.toLowerCase().includes(' mens div 1') || title?.toLowerCase().includes(' mens 1')) &&
      !title?.toLowerCase().includes('pre-round'),
  },
  {
    title: 'Mens 2',
    key: 'mens2',
    match: (title: string | undefined) =>
      (title?.toLowerCase().includes(' mens div 2') || title?.toLowerCase().includes(' mens 2')) &&
      !title?.toLowerCase().includes('pre-round'),
  },
  {
    title: 'Mixed 1',
    key: 'mixed1',
    match: (title: string | undefined) =>
      (title?.toLowerCase().includes(' mixed div 1') ||
        title?.toLowerCase().includes(' mixed 1')) &&
      !title?.toLowerCase().includes('pre-round'),
  },
  {
    title: 'Mixed 2',
    key: 'mixed2',
    match: (title: string | undefined) =>
      (title?.toLowerCase().includes(' mixed div 2') ||
        title?.toLowerCase().includes(' mixed 2')) &&
      !title?.toLowerCase().includes('pre-round'),
  },
  {
    title: 'Womens',
    key: 'womens',
    match: (title: string | undefined) =>
      title?.toLowerCase().includes(' womens:') && !title?.toLowerCase().includes('pre-round'),
  },
  {
    title: 'Cloth',
    key: 'cloth',
    match: (title: string | undefined) =>
      title?.toLowerCase().includes(' cloth:') && !title?.toLowerCase().includes('pre-round'),
  },
] as const

export const NswdlDivGroups = [
  {
    title: 'All',
    key: 'all',
    match: (title: string | undefined) => !title?.toLowerCase().includes('pre-round'),
  },
  {
    title: 'Foam combined',
    key: 'foam',
    match: (title: string | undefined) =>
      !title?.toLowerCase().includes(' cloth:') && !title?.toLowerCase().includes('pre-round'),
  },
  {
    title: 'Cloth combined',
    key: 'cloth_group',
    match: (title: string | undefined) =>
      title?.toLowerCase().includes(' cloth:') && !title?.toLowerCase().includes('pre-round'),
  },
] as const

export const NswdlDivFilters = [...NswdlDivGroups, ...NswdlDivs]

export type NswdlDivFilter = (typeof NswdlDivFilters)[number] | DivisionFilter
export type DivisionDefinition = {
  title: string
  key: string
}

export type DivisionFilter = {
  title: string
  key: string | 'all'
  match: (
    reviewTitle: string | undefined,
    reviewTags?: string[],
    reviewDivisionKey?: string,
  ) => boolean | undefined
}

export type LeaderboardEntry = {
  title: string | null
  groupColor?: string | null
  resultsHashesByDivision: {
    [divisionKey: string]: {
      [reviewId: string]: { [K in DodgeballMeasureKeys]?: string | null } | null | undefined
    }
  }
  metricSettings: {
    hiddenMetrics: DodgeballMeasureKeys[] | null
    statsTemplateKey: 'basic' | 'default' | 'kdr' | Exclude<string, 'basic' | 'default' | 'kdr'>
  }
  gameResultsByPlayer: LeaderboardGameResults
  aggregateResultsByPlayer: LeaderboardAggregateResults | null
  // graphResultsByPlayer: LeaderboardGraphResults | null
  // resultsByTeam: LeaderboardResults
}

export type LeaderboardGraphResults = {
  [K in DodgeballMeasureKeys]: {
    divisions: {
      [divisionOrTagKey: string]: {
        valueAggregatesGraph?: GraphByRoundInfo<AggregateMeta> | null
        graphByPlayerNameHash?: {
          [nameHash: string]: GraphByRoundInfo<PlayerMeasureResult & { displayRank: number }>
        } | null
      }
    }
  }
}

export type LeaderboardGameResults = {
  [K in DodgeballMeasureKeys]?:
    | (FirebaseGameResult & {
        dividers: ResultDividers
      })[]
    | undefined
}

export type LeaderboardAggregateResults = {
  [K in DodgeballMeasureKeys]?: {
    divisions: {
      all: {
        valueAggregatesByPlayer?: AggregateMeta | null
        byPlayerNameHash?: {
          [nameHash: string]: PlayerMeasureResult & { displayRank: number }
        } | null
        byPlayerRanking?: {
          [displayRank: number]: (PlayerMeasureResult & { displayRank: number })[]
        } | null

        valueAggregatesByTeam?: AggregateMeta | null
        byTeamNameHash?: {
          [teamNameHash: string]: TeamMeasureResult & { displayRank: number }
        } | null
        byTeamRanking?: {
          [displayRank: number]: (TeamMeasureResult & { displayRank: number })[]
        } | null
      }
      [divisionOrTagKey: string]: {
        valueAggregatesByPlayer?: AggregateMeta | null
        byPlayerNameHash?: {
          [nameHash: string]: PlayerMeasureResult & { displayRank: number }
        } | null
        byPlayerRanking?: {
          [displayRank: number]: (PlayerMeasureResult & { displayRank: number })[]
        } | null

        valueAggregatesByTeam?: AggregateMeta | null
        byTeamNameHash?: {
          [teamNameHash: string]: TeamMeasureResult & { displayRank: number }
        } | null
        byTeamRanking?: {
          [displayRank: number]: (TeamMeasureResult & { displayRank: number })[]
        } | null
      }
    }
  }
}

export type FirebaseGroupLeaderboardEntry = FirebaseBaseLeaderboardEntry & {
  type?: undefined | 'private'
  publicLeaderboardId?: string | null
}

export type FirebasePublicLeaderboardEntry = FirebaseBaseLeaderboardEntry & {
  type: 'public'
  groupId: string
}
export type FirebaseBaseLeaderboardEntry = {
  title: string | null
  groupColor?: string | null
  metricSettings?: {
    hiddenMetrics?: DodgeballMeasureKeys[] | null
    statsTemplateKey: 'basic' | 'default' | 'kdr' | Exclude<string, 'basic' | 'default' | 'kdr'>
  } | null
  /**
   * @deprecated
   */
  resultsHashes?: {
    [reviewId: string]: {
      [teamNameHash: string]:
        | {
            [K in DodgeballMeasureKeys]?: string | null
          }
        | null
        | undefined
    }
  }

  nameChanges?: {
    [existingPlayerName: string]: FirebaseNameChangeEntry & {
      // When Accepting a name change you will update all the reviews AND change this .accepted = true
      // if accepted === true then immediately display the new name in the leaderboard
      // when regenerated the leaderboard we can clean up by deleting all the "accepted" NameChangeEntry
      accepted: boolean
    }
  } | null

  /**
   * Used to keep compare if there are updates between public and group leaderboards.
   * Used to check when new emails need to be sent
   */
  resultsHashesByDivision?: {
    [divisionKey: string | '_']: {
      [reviewId: string]: {
        [teamNameHash: string]:
          | {
              [K in DodgeballMeasureKeys]?: string | null
            }
          | null
      }
    }
  }

  aggregateResultsByPlayer?: FirebaseLeaderboardAggregateResults | null
  // graphResultsByPlayer?: FirebaseLeaderboardGraphResults | null
  resultsByPlayer: FirebaseLeaderboardGameResults
  // resultsByTeam: FirebaseLeaderboardResults
}
export type FirebaseLeaderboardAggregateResults = {
  [K in DodgeballMeasureKeys]: {
    divisions: {
      all: {
        valueAggregatesByPlayer?: AggregateMeta | null
        byPlayerNameHash?: {
          [nameHash: string]: PlayerMeasureResult & { displayRank: number }
        } | null
        byPlayerRanking?: {
          [displayRank: number]: (PlayerMeasureResult & { displayRank: number })[]
        } | null

        valueAggregatesByTeam?: AggregateMeta | null
        byTeamNameHash?: {
          [teamNameHash: string]: TeamMeasureResult & { displayRank: number }
        } | null
        byTeamRanking?: {
          [displayRank: number]: (TeamMeasureResult & { displayRank: number })[]
        } | null
      }
      [divisionOrTagKey: string]: {
        valueAggregatesByPlayer?: AggregateMeta | null
        byPlayerNameHash?: {
          [nameHash: string]: PlayerMeasureResult & { displayRank: number }
        } | null
        byPlayerRanking?: {
          [displayRank: number]: (PlayerMeasureResult & { displayRank: number })[]
        } | null

        valueAggregatesByTeam?: AggregateMeta | null
        byTeamNameHash?: {
          [teamNameHash: string]: TeamMeasureResult & { displayRank: number }
        } | null
        byTeamRanking?: {
          [displayRank: number]: (TeamMeasureResult & { displayRank: number })[]
        } | null
      }
    }
  }
}

export type AggregateMeta = {
  average: number
  median: number
  maximum: number
  minimum: number
}
export type FirebaseLeaderboardGraphResults = {
  [K in DodgeballMeasureKeys]: {
    divisions: {
      [divisionOrTagKey: string]: {
        valueAggregatesGraph?: GraphByRoundInfo<AggregateMeta> | null
        graphByPlayerNameHash?: {
          [nameHash: string]: GraphByRoundInfo<PlayerMeasureResult & { displayRank: number }>
        } | null
      }
    }
  }
}

export type GraphByRoundInfo<T> = {
  [category: string]: {
    [roundNumber: number]: T
  }
}

export type FirebaseLeaderboardGameResults = {
  [K in DodgeballMeasureKeys]?: {
    [nameHash: string]: {
      [pushKey: string]: FirebaseGameResult & {
        dividers: ResultDividers
      }
    }
  } | null
}

export type FirebaseGameResult = FirebaseMeasureResult & {
  player: string
  normalisedPlayer: string | null
  playerId: string | undefined
  title: string | undefined
  reviewId: string
  team?: number | null
  teamName: string | null
  normalisedTeamName: string | null
  opponentName?: string | null
}

export type ResultDividers = {
  matchingDivisions?: Omit<NswdlDivFilter, 'match'>[] | null
  division?: { key: string; title: string } | null
  round: RoundInfo
}

export type RoundInfo = { name: string; number: number; category: string }

export type PlayerMeasureResult = FirebaseMeasureResult & {
  player: string
}

export type TeamMeasureResult = FirebaseMeasureResult & {
  team: string
}

export type FirebaseMeasureResult = {
  value: number | null
  displayValue: string | null
  displayBreakdown: string | null
}
