import React, {
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { cn } from './utils/tailwindUtils'

export interface SquashingBoxProps {
  squashedChildrenArray?: ReactNode[]
  children?: ReactNode
  className?: string
}

export type SquashingBoxHandle = {
  blur: () => void
  focus: () => void
}

export const SquashingBox = React.memo(
  React.forwardRef<SquashingBoxHandle, SquashingBoxProps>(
    function SquashingBox(props, forwardedRef) {
      const ref = useRef<HTMLDivElement>(null)
      const [index, setIndex] = useState(0)
      const mapRef = useRef(new Map<number, number>())

      const blur = useCallback(() => {
        return ref.current?.blur()
      }, [])

      const focus = useCallback(() => {
        return ref.current?.focus()
      }, [])

      useImperativeHandle(
        forwardedRef,
        () => ({
          blur,
          focus,
        }),
        [blur, focus],
      )

      const availableChildren = useMemo(() => {
        return [props.children].concat(props.squashedChildrenArray ?? [])
      }, [props.children, props.squashedChildrenArray])

      const checkSquash = useCallback(() => {
        const box = ref.current
        if (!box) return

        if (box.scrollWidth && box.clientWidth < box.scrollWidth) {
          if (availableChildren.length > index + 1) {
            mapRef.current.set(index, box.scrollWidth)
            setIndex((prev) => prev + 1)
          }
        } else if (
          box.scrollWidth &&
          box.clientWidth > box.scrollWidth &&
          mapRef.current.has(index - 1) &&
          box.clientWidth > mapRef.current.get(index - 1)!
        ) {
          setIndex((prev) => prev - 1)
        }
      }, [index, availableChildren])

      useEffect(() => {
        const box = ref.current
        if (!box) return

        const observer = new ResizeObserver(checkSquash)
        let childrenLength = availableChildren.length

        const checkSquashAgain = () =>
          requestAnimationFrame(() => {
            checkSquash()
            if (--childrenLength === 0) return
            checkSquashAgain()
          })

        checkSquashAgain()
        observer.observe(box)

        return () => {
          observer.disconnect()
        }
      }, [checkSquash, availableChildren])

      const currentChild = availableChildren[index]
      const isFinalChild = availableChildren.length === index + 1

      if (!currentChild || React.Children.toArray(currentChild).every((it) => !it)) {
        return null
      }

      return (
        <div
          className={cn(
            props.className,
            props.squashedChildrenArray?.length && !isFinalChild && 'overflow-x-clip',
          )}
          ref={ref}>
          {currentChild}
        </div>
      )
    },
  ),
)

SquashingBox.displayName = 'SquashingBox'
