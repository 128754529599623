'use client'

// import all translation resource groups
import i18next from 'i18next'
import detector from 'i18next-browser-languagedetector'
import backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { englishLocales } from './en/en'
import { vietnameseLocales } from './vi/vi'

const languageTranslation = {
  en: englishLocales,
  vi: vietnameseLocales,
}

i18next
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    resources: languageTranslation,
    fallbackLng: 'en', // fallback language
    supportedLngs: ['en', 'vi'],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      lookupQuerystring: 'lng',
    },
  })
  .then(() => i18next)

export default i18next
