'use client'

import { Flex, Image } from '@chakra-ui/react'
import React, { forwardRef, useRef } from 'react'
import closeIcon from '../icons/close_button.png'
import { useCompositeRefObject } from './UseCompositeRefObject'
import { NCImage } from './common/nextJs/components/nextCompatibleImage'
import { cn } from './common/utils/tailwindUtils'

export const SearchInput = forwardRef<
  HTMLInputElement,
  Omit<React.HTMLProps<HTMLInputElement>, 'onSubmit'> & {
    id?: string
    submitText?: string
    onSubmit: (value: string) => void
    onClose?: () => void
    className?: string
  }
>(function SearchInput({ id, submitText = 'Go', onSubmit, onClose, className, ...props }, ref) {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const refs = useCompositeRefObject(ref, inputRef)
  return (
    <div className={cn('flex h-[35px] w-full gap-1', className)}>
      <input
        id={id}
        className={
          'box-border h-[35px] min-w-0 max-w-[500px] flex-1 rounded-[4px] border-none p-[8px]'
        }
        type={'text'}
        ref={refs}
        onKeyDown={(e) => e.key === 'Enter' && inputRef.current && onSubmit(e.currentTarget.value)}
        {...props}
      />
      <button
        className={`flex h-[35px] shrink-0 grow-0 cursor-pointer items-center text-nowrap rounded-[4px] border-none
          bg-[#d63e4f] px-6 py-0 font-league-spartan text-[14px] !text-[#e8e8e8] font-bold
          hover:brightness-[120%]`}
        onClick={() => inputRef.current && onSubmit(inputRef.current.value)}>
        {submitText}
      </button>
      {onClose && (
        <NCImage
          draggable={false}
          src={closeIcon}
          onClick={onClose}
          className='aspect-square h-[35px] w-[35px] shrink-0 grow-0 object-contain p-[6px] hover:opacity-80'
        />
      )}
    </div>
  )
})
