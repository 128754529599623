'use client'

import * as React from 'react'
import { PropsWithChildren, useMemo } from 'react'
import { cn } from './common/utils/tailwindUtils'

export const CompositeMarker = React.memo(function CompositeMarker<T>({
  left,
  shouldSimplify,
  isNote,
  displayStyle,
  ...props
}: PropsWithChildren<{
  shouldSimplify: boolean
  isNote: boolean
  displayStyle: 'above' | 'centre' | 'below'
  left?: string
}>) {
  const style = useMemo(
    () => ({
      left: left,
    }),
    [left],
  )

  return (
    <div
      className='group absolute'
      style={style}>
      <div
        className={cn(
          `relative top-[-5px] z-0 flex h-[35px] w-[20px] -translate-x-2/4 translate-y-[24px] cursor-grab
          justify-center rounded-[10px] border-0 border-solid border-white bg-[rgba(141,141,141,0.25)]
          group-focus-within:hidden group-hover:hidden`,
          isNote ? 'bookmark' : '',
          displayStyle === 'above' ? '!-translate-x-2/4 !-translate-y-[-36px]'
          : displayStyle === 'centre' ?
            '!h-[20px] !-translate-x-2/4 !translate-y-0 !bg-[rgba(141,141,141,0.8)]'
          : '',
        )}
        tabIndex={0}
      />
      <div
        className='pointer-events-auto z-[6] hidden w-fit flex-row justify-start overflow-visible
          group-focus-within:flex group-hover:flex'>
        {props.children}
      </div>
    </div>
  )
})
