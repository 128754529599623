import { forwardRef, lazy } from 'react'

const NextLink = lazy(() => import('next/link'))
const ReactRouterLink = lazy(() =>
  import('react-router').then((module) => ({ default: module.Link })),
)

const NCLink = forwardRef(function NCLink(
  props: Omit<React.ComponentProps<'a'>, 'href'> & { href: string },
  ref: React.Ref<HTMLAnchorElement>,
) {
  if (process.env && process.env.REACT_APP_RUN_MODE === 'nextjs') {
    return (
      <NextLink
        {...props}
        ref={ref}
        href={props.href}>
        {props.children}
      </NextLink>
    )
  }
  
  return (
    <ReactRouterLink
      {...props}
      ref={ref}
      to={props.href}>
      {props.children}
    </ReactRouterLink>
  )
})

export default NCLink
