'use client'

import { PropsWithChildren } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

export default function DndProviderClient({ children }: PropsWithChildren) {
  return (
    <DndProvider
      backend={HTML5Backend}
      context={typeof window !== 'undefined' ? window : undefined}>
      {children}
    </DndProvider>
  )
}
