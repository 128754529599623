import type { DynamicOptions, LoaderComponent } from 'next/dynamic'
import React, { ComponentType } from 'react'

export default function dynamic<P>(
  component: () => LoaderComponent<P>,
  options?: DynamicOptions<P>,
) {
  if (process.env && process.env.REACT_APP_RUN_MODE === 'nextjs') {
    return React.lazy<ComponentType<P>>(async () =>
      import('next/dynamic').then((mod) => ({
        default: mod.default<P>(() => component(), options),
      })),
    )
  }
  return React.lazy(() =>
    component().then((mod) => ({ default: 'default' in mod ? mod.default : mod })),
  )
}
