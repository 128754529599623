import type { GTMParams } from '@next/third-parties/dist/types/google'
import { lazy } from 'react'

const GoogleTagManager = lazy(() =>
  import('@next/third-parties/google').then((mod) => ({
    default: mod.GoogleTagManager,
  })),
)

// Remove lazy loading for sendGTMEvent
let sendGTMEvent: ((data: object, dataLayerName?: string) => void) | null = null

// Load the function when needed
const loadGTMEvent = async () => {
  if (!sendGTMEvent) {
    const mod = await import('@next/third-parties/google')
    sendGTMEvent = mod.sendGTMEvent
  }
  return sendGTMEvent
}

export function NCGoogleTagManager(props: GTMParams) {
  if (process.env && process.env.REACT_APP_RUN_MODE === 'nextjs') {
    return <GoogleTagManager {...props} />
  }
  return <></>
}

export async function NCSendGTMEvent(data: object, dataLayerName?: string) {
  if (process.env && process.env.REACT_APP_RUN_MODE === 'nextjs') {
    const gtmEvent = await loadGTMEvent()
    gtmEvent?.(data, dataLayerName)
  }
}
