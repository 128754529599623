'use client'

import { Flex, FlexProps, Image } from '@chakra-ui/react'
import { NCImage } from 'components/common/nextJs/components/nextCompatibleImage'
import { ViewingMode } from 'data/ViewingMode'
import { StaticImport } from 'next/dist/shared/lib/get-img-props'
import React, { CSSProperties, useCallback, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { VideoController, useVideoPlayerStatus } from '../components/VideoController'
import close_icon from '../icons/close_button.png'
import exit_fullscreen_icon from '../icons/exit_fullscreen_button.png'
import fullscreen_icon from '../icons/fullscreen_button.png'
import previous_icon from '../icons/previous_button.png'
import replay_icon from '../icons/replay_button.png'
import replay_highlight_icon from '../icons/replay_highlight_icon.png'
import share_icon from '../icons/share_social_button.png'
import skip_icon from '../icons/skip_button.png'
import square_icon from '../icons/square.png'
import stop_watch_icon from '../icons/stop_watch_button.png'
import watch_icon from '../icons/watch_button.png'
import { HighlightPlayer, HighlightTypes } from './HighlightsPlayer'

export type HighlightsMode = 'Spotlight' | 'Highlights'

function getHighlightsModeDescription(highlightsMode: HighlightsMode) {
  switch (highlightsMode) {
    case 'Spotlight':
      return 'Spotlight (PRO)'
    case 'Highlights':
      return 'Highlights'
  }
}

export type ViewModeButtonProps = {
  viewingMode: ViewingMode
  exitEditingMode?: () => void
}

export function HighlightsButtons({
  height = 40,
  videoController,
  isPlayingHighlights,
  hasPlayersOrFilters,
  highlightPlayer,
  displayStyle,
  highlightsMode,
  fullscreenClicked,
  watchMode,
  onWatchModeClicked,
  canExitWatchMode,
  isFullScreen,
  style,
  disableBookmarkPlaybackControls,
  viewingModeButtonProps,
}: {
  height?: number
  videoController: VideoController | undefined
  isPlayingHighlights: false | HighlightTypes
  hasPlayersOrFilters: boolean
  highlightPlayer: HighlightPlayer
  displayStyle: 'large' | 'small' | 'smallest'
  highlightsMode: HighlightsMode
  fullscreenClicked: () => void
  watchMode: boolean
  onWatchModeClicked: () => void
  canExitWatchMode: boolean
  isFullScreen: boolean
  style?: CSSProperties
  disableBookmarkPlaybackControls?: boolean
  viewingModeButtonProps?: ViewModeButtonProps
}) {
  const canStartOver = useVideoPlayerStatus(
    videoController,
    30,
    false,
    useCallback((state) => state.currentTime > 5, []),
  )

  const handleSkipToLastBookmark = useCallback(() => {
    highlightPlayer.skipToLastBookmark()
    if (!watchMode) {
      onWatchModeClicked()
    }
  }, [highlightPlayer, watchMode, onWatchModeClicked])

  const handleSkipToNextBookmark = useCallback(() => {
    highlightPlayer.skipToNextBookmark()
    if (!watchMode) {
      onWatchModeClicked()
    }
  }, [highlightPlayer, watchMode, onWatchModeClicked])

  const handleFullscreenClick = useCallback(() => {
    fullscreenClicked()
  }, [fullscreenClicked])

  return (
    <Flex
      height={height}
      gap={'4px'}
      padding={'0 8px'}
      alignItems={'center'}
      style={style}>
      <HighlightsButton
        height={height - 4}
        src={replay_icon}
        disabled={!canStartOver}
        alt={'Start video from beginning'}
        onClick={highlightPlayer.startOver}
      />

      {(isPlayingHighlights === 'highlights' ||
        (isPlayingHighlights !== 'bookmarks' &&
          hasPlayersOrFilters &&
          viewingModeButtonProps?.viewingMode === 'view')) && (
        <HighlightsButton
          height={height - 4}
          src={isPlayingHighlights ? square_icon : skip_icon}
          alt={
            isPlayingHighlights ?
              `Stop watching ${getHighlightsModeDescription(highlightsMode)}`
            : `Skip to ${getHighlightsModeDescription(highlightsMode)}`
          }
          onClick={highlightPlayer.toggleMatchHighlights}
          text={
            isPlayingHighlights ?
              `${displayStyle === 'large' ? 'Stop watching' : 'Stop'} ${getHighlightsModeDescription(highlightsMode)}`
            : `${displayStyle === 'large' ? 'Skip to' : 'See'} ${getHighlightsModeDescription(highlightsMode)}`
          }
        />
      )}
      {watchMode && !disableBookmarkPlaybackControls && (
        <>
          <HighlightsButton
            height={height - 4}
            src={previous_icon}
            alt={`Back to previous bookmark`}
            onClick={handleSkipToLastBookmark}
            disabled={!highlightPlayer.hasLastBookmark}
          />
          <HighlightsButton
            height={height - 4}
            src={skip_icon}
            alt={`Skip to next bookmark`}
            onClick={handleSkipToNextBookmark}
            disabled={!highlightPlayer.hasNextBookmark}
            text={'Next bookmark'}
          />
        </>
      )}
      {viewingModeButtonProps &&
        viewingModeButtonProps.viewingMode === 'edit' &&
        viewingModeButtonProps.exitEditingMode && (
          <HighlightsButton
            height={height - 4}
            src={close_icon}
            alt={'Stop editing mode'}
            onClick={viewingModeButtonProps.exitEditingMode}
            text={'Stop editing'}
          />
        )}
      {((canExitWatchMode && watchMode) || !watchMode) &&
        (!viewingModeButtonProps || viewingModeButtonProps.viewingMode !== 'edit') && (
          <HighlightsButton
            height={height - 4}
            src={watchMode ? stop_watch_icon : watch_icon}
            alt={'Toggle presenting mode'}
            onClick={onWatchModeClicked}
            text={
              displayStyle === 'large' ?
                watchMode ?
                  'Exit Presenting Mode'
                : 'Enter Presenting Mode'
              : watchMode ?
                'Exit Presenting Mode'
              : 'Present'
            }
          />
        )}
      <HighlightsButton
        height={height - 4}
        src={isFullScreen ? exit_fullscreen_icon : fullscreen_icon}
        alt={'Toggle fullscreen mode'}
        onClick={handleFullscreenClick}
      />
    </Flex>
  )
}

export function BookmarkPlaybackControls(props: {
  height?: number
  watchMode: boolean
  isPlayingHighlights: false | 'highlights' | 'bookmarks'
  highlightPlayer: HighlightPlayer
  onWatchModeClicked: () => void
  displayStyle: 'large' | 'small' | 'smallest'
}) {
  return (
    <>
      <HighlightsButton
        height={props.height}
        src={replay_highlight_icon}
        backgroundColor={'rgba(70,108,242,0.9)'}
        alt={'Start highlight from beginning'}
        onClick={props.highlightPlayer.startOverCurrentHighlight}
      />

      <HighlightsButton
        height={props.height}
        src={previous_icon}
        backgroundColor={'rgba(70,108,242,0.9)'}
        alt={`Back to previous bookmark`}
        onClick={() => {
          props.highlightPlayer.skipToLastBookmark()
          if (!props.watchMode) {
            props.onWatchModeClicked()
          }
        }}
        disabled={!props.highlightPlayer.hasLastBookmark}
      />
      <HighlightsButton
        height={props.height}
        src={skip_icon}
        backgroundColor={'rgba(70,108,242,0.9)'}
        alt={`Skip to next bookmark`}
        onClick={() => {
          props.highlightPlayer.skipToNextBookmark()
          if (!props.watchMode) {
            props.onWatchModeClicked()
          }
        }}
        disabled={!props.highlightPlayer.hasNextBookmark}
        text={props.displayStyle === 'large' ? 'Next bookmark' : 'Next'}
      />
      <HighlightsButton
        height={props.height}
        backgroundColor={'rgba(70,108,242,0.9)'}
        src={close_icon}
        alt={'Stop playing bookmarks'}
        onClick={props.highlightPlayer.stopPlayingHighlights}
        text={'Stop playing bookmarks'}
      />
    </>
  )
}
export const HighlightsButton = React.memo(function HighlightsButton({
  alt,
  onClick,
  src,
  text,
  disabled,
  height = 36,
  ...flexProps
}: {
  height?: number
  src: string | StaticImport
  alt: string
  onClick: () => void
  text?: string
  disabled?: boolean
} & Omit<FlexProps, 'onClick'>) {
  const padding = useMemo(() => (height * 8) / 36, [height])
  const fontPadding = useMemo(() => (height * 20) / 36, [height])

  const style = useMemo<CSSProperties>(
    () => ({
      cursor: disabled ? undefined : 'pointer',
      padding: padding,
      backgroundColor: disabled ? 'rgba(115,115,115,0.9)' : undefined,
      color: disabled ? '#b0b0b0' : undefined,
      opacity: disabled ? '0.6' : undefined,
      height,
      fontSize: height - fontPadding,
    }),
    [disabled, padding, height, fontPadding],
  )

  const hoverStyle = useMemo(
    () => ({
      filter: disabled || isMobile ? undefined : 'brightness(120%)',
    }),
    [disabled],
  )

  const activeStyle = useMemo(
    () => ({
      filter: disabled ? undefined : 'brightness(120%)',
    }),
    [disabled],
  )

  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick()
    }
  }, [disabled, onClick])

  const imageStyle = useMemo<CSSProperties>(
    () => ({
      height: height - padding * 2,
      width: height - padding * 2,
    }),
    [height, padding],
  )

  return (
    <Flex
      borderRadius={999}
      _hover={hoverStyle}
      _active={activeStyle}
      boxSizing={'border-box'}
      backgroundColor={'rgba(252,56,75,0.9)'}
      color={'white'}
      style={style}
      alignItems={'center'}
      justifyContent={'center'}
      onClick={handleClick}
      fontWeight={500}
      pointerEvents={'auto'}
      fontFamily={'LeagueSpartan, sans-serif'}
      gap={'8px'}
      whiteSpace={'nowrap'}
      {...flexProps}>
      <NCImage
        style={imageStyle}
        className='box-border object-contain'
        src={src}
        alt={alt}
      />
      {text}
    </Flex>
  )
})

export function WatcherHighlightsButtons(props: {
  height?: number
  videoController: VideoController | undefined
  highlightPlayer: HighlightPlayer
  highlightsMode: HighlightsMode
  fullscreenClicked: () => void
  isFullScreen: boolean
  style?: CSSProperties
  shareReelClicked: () => void
}) {
  const canStartOver = useVideoPlayerStatus(
    props.videoController,
    30,
    false,
    (state) => state.currentTime > 5,
  )
  const { height = 40 } = props
  return (
    <Flex
      height={height}
      gap={'4px'}
      padding={'0 8px'}
      alignItems={'center'}
      style={props.style}>
      <HighlightsButton
        height={height - 4}
        src={replay_icon}
        disabled={!canStartOver}
        alt={'Start video from beginning'}
        onClick={props.highlightPlayer.startOver}
      />
      <HighlightsButton
        height={height - 4}
        src={share_icon}
        alt={'Share this video'}
        onClick={props.shareReelClicked}
        text={'Share this reel'}
      />

      <HighlightsButton
        height={height - 4}
        src={props.isFullScreen ? exit_fullscreen_icon : fullscreen_icon}
        alt={'fullscreen mode'}
        onClick={props.fullscreenClicked}
      />
    </Flex>
  )
}
