import { NCImage } from 'components/common/nextJs/components/nextCompatibleImage'
import { cn } from 'components/common/utils/tailwindUtils'
import { TimelineReplyFirebaseEntry } from 'data/common'
import { User } from 'firebase/auth'
import {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import useHover from '../components/UseHover'
import closeButtonIcon from '../icons/close_button.png'
import thumbButtonIcon from '../icons/thumbs_button.png'

export const supportedEmojis = ['😂', '👍', '❤️', '🤔', '+1', '🥲'] as const
export const emojiDisplay: Map<string, ReactNode> = [
  [
    '+1',
    <span
      key='+1'
      className='aspect-square h-[1em] w-[1em] rounded-full bg-gray-800 font-league-spartan text-[1.2em] italic
        text-[#8bb4ff] font-bold'>
      {' '}
      <span className='text-[#377dff]'>+</span>1{' '}
    </span>,
  ] as const,
].toMap()
function EmojiReplyButtonBar(props: {
  repliesByUser: TimelineReplyFirebaseEntry[] | undefined
  onClick: (reply: string) => void
}) {
  const [expandState, setExpanded] = useState<{
    expanded: boolean
  }>({
    expanded: false,
  })
  const [isHovered, hoverRef] = useHover<HTMLDivElement>()
  const replyByUser = useMemo(() => {
    return (
      props.repliesByUser &&
      props.repliesByUser
        .groupBy((it) => it.message)
        ?.keysArray()
        .firstOrNull()
    )
  }, [props.repliesByUser])

  const handleEmojiReplyButtonClick = useCallback(
    (reply: string) => {
      props.onClick(reply)
      setExpanded({ expanded: false })
    },
    [props],
  )
  useEffect(() => {
    if (!isHovered) setExpanded({ expanded: false })
  }, [isHovered])

  const handleReplyButtonClick = useCallback(() => {
    setExpanded({ expanded: true })
  }, [])
  const handleCloseButtonClick = useCallback(() => {
    setExpanded({ expanded: false })
  }, [])
  return (
    <div
      ref={hoverRef}
      style={{
        position: 'absolute',
        display: 'flex',
        right: 0,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        pointerEvents: 'auto',
        alignItems: 'center',
      }}>
      <div
        className={cn(
          'z-[1] flex gap-[2px] rounded-full bg-[rgb(51,51,51)] shadow-2xl',
          expandState.expanded ? 'p-1' : 'p-0',
        )}>
        {expandState.expanded &&
          supportedEmojis.map((it) => (
            <EmojiReplyButton
              key={it}
              emoji={it}
              repliesByUser={props.repliesByUser}
              onClick={handleEmojiReplyButtonClick}
            />
          ))}
      </div>
      {!expandState.expanded && !replyByUser && (
        <ReplyPlaceholderButton
          displayMode={'open'}
          key={'reply'}
          onClick={handleReplyButtonClick}
        />
      )}
      {!expandState.expanded && replyByUser && (
        <EmojiReplyButton
          key={'reply'}
          emoji={replyByUser}
          repliesByUser={props.repliesByUser}
          onClick={handleReplyButtonClick}
        />
      )}
      {expandState.expanded && (
        <ReplyPlaceholderButton
          displayMode={'close'}
          key={'reply'}
          onClick={handleCloseButtonClick}
        />
      )}
    </div>
  )
}

function EmojiReplyButton({
  emoji,
  onClick,
  repliesByUser,
}: {
  emoji: string
  onClick: (emoji: string) => void
  repliesByUser: TimelineReplyFirebaseEntry[] | undefined
}) {
  const chosen = useMemo(() => {
    return repliesByUser?.find((it) => it.message === emoji)
  }, [emoji, repliesByUser])
  const handleClick = useCallback(() => {
    onClick(emoji)
  }, [emoji, onClick])

  return (
    <div
      className={cn(
        `pointer-events-auto box-border inline-flex aspect-square h-[30px] cursor-pointer items-center
        justify-center rounded-full font-league-spartan text-xl text-white font-bold`,
        chosen ? 'hover:bg-[rgb(255,255,255,0.8)]' : 'hover:bg-[rgb(255,255,255,0.2)]',
        chosen && 'bg-[rgb(255,255,255,0.5)]',
        chosen && 'border-[2px] border-solid border-[rgb(255,255,255,0.1)]',
      )}
      onClick={handleClick}>
      {emojiDisplay.get(emoji) ?? emoji}
    </div>
  )
}

function ReplyPlaceholderButton({
  displayMode,
  onClick,
  onHoverChanged,
}: PropsWithChildren & {
  displayMode: 'open' | 'close'
  onHoverChanged?: (hover: boolean) => void
  onClick: () => void
}) {
  const [isHover, hoverRef] = useHover<HTMLImageElement>()
  useEffect(() => {
    onHoverChanged?.(isHover)
  }, [onHoverChanged, isHover])
  return (
    <NCImage
      ref={hoverRef}
      alt={'React to this comment'}
      src={displayMode === 'open' ? thumbButtonIcon : closeButtonIcon}
      onClick={onClick}
      style={{
        display: 'inline-flex',
        boxSizing: 'border-box',
        height: '30px',
        opacity: isHover ? '0.4' : '0.3',
        borderRadius: '999px',
        fontSize: '60%',
        aspectRatio: '1/1',
        alignItems: 'center',
        pointerEvents: 'auto',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
    />
  )
}

export function EmojiReplyDisplayBar(
  props: PropsWithChildren & {
    repliesByEmoji: Map<string, TimelineReplyFirebaseEntry[]>
    style?: CSSProperties
  },
) {
  const emojis = useMemo(() => props.repliesByEmoji.keysArray(), [props.repliesByEmoji])

  const [isHover, hoverRef] = useHover<HTMLDivElement>()
  const entries = useMemo(() => {
    return props.repliesByEmoji.entriesArray()
  }, [props.repliesByEmoji])
  const entriesCount = useMemo(() => {
    return entries.map(([key, array]) => array.length).sum()
  }, [entries])
  return (
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        width: '100%',
        pointerEvents: 'none',
        gap: '2px',
        justifyContent: 'left',
        alignItems: 'normal',
        fontFamily: 'LeagueSpartan,sans-serif',
        fontSize: '12px',
        color: 'white',
        ...props.style,
      }}>
      <div
        ref={hoverRef}
        className='pointer-events-auto flex flex-wrap items-center gap-[2px] text-sm'>
        <span className='w-min-[1em] h-min-[1em] font-league-spartan font-bold'>
          {emojis.sort().map((it) => emojiDisplay.get(it) ?? `${it}`)}
        </span>
        {entriesCount || ''}
      </div>
      {isHover && <EmojiReplyCreatorsDisplay repliesByEmoji={props.repliesByEmoji} />}
    </div>
  )
}

function EmojiReplyCreatorsDisplay(
  props: PropsWithChildren & {
    repliesByEmoji: Map<string, TimelineReplyFirebaseEntry[]>
  },
) {
  const entries = useMemo(() => {
    return props.repliesByEmoji.entriesArray()
  }, [props.repliesByEmoji])
  return (
    <div
      style={{
        display: 'inline',
        position: 'absolute',
        padding: '4px',
        fontSize: '1.2em',
        maxWidth: 'calc(100% - 20px)',
        fontFamily: 'LeagueSpartan,sans-serif',
        textAlign: 'left',
        borderRadius: '10px',
        boxSizing: 'border-box',
        backgroundColor: 'rgb(40,40,40,0.9)',
        whiteSpace: 'pre-wrap',
      }}>
      {entries
        .map(
          ([message, entries]) =>
            message + ' ' + entries.mapNotNull((it) => it.createBy?.displayName).join(', '),
        )
        .join('\n')}
    </div>
  )
}

export const ReplyBar = ({
  replies,
  user,
  onClick,
  allowReplying,
  children,
  className,
}: PropsWithChildren & {
  replies?: { [id: string]: TimelineReplyFirebaseEntry }
  user: User | undefined
  onClick: (reply: string) => void
  allowReplying: boolean
  className?: string
}) => {
  const repliesList = useMemo(() => replies && Object.values(replies), [replies])
  const repliesByEmoji = useMemo(() => repliesList?.groupBy((it) => it.message), [repliesList])
  const repliesByUser = useMemo(
    () => repliesList?.filter((it) => it.createBy?.uid === user?.uid),
    [repliesList, user],
  )
  const handleClick = useCallback((message: string) => onClick(message), [onClick])
  return (
    <div
      className={cn(
        'pointer-events-none z-10 flex h-auto w-full items-start justify-center gap-1',
        className,
      )}>
      {repliesByEmoji && <EmojiReplyDisplayBar repliesByEmoji={repliesByEmoji} />}
      {allowReplying && (
        <EmojiReplyButtonBar
          repliesByUser={repliesByUser}
          onClick={handleClick}
        />
      )}
      <div>{children}</div>
    </div>
  )
}
