import plusButtonIcon from 'icons/plus_button.png'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { NCImage } from './nextJs/components/nextCompatibleImage'

export default function SuggestionsPortal({
  filteredSuggestions,
  currentValue,
  suggestionPosition,
  onSelectSuggestion,
}: {
  filteredSuggestions?: string[]
  currentValue: string | undefined
  suggestionPosition: { top: number; left: number; width: string | number }
  onSelectSuggestion: (suggestion: string) => void
}) {
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null)

  useEffect(() => {
    setTargetElement(document.body)
  }, [])

  if (filteredSuggestions === undefined || !currentValue || !targetElement) return null
  return (
    <>
      {targetElement &&
        ReactDOM.createPortal(
          <ul
            className='fixed z-10 mt-1 w-fit list-none bg-[#353a44] p-0 font-league-spartan text-lg text-white'
            style={{
              top: suggestionPosition.top + 'px',
              left: suggestionPosition.left + 'px',
            }}>
            {filteredSuggestions?.map((suggestion) => (
              <li
                key={suggestion}
                className='cursor-pointer px-2 py-2 hover:border-2 hover:border-solid hover:border-gray-500 hover:bg-[#3E5076]'
                onClick={() => {
                  onSelectSuggestion?.(suggestion)
                }}>
                {suggestion}
              </li>
            ))}
            <li
              key={0}
              className='flex cursor-pointer items-center gap-2 px-2 py-1 hover:border-2 hover:border-solid
                hover:border-gray-500 hover:bg-[#3E5076]'
              onClick={() => {
                onSelectSuggestion(currentValue)
              }}>
              <NCImage
                src={plusButtonIcon}
                className='h-7 w-7'
                alt='plus'
              />
              <div className='flex flex-col'>
                <div>{currentValue}</div>
                <div className='text-sm'>Unlinked team</div>
              </div>
            </li>
          </ul>,
          targetElement,
        )}
    </>
  )
}
