import { StaticImport } from 'next/dist/shared/lib/get-img-props'
import React, { CSSProperties, HTMLAttributes, HtmlHTMLAttributes } from 'react'
import { NCImage, getImgSrcFromImport } from './nextJs/components/nextCompatibleImage'
import { cn } from './utils/tailwindUtils'

export type IconInfo =
  | string
  | { src: string | StaticImport; filter?: string; opacity?: string }
  | StaticImport

export interface BackgroundIconProps {
  src?: IconInfo
  alt?: string
  disableContextMenu?: boolean
  className?: string
  style?: CSSProperties
}

function isNotImageSrc(src: IconInfo): src is Exclude<IconInfo, string | StaticImport> {
  if (typeof src === 'string') return false
  if ('default' in src) return false
  if ('width' in src && 'height' in src) return false

  return !!src
}

/**
 * For when we really don't want context menus
 * @param props
 * @constructor
 */
export const BackgroundIcon = React.memo(function BackgroundIcon(
  props: BackgroundIconProps & HTMLAttributes<HTMLImageElement>,
) {
  const { src, alt, disableContextMenu, ...imageProps } = props
  if (!props.disableContextMenu)
    return (
      <>
        {src &&
          (isNotImageSrc(src) ?
            <NCImage
              className={cn(props.className)}
              draggable={false}
              {...imageProps}
              style={{
                ...props.style,
                opacity: props.style?.opacity ?? src?.opacity,
                filter: props.style?.filter ?? src?.filter,
              }}
              src={src?.src}
              alt={props.alt}
            />
          : <NCImage
              className={props.className}
              style={props.style}
              draggable={false}
              {...imageProps}
              src={src}
              alt={props.alt}
            />)}
      </>
    )
  return (
    <div
      {...imageProps}
      className={props.className}
      style={{
        ...props.style,
      }}>
      {src &&
        (isNotImageSrc(src) ?
          <div
            style={{
              backgroundImage: src && `url('${src.src}')`,
              filter: src?.filter,
              opacity: src?.opacity,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              height: '100%',
              width: '100%',
            }}
          />
        : <div
            style={{
              objectFit: 'inherit',
              backgroundImage: props.src && `url('${getImgSrcFromImport(src)}')`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              height: '100%',
              width: '100%',
            }}
          />)}
    </div>
  )
})
