'use client'

import { Input } from '@chakra-ui/react'
import React from 'react'
import { Dispatch, ReactNode, SetStateAction, useCallback, useMemo } from 'react'
import { cn } from './common/utils/tailwindUtils'

export type MultiSelectOption<T> = { children: ReactNode; value: T; disabled?: boolean }

export function MultiSelect<T>(props: {
  options:
    | MultiSelectOption<T>[]
    | ((toggleSelection: (selected: T) => void) => MultiSelectOption<T>[])
  selected: T[]
  setSelected: Dispatch<SetStateAction<T[]>>
  onToggleChanged?: (
    value: T,
    checked: boolean,
  ) => boolean | undefined | Promise<boolean | undefined>
  getKey: (value: T) => string
  className?: string
}) {
  const { current: getKey } = React.useRef(props.getKey)

  const { setSelected, options, onToggleChanged, selected } = props
  const toggleSelection = useCallback(
    async (value: T) => {
      const valueHash = getKey(value)
      if (
        selected.find((it) => getKey(it) === valueHash) &&
        (!onToggleChanged || (await onToggleChanged(value, false)))
      ) {
        setSelected(selected.filter((it) => getKey(it) !== valueHash))
      } else if (!onToggleChanged || (await onToggleChanged(value, true))) {
        setSelected(selected.concat(value))
      }
    },
    [getKey, onToggleChanged, selected, setSelected],
  )

  const mappedOptions = useMemo(
    () => (Array.isArray(options) ? options : options(toggleSelection)),
    [options, toggleSelection],
  )
  return (
    <div className={cn('flex flex-col flex-nowrap gap-2', props.className)}>
      {mappedOptions.map((option) => {
        const valueHash = getKey(option.value)
        return (
          <div
            key={valueHash}
            className='relative flex w-full flex-row flex-nowrap items-center gap-2'>
            <Input
              type={'checkbox'}
              readOnly={option.disabled}
              disabled={option.disabled}
              checked={!!props.selected.find((it) => getKey(it) === valueHash)}
              onClick={() => toggleSelection(option.value)}
            />
            <div className='pointer-events-auto'>{option.children}</div>
          </div>
        )
      })}
    </div>
  )
}
