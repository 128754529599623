'use client'

import React, { PropsWithChildren, RefObject, useEffect, useRef } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(
  ref: RefObject<HTMLDivElement | null>,
  onClickOutside?: (event: PointerEvent) => void,
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: PointerEvent) => {
      if (ref.current && event.target instanceof Element && ref.current.contains(event.target)) {
        return
      }
      onClickOutside?.(event)
    }
    // Bind the event listener
    document.addEventListener('pointerup', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('pointerup', handleClickOutside)
    }
  }, [ref, onClickOutside])
}

/**
 * Component that alerts if you click outside of it
 */
export default function OutsideAlerter(
  props: PropsWithChildren<{ className?: string; onClickOutside?: (e: PointerEvent) => void }>,
) {
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  useOutsideAlerter(wrapperRef, props.onClickOutside)

  return (
    <div
      className={props.className}
      ref={wrapperRef}>
      {props.children}
    </div>
  )
}
