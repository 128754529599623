import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import {
  BrowserRouter,
  NavigateOptions,
  StaticRouter,
  redirect as reactRedirect,
  useLocation as useReactLocation,
  useNavigate as useReactRouterNavigate,
  useSearchParams as useReactRouterSearchParams,
} from 'react-router'
import { NavigateFunction, RouterContextType, RouterHooks, RouterProps } from './routerInterfaces'

export const RouterContext = createContext<RouterContextType>({ isReactRouter: true })

export function Router(props: RouterProps) {
  const children = useMemo(() => <>{props.children}</>, [props.children])

  const [showBrowserRouter, setShowBrowserRouter] = useState(true)

  useEffect(() => {
    setShowBrowserRouter(true)
  }, [])

  return (
    <RouterContext.Provider value={{ isReactRouter: true }}>
      {showBrowserRouter ?
        <BrowserRouter>{children}</BrowserRouter>
      : <StaticRouter location='/'>{children}</StaticRouter>}
    </RouterContext.Provider>
  )
}

const hooks: RouterHooks = {
  useSearchParams(): URLSearchParams {
    return useReactRouterSearchParams()[0]
  },

  useNavigate(): NavigateFunction {
    const navigate = useReactRouterNavigate()
    return useCallback(
      (to: string | number, options?: NavigateOptions) => {
        if (typeof to === 'number') {
          navigate(to)
        } else {
          navigate(to, options)
        }
      },
      [navigate],
    )
  },

  useLocation(): string {
    const location = useReactLocation()
    return location.pathname
  },

  redirect(to: string) {
     reactRedirect(to)
  },
}

export default hooks
