import { NavButton } from 'components/CollapsableTopBar'
import { NCImage } from 'components/common/nextJs/components/nextCompatibleImage'
import { DocumentPermissions } from 'data/common'
import React from 'react'
import logoSvg from './betalogo.svg'

export function PlaybackHomeButton({
  documentPermissions,
  showCreateButton,
  compactMode,
}: {
  documentPermissions?: DocumentPermissions | undefined
  showCreateButton: boolean
  compactMode?: boolean
}) {
  return (
    <NavButton
      href='/'
      className='h-full bg-transparent p-0'
      alt={'Go to the home page or create a new playback'}>
      <PlaybackText />
    </NavButton>
  )
}

export const PlaybackText = React.memo(() => (
  <NCImage
    src={logoSvg}
    className='h-full'
  />
))
