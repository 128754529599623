'use client'

import { Flex } from '@chakra-ui/react'
import { ReactNode, createRef } from 'react'
import { isMobile } from 'react-device-detect'
import ReactPlayer from 'react-player'
import LazyReactPlayer from 'react-player/lazy'
import { getAspectRatioFitDimensions } from '../util/aspectRatioUtils'
import { BaseVideoPlayer } from './BaseVideoPlayer'
import { VideoPlayerProps } from './VideoPlayerProps'
import { VideoPlayerStatus } from './VideoPlayerStatus'

export class ReactVideoPlayer extends BaseVideoPlayer<ReactPlayer> {
  state = {
    playing: false,
    aspectRatio: undefined,
  }
  videoPlayerRef = createRef<LazyReactPlayer>()
  videoPlayerContainerRef = createRef<HTMLDivElement>()
  dimensionChangeCounter = 0
  statusAfterRedraw?: VideoPlayerStatus

  getStatusOwner(): ReactPlayer | undefined {
    return this.videoPlayerRef.current || undefined
  }

  shouldComponentUpdate(
    nextProps: Readonly<VideoPlayerProps>,
    nextState: Readonly<object>,
    nextContext: any,
  ): boolean {
    if (
      nextProps.source === 'Facebook_url' &&
      (nextProps.width !== this.props.width || nextProps.height !== this.props.height)
    ) {
      this.dimensionChangeCounter++
      if (!this.statusAfterRedraw) this.statusAfterRedraw = { ...this.currentStatus }
    }
    return true
  }

  async play(): Promise<void> {
    try {
      this.setState((state) => ({ ...state, playing: true }))
    } catch (e) {
      console.error(e)
    }
  }

  async pause(): Promise<void> {
    try {
      this.setState((state) => ({ ...state, playing: false }))
    } catch (e) {
      console.error(e)
    }
  }

  protected async seekToInternal(time: number): Promise<void> {
    try {
      await this.videoPlayerRef.current?.seekTo(time, 'seconds')
    } catch (e) {
      console.error(e)
    }
  }

  protected async scrubToInternal(time: number): Promise<void> {
    try {
      await this.videoPlayerRef.current?.seekTo(time, 'seconds')
    } catch (e) {
      console.error(e)
    }
  }

  async setPlaybackRate(suggestedRate: number): Promise<void> {
    // do nothing
  }

  protected async getPlayerStatusInternal(player: ReactPlayer): Promise<VideoPlayerStatus> {
    let title: string | undefined
    try {
      title = player.getInternalPlayer()['videoTitle']
    } catch {
      title = undefined
    }
    const [currentTime, duration] = await Promise.all([
      player.getCurrentTime(),
      player.getDuration(),
    ])
    return Object.assign(this.currentStatus, {
      currentTime,
      duration: currentTime > duration ? currentTime : duration,
      title,
    })
  }

  handlePlayerReady = async (player: ReactPlayer) => {
    this.getPlayerStatus(player).then((status) => this.props.onPlayerReady(this, status))
  }
  handlePlay = () => {
    this.currentStatus.playbackState = 'PLAYING'
    this.setState((state) => ({ ...state, playing: true }))
    this.props.onPlay()

    if (this.statusAfterRedraw) {
      this.videoPlayerRef?.current?.seekTo(this.statusAfterRedraw?.currentTime)
      this.statusAfterRedraw = undefined
    }
  }

  handlePause = () => {
    this.currentStatus.playbackState = 'PAUSED'
    this.setState((state) => ({ ...state, playing: false }))
    this.props.onPause()
  }

  render(): ReactNode {
    let [maxWidth, maxHeight] = getAspectRatioFitDimensions(
      4 / 3,
      this.props.width ?? 999999,
      this.props.height ?? 9999999,
    )
    maxWidth = Math.floor(maxWidth)
    maxHeight = Math.floor(maxHeight)
    return (
      <>
        <Flex
          position={'relative'}
          alignItems={'center'}
          justifyContent={'center'}
          ref={this.videoPlayerContainerRef}
          style={{
            width: isMobile ? this.props.width : `${maxWidth}px`,
            height: isMobile ? maxHeight : `${maxHeight}px`,
          }}>
          {/*<div ref={this.videoPlayerSizeRef}>*/}
          <LazyReactPlayer
            key={this.dimensionChangeCounter}
            playing={this.state.playing}
            muted={true}
            width={isMobile ? undefined : `${maxWidth}px`}
            height={isMobile ? undefined : `100%`}
            // facebook video id is the whole url
            url={this.props.videoId}
            controls={true}
            ref={this.videoPlayerRef}
            // videoId={this.props.videoId}
            onReady={this.handlePlayerReady}
            onPause={this.handlePause}
            config={{
              facebook: {
                attributes: {
                  'data-width': isMobile ? undefined : `${maxWidth}px`,
                  // 'data-height': isMobile ? undefined : `auto`,
                },
                appId: '1333355750783915',
              },
            }}
            onPlay={this.handlePlay}
          />
          {/*</div>*/}
        </Flex>
      </>
    )
  }
}
