'use client'

import React from 'react'
import dynamic from './common/nextJs/components/nextCompatibleDynamic'

const Helmet = dynamic(() => import('react-helmet'), { ssr: false })

export function DocumentTitle(props: { title: string; children: React.ReactNode }) {
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      {props.children}
    </>
  )
}
