'use client'

import AppBaseWrappers from 'nextJs/layouts/appBaseWrappers'
import ReactDOM from 'react-dom/client'
import './App.css'
import AppRoutes from './appRoutes'
import './components/common/Dialog.css'
import { disableErrorOverlay } from './components/common/utils/reactUtils'
import './index.css'
import './locales/i18n'
import reportWebVitals from './reportWebVitals'
import './ui/EventFilterBar.css'
import './util/stringUtils'
import { RouterProvider } from 'react-router'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
if (process.env.NODE_ENV === 'development') {
  disableErrorOverlay()
}

root.render(
  <AppBaseWrappers>
    <AppRoutes />
  </AppBaseWrappers>,
)
// root.render(
//   <React.StrictMode>
//     <RouterProvider router={router} />
//   </React.StrictMode>
// )
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
