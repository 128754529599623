/* eslint-disable @typescript-eslint/no-namespace */
import {
  BaseTimelineMarkerFirebaseEntry,
  FirebaseDrawingPath,
  FirebaseEventExtra,
  FirebaseEventMetaStatsEntry,
  FirebaseSubmittedChangeEntry,
  PlayerFirebaseEntry,
  ReviewFirebaseEntry,
  TimelineMarkerFirebaseEntry,
  TimelineMarkerNoteFirebaseEntry,
} from 'data/common'
import { EventDefinition, NOTE_DEFINITION, resolveStringOrFn } from 'templates/TemplateConfig'
import { Player } from './data/Player'
import { BaseTimelineEvent, TimelineEvent, TimelineNote } from './data/TimelineEvent'
import { TimelineEventExtra } from './data/TimelineEventExtra'
import { mapLegacyKeyToEvent as mapLegacyKeyToDefinition } from './data/backwards/MapLegacyKeyToEvent'

export namespace TimelineStorage {
  export function mapFirebaseFromTimelineEvent(
    event: TimelineEvent<EventDefinition>,
  ): TimelineMarkerFirebaseEntry {
    const baseProps = {
      id: event.id,
      time: event.time,
      duration: event.duration || null,
      timeEnd: null,
      title: resolveStringOrFn(event.title),
      extra: (event.extra && mapFirebaseFromExtra(event.extra)) || null,
      dataTags: event.tags || null,
      fallbackDefinitionKey: event.fallbackDefinition_key || null,
      who: event.who || null,
      definitionKey: event.tag.key,
      description: resolveStringOrFn(event.description) || null,
      team: event.team,
      createdDate: event.createdDate,
      modifiedDate: event.modifiedDate || null,
      createBy: event.createBy,
      modifiedBy: event.modifiedBy,
      seenBy: event.seenBy,
      isDeleted: !!event?.isDeleted,
    } satisfies BaseTimelineMarkerFirebaseEntry

    if (event.specialType === 'note') {
      return {
        ...baseProps,
        definitionKey: event.definition_key,
        replies: mapFirebaseRepliesFromTimelineEventReplies(event.replies),
        reactedDate: event.reactedDate || null,
        parentNoteId: event.parentNoteId || null,
      } satisfies TimelineMarkerNoteFirebaseEntry
    }

    return baseProps
  }

  export function mapFirebaseRepliesFromTimelineEventReplies(
    replies: TimelineNote<EventDefinition>['replies'],
  ): TimelineMarkerNoteFirebaseEntry['replies'] {
    return { ...replies }
  }

  export function mapFirebaseRepliesToTimelineEventReplies(
    replies: TimelineMarkerNoteFirebaseEntry['replies'],
  ): TimelineNote<EventDefinition>['replies'] {
    return { ...replies }
  }

  export const mapFirebaseToTimelineEvent =
    (source: TimelineEvent<EventDefinition>['source']) =>
    (entry: TimelineMarkerFirebaseEntry): TimelineEvent<EventDefinition> => {
      let definition: EventDefinition | undefined = undefined
      let fallbackDefinition: EventDefinition | undefined = undefined
      let extra: FirebaseEventExtra | undefined
      try {
        definition = mapLegacyKeyToDefinition(entry.definitionKey)
        fallbackDefinition =
          entry.fallbackDefinitionKey ?
            mapLegacyKeyToDefinition(entry.fallbackDefinitionKey)
          : undefined
        if (definition || fallbackDefinition) {
          extra = entry.extra || undefined
        } else {
          definition = NOTE_DEFINITION
          extra = `${entry.title + '\n' || ''}${(entry.extra ? mapFirebaseToExtra(entry.extra).message : undefined) || ''}`
        }
      } catch (e) {
        console.log(e)
        definition = NOTE_DEFINITION
        extra = `${entry.title + '\n' || ''}${(entry.extra ? mapFirebaseToExtra(entry.extra).message : undefined) || ''}`
      }

      const key = definition?.key ?? entry.definitionKey
      definition = definition ?? fallbackDefinition ?? NOTE_DEFINITION

      const baseProps = {
        ...definition,
        specialType: undefined,
        id: entry.id,
        time: entry.time,
        duration: entry.duration ?? ((entry.timeEnd && entry.timeEnd - entry.time) || undefined),
        tags: definition.dataTags || entry.dataTags || undefined,
        tag: definition,
        fallbackDefinition_key: entry.fallbackDefinitionKey || undefined,
        extra: extra ? mapFirebaseToExtra(extra) : undefined,
        title: resolveStringOrFn(definition.title),
        description: resolveStringOrFn(definition.description),
        color: definition.color,
        icon: definition.icon,
        who: (entry.who || []) as Player[],
        definition_key: key,
        team: entry.team || 0,
        createdDate: entry.createdDate || 1,
        modifiedDate: entry.modifiedDate || undefined,
        createBy: entry.createBy || { uid: '0', displayName: ' ' },
        modifiedBy: entry.modifiedBy || [],
        seenBy: entry.seenBy || {},
        isDeleted: entry.isDeleted,
        source,
      } satisfies BaseTimelineEvent<EventDefinition>

      if (key === 'note' || key === 'note_sketch' || key === 'clip') {
        const note = entry as TimelineMarkerNoteFirebaseEntry
        return {
          ...baseProps,
          specialType: 'note',
          reactedDate: note.reactedDate || undefined,
          definition_key: key,
          replies: mapFirebaseRepliesToTimelineEventReplies(note.replies),
          parentNoteId: note.parentNoteId || undefined,
        }
      }

      return baseProps
    }

  export function mapFirebaseFromMetaStats(metaStats: MetaStats): FirebaseEventMetaStatsEntry {
    return { ...metaStats }
  }

  export function mapFirebaseToMetaStats(entry: FirebaseEventMetaStatsEntry): MetaStats {
    return {
      ...entry,
      requestedChangesCount: entry.requestedChangesCount ?? 0,
      trackingProgress: entry.trackingProgress ?? 0,
    }
  }

  export function mapFirebaseFromExtra(extra: TimelineEventExtra): FirebaseEventExtra {
    return {
      type: 'object',
      drawing: extra.drawing ? { paths: mapFirebaseFromCanvasPaths(extra.drawing) } : null,
      message: extra.message ?? null,
    }
  }

  export function mapFirebaseToExtra(extra: FirebaseEventExtra): TimelineEventExtra {
    if (typeof extra === 'string') {
      return { message: extra }
    }
    return {
      drawing: extra.drawing?.paths ? mapFirebaseToCanvasPaths(extra.drawing.paths) : undefined,
      message: extra.message ?? undefined,
    }
  }

  export function mapFirebaseFromCanvasPaths(paths: CanvasPath[]): FirebaseDrawingPath[] {
    return paths.map((it) => ({ ...it }) satisfies FirebaseDrawingPath)
  }

  export function mapFirebaseToCanvasPaths(paths: FirebaseDrawingPath[]): CanvasPath[] {
    return paths.map((it) => ({ ...it, drawMode: true }) satisfies CanvasPath)
  }

  export const mapFirebaseToPlayer =
    (source: Player['source']) =>
    (entry: PlayerFirebaseEntry): Player => ({
      ...entry,
      team: entry.team || 0,
      name: entry.name.replace(/\s+/g, ' ').trim(),
      matchNumber: entry.matchNumber || 0,
      source,
    })

  export const mapFirebaseFromPlayer = (player: Player): PlayerFirebaseEntry => ({
    id: player.id,
    team: player.team,
    matchNumber: player.matchNumber,
    color: player.color,
    name: player.name,
    index: player.index,
  })

  export const mapFirebaseToSubmittedChange = <T extends object>(
    entry: FirebaseSubmittedChangeEntry<T>,
  ): SubmittedChange<T> => ({
    ...entry,
  })

  export const mapFirebaseFromSubmittedChange = <T extends object>(
    entry: SubmittedChange<T>,
  ): FirebaseSubmittedChangeEntry<T> => ({
    ...entry,
  })
}

export type CreateReviewFirebaseEntry = Required<
  Pick<ReviewFirebaseEntry, 'videoId' | 'source' | 'activityType'>
> &
  Pick<ReviewFirebaseEntry, 'parentStatRecord' | 'statsTemplateKey'>

export interface MetaStats {
  eventCount: number
  commentCount: number
  requestedChangesCount: number
  /** 0 - 1.0 */
  trackingProgress: number
  score: { win: number; lose: number; draw: number }
}

export type SubmittedChange<T extends object | string | number> = {
  changes: T
  dateSubmitted: number
  ownerUid: string
  ownerName: string | null
  ownerEmail: string | null
}
type Point = {
  x: number
  y: number
}
type CanvasPath = {
  readonly paths: Point[]
  readonly strokeWidth: number
  readonly strokeColor: string
  readonly drawMode: boolean
  readonly startTimestamp?: number
  readonly endTimestamp?: number
}
