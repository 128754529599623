import { UnreachableError } from 'components/common/utils/error'
import { VideoSource } from 'data/common'
import { FirebaseVideoSource } from 'data/common'
import getVideoId from 'get-video-id'

export async function getThumbnail(
  videoId: string | null,
  baseSource: FirebaseVideoSource | null,
): Promise<string> {
  if (!videoId) return getPlaceholderThumbnail()
  const source: VideoSource = baseSource || 'Youtube'
  switch (source) {
    case 'Youtube':
      return getYoutubeThumbnailLink(videoId)
    case 'Facebook_url':
      return (await getFacebookThumbnail(videoId)) || getPlaceholderThumbnail()
    case 'Youtube_url':
      const youtubeID = getVideoId(videoId)
      if (baseSource === 'Youtube_url' && youtubeID.id && youtubeID.service === 'youtube')
        return getYoutubeHqThumbnailLink(youtubeID.id)
      return getPlaceholderThumbnail()
    default:
      throw UnreachableError(source)
  }
}

export function getYoutubeThumbnailLink(videoId: string) {
  return `https://img.youtube.com/vi/${videoId}/default.jpg`
}

export function getYoutubeHqThumbnailLink(videoId: string) {
  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
}

export function getPlaceholderThumbnail() {
  return `https://playbackreview.com/preview.jpg`
}

export async function getFacebookThumbnail(url: string) {
  return getPlaceholderThumbnail()
}
// const id = await getFacebookVideoId(url)
// if (!id) return undefined
// return `https://graph.facebook.com/${id}/picture`
