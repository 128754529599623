'use client'

import { ReactNode, useEffect } from 'react'

export default function VhProvider({ children }: { children: ReactNode }) {
  useEffect(() => {
    const handleResize = () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return <>{children}</>
}
