'use client'

import { Colors } from 'Colors'
import { useSingleGroupPlayerStore } from 'UseGroupSelectionStore'
import { normaliseName } from 'data/NormaliseName'
import { stringAsDatabaseKey } from 'data/common'
import {
  AggregateMeta,
  FirebaseBaseLeaderboardEntry,
  FirebaseGameResult,
  NswdlDivFilter,
  NswdlDivFilters,
  ResultDividers,
} from 'data/leaderboardtypes'
import { User } from 'firebase/auth'
import { StaticImport } from 'next/dist/shared/lib/get-img-props'
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart } from 'recharts'
import DodgeballIcons from 'templates/dodgeball/DodgeballIcons'
import { DodgeballMeasureKeys } from 'templates/dodgeball/MeasureKeys'
import { PillContainer } from 'util/dialogUtils'
import userIcon from '../assets/img/sportsplayeravatar.webp'
import logo from '../betalogo.svg'
import { DodgeballLeaderboardMeasures } from '../templates/dodgeball/DodgeballLeaderboardMeasures'
import { DraggableScrollBox } from './DraggableScrollBox'
import {
  HighlightedNoteBox,
  Measure,
  MeasureResult,
  ignoreMultiKeyedResultErrors,
  mapToLeaderboardEntryGameResults,
} from './RankingsByDiv'
import { ReviewResultsList, filterResults, gameResultsToLeagueResults } from './RankingsTable'
import { AvatarDisplay } from './UploadAvatar'
import { compositeRef } from './UseCompositeRefObject'
import {
  FirebaseComponents,
  FirebaseDb,
  FirebaseDbReference,
  MultiKeyedDatabaseResult,
  ignoreError,
  useDatabaseMultiRefLiveValue,
  useDatabaseRefLiveValueMemo,
} from './common/Firebase'
import { useFeatureFlag } from './common/hooks/useFeatureFlags'
import { NCImage } from './common/nextJs/components/nextCompatibleImage'
import { cn } from './common/utils/tailwindUtils'

import defend_icon = DodgeballIcons.defend_icon
import trade_icon = DodgeballIcons.trade_icon
import opp_shot_icon = DodgeballIcons.opp_shot_icon

export namespace PlayerStatProfile {
  export const BASIC = 0
  export const ADVANCED = 1
  export type StatsLevel = typeof BASIC | typeof ADVANCED
}

export const PlayerLeaderboardStatProfile = forwardRef<
  HTMLDivElement,
  {
    className?: string
    animationKey?: number
    firebase: FirebaseComponents
    user: User | undefined
    leaderboardRef: FirebaseDbReference<FirebaseBaseLeaderboardEntry>
    groupId: string
    playerName: string
    includeForFun?: boolean
    selectedMeasure?: Measure
    onMeasureSelected?: (measure: Measure) => void
    onDivisionSelected?: (division: NswdlDivFilter) => void
    selectedDivision?: NswdlDivFilter
    isMemberOfGroup?: boolean
    onlySpotlight?: boolean
    zoom?: number
  } & (
    | {
        maxRanking: number | undefined
        measureRankings: Map<
          Measure,
          {
            displayRank: number
            player: string
            reviews: (FirebaseGameResult & {
              dividers: ResultDividers
            })[]
            total: MeasureResult | undefined
          }[]
        >
      }
    | {
        maxRanking?: number
        measureRankings?: undefined
      }
  )
>(function PlayerLeaderboardStatProfile(
  { selectedMeasure: externallySelectedMeasure, ...props },
  ref,
) {
  const groupName = useDatabaseRefLiveValueMemo(
    () => props.leaderboardRef.childFromKey('title'),
    [props.leaderboardRef],
  )
  const groupColor = useDatabaseRefLiveValueMemo(
    () => props.leaderboardRef.childFromKey('groupColor'),
    [props.leaderboardRef],
  )
  const results = useDatabaseMultiRefLiveValue<
    DodgeballMeasureKeys,
    NonNullable<
      NonNullable<FirebaseBaseLeaderboardEntry['resultsByPlayer']>[DodgeballMeasureKeys]
    >[string]
  >(
    () =>
      DodgeballLeaderboardMeasures.map((measure) => ({
        key: measure.key,
        ref: props.leaderboardRef
          .child('resultsByPlayer')
          .child(measure.key)
          .child(stringAsDatabaseKey(props.playerName)),
      })),
    [props.leaderboardRef, props.playerName],
  )

  const parsedResults = useMemo(() => ignoreMultiKeyedResultErrors(results), [results])
  const playerKey = stringAsDatabaseKey(props.playerName)
  const parsedResultsByPlayer: FirebaseBaseLeaderboardEntry['resultsByPlayer'] = useMemo(
    () =>
      Object.entries(parsedResults.results)
        .map(([measureKey, gameResults]) => {
          return [measureKey as DodgeballMeasureKeys, { [playerKey]: gameResults }] as [
            DodgeballMeasureKeys,
            FirebaseBaseLeaderboardEntry['resultsByPlayer'][DodgeballMeasureKeys],
          ]
        })
        .toObject(),
    [parsedResults.results, playerKey],
  )

  const distinctDivisionKeys = useMemo(
    () =>
      Object.values(parsedResults.results)
        .filterNotNull()
        .flatMap((it) => Object.values(it).mapProp('dividers'))
        .flatMap((it) => it.matchingDivisions?.map((it) => it.key))
        .distinct(),
    [parsedResults.results],
  )
  const divisionsFilters = useMemo(
    () => NswdlDivFilters.filter((it) => distinctDivisionKeys.includes(it.key)),
    [distinctDivisionKeys],
  )

  const selectedDivisionState = useState<NswdlDivFilter>(
    props.selectedDivision ?? divisionsFilters.firstOrNull() ?? NswdlDivFilters[0],
  )

  const [selectedDivision, setSelectedDivision] = selectedDivisionState

  useEffect(
    () => props.selectedDivision && setSelectedDivision(props.selectedDivision),
    [props.selectedDivision, setSelectedDivision],
  )

  const resultsFilteredByDividers = useMemo(() => {
    return filterResults(
      mapToLeaderboardEntryGameResults(parsedResultsByPlayer),
      (it) => it.dividers.matchingDivisions?.any((it) => it.key === selectedDivision.key) ?? false,
    )
  }, [parsedResultsByPlayer, selectedDivision])
  const normalisedPlayerName = useMemo(() => normaliseName(props.playerName), [props.playerName])
  const reviewsResultsByMeasure = useMemo(() => {
    return DodgeballLeaderboardMeasures.map((measure) => {
      const result = gameResultsToLeagueResults(
        measure,
        resultsFilteredByDividers,
        (it) => (it.normalisedPlayer ?? normaliseName(it.player)) === normalisedPlayerName,
      )

      const displayValue = result.total?.displayValue ?? result.total?.value ?? 'N/A'
      return [measure.key, { result, displayValue: displayValue, value: result.total?.value }] as [
        Measure['key'],
        {
          result: ReturnType<typeof gameResultsToLeagueResults>
          displayValue: string
          value: number
        },
      ]
    }).toMap()
  }, [resultsFilteredByDividers, normalisedPlayerName])

  const [selectedMeasure, setSelectedMeasure] = useState<Measure | undefined>(
    externallySelectedMeasure ?? DodgeballLeaderboardMeasures[0],
  )

  useEffect(() => {
    if (externallySelectedMeasure) {
      setSelectedMeasure(externallySelectedMeasure)
    }
  }, [externallySelectedMeasure])

  const reviewsToShow = useMemo(() => {
    if (!selectedMeasure) return undefined
    return reviewsResultsByMeasure.get(selectedMeasure.key)?.result?.reviews
  }, [selectedMeasure, reviewsResultsByMeasure])

  const containerRef = useRef<HTMLDivElement>(null)
  const scrollToSelectedMeasure = (selectedMeasure: Measure) => {
    const element = containerRef.current
      ?.getElementsByClassName(`measure-${selectedMeasure.key}`)
      .item(0)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    }
  }

  const scrollToSelectedDivision = (selectedDivision: NswdlDivFilter) => {
    const element = document.getElementById(`division-${selectedDivision.key}`)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    }
  }

  useEffect(() => {
    if (!selectedMeasure) return
    const timeout = setTimeout(() => {
      scrollToSelectedMeasure(selectedMeasure)
    }, 1000)
    return () => clearTimeout(timeout)
  }, [selectedMeasure, props.animationKey])

  useEffect(() => {
    if (!selectedDivision) return
    const timeout = setTimeout(() => {
      scrollToSelectedDivision(selectedDivision)
    }, 2000)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDivision])

  const measures = useMemo(
    () =>
      DodgeballLeaderboardMeasures.filter(
        (it) => !it.hidden && (props.includeForFun || !it.forfun),
      ),
    [props.includeForFun],
  )

  const rankingsByMeasure = useDatabaseMultiRefLiveValue(() => {
    return measures.map((measure) => ({
      key: measure.key,
      ref: props.leaderboardRef
        .childFromKey('aggregateResultsByPlayer')
        .childFromKey(measure.key)
        .childFromKey('divisions')
        .childFromKey(selectedDivision.key)
        .childFromKey('byPlayerNameHash')
        .childFromKey(stringAsDatabaseKey(props.playerName)),
    }))
  }, [
    selectedMeasure,
    resultsFilteredByDividers,
    selectedDivision,
    props.leaderboardRef,
    props.playerName,
  ])

  const aggregateMetaByMeasure = useDatabaseMultiRefLiveValue(() => {
    return measures.map((measure) => ({
      key: measure.key,
      ref: props.leaderboardRef
        .childFromKey('aggregateResultsByPlayer')
        .childFromKey(measure.key)
        .childFromKey('divisions')
        .childFromKey(selectedDivision.key)
        .childFromKey('valueAggregatesByPlayer'),
    }))
  }, [
    selectedMeasure,
    resultsFilteredByDividers,
    selectedDivision,
    props.leaderboardRef,
    props.playerName,
  ])

  return (
    <div
      ref={compositeRef(ref, containerRef)}
      className={cn(
        `flex max-h-full w-[360px] flex-col gap-4 overflow-y-auto overflow-x-clip rounded-lg bg-gradient-to-r
        from-[#3E5076] from-20% to-dark-grey pt-5 text-white shadow-2xl`,
        props.className,
      )}
      style={{ zoom: props.zoom }}>
      <ProfileSpotlight
        animationKey={props.animationKey ?? 0}
        firebase={props.firebase.firebaseDb}
        groupName={groupName ?? undefined}
        groupColor={groupColor ?? undefined}
        groupId={props.groupId}
        isMemberOfGroup={props.isMemberOfGroup ?? false}
        parsedResults={parsedResults}
        playerName={props.playerName}
        reviewsResultsByMeasure={reviewsResultsByMeasure}
        aggregateMetaByMeasure={aggregateMetaByMeasure}
      />
      {!props.onlySpotlight && (
        <>
          <DraggableScrollBox
            direction='horizontal'
            handleScrollWheel={true}
            className='max-w-full px-3 scrollbar-none'>
            {divisionsFilters.length ?
              <PillContainer<NswdlDivFilter>
                noWrap={true}
                defaultColor={Colors.color_black}
                pills={divisionsFilters.map((it) => ({
                  title: it.title,
                  state: it,
                }))}
                id={(it) => `division-${it.key}`}
                state={selectedDivision}
                setState={props.onDivisionSelected ?? setSelectedDivision}
              />
            : <div className='flex h-6 flex-row'></div>}
          </DraggableScrollBox>
          <div className='flex min-h-0 shrink grow basis-0 flex-col'>
            <DraggableScrollBox
              direction='horizontal'
              handleScrollWheel={true}
              className='h-fit w-full shrink-0 px-4 py-3 pt-1 scrollbar-none'>
              <div className='flex h-fit w-full flex-row flex-nowrap items-center gap-2'>
                {measures.map((measure, index) => {
                  const entry = reviewsResultsByMeasure.get(measure.key)

                  const cachedResult = ignoreError(rankingsByMeasure[measure.key])
                  const result =
                    props.measureRankings
                      ?.get(measure)
                      ?.find((it) => it.player === props.playerName) ?? cachedResult

                  const displayRanking =
                    (
                      result &&
                      (props.maxRanking === undefined || result.displayRank <= props.maxRanking)
                    ) ?
                      result.displayRank.toString()
                    : undefined
                  return (
                    <ProfileMeasureDisplay
                      className={`measure-${measure.key}`}
                      ranking={displayRanking}
                      backgroundColor={Colors.color_dark_grey}
                      firebase={props.firebase.firebaseDb}
                      key={measure.key}
                      playerName={props.playerName}
                      measure={measure}
                      selected={selectedMeasure === measure}
                      onClick={props.onMeasureSelected ?? setSelectedMeasure}
                      value={entry?.displayValue ?? '--'}
                    />
                  )
                })}
              </div>
            </DraggableScrollBox>

            {selectedMeasure && reviewsToShow && (
              <DraggableScrollBox
                direction='both'
                className='overflow-auto px-4 py-5 shadow-inner shadow-black/30 scrollbar-none'>
                <ReviewResultsList
                  firebase={props.firebase}
                  measure={selectedMeasure}
                  reviews={reviewsToShow}
                  enableReviewNavigation={true}
                  referrerUrl={window.location.pathname + window.location.search}
                />
              </DraggableScrollBox>
            )}
          </div>
        </>
      )}
    </div>
  )
})

const ProfileSpotlight = forwardRef<
  HTMLDivElement,
  {
    groupName: string | undefined
    groupColor: string | undefined
    playerName: string
    firebase: FirebaseDb
    parsedResults: ReturnType<
      typeof ignoreMultiKeyedResultErrors<
        DodgeballMeasureKeys,
        {
          [pushKey: string]: FirebaseGameResult & {
            dividers: ResultDividers
          }
        }
      >
    >
    groupId: string
    isMemberOfGroup: boolean
    animationKey: number
    aggregateMetaByMeasure: MultiKeyedDatabaseResult<
      DodgeballMeasureKeys,
      AggregateMeta | undefined | null
    >
    reviewsResultsByMeasure: Map<
      DodgeballMeasureKeys,
      {
        result: ReturnType<typeof gameResultsToLeagueResults>
        displayValue: string
        value: number
      }
    >
  }
>(function ProfileSpotlight(
  { groupName, groupColor, parsedResults, reviewsResultsByMeasure, ...props },
  ref,
) {
  const { groupPlayer, updateGroupPlayerAvatar } = useSingleGroupPlayerStore(
    props.firebase,
    props.groupId,
    props.playerName,
  )
  const { value: enableStatProfilePicture } = useFeatureFlag(
    'enableStatProfilePicture',
    false,
    true,
  )
  return (
    <div
      ref={ref}
      className='flex flex-col gap-4 px-4'>
      <div className='relative flex flex-row flex-nowrap items-start justify-between'>
        <div className='flex flex-col'>
          <span
            className='font-league-spartan text-xl leading-none font-bold'
            style={{ color: groupColor ?? undefined }}>
            {groupName}
          </span>
          <span className='font-montserrat text-xl leading-4 font-semibold'>
            {props.playerName}
          </span>
        </div>
        <NCImage
          className='h-full w-24 object-contain object-top'
          src={logo}
        />
      </div>
      {Object.entries(parsedResults.errors).any(([measureKey, error]) => !!error) && (
        <HighlightedNoteBox>Error loading results for {props.playerName}</HighlightedNoteBox>
      )}

      <div className='relative flex flex-row justify-stretch gap-4'>
        <AvatarDisplay
          blur={!enableStatProfilePicture}
          backgroundColor={Colors.color_dark_grey}
          src={groupPlayer?.avatarUrl ?? userIcon}
          onAvatarChange={props.isMemberOfGroup ? updateGroupPlayerAvatar : undefined}
          className={cn('min-h-full w-32 min-w-0 rounded-md bg-slate-800')}
        />

        <div className='flex min-w-0 flex-col gap-4'>
          <div className='z-10 flex flex-col gap-1'>
            {DodgeballLeaderboardMeasures.find((it) => it.key === 'value_ratio') && (
              <MainMeasureDisplay
                title='Kill Death Ratio'
                icon={trade_icon}
                firebase={props.firebase}
                playerName={props.playerName}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                measure={DodgeballLeaderboardMeasures.find((it) => it.key === 'value_ratio')!}
                value={reviewsResultsByMeasure.get('value_ratio')?.displayValue ?? 'N/A'}
              />
            )}
            {DodgeballLeaderboardMeasures.find((it) => it.key === 'defence_rate') && (
              <MainMeasureDisplay
                title='Defence'
                icon={defend_icon}
                firebase={props.firebase}
                playerName={props.playerName}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                measure={DodgeballLeaderboardMeasures.find((it) => it.key === 'defence_rate')!}
                value={reviewsResultsByMeasure.get('defence_rate')?.displayValue ?? 'N/A'}
              />
            )}
            {DodgeballLeaderboardMeasures.find((it) => it.key === 'set_win_rate') && (
              <MainMeasureDisplay
                title='Set win rate'
                icon={opp_shot_icon}
                firebase={props.firebase}
                playerName={props.playerName}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                measure={DodgeballLeaderboardMeasures.find((it) => it.key === 'set_win_rate')!}
                value={reviewsResultsByMeasure.get('set_win_rate')?.displayValue ?? 'N/A'}
              />
            )}
          </div>
          <div className='h-12 w-10'>
            <div className='absolute bottom-0 right-0 w-fit'>
              <PlayerRadarChart
                key={props.animationKey}
                playerName={props.playerName}
                reviewResultsByMeasure={reviewsResultsByMeasure}
                aggregateMetaByMeasure={props.aggregateMetaByMeasure}
                size={200}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

function MainMeasureDisplay({
  measure,
  playerName,
  value,
  ...props
}: {
  icon?: string | StaticImport
  title: string
  firebase: FirebaseDb
  playerName: string
  measure: Measure
  value: string
}) {
  return (
    <div className='flex flex-col'>
      <div
        className='flex flex-row items-center gap-2 text-nowrap font-montserrat text-xs uppercase text-playback-crimson
          font-semibold'>
        {props.icon && (
          <NCImage
            src={props.icon}
            className='h-[2em] w-[2em] object-contain'
            // use https://isotropic.co/tool/hex-color-to-css-filter/ to get the filter
            style={{
              filter:
                'invert(43%) sepia(77%) saturate(4980%) hue-rotate(333deg) brightness(91%) contrast(89%)',
            }}
          />
        )}
        {props.title}
      </div>
      <span className='font-league-spartan text-[1.5rem] leading-9 font-bold'>{value}</span>
    </div>
  )
}
export function ProfileMeasureDisplay({
  measure,
  value,
  onClick,
  ranking,
  ...props
}: {
  className?: string
  ranking?: string
  firebase: FirebaseDb
  playerName: string
  measure: Measure
  value: string
  backgroundColor: string
  onClick?: (measure: Measure) => void
  selected?: boolean
}) {
  return (
    <div
      onClick={onClick && (() => onClick?.(measure))}
      className={cn(
        `pointer-events-auto flex w-fit flex-row gap-4 rounded-md border border-b-0 border-r-0 border-solid
        border-white border-b-transparent border-r-transparent border-opacity-20 bg-gradient-to-tl
        from-[#dedede17] from-20% to-[#ebebeb4e] p-[6px]`,
        props.selected && 'outline outline-2 outline-offset-2',
        props.className,
      )}>
      <div className='flex flex-col'>
        <span className='text-nowrap font-sans text-2xs uppercase text-black font-medium'>
          {measure.title}
        </span>
        <div className='flex flex-row justify-between'>
          <span className='font-league-spartan text-xl font-extrabold'>{value}</span>
          {ranking !== undefined && (
            <span className='flex flex-col items-end justify-center text-nowrap font-montserrat text-xs'>
              🏆 {ranking}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

function usePreviousNonNull<T>(value: T): T {
  const nonNUll = useRef<T>(value)
  return useMemo(() => {
    if (value !== undefined && value !== null) nonNUll.current = value
    return nonNUll.current
  }, [value])
}

function useLatestNonNullOnDep<T>(value: T | undefined, dep: any): T | undefined {
  const previousValue = usePreviousNonNull(value)

  const previousOnDep = useMemo(() => {
    return previousValue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dep)

  return value ?? previousOnDep
}
function scaleViaMiddle(value: number | undefined, min: number, max: number, middle: number) {
  if (value === undefined) return { value: undefined, min, max, middle }
  const clampedValue = Math.min(Math.max(value, min), max)

  let scaledValue
  if (clampedValue < middle) {
    scaledValue = (clampedValue - min) / (middle - min) / 2
  } else {
    scaledValue = 0.5 + (clampedValue - middle) / (max - middle) / 2
  }

  return { scaledValue, min, max, middle }
}
type GetMeasureValue = (measureKey: DodgeballMeasureKeys) => number | undefined
type ValueProvider = (props: {
  getMeasureValue: GetMeasureValue
  getMeasureDenominator: GetMeasureValue
}) => number | undefined

function scaleViaAggregate({
  value,
  reviewResultsByMeasure,
  aggregateMetaByMeasure,
  defaultMin,
  defaultMax,
}: {
  value: ValueProvider
  reviewResultsByMeasure: Map<
    DodgeballMeasureKeys,
    {
      result: ReturnType<typeof gameResultsToLeagueResults>
      displayValue: string
      value: number
    }
  >
  aggregateMetaByMeasure: MultiKeyedDatabaseResult<
    DodgeballMeasureKeys,
    AggregateMeta | undefined | null
  >
  defaultMin: number
  defaultMax: number
}) {
  const resultValue = value({
    getMeasureValue: (measureKey) => reviewResultsByMeasure.get(measureKey)?.value,
    getMeasureDenominator: (measureKey) => reviewResultsByMeasure.get(measureKey)?.value,
  })
  const maxValue = value({
    getMeasureValue: (measureKey) => ignoreError(aggregateMetaByMeasure?.[measureKey])?.maximum,
    getMeasureDenominator: (measureKey) =>
      ignoreError(aggregateMetaByMeasure?.[measureKey])?.maximum,
  })
  const minValue = value({
    getMeasureValue: (measureKey) => ignoreError(aggregateMetaByMeasure?.[measureKey])?.minimum,
    getMeasureDenominator: (measureKey) =>
      ignoreError(aggregateMetaByMeasure?.[measureKey])?.maximum,
  })
  const medianValue = value({
    getMeasureValue: (measureKey) => ignoreError(aggregateMetaByMeasure?.[measureKey])?.median,
    getMeasureDenominator: (measureKey) =>
      ignoreError(aggregateMetaByMeasure?.[measureKey])?.median,
  })
  return {
    ...scaleViaMiddle(
      resultValue,
      minValue ?? defaultMin,
      maxValue ?? defaultMax,
      medianValue ?? ((minValue ?? defaultMin) + (maxValue ?? defaultMax)) / 2,
    ),
    actualValue: resultValue,
  }
}

function usePlayerRadarData(
  playerName: string,
  reviewResultsByMeasure: Map<
    DodgeballMeasureKeys,
    {
      result: ReturnType<typeof gameResultsToLeagueResults>
      displayValue: string
      value: number
    }
  >,
  aggregateMetaByMeasure: MultiKeyedDatabaseResult<
    DodgeballMeasureKeys,
    AggregateMeta | undefined | null
  >,
) {
  const scale = useCallback(
    ({
      value,
      defaultMin,
      defaultMax,
    }: {
      value: ValueProvider
      defaultMin: number
      defaultMax: number
    }) => {
      const result = scaleViaAggregate({
        value,
        reviewResultsByMeasure,
        aggregateMetaByMeasure,
        defaultMin,
        defaultMax,
      })

      return {
        actualValue: result.actualValue,
        actualMin: result.min,
        actualMax: result.max,
        actualMedian: result.middle,
        value: result.scaledValue,
        min: 0.0,
        max: 1.0,
      }
    },
    [aggregateMetaByMeasure, reviewResultsByMeasure],
  )
  return [
    {
      subject: 'Hit rate',
      ...scale({
        value: ({ getMeasureValue }) => getMeasureValue('hit_rate'),
        defaultMin: 0.0,
        defaultMax: 0.4,
      }),
    },
    {
      subject: 'Clutch',
      ...scale({
        value: ({ getMeasureValue, getMeasureDenominator }) =>
          getMeasureValue('sets_clutch_win_rate'),
        defaultMin: 0.0,
        defaultMax: 0.6,
      }),
    },
    {
      subject: 'Defence',
      ...scale({
        value: ({ getMeasureValue }) => getMeasureValue('defence_rate'),
        defaultMin: 0.6,
        defaultMax: 0.9,
      }),
    },
    {
      subject: 'Catches',
      ...scale({
        value: ({ getMeasureValue, getMeasureDenominator }) =>
          getMeasureDenominator('sets_played') ?
            (getMeasureValue('catches') ?? 0) /
            Math.max(getMeasureDenominator('sets_played') ?? 1, 1)
          : undefined,
        defaultMin: 0.0,
        defaultMax: 0.25,
      }),
    },
    {
      subject: 'Opening rush',
      ...scale({
        value: ({ getMeasureValue }) => getMeasureValue('opening_engagement_hit_rate'),
        defaultMin: 0.0,
        defaultMax: 0.7,
      }),
    },
  ] as const
}

function PlayerRadarChart(props: {
  className?: string
  size: number
  playerName: string
  reviewResultsByMeasure: Map<
    DodgeballMeasureKeys,
    {
      result: ReturnType<typeof gameResultsToLeagueResults>
      displayValue: string
      value: number
    }
  >
  aggregateMetaByMeasure: MultiKeyedDatabaseResult<
    DodgeballMeasureKeys,
    AggregateMeta | undefined | null
  >
}) {
  const radarData = usePlayerRadarData(
    props.playerName,
    props.reviewResultsByMeasure,
    props.aggregateMetaByMeasure,
  )
  const scaledSetWinRate = useMemo(() => {
    const result = scaleViaAggregate({
      value: ({ getMeasureValue }) => getMeasureValue('set_win_rate'),
      aggregateMetaByMeasure: props.aggregateMetaByMeasure,
      reviewResultsByMeasure: props.reviewResultsByMeasure,
      defaultMin: 0.0,
      defaultMax: 1.0,
    })
    return result.scaledValue ?? 0
  }, [props.aggregateMetaByMeasure, props.reviewResultsByMeasure])

  const mappedData = useMemo(() => {
    const data =
      radarData?.map(({ max, subject, value, min }) => ({
        subject,
        A: Math.min(Math.max(((Math.min(value ?? 0, max) - min) * 100) / (max - min), 0.0), 100.0),
        B: Math.min(Math.max(scaledSetWinRate * 100, 0.0), 100.0),
      })) ?? []
    if (props.playerName.toLocaleLowerCase().includes('christian medina')) {
      debugger
    }
    return data
  }, [radarData, scaledSetWinRate, props.playerName])

  return (
    <RadarChart
      outerRadius={props.size / 6.5}
      width={props.size}
      height={(props.size * 0.9) / 2}
      data={mappedData}>
      <PolarGrid strokeOpacity={0.1} />
      <PolarRadiusAxis
        domain={[0.0, 100.0]}
        opacity={0}
      />
      <PolarAngleAxis
        dataKey='subject'
        strokeOpacity={0.1}
        fontSize={8}
        tick={{ fill: 'white', opacity: 0.5 }}
        fontFamily={'Montserrat,sans-serif'}
      />
      <Radar
        name='Overall'
        dataKey='B'
        stroke='#338833'
        fill='#338833'
        fillOpacity={0.6}
      />
      <Radar
        name='Player'
        dataKey='A'
        stroke='#888888'
        fill='#888888'
        fillOpacity={0.6}
      />
    </RadarChart>
  )
}
