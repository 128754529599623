import React, { HTMLAttributes } from 'react'
import { BackgroundIcon, BackgroundIconProps, IconInfo } from './BackgroundIcon'
import { cn } from './utils/tailwindUtils'

export type LayeredIcon = IconInfo | IconInfo[]

// export function composeLayeredIcon(...icons: LayeredIcon[]) {
//   const result: LayeredIcon = []
//   icons.forEach((icon) => {
//     if (Array.isArray(icon)) {
//       result.push(...icon)
//     } else {
//       result.push(icon)
//     }
//   })
//
//   return result
// }
function objectFitClass(
  objectFit: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down' | undefined,
) {
  if (!objectFit) return ''
  return {
    contain: 'object-contain',
    cover: 'object-cover',
    fill: 'object-fill',
    none: 'object-none',
    'scale-down': 'object-scale-down',
  }[objectFit]
}
export const LayeredIconView = React.memo(function LayeredIconView({
  src,
  iconProps,
  className,
  style,
  objectFit,
  ...divProps
}: {
  src?: LayeredIcon
  className?: string
  style?: React.CSSProperties
  iconProps?: Omit<BackgroundIconProps, 'src' | 'alt'>
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'
} & HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn('relative flex', className)}
      style={style}
      {...divProps}>
      {!Array.isArray(src) && (
        <BackgroundIcon
          src={src}
          alt={''}
          className={cn(objectFitClass(objectFit), className, 'absolute h-full w-full')}
          {...iconProps}
        />
      )}
      {Array.isArray(src) &&
        src?.map((icon, index) => (
          <BackgroundIcon
            key={index}
            alt={''}
            {...iconProps}
            src={icon}
            className={cn(objectFitClass(objectFit), className, 'absolute h-full w-full')}
          />
        ))}
    </div>
  )
})
