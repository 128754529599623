'use client'

import {
  FirebaseDb,
  useDatabasePathLiveValue,
  useMappedDatabasePathLiveValue,
} from 'components/common/Firebase'
import { FirebaseGroupEntry } from 'data/common'
import { useEffect } from 'react'

export function useGroupPermissionsStore(
  firebaseDb: FirebaseDb,
  userId: string | undefined | null,
  groupId: string | undefined,
) {
  const editingUsersResult = useMappedDatabasePathLiveValue<FirebaseGroupEntry['editingUsers']>(
    firebaseDb,
    (userId && groupId && `groups/${groupId}/editingUsers`) ?? undefined,
  )

  const ownerResult = useMappedDatabasePathLiveValue<NonNullable<FirebaseGroupEntry['owner']>>(
    firebaseDb,
    (userId && groupId && `groups/${groupId}/owner`) ?? undefined,
  )

  const membersResult = useMappedDatabasePathLiveValue<NonNullable<FirebaseGroupEntry['members']>>(
    firebaseDb,
    (userId && groupId && `groups/${groupId}/members`) ?? undefined,
  )

  const blockExternalAccessToReviews = useDatabasePathLiveValue<boolean | undefined>(
    firebaseDb,
    `groups/${groupId}/security/blockExternalAccessToReviews`,
  )

  useEffect(() => {
    if (editingUsersResult.type === 'ERROR' && process.env.NODE_ENV === 'development') {
      console.log('Error in editingUsersResult', editingUsersResult.error)
    }
  }, [editingUsersResult])
  useEffect(() => {
    if (ownerResult.type === 'ERROR' && process.env.NODE_ENV === 'development') {
      console.log('Error in ownerResult', ownerResult.error)
    }
  }, [ownerResult])
  useEffect(() => {
    if (membersResult.type === 'ERROR' && process.env.NODE_ENV === 'development') {
      console.log('Error in membersResult', membersResult.error)
    }
  }, [membersResult])

  if (userId === null) {
    return {
      isOwner: false,
      isMember: false,
      canEdit: false,
    }
  }
  return {
    isOwner:
      ownerResult.type === 'SUCCESS' ? !!userId && ownerResult.value === userId
      : ownerResult.type === 'ERROR' ? undefined
      : null,
    isMember:
      (
        editingUsersResult.type === 'SUCCESS' &&
        membersResult.type === 'SUCCESS' &&
        ownerResult.type === 'SUCCESS'
      ) ?
        !!userId ?
          !!editingUsersResult.value?.[userId] ||
          !!membersResult.value?.[userId] ||
          ownerResult.value === userId
        : false
      : (
        ownerResult.type === 'ERROR' ||
        membersResult.type === 'ERROR' ||
        editingUsersResult.type === 'ERROR'
      ) ?
        undefined
      : null,
    canEdit:
      editingUsersResult.type === 'SUCCESS' && ownerResult.type === 'SUCCESS' ?
        !!userId && (!!editingUsersResult.value?.[userId] || ownerResult.value === userId)
      : ownerResult.type === 'ERROR' || editingUsersResult.type === 'ERROR' ? undefined
      : null,
    blockExternalAccessToReviews,
  }
}
