'use client'

import { Flags } from 'FLAGS'
import { useGroupDetailsStore } from 'UseGroupSelectionStore'
import { ignoreMultiKeyedResultErrors } from 'components/RankingsByDiv'
import { FirebaseFlagEntry } from 'components/common/hooks/useFeatureFlags'
import { UnreachableError } from 'components/common/utils/error'
import {
  FLAGS_CONFIG,
  FLAG_DEFAULTS,
  ProFeatureFlagsDefinition,
  SubscriptionContext,
} from 'data/ProFeatureFlagConfig'
import {
  FirebaseGroupDetails,
  FirebaseGroupEntry,
  ReviewFirebaseEntry,
  SubscriptionType,
} from 'data/common'
import { Subscription } from 'data/stripe/StripeSubscription'
import { User } from 'firebase/auth'
import { where } from 'firebase/firestore'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { firebaseConfig } from 'services/FirebaseConfig'
import { useFlagsStore } from 'ui/AdminManagementDialog'
import {
  FirebaseComponents,
  useDatabaseMultiPathLiveValue,
  useDatabasePathLiveValue,
  useFireStoreMultiRefLiveCollectionValue,
  useFireStoreMultiRefLiveDocValue,
  useFirebase,
  useFirestoreNullableQueryLiveValue,
} from '../components/common/Firebase'

export type FlagProvider<P extends ViewContext, T> = (
  props: P,
) => Promise<T | undefined> | T | undefined

export type SubscriptionConfig = {
  [key in FlagsSubscriptionType]: (
    context: Extract<SubscriptionContext, { type: key }>,
  ) => ProFeatureFlagsDefinition
}

export type BaseProFeatureFlagsDefinition = {
  [id: string]:
    | GroupBasedFlagDefinition<any>
    | ReviewBasedFlagDefinition<any>
    | GeneralFlagDefinition<any>
}

export type ProFeatureFlags = {
  [key in keyof Required<ProFeatureFlagsDefinition>]:
    | Awaited<ReturnType<NonNullable<ProFeatureFlagsDefinition[key]>['provider']>>
    | undefined
}

export type BaseFlagsSubscriptionContext = { type: string }
export type FlagsSubscriptionType = SubscriptionContext['type']

type UseProFeaturesStoreReturn = {
  featureFlags: ProFeatureFlags
  subscriptionSources: SubscriptionContext[]
  refresh: () => void
}

const mergeFeatureFlags = (
  baseFlags: ProFeatureFlags,
  additionalFlags: ProFeatureFlags,
): ProFeatureFlags => {
  const mergedFlags: Required<ProFeatureFlags> = { ...baseFlags }
  for (const key in additionalFlags) {
    const castedKey = key as keyof ProFeatureFlags
    if (additionalFlags[castedKey] !== undefined) {
      const mergedFlagsValue = mergedFlags[castedKey]
      const additionalFlagsValue = additionalFlags[castedKey]
      Object.assign(mergedFlags, {
        [castedKey]:
          typeof mergedFlagsValue === 'number' && typeof additionalFlagsValue === 'number' ?
            Math.max(mergedFlagsValue, additionalFlagsValue)
          : additionalFlagsValue || mergedFlagsValue,
      })
    }
  }
  return mergedFlags
}

export const useProFeaturesStore = ({
  user,
  groupId,
  reviewId,
}: {
  user?: User | undefined | null
  // the group dashboard or leaderboard the user is viewing
  // NOTE: NOT!! the group that the review belongs to since this can be obtained using the reviewId
  groupId?: string | undefined
  // the review the user is viewing
  reviewId?: string | undefined
}): UseProFeaturesStoreReturn => {
  const firebase = useFirebase(firebaseConfig)
  const [featureFlags, setFeatureFlags] = useState<ProFeatureFlags>({ ...FLAG_DEFAULTS })
  const [subscriptionContexts, setSubscriptionContexts] = useState<SubscriptionContext[]>([])
  const { groupDetails } = useGroupDetailsStore(firebase.firebaseDb, groupId)
  const userFlags = useFlagsStore(firebase.firebaseDb, 'users', user?.uid)

  const userGroups = useDatabasePathLiveValue<{ [groupId: string]: boolean }>(
    firebase.firebaseDb,
    user ? `users/${user.uid}/groups` : undefined,
  )

  const viewedGroupMembers = useDatabasePathLiveValue<FirebaseGroupEntry['editingUsers']>(
    firebase.firebaseDb,
    groupId ? `groups/${groupId}/editingUsers` : undefined,
  )

  const reviewGroups = useDatabasePathLiveValue<ReviewFirebaseEntry['groups']>(
    firebase.firebaseDb,
    reviewId ? `reviews/${reviewId}/groups` : undefined,
  )

  const viewedGroupSubscriptionInfo = useGroupsSubscriptionInfo(firebase, groupId)
  const reviewGroupsSubscriptionsInfo = useGroupsSubscriptionInfo(firebase, reviewGroups)
  const userGroupsSubscriptionsInfo = useGroupsSubscriptionInfo(firebase, userGroups)

  const userGroupIds = useMemo(
    () =>
      Object.entries(userGroups ?? {}).mapNotNull(([groupId, value]) =>
        value ? groupId : undefined,
      ),
    [userGroups],
  )
  const reviewGroupIds = useMemo(
    () =>
      Object.entries(reviewGroups ?? {}).mapNotNull(([groupId, value]) =>
        value ? groupId : undefined,
      ),
    [reviewGroups],
  )

  const groupIds = useMemo(
    () => [groupId, ...userGroupIds, ...reviewGroupIds].filterNotNull(),
    [groupId, userGroupIds, reviewGroupIds],
  )

  const groupFlags = useFlagsStore(firebase.firebaseDb, 'groups', groupIds)

  const reviewOwner = useDatabasePathLiveValue<ReviewFirebaseEntry['owner']>(
    firebase.firebaseDb,
    reviewId ? `reviews/${reviewId}/owner` : undefined,
  )

  const reviewEditingUsers = useDatabasePathLiveValue<ReviewFirebaseEntry['editingUsers']>(
    firebase.firebaseDb,
    reviewId ? `reviews/${reviewId}/editingUsers` : undefined,
  )

  const createFlagContext = useMemo(() => {
    return subscriptionContextToFlagContextFactory({
      user: user,
      groupId: groupId,
      reviewId: reviewId,
      reviewGroups: reviewGroups,
      reviewAccess: { owner: reviewOwner, editors: reviewEditingUsers },
      groupAccess: {
        owner: (groupId && viewedGroupSubscriptionInfo[groupId]?.owner) ?? null,
        members: viewedGroupMembers ?? {},
      },
      userGroups: userGroups,
    })
  }, [
    groupId,
    reviewId,
    reviewGroups,
    userGroups,
    reviewOwner,
    reviewEditingUsers,
    viewedGroupMembers,
    user,
    viewedGroupSubscriptionInfo,
  ])

  const updateFeatureFlags = useCallback(async () => {
    const mergedFlags = await renderCombinedFlags(subscriptionContexts, createFlagContext)
    setFeatureFlags(mergedFlags)
  }, [createFlagContext, subscriptionContexts])

  useEffect(() => {
    updateFeatureFlags()
  }, [updateFeatureFlags])

  const refresh = useCallback(async () => {
    // Re-fetch or recheck the subscription sources and update the state
    // Implement your logic here to recheck the sources
    // Example placeholder:
    const groups = [
      ...Object.entries(viewedGroupSubscriptionInfo).map(([groupId, subscriptionsInfo]) => ({
        groupId,
        groupDetails: undefined,
        groupSubscriptions: subscriptionsInfo?.subscriptions ?? [],
      })),
      ...Object.entries(reviewGroupsSubscriptionsInfo).map(([groupId, subscriptionsInfo]) => ({
        groupId,
        groupDetails: undefined,
        groupSubscriptions: subscriptionsInfo?.subscriptions ?? [],
      })),
      ...Object.entries(userGroupsSubscriptionsInfo).map(([groupId, subscriptionsInfo]) => ({
        groupId,
        groupDetails: undefined,
        groupSubscriptions: subscriptionsInfo?.subscriptions ?? [],
      })),
    ]
      .filterNotNull()
      .distinctBy(({ groupId }) => groupId)

    const newSubscriptionSources = await fetchSubscriptionSources({
      firebase,
      user,
      // TODO: Add individual user subscriptions
      userSubscriptions: [],
      groups,
      userFlags,
      groupFlags,
    })

    setSubscriptionContexts(newSubscriptionSources)
  }, [
    viewedGroupSubscriptionInfo,
    reviewGroupsSubscriptionsInfo,
    userGroupsSubscriptionsInfo,
    firebase,
    user,
    userFlags,
    groupFlags,
  ])

  useEffect(() => {
    refresh()
  }, [groupDetails, refresh])

  // TODO(chien): Add "check if a group has pro-features enabled"

  return { featureFlags, subscriptionSources: subscriptionContexts, refresh }
}

function getSubscriptionByPlanType(
  userSubscriptions: Subscription[],
  groupId: string | undefined,
  planType: string,
) {
  return userSubscriptions.find(
    (s) => groupId && s.metadata.groupId === groupId && s.metadata.plan_type === planType,
  )
}

async function fetchSubscriptionSources({
  firebase,
  user,
  userSubscriptions,
  groups,
  userFlags,
  groupFlags,
}: {
  firebase: FirebaseComponents
  user: User | undefined | null
  userSubscriptions: Subscription[]
  groups: {
    groupId: string
    groupSubscriptions: Subscription[]
    groupDetails: FirebaseGroupDetails | undefined
  }[]
  userFlags: NonNullable<FirebaseFlagEntry['users']>
  groupFlags:NonNullable<FirebaseFlagEntry['groups']>
}): Promise<SubscriptionContext[]> {
  // TODO(Davey): CHECK SUBSCRIPTION SOURCES
  const teamSubscriptionPlans = groups.mapNotNull(({ groupSubscriptions, groupId }) => {
    const teamSubscriptionPlan = getSubscriptionByPlanType(groupSubscriptions, groupId, 'team')
    return teamSubscriptionPlan ? ([groupId, teamSubscriptionPlan] as const) : undefined
  })
  const leagueSubscriptionPlans = groups.mapNotNull(({ groupSubscriptions, groupId }) => {
    const leagueSubscriptionPlan = getSubscriptionByPlanType(groupSubscriptions, groupId, 'league')
    return leagueSubscriptionPlan ? ([groupId, leagueSubscriptionPlan] as const) : undefined
  })
  const individualSubscriptionPlan = getSubscriptionByPlanType(
    userSubscriptions,
    undefined,
    'individual',
  )

  const groupFlagsMapped = Object.entries(groupFlags ?? {})
    .mapNotNull(([groupId, flags]) => {
      const type = Object.entries(flags ?? {})
        .mapNotNull<[string, boolean], Extract<FlagsSubscriptionType, 'teamPro' | 'leaguePro'>>(
          ([subscriptionType, value]) =>
            value ?
              subscriptionType === 'team_pro' ? 'teamPro'
              : subscriptionType === 'league_pro' ? 'leaguePro'
              : undefined
            : undefined,
        )
        .firstOrNull()
      if (!type) return undefined
      return {
        type: type,
        groupId: groupId,
        interval: 'forever',
        seats: 10,
      } satisfies SubscriptionContext
    })
    .filterNotNull()
  return (
    [
      user && (Flags.STAFF.includes(user.uid) || userFlags[user.uid]?.staff) ?
        {
          type: 'staff',
          userId: user.uid,
        }
      : undefined,
      (
        user &&
        (individualSubscriptionPlan || Flags.PRO_TRIAL.includes(user.uid) || userFlags[user.uid]?.statsPro)
      ) ?
        {
          type: 'statsPro',
          userId: user.uid,
        }
      : undefined,
      ...teamSubscriptionPlans.map(
        ([groupId, teamSubscriptionPlan]) =>
          ({
            type: 'teamPro',
            groupId: groupId,
            interval: teamSubscriptionPlan.metadata?.interval,
            // TODO: Check subscription for number of members
            seats: teamSubscriptionPlan.quantity ?? 10,
          }) satisfies SubscriptionContext,
      ),
      ...leagueSubscriptionPlans.map(
        ([groupId, leagueSubscriptionPlan]) =>
          ({
            type: 'leaguePro',
            groupId: groupId,
            interval: leagueSubscriptionPlan.metadata?.interval,
            // TODO: Check subscription for number of members
            seats: leagueSubscriptionPlan.quantity ?? 10,
          }) satisfies SubscriptionContext,
      ),
      ...groupFlagsMapped,
    ] satisfies (SubscriptionContext | undefined)[]
  ).filterNotNull()
}

export async function renderCombinedFlags(
  subscriptionContexts: SubscriptionContext[],
  flagContext: ViewContext,
  subscriptionConfig: SubscriptionConfig = FLAGS_CONFIG,
  flagDefaults = FLAG_DEFAULTS,
) {
  let mergedFlags: ProFeatureFlags = { ...flagDefaults }
  await subscriptionContexts.mapAwait(async (subscriptionContext) => {
    mergedFlags = mergeFeatureFlags(
      mergedFlags,
      await renderFlags(subscriptionContext, flagContext, subscriptionConfig),
    )
  })

  return mergedFlags
}

/**
 * Processes and renders feature flags based on a subscription context and view context.
 *
 * @param subscriptionContext - The subscription details (e.g., team pro, league pro, stats pro) that determine available features
 * @param flagContext - The current view context containing information about which group/review is being viewed and user access
 * @param config - Configuration mapping subscription types to their feature flag definitions (defaults to FLAGS_CONFIG)
 * @returns A promise resolving to ProFeatureFlags object where each key maps to its computed flag value
 *
 * The function works by:
 * 1. Getting the feature provider for the subscription type
 * 2. Processing each flag definition based on its type:
 *    - 'group': Only processes if there's a group in view
 *    - 'review': Only processes if there's a review in view
 *    - 'general': Only processes if there's a review in view
 * 3. Converting the processed flags into a key-value object
 *
 * Each flag value is computed by calling its provider function with the appropriate context.
 */
export async function renderFlags(
  subscriptionContext: SubscriptionContext,
  flagContext: ViewContext,
  config: SubscriptionConfig = FLAGS_CONFIG,
): Promise<ProFeatureFlags> {
  const provider = config[subscriptionContext.type](subscriptionContext as any)
  const mapped = (
    await Object.entries(provider).mapAwait(async ([key, value]) => {
      const castedKey = key as keyof ProFeatureFlagsDefinition
      const castedValue =
        value as BaseProFeatureFlagsDefinition[keyof BaseProFeatureFlagsDefinition]
      switch (castedValue.type) {
        case 'group':
          return flagContext.groupInView ?
              ([
                castedKey,
                await castedValue.provider({
                  ...flagContext,
                  groupInView: flagContext.groupInView,
                }),
              ] as [keyof ProFeatureFlags, ProFeatureFlags[keyof ProFeatureFlags]])
            : undefined
        case 'review':
          return flagContext.reviewInView ?
              ([
                castedKey,
                await castedValue.provider({
                  ...flagContext,
                  reviewInView: flagContext.reviewInView,
                }),
              ] as [keyof ProFeatureFlags, ProFeatureFlags[keyof ProFeatureFlags]])
            : undefined
        case 'general':
          return flagContext.reviewInView ?
              ([castedKey, await castedValue.provider(flagContext)] as [
                keyof ProFeatureFlags,
                ProFeatureFlags[keyof ProFeatureFlags],
              ])
            : undefined
        default:
          throw UnreachableError(castedValue)
      }
    })
  ).filterNotNull()

  return mapped.toObject() as ProFeatureFlags
}

const subscriptionContextToFlagContextFactory = ({
  user,
  groupId,
  reviewId,
  groupAccess,
  reviewAccess,
  userGroups,
  reviewGroups,
}: {
  user: User | undefined | null
  reviewId: string | undefined
  // the group dashboard or leaderboard the user is viewing
  groupId: string | undefined
  // access to the group of the dashboard or leaderboard the user is viewing
  groupAccess?: {
    members: FirebaseGroupEntry['editingUsers']
    owner: FirebaseGroupEntry['owner']
  }
  reviewAccess?: {
    editors: ReviewFirebaseEntry['editingUsers']
    owner: ReviewFirebaseEntry['owner']
  }
  userGroups?: {
    [groupId: string]: boolean
  }
  reviewGroups?: {
    [groupId: string]: boolean
  }
}): ViewContext => {
  return {
    groupInView:
      groupId ?
        {
          groupId,
          isUserInGroup:
            !!user && (groupAccess?.owner === user.uid || groupAccess?.members[user.uid] === true),
        }
      : undefined,
    reviewInView:
      reviewId ?
        {
          reviewId,
          isUserAnEditor:
            !!user &&
            (reviewAccess?.owner === user.uid || reviewAccess?.editors?.[user.uid] === true),
          groupIds: reviewGroups,
        }
      : undefined,
    user:
      user ?
        {
          userId: user.uid,
          groupIds: userGroups,
        }
      : undefined,
  }
}

// Information about where the user is trying to access the pro feature
export type ViewContext = {
  groupInView?: {
    // the group dashboard/private leaderboard the user is viewing
    groupId: string
    // whether the group is the one being checked
    isUserInGroup: boolean
  }
  reviewInView?: {
    // the review the user is viewing
    reviewId: string
    // the group the review belongs to
    groupIds?: { [groupId: string]: boolean | undefined }
    // whether the review belongs to the paid group being checked
    isUserAnEditor: boolean
  }
  user?: {
    userId: string
    // the groups the user is a member of
    groupIds?: { [groupId: string]: boolean | undefined }
  }
}

type GroupViewContext = ViewContext & Required<Pick<ViewContext, 'groupInView'>>
type ReviewViewContext = ViewContext & Required<Pick<ViewContext, 'reviewInView'>>

export type GroupBasedFlagDefinition<T> = {
  type: 'group'
  provider: FlagProvider<GroupViewContext, T>
}
export type ReviewBasedFlagDefinition<T> = {
  type: 'review'
  provider: FlagProvider<ReviewViewContext, T>
}
export type GeneralFlagDefinition<T> = {
  type: 'general'
  provider: FlagProvider<ViewContext, T>
}

export function groupFlag<T>(p: FlagProvider<GroupViewContext, T>): GroupBasedFlagDefinition<T> {
  return { type: 'group', provider: p }
}
export function reviewFlag<T>(p: FlagProvider<ReviewViewContext, T>): ReviewBasedFlagDefinition<T> {
  return { type: 'review', provider: p }
}
export function generalFlag<T>(p: FlagProvider<ViewContext, T>): GeneralFlagDefinition<T> {
  return { type: 'general', provider: p }
}

function useGroupsSubscriptionInfo(
  firebase: FirebaseComponents,
  groups: { [groupId: string]: boolean } | string | undefined,
) {
  const groupIds = useMemo(() => {
    if (typeof groups === 'string') return [groups]
    return Object.entries(groups ?? {})
      .filter(([_, value]) => value)
      .map(([id]) => id)
      .distinct()
  }, [groups])

  const reviewGroupsWithGroupOwners = useDatabaseMultiPathLiveValue<string, string>(
    firebase.firebaseDb,
    () => groupIds.mapNotNull((id) => ({ key: id, path: `groups/${id}/owner` })),
    [groupIds],
  )
  const reviewGroupsWithSubscriptionPath = useDatabaseMultiPathLiveValue<
    string,
    NonNullable<FirebaseGroupEntry['subscription']>
  >(
    firebase.firebaseDb,
    () => groupIds.mapNotNull((id) => ({ key: id, path: `groups/${id}/subscription` })),
    [groupIds],
  )

  const reviewGroupSubscriptions = useFireStoreMultiRefLiveDocValue<string, Subscription>(() => {
    return Object.entries(
      ignoreMultiKeyedResultErrors(reviewGroupsWithSubscriptionPath).results,
    ).mapNotNull(([id, subscriptionInfo]) => {
      if (!subscriptionInfo?.firestorePath) return undefined
      return {
        key: id,
        ref: firebase.firestoreDb.doc(subscriptionInfo.firestorePath),
      }
    })
  }, [reviewGroupsWithSubscriptionPath, firebase.firestoreDb])

  const reviewOwnerSubscriptions = useFireStoreMultiRefLiveCollectionValue<
    string,
    Subscription
  >(() => {
    return Object.entries(
      ignoreMultiKeyedResultErrors(reviewGroupsWithGroupOwners).results,
    ).mapNotNull(([id, owner]) => {
      if (!owner) return undefined
      return {
        key: id,
        ref:
          owner ?
            firebase.firestoreDb
              .collection('users')
              .doc(owner)
              .collection('subscriptions')
              .query<Subscription>(where('status', 'in', ['active']))
          : undefined,
      }
    })
  }, [reviewGroupsWithGroupOwners, firebase.firestoreDb])

  return useMemo(() => {
    const combinedInfo: {
      [groupId: string]: { subscriptions?: Subscription[]; owner?: string } | undefined
    } = {}

    Object.entries(ignoreMultiKeyedResultErrors(reviewGroupsWithGroupOwners).results).forEach(
      ([groupId, owner]) => {
        combinedInfo[groupId] = { ...combinedInfo[groupId], owner: owner }
      },
    )

    // Combine owners
    Object.entries(ignoreMultiKeyedResultErrors(reviewGroupSubscriptions).results).forEach(
      ([groupId, subscription]) => {
        if (subscription)
          combinedInfo[groupId] = {
            ...combinedInfo[groupId],
            subscriptions: [...(combinedInfo[groupId]?.subscriptions ?? []), subscription],
          }
      },
    )

    // Combine subscriptions
    Object.entries(ignoreMultiKeyedResultErrors(reviewOwnerSubscriptions).results).forEach(
      ([groupId, subscriptions]) => {
        if (subscriptions)
          combinedInfo[groupId] = {
            ...combinedInfo[groupId],
            subscriptions: [...(combinedInfo[groupId]?.subscriptions ?? []), ...subscriptions],
          }
      },
    )

    return combinedInfo
  }, [reviewGroupSubscriptions, reviewGroupsWithGroupOwners, reviewOwnerSubscriptions])
}
