'use client'

import { DocumentPermissions, ReviewFirebaseEntry } from 'data/common'
import { User } from 'firebase/auth'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ActivityTemplates } from 'templates/TemplateLibrary'
import { FirebaseDb, useDatabaseRefLiveValue } from '../components/common/Firebase'
import { useQueryString } from '../components/common/utils/QueryString'
import { PropType } from '../components/common/utils/typescriptUtils'
import { ViewingMode } from '../data/ViewingMode'
import { ReviewActivityType } from './ReviewMetaStore'

export type SetViewingModeProps =
  | { viewingMode: 'view'; user?: User }
  | { viewingMode: 'edit'; user: User }

export type SetDocumentPermissionsProps =
  | ({
      documentPermissions: DocumentPermissions
      credentials?: DocumentPermissionCredentials
    } & {
      documentPermissions: 'edit'
      // TODO(DAvey): require some sort of edit token
      // credentials: Required<DocumentPermissionCredentials>
    })
  | { documentPermissions: 'view' }

export type DocumentPermissionCredentials = {
  edit?: string
}

/**
 * This will
 */
export function useDocumentPermissionsStore({
  reviewId,
  firebaseDb,
  user,
  isLocalReview,
  activityType,
}: {
  activityType: ReviewActivityType | undefined
  reviewId: string | undefined
  firebaseDb: FirebaseDb
  user: User | undefined
  isLocalReview: boolean
}): {
  documentPermissions?: DocumentPermissions
  viewingMode: ViewingMode
  owner: string | undefined
  ownerName: string | undefined
  isCreatedByStaff: boolean | undefined
  /** Attempt to change the viewingmode based on whether they have permissions and are logged in**/
  setViewingMode: (props: SetViewingModeProps) => boolean
  /** This should only be used when navigating to a design assuming permissions have been pre-determined*/
  // TODO(DAvey): require some sort of edit token
  setDocumentPermissions: (props: SetDocumentPermissionsProps) => void
} {
  // TODO(DAvey): require some sort of edit token
  const [queryPermissions, setQueryPermissions] = useQueryString('mode')
  const [viewMode, setViewingModeInternal] = useState<ViewingMode>('view')

  const dbRef = useMemo(
    () =>
      !!reviewId ?
        firebaseDb.getRef<ReviewFirebaseEntry['editingUsers']>(`reviews/${reviewId}/editingUsers`)
      : undefined,
    [reviewId, firebaseDb],
  )
  const editingUsers: PropType<ReviewFirebaseEntry, 'editingUsers'> = useDatabaseRefLiveValue<
    PropType<ReviewFirebaseEntry, 'editingUsers'>
  >({
    ref: dbRef,
  })

  const owner: PropType<ReviewFirebaseEntry, 'owner'> = useDatabaseRefLiveValue<
    PropType<ReviewFirebaseEntry, 'owner'>
  >({
    ref: useMemo(
      () => (reviewId ? firebaseDb.getRef(`reviews/${reviewId}/owner`) : undefined),
      [reviewId, firebaseDb],
    ),
  })

  const ownerName: PropType<ReviewFirebaseEntry, 'ownerName'> = useDatabaseRefLiveValue<
    PropType<ReviewFirebaseEntry, 'ownerName'>
  >({
    ref: useMemo(
      () => (reviewId ? firebaseDb.getRef(`reviews/${reviewId}/ownerName`) : undefined),
      [reviewId, firebaseDb],
    ),
  })

  const isCreatedByStaff: PropType<ReviewFirebaseEntry, 'isCreatedByStaff'> =
    useDatabaseRefLiveValue<PropType<ReviewFirebaseEntry, 'isCreatedByStaff'>>({
      ref: useMemo(
        () => (reviewId ? firebaseDb.getRef(`reviews/${reviewId}/isCreatedByStaff`) : undefined),
        [reviewId, firebaseDb],
      ),
    })

  const editingUsersArray = useMemo(() => {
    return Object.keys(editingUsers ?? {})
  }, [editingUsers])

  const mappedDocumentPermissions: DocumentPermissions | undefined = useMemo(() => {
    if (!reviewId) return undefined
    if (queryPermissions === 'view') {
      return (
          isLocalReview ||
            (user &&
              (editingUsersArray.includes(user.uid) || (owner !== undefined && owner === user.uid)))
        ) ?
          'edit'
        : 'view'
    } else if (queryPermissions === 'edit') {
      return 'edit'
    } else {
      // TODO(Davey) Eventually we want to make it default to View permissions or No permissions.
      //  But first we need to make sure all our share links are generated with the correct (permissions) parameter.
      return 'edit'
    }
  }, [user, owner, queryPermissions, isLocalReview, editingUsersArray, reviewId])

  useEffect(() => {
    if (queryPermissions === 'edit' && !!reviewId) {
      // Claim edit permissions
      if (user && !editingUsersArray.includes(user.uid)) {
        firebaseDb.getRef(`reviews/${reviewId}/editingUsers/${user.uid}`).set(true)
      }
    }
  }, [firebaseDb, user, reviewId, queryPermissions, editingUsersArray])
  useEffect(() => {
    if (
      activityType &&
      ActivityTemplates[activityType].defaultViewMode === 'edit' &&
      mappedDocumentPermissions === 'edit' &&
      (user || isLocalReview)
    ) {
      setViewingModeInternal('edit')
    }
  }, [activityType, mappedDocumentPermissions, user, isLocalReview])
  useEffect(() => {
    if (!user && !!reviewId) {
      if (isLocalReview) {
        setViewingModeInternal('edit')
      } else {
        setViewingModeInternal('view')
      }
    }
  }, [user, isLocalReview, reviewId])

  const setViewingMode = useCallback(
    (props: SetViewingModeProps) => {
      if (props.viewingMode === 'view') {
        setViewingModeInternal('view')
        return true
      } else if (props.viewingMode === 'edit' && mappedDocumentPermissions === 'edit') {
        setViewingModeInternal('edit')
        return true
      }
      return false
    },
    [mappedDocumentPermissions],
  )

  const setDocumentPermissions = useCallback(
    (props: SetDocumentPermissionsProps) => {
      if (props.documentPermissions === 'view') {
        setQueryPermissions('view')
        return true
      } else if (
        props.documentPermissions === 'edit'
        // TODO(Davey): CHeck credentials somehow here
        // && credientalsValid()
      ) {
        setQueryPermissions('edit')
        return true
      }
      return false
    },
    [setQueryPermissions],
  )

  return useMemo(
    () => ({
      documentPermissions: mappedDocumentPermissions,
      viewingMode: viewMode,
      setViewingMode,
      owner: owner,
      ownerName,
      isCreatedByStaff,
      setDocumentPermissions: setDocumentPermissions,
    }),
    [
      mappedDocumentPermissions,
      viewMode,
      setViewingMode,
      owner,
      ownerName,
      isCreatedByStaff,
      setDocumentPermissions,
    ],
  )
}
