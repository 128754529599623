'use client'

import { Box, Flex } from '@chakra-ui/react'
import { NCImage } from 'components/common/nextJs/components/nextCompatibleImage'
import { cn } from 'components/common/utils/tailwindUtils'
import { FirebaseGroupPlayerProfileEntry } from 'data/common'
import {
  CSSProperties,
  Component,
  HTMLProps,
  PropsWithChildren,
  ReactNode,
  createRef,
  forwardRef,
} from 'react'
import { isMobile } from 'react-device-detect'
import { ShortcutIcon } from '../components/ShortcutIcon'
import { Player } from '../data/Player'
import plusButtonIcon from '../icons/plus_button.png'
import { isInputEvent } from '../util/inputUtils'
import { PlayerStore } from './PlayerStore'
import { AddPlayerTile, PlayerTile, SelectionState } from './PlayerTile'
import { TimelineStore } from './TimelineStore'
import { NCSendGTMEvent } from 'components/common/nextJs/components/nextCompatibleGoogleTags'

const PlayerStates = ['dead', 'not-subbed', undefined] as const
export type PlayerState = (typeof PlayerStates)[number]
interface PlayerPanelProps {
  className?: string
  title?: ReactNode
  style?: CSSProperties
  playerStore: PlayerStore
  overridePlayerState?: (players: Player[]) => Map<Player, PlayerState> | undefined
  timelineStore?: TimelineStore
  onPlayerClick?: (player: Player) => void
  onPlayerSubmit?: (player: Player) => void
  playerExtra?: (player: Player) => ReactNode
  onClose?: () => void
  handleShortcuts?: boolean
  displayStyle?: 'expanding' | 'expanded'
  iconSize?: number
  canEditNames: boolean | undefined
  canAddPlayers: boolean | undefined
  canEnableEditingNames?: boolean | undefined
  onRequestEditNameMode?: (player: Player) => void
  selectedPlayers?: Player[]
  team: number
  matchNumber: number
  groupPlayerNames?: string[] | undefined
}

interface State {
  hover: boolean
  focus: boolean
  playerIdQueuedForExpanding?: string
}

export class PlayerPanel extends Component<PlayerPanelProps, State> {
  state: State = {
    hover: false,
    focus: false,
    playerIdQueuedForExpanding: undefined,
  }
  containerRef = createRef<HTMLDivElement>()
  protected handleKeyPressedOnWindow = (event: KeyboardEvent) => {
    if (isInputEvent()) return
    if (event.code.startsWith('Digit') && this.props.handleShortcuts) {
      const index = parseInt(event.key) - 1
      const player = this.getFilteredPlayers()?.getOrNull(index)
      player && this.handlePlayerClick(player)
    }

    if (event.code === 'Tab' && this.props.handleShortcuts) {
      event.preventDefault()
      this.handleCreatePlayer()
    }
  }

  getBoundingClientRect(): DOMRect | undefined {
    return this.containerRef.current?.getBoundingClientRect()
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPressedOnWindow)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPressedOnWindow)
  }

  protected handleCreatePlayer = async () => {
    const player = await this.props.playerStore.createPlayer(
      this.props.team,
      this.props.matchNumber,
    )
    this.setState((state) => ({
      ...state,
      playerIdQueuedForExpanding: player.id,
    }))
  }

  protected handlePlayerNameUpdate = (player: Player, nameChange: string) => {
    this.props.playerStore.updatePlayerName(player, nameChange)
  }

  protected handlePlayerClick = (player: Player) => {
    this.props.onPlayerClick?.(player)
    this.setState((state) => ({
      ...state,
      playerIdQueuedForExpanding: undefined,
    }))
  }
  protected handlePlayerSubmit = (player: Player) => {
    this.props.onPlayerSubmit?.(player)
    this.setState((state) => ({
      ...state,
      playerIdQueuedForExpanding: undefined,
    }))
  }

  protected handleHoverEnter = () => {
    if (!isMobile) this.setState((state) => ({ ...state, hover: true }))
  }
  protected handleHoverExit = () => {
    this.setState((state) => ({ ...state, hover: false }))
  }
  protected handlePlayerDelete = (player: Player) => {
    NCSendGTMEvent({
      event: 'player_deleted',
      category: 'player',
    })
    this.props.playerStore.deletePlayer(player.id)
    this.props.timelineStore?.removePlayer(player.id)
  }
  protected handlePlayerFocus = () => {
    this.setState((state) => ({ ...state, focus: true }))
  }
  protected handlePlayerBlur = () => {
    this.setState((state) => ({ ...state, focus: false }))
    this.setState((state) => ({
      ...state,
      playerIdQueuedForExpanding: undefined,
    }))
  }
  protected getFilteredPlayers = () => {
    return this.props.playerStore.players.filter(
      (player) => player.team === this.props.team && player.matchNumber === this.props.matchNumber,
    )
  }

  isPlayerQueuedForEditing = (player: Player) => {
    return player.id === this.state.playerIdQueuedForExpanding
  }

  expanded() {
    return this.state.hover || this.state.focus || this.props.displayStyle === 'expanded'
  }

  render() {
    const size = this.props.iconSize || 45

    const filteredPlayers = this.getFilteredPlayers()

    const playerStateMap = this.props.overridePlayerState?.(filteredPlayers)

    return (
      <PlayerPanelContainer
        ref={this.containerRef}
        style={this.props.style}
        className={this.props.className}
        onPointerEnter={this.handleHoverEnter}
        onPointerLeave={this.handleHoverExit}>
        {(this.props.title && (
          <PlayerPanelTitle expanded={this.expanded()}>{this.props.title}</PlayerPanelTitle>
        )) ||
          undefined}
        {filteredPlayers.map((player, index) => {
          const selectionState: SelectionState | undefined =
            this.props.selectedPlayers?.length ?
              this.props.selectedPlayers.includes(player) ?
                'selected'
              : 'deselected'
            : undefined
          return (
            <PlayerTile
              focusOnMountIfExpanded={this.isPlayerQueuedForEditing(player)}
              size={size}
              key={player.id}
              player={player}
              groupPlayerNames={this.props.groupPlayerNames}
              onPlayerNameUpdate={this.handlePlayerNameUpdate}
              expandedWidth={'200px'}
              displayStyle={
                this.expanded() || this.isPlayerQueuedForEditing(player) ? 'name+icon' : 'icon-only'
              }
              onPlayerClick={this.handlePlayerClick}
              onPlayerDelete={this.handlePlayerDelete}
              shortcut={this.props.handleShortcuts ? `${index + 1}` : undefined}
              onFocus={this.handlePlayerFocus}
              onBlur={this.handlePlayerBlur}
              onPlayerSubmit={this.handlePlayerSubmit}
              extra={this.props.playerExtra}
              editMode={!!this.props.canEditNames}
              canEnableEditing={!!this.props.canEnableEditingNames}
              onRequestEditMode={() => this.props.onRequestEditNameMode?.(player)}
              selectionState={
                (
                  playerStateMap?.get(player) === 'dead' ||
                  playerStateMap?.get(player) === 'not-subbed'
                ) ?
                  'deselected'
                : undefined
              }
            />
          )
        })}
        {this.props.canAddPlayers && (
          <AddPlayerTile
            size={size}
            displayStyle={this.expanded() ? 'expanded' : 'circle'}
            onClick={this.handleCreatePlayer}
          />
        )}
      </PlayerPanelContainer>
    )
  }
}

export class ChooserPlayerPanel extends PlayerPanel {
  handleCloseButtonClicked = () => {
    this.props.onClose?.()
    this.setState((state) => ({
      ...state,
      playerIdQueuedForExpanding: undefined,
    }))
  }

  render() {
    const size = this.props.iconSize || 30
    const filteredPlayers = this.getFilteredPlayers()
    const playerStateMap = this.props.overridePlayerState?.(filteredPlayers)
    return (
      <PlayerPanelContainer
        direction='horizontal'
        style={{
          ...this.props.style,
        }}
        className={cn('rounded-lg shadow-2xl shadow-black', this.props.className)}>
        <Flex
          direction={'column'}
          gap={20}>
          <Flex direction={'column'}>
            <Box
              textTransform={'none'}
              style={{ fontVariationSettings: "'wght' 400" }}>
              {this.props.title}
            </Box>
            <Box color={'rgba(255,255,255,0.3)'}>Choose a player</Box>
          </Flex>
          <Flex
            direction={'row'}
            gap={12}>
            {this.props.onClose && (
              <div className='relative box-border flex cursor-pointer items-center justify-center'>
                <NCImage
                  className='player-panel-close mx-1 aspect-square h-5 rotate-45 rounded-full p-1 hover:bg-[#282c34]'
                  src={plusButtonIcon}
                  onClick={this.handleCloseButtonClicked}
                  alt={'close the player selection panel'}
                />
                <ShortcutIcon
                  className='left-0 top-[-8px]'
                  shortcut={'Escape'}
                  fontSize={`${size / 3.5}px`}
                />
              </div>
            )}
            <PlayerPanelPlayerTiles
              className='w-full'
              horizontal={true}>
              {filteredPlayers.map((player, index) => {
                return (
                  <PlayerTile
                    size={size}
                    focusOnMountIfExpanded={this.isPlayerQueuedForEditing(player)}
                    key={player.id}
                    player={player}
                    expandedWidth={'200px'}
                    shortcut={this.props.handleShortcuts ? `${index + 1}` : undefined}
                    onPlayerNameUpdate={this.handlePlayerNameUpdate}
                    onPlayerDelete={
                      this.isPlayerQueuedForEditing(player) ? this.handlePlayerDelete : undefined
                    }
                    onPlayerClick={this.handlePlayerClick}
                    onPlayerSubmit={this.handlePlayerSubmit}
                    displayStyle={this.isPlayerQueuedForEditing(player) ? 'name+icon' : 'expanding'}
                    editMode={!!this.props.canEditNames}
                    canEnableEditing={!!this.props.canEnableEditingNames}
                    onRequestEditMode={() => this.props.onRequestEditNameMode?.(player)}
                    selectionState={
                      (
                        playerStateMap?.get(player) === 'dead' ||
                        playerStateMap?.get(player) === 'not-subbed'
                      ) ?
                        'deselected'
                      : undefined
                    }
                  />
                )
              })}
            </PlayerPanelPlayerTiles>
            <AddPlayerTile
              size={size}
              onClick={this.handleCreatePlayer}
              displayStyle='circle'
              shortcut={'Tab'}
            />
          </Flex>
        </Flex>
      </PlayerPanelContainer>
    )
  }
}

export class TopPlayerPanel extends PlayerPanel {
  render() {
    const size = this.props.iconSize || 30
    return (
      // <DraggableScrollBox direction={"horizontal"} overflowX={"auto"}>
      <Flex
        style={{ fontSize: `${size * 0.6}px`, gap: (size * 2) / 3 }}
        onPointerEnter={this.handleHoverEnter}
        onPointerLeave={this.handleHoverExit}
        className={this.props.className}
        position={'relative'}
        alignItems={'center'}
        // justifyContent={"center"}
        flexWrap={'nowrap'}
        zIndex={1}
        padding={'4px 12px'}
        width={'fit-content'}
        boxSizing={'border-box'}
        minWidth={0}
        boxShadow={'0 10px 10px rgb(0,0,0,0.5)'}
        // backgroundColor='rgb(0,0,0,0.3)'
        pointerEvents={'auto'}>
        {this.props.title}
        {this.getFilteredPlayers().map((it) => (
          <Flex
            flexDirection={'row'}
            alignItems={'center'}
            key={it.id}
            gap={'4px'}>
            <>
              <PlayerTile
                player={it}
                size={size}
                focusOnMountIfExpanded={this.isPlayerQueuedForEditing(it)}
                editMode={!!this.props.canEditNames}
                canEnableEditing={!!this.props.canEnableEditingNames}
                displayStyle={
                  this.state.hover || this.props.displayStyle === 'expanded' ?
                    'name+icon'
                  : 'expanding'
                }
                expandedWidth={'fit-content'}
                selectionState={
                  this.props.playerStore.selectedPlayers?.includes(it) ? 'selected'
                  : this.props.playerStore.selectedPlayers?.length ?
                    'deselected'
                  : 'normal'
                }
                groupPlayerNames={this.props.groupPlayerNames}
                onRequestEditMode={() => {
                  return this.props.onRequestEditNameMode?.(it)
                }}
                onPlayerNameUpdate={this.handlePlayerNameUpdate}
                onPlayerDelete={this.handlePlayerDelete}
                onPlayerClick={this.handlePlayerClick}
                onPlayerSubmit={this.handlePlayerSubmit}
              />
              {this.props.playerExtra?.(it)}
            </>
          </Flex>
        ))}{' '}
        {this.props.canAddPlayers && (
          <AddPlayerTile
            size={size}
            displayStyle={this.expanded() ? 'expanded' : 'circle'}
            onClick={this.handleCreatePlayer}
          />
        )}
      </Flex>
      // </DraggableScrollBox>
    )
  }
}

const PlayerPanelContainer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    direction?: 'vertical' | 'horizontal'
  }> &
    HTMLProps<HTMLDivElement>
>(function PlayerPanelContainer({ direction = 'vertical', children, ...props }, ref) {
  return (
    <div
      ref={ref}
      {...props}
      className={cn(
        `pointer-events-auto mt-[30px] flex min-w-[120px] flex-col gap-2 overflow-visible whitespace-nowrap
        bg-[hsla(191,39%,25%,80%)] font-league-spartan capitalize text-white transition-all font-medium
        [&_*]:transition-all`,
        direction === 'horizontal' && 'w-fit flex-row items-center gap-1 p-2 backdrop-blur-[5px]',
        props.className,
      )}
      style={props.style}>
      {children}
    </div>
  )
})

const PlayerPanelTitle = forwardRef<
  HTMLSpanElement,
  PropsWithChildren<{
    expanded?: boolean
  }> &
    HTMLProps<HTMLSpanElement>
>(function PlayerPanelContainer({ children, ...props }, ref) {
  return (
    <span
      ref={ref}
      className={cn(
        'mb-5 text-center font-league-spartan text-[20px] text-white',
        props.expanded && 'text-[40px]',
        props.className,
      )}
      style={{ fontVariationSettings: "'wght' 700", ...props.style }}
      {...props}>
      {children}
    </span>
  )
})

const PlayerPanelPlayerTiles = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    horizontal?: boolean
  }> &
    HTMLProps<HTMLDivElement>
>(function PlayerPanelPlayerTiles({ children, horizontal, ...props }, ref) {
  return (
    <div
      ref={ref}
      {...props}
      className={cn(
        'flex flex-col flex-wrap gap-2 transition-all',
        horizontal && 'flex-row flex-nowrap items-center justify-center',
        props.className,
      )}
      style={{ fontVariationSettings: "'wght' 700", ...props.style }}>
      {children}
    </div>
  )
})
