import { StaticImport } from 'next/dist/shared/lib/get-img-props'
import React, { lazy } from 'react'
import { CSSProperties, ReactElement, ReactNode } from 'react'
import { cn } from '../../utils/tailwindUtils'

export type ReactNodeIconProps = {
  width?: number | `${number}`
  height?: number | `${number}`
  alt?: string
  icon: ReactNode
  style?: CSSProperties
}

const NextImage = lazy(() => import('next/image'))

export function getImgSrcFromImport(importImg: string | StaticImport): string | undefined {
  if (!importImg) return undefined

  if (typeof importImg === 'string') {
    return importImg
  }

  const data = 'default' in importImg ? importImg.default : importImg
  return data.src
}
export type RenderableImageNode = ReactElement | string | StaticImport

export function NodeOrImage({ node }: { node: RenderableImageNode | undefined }) {
  if (!node) return null
  if (React.isValidElement<any>(node)) return node
  return <NCImage src={node} />
}

export const NCImage = React.memo(
  React.forwardRef(function NCImage(
    props: Omit<React.ComponentPropsWithoutRef<'img'>, 'src' | 'width' | 'height'> & {
      src: string | StaticImport | undefined
      alt?: string
    },
    ref: React.Ref<HTMLImageElement>,
  ) {
    if (!props.src || typeof props.src === 'string')
      return (
        // eslint-disable-next-line react/forbid-elements, @next/next/no-img-element
        <img
          {...props}
          className={cn('h-auto max-h-full w-auto', props.className)}
          src={props.src}
          ref={ref}
        />
      )

    const srcString = getImgSrcFromImport(props.src)
    if (process.env && process.env.REACT_APP_RUN_MODE === 'nextjs') {
      return (
        <NextImage
          {...props}
          className={cn('h-auto max-h-full w-auto', props.className)}
          src={props.src}
          alt={props.alt || ''}
          ref={ref}
        />
      )
    }
    return (
      // eslint-disable-next-line react/forbid-elements, @next/next/no-img-element
      <img
        {...props}
        className={cn('h-auto max-h-full w-auto', props.className)}
        src={srcString}
        ref={ref}
      />
    )
  }),
)
