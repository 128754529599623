'use client'

import React, { CSSProperties, Component, FormEvent, lazy } from 'react'
import SuggestionsPortal from './SuggestionsPortal'
import { SingleAndDoubleClickHandler } from './UseSingleAndDoubleClick'
import { cn } from './utils/tailwindUtils'

export type EditOnInteraction = 'click' | 'dblclick'

export interface EditableTextDivProps
  extends Omit<
    React.HTMLProps<HTMLDivElement>,
    'onChange' | 'ref' | 'onSubmit' | 'onBlur' | 'onClick' | 'onDoubleClick'
  > {
  onChange?: (changedValue: string) => void
  onSubmit?: (changedValue: string) => void
  onEnter?: (changedValue: string) => void
  onBlur?: (value: string, event?: React.FocusEvent) => void
  onClick?: (event: React.PointerEvent) => void
  onDoubleClick?: (event: React.PointerEvent) => void
  blurOnEnter?: boolean
  disabled?: boolean
  editOn?: EditOnInteraction
  focusOnMount?: boolean
  placeholderStyle?: CSSProperties
  suggestions?: string[]
  maxPreviewLength?: number
}

export interface EditableTextDivState {
  editing: boolean
  original: string | undefined
  pendingDblClick: boolean
  filteredSuggestions: string[]
  currentSuggestionState: { value: string | undefined }
  suggestionPosition: { top: number; left: number; width: string | number }
}

export class EditableStringDiv extends Component<EditableTextDivProps, EditableTextDivState> {
  state: EditableTextDivState = {
    editing: false,
    original: undefined,
    pendingDblClick: false,
    filteredSuggestions: [],
    currentSuggestionState: { value: undefined },
    suggestionPosition: { top: 0, left: 0, width: 'fit-content' },
  }
  divRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    if (this.props.focusOnMount && !this.props.disabled) {
      this.focus()
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<EditableTextDivProps>,
    nextState: Readonly<EditableTextDivState>,
  ): boolean {
    return (
      (!nextState.editing || !!this.props.suggestions) &&
      (nextProps.children !== this.props.children ||
        nextProps !== this.props ||
        nextState !== this.state)
    )
  }

  value = () => this.divRef.current?.innerText

  hasFocus = (): boolean => this.state.editing

  focus = () => {
    if (this.getEditOn() === 'dblclick') {
      this.setState(
        (state) => ({ ...state, pendingDblClick: true }),
        () => {
          setTimeout(() => {
            this.divRef.current?.focus()
            this.setState((state) => ({ ...state, pendingDblClick: false }))
          }, 0)
        },
      )
    } else {
      this.divRef.current?.focus()
    }
  }

  blur = () => {
    this.divRef.current?.blur()
  }

  updateSuggestionsPosition = () => {
    const div = this.divRef.current
    if (div) {
      const rect = div.getBoundingClientRect()
      this.setState((state) => ({
        ...state,
        suggestionPosition: {
          ...state.suggestionPosition,
          top: rect.bottom,
          left: rect.left,
        },
      }))
    }
  }

  updateSuggestions = (value: string) => {
    const { suggestions } = this.props
    if (suggestions) {
      const searchTerm = value.trim().toLowerCase()
      const filteredSuggestions =
        searchTerm ?
          suggestions.filter((suggestion) => suggestion.toLowerCase().includes(searchTerm))
        : []
      this.setState((state) => ({
        ...state,
        filteredSuggestions,
        currentSuggestionState: { ...state.currentSuggestionState, value },
      }))
    }
  }

  handleChange = (value: string) => {
    this.props.onChange?.(value)
    this.updateSuggestionsPosition()
    this.updateSuggestions(value)
  }

  handleFocus = (event: React.FocusEvent<HTMLDivElement>) => {
    this.setState((state) => ({
      ...state,
      editing: true,
      original: event.target.innerText,
    }))
  }

  handleBlur = (value: string, dontSubmit?: boolean, e?: React.FocusEvent) => {
    this.setState(
      (state) => ({
        ...state,
        editing: false,
        original: undefined,
      }),
      () => {
        if (this.state.original !== value && !dontSubmit) this.props.onSubmit?.(value)
        this.clearSuggestion()
        this.props.onBlur?.(value, e)
      },
    )
  }

  handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const div = this.divRef.current
    if (e.key === 'Escape' && div && this.state.editing) {
      e.stopPropagation()
      div.innerText = this.state.original || ''
      this.handleBlur(div.innerText, true)
      // we want to blur async so other key listeners know its coming from a contenteditable
      setTimeout(() => {
        div.blur()
      }, 100)
    } else if ((e.key === 'Enter' || e.key === 'NumpadEnter') && div && this.state.editing) {
      e.stopPropagation()
      if (this.props.blurOnEnter && !e.shiftKey) {
        e.preventDefault()
        this.handleBlur(div.innerText)
        this.props.onEnter?.(div.innerText)
        // we want to blur async so other key listeners know its coming from a contenteditable
        setTimeout(() => {
          div.blur()
        }, 100)
      }
    }
  }

  private isContentEditReady = () => {
    return (
      !this.props.disabled &&
      (this.getEditOn() === 'click' || this.state.pendingDblClick) &&
      !this.state.editing
    )
  }

  private isContentEditable = () => {
    return (
      !this.props.disabled &&
      (this.getEditOn() === 'click' || this.state.pendingDblClick || this.state.editing)
    )
  }

  private click = (e: React.PointerEvent) => {
    this.props.onClick?.(e)
    if (!this.props.disabled) {
      if (this.getEditOn() === 'dblclick') {
        this.setState((state) => ({ ...state, pendingDblClick: true }))
        setTimeout(() => {
          this.setState((state) => ({ ...state, pendingDblClick: false }))
        }, 300)
      }
    }
  }

  clickHandler = new SingleAndDoubleClickHandler<React.PointerEvent<HTMLDivElement>>({
    actionSingleClick: (e) => {
      if (this.hasFocus()) return
      this.props.onClick?.(e)
    },
    actionDoubleClick: (event) => {
      if (this.getEditOn() === 'dblclick') {
        const div = this.divRef.current
        if (!div) return
        this.focus()
        // div.contentEditable = "true";
      } else {
        this.props.onDoubleClick?.(event)
      }
    },
    stopPointerDownPropagation: true,
    stopPointerUpPropagation: true,
  })
  handleDown = (e: React.PointerEvent<HTMLDivElement>) => {
    !this.props.disabled && e.stopPropagation()
    !this.props.disabled && this.clickHandler.handleDown(e, !this.props.disabled)
  }
  handleUp = (e: React.PointerEvent<HTMLDivElement>) => {
    !this.props.disabled && e.stopPropagation()
    !this.props.disabled && this.clickHandler.handleUp(e, !this.props.disabled)
  }
  handleMove = (e: React.PointerEvent<HTMLDivElement>) => {
    !this.props.disabled && e.stopPropagation()
    !this.props.disabled && this.clickHandler.handleMove(e)
  }

  handleClick = (e: React.PointerEvent<HTMLDivElement>) => {
    !this.props.disabled && e.preventDefault()
    !this.props.disabled && e.stopPropagation()
    if (this.props.disabled) {
      this.props.onClick?.(e)
    }
    // this.click(e);
  }

  handleDoubleClick = (e: React.PointerEvent<HTMLDivElement>) => {
    !this.props.disabled && e.preventDefault()
    !this.props.disabled && e.stopPropagation()
    if (this.props.disabled) {
      this.props.onDoubleClick?.(e)
    }
    // this.click(e);
  }

  private getEditOn = (): EditOnInteraction => {
    if (this.props.editOn === undefined) return 'click'
    return this.props.editOn
  }

  selectSuggestion = (suggestion: string) => {
    const div = this.divRef.current
    if (div) {
      div.innerText = suggestion
      this.props.onSubmit?.(suggestion)
      this.clearSuggestion()
    }
  }

  private clearSuggestion = () => {
    this.setState((state) => ({ ...state, currentSuggestionState: { value: undefined } }))
  }

  render() {
    const {
      onChange,
      onSubmit,
      onEnter,
      onBlur,
      onClick,
      blurOnEnter,
      disabled,
      editOn,
      focusOnMount,
      placeholderStyle,
      translate = 'no',
      as,
      maxPreviewLength,
      ...divProps
    } = this.props

    const isTruncated =
      typeof divProps.children === 'string' &&
      maxPreviewLength &&
      divProps.children.length > maxPreviewLength &&
      !this.hasFocus()

    return (
      <>
        <div
          {...divProps}
          className={cn('editable-div', divProps.className)}
          ref={this.divRef}
          onChange={() => {
            //do nothing
          }}
          onKeyDown={this.handleKeyPress}
          data-text={divProps.placeholder}
          suppressContentEditableWarning={true}
          translate={translate}
          style={{
            cursor: disabled ? undefined : 'text',
            userSelect: 'text',
            ...divProps.style,
            ...(!divProps.children ? placeholderStyle : {}),
            overflow: isTruncated ? 'hidden' : 'visible',
            textOverflow: isTruncated ? 'ellipsis' : 'clip',
            maxWidth: isTruncated ? `${maxPreviewLength}ch` : 'none',
          }}
          data-dblclick-editable={!disabled && editOn === 'dblclick'}
          data-disabled-editable={disabled}
          contentEditable={this.isContentEditable()}
          onFocus={this.handleFocus}
          onPointerUp={this.handleUp}
          onPointerDown={this.handleDown}
          onPointerMove={this.handleMove}
          onClick={this.handleClick}
          onDoubleClick={this.handleDoubleClick}
          onInput={(e: FormEvent<HTMLDivElement>) =>
            this.handleChange((e.target as HTMLDivElement).innerText)
          }
          onBlur={(e: React.FocusEvent<HTMLDivElement>) =>
            this.handleBlur((e.target as HTMLDivElement).innerText, false, e)
          }
          onSubmit={undefined}>
          {divProps.children}
        </div>

        {this.state.filteredSuggestions !== undefined && (
          <SuggestionsPortal
            filteredSuggestions={this.state.filteredSuggestions}
            currentValue={this.state.currentSuggestionState.value}
            suggestionPosition={this.state.suggestionPosition}
            onSelectSuggestion={this.selectSuggestion}
          />
        )}
      </>
    )
  }
}
