'use client'

import * as React from 'react'
import { PropsWithChildren, useCallback, useRef } from 'react'
import { ScrollBox } from './ScrollBox'
import { useCompositeRefObject } from './UseCompositeRefObject'

export const DraggableScrollBox = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren &
    React.HTMLProps<HTMLDivElement> & {
      direction: 'horizontal' | 'vertical' | 'both'
      startPos?: 'center'
      handleScrollWheel?: boolean
    }
>(function DraggableScrollBox(
  { children, direction, startPos, handleScrollWheel, ...htmlDivProps },
  forwardedRef,
) {
  const ref = useRef<HTMLDivElement>(null)
  const handleDrag = useCallback(
    (event: React.PointerEvent) => {
      if (
        ref.current &&
        (event.pointerType === 'touch' ||
          (event.pointerType === 'mouse' && (event.buttons & 1) == 1))
      ) {
        if (direction === 'horizontal') {
          ref.current?.scrollBy({ left: -event.movementX })
        } else if (direction === 'vertical') {
          ref.current?.scrollBy({ top: -event.movementY })
        } else {
          ref.current?.scrollBy({ left: -event.movementX, top: -event.movementY })
        }
      }
      event.stopPropagation()
    },
    [direction],
  )
  const multiRef = useCompositeRefObject(forwardedRef, ref)
  return (
    <ScrollBox
      draggable={false}
      ref={multiRef}
      onPointerMove={handleDrag}
      direction={direction}
      startPos={startPos}
      handleScrollWheel={handleScrollWheel}
      {...htmlDivProps}>
      {children}
    </ScrollBox>
  )
})
