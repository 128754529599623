'use client'

import { useLeaderboardFilterStrategy } from 'components/RankingsTableFilterStrategy'
import {
  FirebaseGameResult,
  LeaderboardGameResults,
  NswdlDivFilter,
  ResultDividers,
  RoundInfo,
} from 'data/leaderboardtypes'
import { useMemo } from 'react'
import { Measure } from '../components/RankingsByDiv'
import {
  GenericDisplayRankedResults,
  RankedPlayerMeasureResults,
  RankedTeamMeasureResults,
  calculateRankedPlayerResults,
  calculateRankedTeamResults,
  filterResults,
  gameResultsFilters,
  rankBy,
} from '../components/RankingsTable'

export type RankedItem<T> = {
  rank: number
  item: T
}

export type UseRankingsResults = {
  rankings: GenericDisplayRankedResults[]
  rankingsByMeasure: Map<Measure, GenericDisplayRankedResults[]>
  previousRankings: GenericDisplayRankedResults[]
  top3Record: GenericDisplayRankedResults[] | undefined
  filterdCarouselRankingsByMeasure: Map<Measure, GenericDisplayRankedResults[]>
  filteredTableRankings: (
    rankings: GenericDisplayRankedResults[] | undefined,
  ) => GenericDisplayRankedResults[] | undefined
}

export function useRankingsResults(
  gameResults: LeaderboardGameResults,
  // aggregateResults: LeaderboardAggregateResults,
  selectedRound: RoundInfo | undefined,
  selectedDivision: NswdlDivFilter,
  selectedMeasure: Measure,
  searchPlayerTerm?: string | undefined,
  searchTeamsTerm?: string | undefined,
  byTeam?: boolean,
): UseRankingsResults {
  const filteredResults = useMemo(() => {
    return filterResults(
      gameResults,
      selectedRound ?
        gameResultsFilters(selectedRound.category, selectedDivision.key, selectedRound.number)
      : () => false,
    )
  }, [gameResults, selectedRound, selectedDivision])

  const rankings = useMemo(() => {
    return byTeam ?
        calculateRankedTeamResults(filteredResults, selectedMeasure)
      : calculateRankedPlayerResults(filteredResults, selectedMeasure)
  }, [filteredResults, selectedMeasure, byTeam])

  const {
    commonRankings,
    leaderboardTableRankings: filteredTableRankings,
    profileCarouselRankings,
  } = useLeaderboardFilterStrategy({ byTeam, searchPlayerTerm, searchTeamsTerm })

  const rankingsByMeasure = useMemo(() => {
    // TODO(Davey): Figure out how to show all rankings
    const map = new Map<Measure, GenericDisplayRankedResults[]>()
    map.set(selectedMeasure, commonRankings(rankings) ?? [])

    return map
  }, [selectedMeasure, rankings, commonRankings])

  const filterdCarouselRankingsByMeasure = useMemo(() => {
    return rankingsByMeasure.mapValues((rankings) => profileCarouselRankings(rankings))
  }, [rankingsByMeasure, profileCarouselRankings])

  const previousFilteredResults = useMemo(() => {
    return filterResults(
      gameResults,
      selectedRound ?
        gameResultsFilters(selectedRound.category, selectedDivision.key, selectedRound.number - 1)
      : () => false,
    )
  }, [gameResults, selectedRound, selectedDivision])

  const previousRankings = useMemo(() => {
    return byTeam ?
        calculateRankedTeamResults(previousFilteredResults, selectedMeasure)
      : calculateRankedPlayerResults(previousFilteredResults, selectedMeasure)
  }, [selectedMeasure, previousFilteredResults, byTeam])

  const top3Record = useMemo(() => {
    const filteredMeasureResults = filteredResults[selectedMeasure.key]
    return (
      filteredMeasureResults &&
      rankBy(filteredMeasureResults, (it) => it.value ?? -1)
        .filter((it) => it.rank <= 3)
        .map(
          (it) =>
            ({
              displayName: it.item.player,
              displayRank: it.rank,
              player: it.item.player,
              team: undefined,
              total: it.item,
              reviews: [it.item],
            }) satisfies GenericDisplayRankedResults,
        )
    )
  }, [filteredResults, selectedMeasure])

  return {
    rankings,
    rankingsByMeasure,
    previousRankings,
    top3Record: byTeam ? undefined : top3Record,
    filterdCarouselRankingsByMeasure,
    filteredTableRankings,
  }
}
