'use client'

import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

export const VIDEO_PLAYER_WIDTH = 800
export const WIDTH_BOUNDARY = 1500
export const SMALL_WIDTH_BOUNDARY = 1200
export const MICRO_WIDTH_BOUNDARY = 600
export const SMALLER_HEIGHT_BOUNDARY = 600
export const HEIGHT_BOUNDARY = 1000

export interface WindowDimensions {
  width: number
  height: number
}

function getWindowDimensions(): WindowDimensions {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export function useWindowDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    function handleResize() {
      const newDimensions = getWindowDimensions()
      if (
        isMobile &&
        windowDimensions.height >= newDimensions.height &&
        windowDimensions.width === newDimensions.width
      )
        return
      setWindowDimensions(newDimensions)
    }
    if (windowDimensions.width === 0 && windowDimensions.height === 0) {
      handleResize()
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [windowDimensions])
  return windowDimensions
}
