'use client'

/**
 * Next.js Compatible Router
 *
 * This router provides a unified interface for both Next.js and React Router navigation.
 *
 * Required Configuration in next.config.mjs:
 * ```javascript
 * const nextConfig = {
 *   webpack: (config, { isServer }) => {
 *     config.resolve.alias = {
 *       ...config.resolve.alias,
 *       './reactRouter': './nextRouter',
 *     }
 *     return config
 *   },
 * }
 * ```
 *
 * This configuration ensures that imports of './reactRouter' are redirected to './nextRouter'
 * when running in Next.js mode, allowing for seamless switching between routing implementations.
 */
import hooks, { Router as NCRouter } from './reactRouter'

export const {
  redirect: NCRedirect,
  useLocation: useNCLocation,
  useNavigate: useNCNavigate,
  useSearchParams: useNCSearchParams,
} = hooks

export default NCRouter
