'use client'

import { useCallback, useMemo, useState } from 'react'
import { useNCSearchParams } from '../nextJs/components/nextCompatibleRouter'

export const getQueryStringValues = (
  key: string,
  queryString = window.location.search,
): string[] | undefined => {
  const values = new URLSearchParams(queryString.substring(1))
  const value = values.getAll(key)
  if (!value) return undefined
  if (typeof value === 'string') return [value]
  return value
}

export const getQueryStringValue = (key: string, queryString: string): string | undefined => {
  const values = new URLSearchParams(queryString.substring(1))
  const value = values.getAll(key)
  if (!value) return undefined
  if (typeof value === 'string') return value
  return value[0]
}

const setQueryStringValue = (
  key: string,
  value: string | string[] | undefined,
  queryString = window.location.search,
) => {
  const values = new URLSearchParams(
    queryString.startsWith('?') ? queryString.replace('?', '') : queryString,
  ).toObject()

  let newValues: { [p: string]: string | string[] | undefined }
  if (value === undefined) {
    newValues = { ...values }
    delete newValues[key]
  } else {
    newValues = { ...values, [key]: value }
  }

  const entries = Object.entries(newValues).flatMap(([key, values]) => {
    if (Array.isArray(values)) {
      return values.map((value) => [key, value] as [string, string])
    }
    return [[key, values] as [string, string]]
  })

  const newQsValue = new URLSearchParams(entries)
  setQueryStringWithoutPageReload(`?${newQsValue}`)
}
const setQueryStringWithoutPageReload = (qsValue: string) => {
  const newurl =
    window.location.protocol + '//' + window.location.host + window.location.pathname + qsValue

  window.history.pushState({ path: newurl }, '', newurl)
}

export function useQueryString(
  key: string,
  initialValue?: string,
): [string | undefined, (string: string | undefined, forceRender?: boolean) => void] {
  const searchParams = useNCSearchParams()
  const [value, setValue] = useState<string | undefined>(searchParams.get(key) || initialValue)
  const onSetValue = useCallback(
    (newValue: string | undefined, forceRender?: boolean) => {
      if (forceRender || newValue !== value) {
        setValue(newValue)
        setQueryStringValue(key, newValue)
      }
    },
    [key, value],
  )

  return [value, onSetValue]
}

export function useQueryStringArray(
  key: string,
  initialValue?: string[],
): [string[] | undefined, (string: string[] | undefined, forceRender?: boolean) => void] {
  const [values, setValues] = useState<string[] | undefined>(
    getQueryStringValues(key) || initialValue,
  )
  const onSetValue = useCallback(
    (newValue: string[] | undefined, forceRender?: boolean) => {
      if (forceRender || newValue !== values) {
        setValues(newValue)
        setQueryStringValue(key, newValue)
      }
    },
    [key, values],
  )

  return [values, onSetValue]
}

export function useQueryStringStringify() {
  const queryStringStringify = useCallback(
    (path: string, params: Record<string, string | undefined>) => {
      return `${path}?${new URLSearchParams(
        Object.entries(params)
          .mapNotNull(([key, value]) => (value !== undefined ? ([key, value] as const) : undefined))
          .toObject(),
      ).toString()}`
    },
    [],
  )

  return useMemo(() => ({ queryStringStringify }), [queryStringStringify])
}
