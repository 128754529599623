'use client'

import { StaticImport } from 'next/dist/shared/lib/get-img-props'
import React, { CSSProperties, PropsWithChildren, ReactElement, ReactNode, createRef } from 'react'
import { BackgroundIcon } from './BackgroundIcon'
import { SquashingBox } from './SquashingBox'
import { cn } from './utils/tailwindUtils'

type FlatRoundButtonProps = PropsWithChildren & {
  className?: string
  displayStyle?: 'large' | 'small'
  squashedChildrenFallback?: ReactNode[]
  backgroundColor?: CSSProperties['backgroundColor']
  color?: CSSProperties['color']
  style?: CSSProperties
  onClick?: (e: React.MouseEvent) => unknown | Promise<unknown>
  translate?: 'no' | 'yes'
  tabIndex?: number
  onBlur?: (e: React.FocusEvent) => void
  onFocus?: (e: React.FocusEvent) => void
  ['aria-disabled']?: boolean
  iconVerticalPadding?: string
  iconInvert?: boolean
} & (
    | {
        icon: string | ReactElement | StaticImport
        alt: string
      }
    | {
        icon?: undefined
        alt?: string
      }
  )

// Add a type for the ref methods we want to expose
export type RoundButtonRef = {
  blur: () => void
  focus: () => void
  hasFocus: () => boolean
}

export const RoundButton = React.memo(
  React.forwardRef<RoundButtonRef, FlatRoundButtonProps>(function RoundButton(props, forwardedRef) {
    const {
      icon,
      alt,
      displayStyle = 'large',
      iconInvert,
      squashedChildrenFallback,
      backgroundColor,
      color,
      'aria-disabled': ariaDisabled,
      className,
      onBlur,
      onFocus,
      onClick,
      style,
      translate,
      tabIndex,
      children,
      iconVerticalPadding,
    } = props

    const [disabledOverride, setDisabledOverride] = React.useState(false)
    const ref = React.useRef<HTMLButtonElement>(null)

    const hasFocus = React.useCallback(() => {
      if (typeof document === 'undefined') return false
      return document.activeElement === ref.current
    }, [])

    const blur = React.useCallback(() => {
      return ref.current?.blur()
    }, [])

    const focus = React.useCallback(() => {
      return ref.current?.focus()
    }, [])

    // Combine forwarded ref with internal ref
    React.useImperativeHandle(
      forwardedRef,
      () => ({
        blur,
        focus,
        hasFocus,
      }),
      [blur, focus, hasFocus],
    )

    const handleOnClick = React.useCallback(
      async (e: React.MouseEvent) => {
        if (onClick) {
          const result = onClick(e)
          if (result instanceof Promise) {
            setDisabledOverride(true)
            try {
              await result
            } finally {
              setDisabledOverride(false)
            }
          }
        }
        if (children && hasFocus()) {
          ref.current?.blur()
        }
      },
      [onClick, children, hasFocus],
    )

    const mapChildren = (child: ReactNode): ReactNode => {
      return (
        <div
          className={`relative flex h-[1.5em] max-h-full min-w-fit items-center justify-start transition-all
            ${displayStyle !== 'small' ? 'gap-[5px]' : undefined} `}>
          {icon && !React.isValidElement<any>(icon) && (
            <BackgroundIcon
              draggable={false}
              src={icon}
              style={{
                filter: iconInvert ? `invert()` : undefined,
                paddingTop: iconVerticalPadding,
                paddingBottom: iconVerticalPadding,
              }}
              className={`h-[1.5em] max-h-full w-[25px] object-contain
              round-button-icon${displayStyle === 'large' ? '' : 'small'}`}
              alt={alt}
            />
          )}
          {icon && React.isValidElement(icon) && icon}
          {child}
        </div>
      )
    }

    return (
      <button
        onBlur={onBlur}
        onFocus={onFocus}
        onClick={handleOnClick}
        tabIndex={tabIndex}
        aria-disabled={disabledOverride || ariaDisabled}
        translate={translate}
        ref={ref}
        className={cn(
          `pointer-events-auto flex h-[40px] cursor-pointer items-center justify-center gap-[4px]
          whitespace-nowrap rounded-full border-0 bg-white p-[8px] font-league-spartan text-sm text-zinc-900
          transition-all aria-disabled:pointer-events-none aria-disabled:cursor-default
          aria-disabled:opacity-30 hover:contrast-50 hover:filter disabled:pointer-events-none
          disabled:cursor-default disabled:opacity-30`,
          displayStyle === 'small' && 'p-[8px_10px]',
          className,
        )}
        style={{
          backgroundColor: backgroundColor,
          color: color,
          ...style,
        }}>
        <SquashingBox
          className='font-inherit'
          squashedChildrenArray={squashedChildrenFallback?.map(mapChildren)}>
          {mapChildren(children)}
        </SquashingBox>
      </button>
    )
  }),
)
