import { FirebaseGroupTeamDivision } from './common'
import { FirebaseDivisionDefinition } from './common'
import { FirebaseGroupTeamProfileEntry } from './common'
import { GroupTeamProfileEntry } from './common'

export function mapFromFirebaseGroupTeamDivision(
  entry: FirebaseGroupTeamDivision,
): Pick<FirebaseDivisionDefinition, 'title' | 'key'> {
  return {
    title: 'name' in entry ? entry.name : entry.title,
    key: entry.key,
  }
}

export function mapToFirebaseGroupTeamDivision(
  entry: Pick<FirebaseDivisionDefinition, 'title' | 'key'>,
): FirebaseGroupTeamDivision {
  return {
    title: entry.title,
    key: entry.key,
  }
}

export function mapFromFirebaseGroupTeamProfileEntry(
  entry: FirebaseGroupTeamProfileEntry,
): GroupTeamProfileEntry {
  return {
    id: entry.id,
    name: entry.name,
    division: entry.division ? mapFromFirebaseGroupTeamDivision(entry.division) : null,
    logoUrl: entry.logoUrl,
    contacts: entry.contacts,
  }
}

export function mapToFirebaseGroupTeamProfileEntry(
  entry: GroupTeamProfileEntry,
): FirebaseGroupTeamProfileEntry {
  return {
    id: entry.id,
    name: entry.name,
    division: entry.division ? mapToFirebaseGroupTeamDivision(entry.division) : null,
    logoUrl: entry.logoUrl,
    contacts: entry.contacts,
  }
}
