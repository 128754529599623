export function extractCharsAndNumbers(inputString: string): [string, string] {
  const words = inputString.split(/\s+/)
  const alphanumericWords: string[] = []
  const numericWords: string[] = []

  for (const word of words) {
    if (/\d/.test(word)) {
      numericWords.push(word)
    } else {
      alphanumericWords.push(word)
    }
  }

  return [alphanumericWords.join(' '), numericWords.join(' ')]
}

export function normaliseName(playerName: string | undefined | null): string {
  if (!playerName) return ''
  if (/^\d+$|^\d+s\d+$/.test(playerName)) return playerName

  const [alphabetic, numeric] = extractCharsAndNumbers(playerName.trim())

  const formattedAlphabetic = alphabetic
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')

  return `${formattedAlphabetic} ${numeric}`.trim()
}

export function extractWords(inputString: string | undefined): {
  words: string[]
  wordsCombined: string
} {
  if (!inputString) return { words: [], wordsCombined: '' }

  const words = inputString
    .trim()
    .toLowerCase()
    .split(' ')
    .reduce<string[]>((acc, word) => {
      const cleanedWord = word.trim().toLowerCase()

      // If the word is a number, append to the last word
      if (/^\d+$/.test(cleanedWord)) {
        if (acc.length > 0) {
          // If the last word is a word, add the number to the end of that word
          acc[acc.length - 1] += cleanedWord
        }
      } else if (cleanedWord !== '') {
        // If the word is not a number
        acc.push(cleanedWord)
      }

      return acc
    }, [])

  return { words, wordsCombined: words.join('_') }
}
