import { Box } from '@chakra-ui/react'
import { useElementBoundingRect } from 'components/common/utils/reactUtils'
import { cn } from 'components/common/utils/tailwindUtils'
import { StaticImport } from 'next/dist/shared/lib/get-img-props'
import React, {
  CSSProperties,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { useWindowDimensions } from '../UseWindowDimensions'
import { ScrollMaintainingDiv, useScrollState } from '../components/ScrollMaintainingDiv'
import { BackgroundIcon } from '../components/common/BackgroundIcon'
import minimizeButton from '../icons/exit_fullscreen_button.png'
import openExpandButton from '../icons/open_expand_button.png'
import zoomInIcon from '../icons/zoom_in_icon.png'
import zoomOutIcon from '../icons/zoom_out_icon.png'

export function ShowHidePanel({
  userVisibilityState,
  defaultVisibility = false,
  tabIcon,
  children,
  defaultFontSize = 15,
  title,
  color,
  fontScaleStorageKey,
  style,
  side = 'right',
  showExpandButton = true,
  backgroundColor = 'rgba(17, 24, 27, 0.9)',
  className,
  displayStyle,
  FloatingFooter: Footer = () => <></>,
}: PropsWithChildren<{
  scrollable?: boolean
  backgroundColor?: string
  userVisibilityState?: [boolean | undefined, Dispatch<SetStateAction<boolean | undefined>>]
  style?: CSSProperties
  className?: string
  displayStyle?: 'medium' | 'large'
  defaultVisibility?: boolean
  tabIcon: string | StaticImport
  defaultFontSize?: number
  title: string
  color: string
  fontScaleStorageKey?: string
  showExpandButton?: boolean
  side?: 'right' | 'left'
  FloatingFooter?: () => React.ReactNode
}>) {
  const panelWidth = useMemo(() => (displayStyle === 'large' ? '350px' : '300px'), [displayStyle])
  const windowDimensions = useWindowDimensions()
  const internalUserSetVisibilityState = useState<boolean | undefined>(undefined)
  const [userSetVisibility, setUserSetVisibility] =
    userVisibilityState ?? internalUserSetVisibilityState

  const visible = useMemo(() => {
    if (userSetVisibility === undefined) return defaultVisibility
    else return userSetVisibility
  }, [defaultVisibility, userSetVisibility])

  const handleToggleClick = useCallback(() => {
    setUserSetVisibility((state: boolean | undefined) => {
      if (state === undefined) {
        return !defaultVisibility
      }
      return !state
    })
  }, [setUserSetVisibility, defaultVisibility])

  const scrollState = useScrollState()

  const [isExpanded, setExpanded] = useState<boolean>(false)
  useEffect(() => {
    if (isExpanded) {
      if (document.body !== document.fullscreenElement && isMobile) {
        setTimeout(() => {
          document.body.requestFullscreen()
        }, 100)
      }
    }
  }, [isExpanded])
  const globalFontKey = 'GlobalFontKey'

  const [fontScale, setFontScale] = useState(() =>
    parseFloat(localStorage.getItem(fontScaleStorageKey ?? globalFontKey) ?? '1.0'),
  )
  useEffect(() => {
    localStorage.setItem(fontScaleStorageKey ?? globalFontKey, String(fontScale))
  }, [fontScale, fontScaleStorageKey])
  const contentFontSize = `${fontScale * defaultFontSize}px`

  const handleZoomIn = useCallback(() => {
    setFontScale((it) => it + 0.2)
  }, [setFontScale])
  const handleZoomOut = useCallback(() => {
    setFontScale((it) => Math.max(it - 0.2, 0.8))
  }, [setFontScale])
  const handleExpand = useCallback(() => {
    setExpanded((it) => !it)
  }, [setExpanded])

  const [footerRef, { height: footerHeight }] = useElementBoundingRect()

  return (
    <div
      className={cn(
        'relative box-border min-h-[60%] overflow-y-visible transition-all duration-300 ease-in-out',
        className,
      )}
      style={{
        marginLeft: `calc(${visible ? panelWidth : '0px'} - 40px - ${
          !visible ? '0px'
          : isExpanded ? `${Math.min(windowDimensions.width - 50, 800)}px`
          : panelWidth
        })`,
        maxWidth: isExpanded ? '800px' : 'calc(60vw + 40px)',
        position: 'relative',
        transform:
          visible ? 'translateX(0)'
          : side === 'right' ? 'translateX(calc(100%))'
          : 'translateX(calc(-100% + 40px))',
        height: isExpanded ? '100%' : undefined,
        width:
          !visible ? '0'
          : isExpanded ? windowDimensions.width - 50
          : panelWidth,
        ...style,
      }}>
      <div
        className={cn(
          `pointer-events-auto absolute bottom-0 top-0 mb-auto mt-auto flex h-[80px] w-[40px] cursor-pointer
          flex-wrap content-center items-center justify-center gap-2 rounded-bl-[16px] rounded-tl-[16px]
          bg-[#f1bf46] shadow-[0_0_10px_rgb(0,0,0,0.5)] active:brightness-75`,
          side === 'right' ?
            'rounded-bl-2xl rounded-br-none rounded-tl-2xl rounded-tr-none'
          : 'right-0 rounded-bl-none rounded-br-2xl rounded-tl-none rounded-tr-2xl',
          !isMobile && 'hover:brightness-75',
        )}
        style={{
          backgroundColor: color,
        }}
        onClick={handleToggleClick}>
        <BackgroundIcon
          className='-m-2 h-[40px] object-scale-down'
          src={tabIcon}
          alt={'Show or hide'}
        />
        <div
          style={{
            color: 'black',
            fontFamily: 'LeagueSpartan,sans-serif',
            fontWeight: 550,
            fontSize: '12px',
            textAlign: 'center',
          }}>
          {visible ? 'Hide' : 'Show'}
        </div>
      </div>
      <div
        className={cn(
          `border-radius-[16px] pointer-events-auto relative flex h-full flex-col overflow-y-hidden
          shadow-[0_0_10px_rgb(0,0,0,0.5)]`,
          side === 'right' && 'ml-[40px]',
          side === 'left' && 'mr-[40px]',
        )}
        style={{
          backgroundColor: backgroundColor,
          fontSize: contentFontSize,
        }}>
        <div
          className='text-shadow-custom font-[550] flex h-[44px] items-center justify-start rounded-tl-lg rounded-tr-lg
            bg-[#f1bf46] py-0 pl-5 pr-3 font-league-spartan text-[20px] text-white'
          style={{
            backgroundColor: color,
            textShadow: '1px 0 0 #ffffff',
          }}>
          <div className='overflow-x-hidden text-ellipsis'>{title}</div>
          <div
            className={cn('flex flex-grow items-end justify-end', isExpanded ? 'gap-3' : 'gap-2')}>
            <BackgroundIcon
              className={cn(
                'box-border rounded-md p-[5px] hover:bg-[rgb(0,0,0,0.3)]',
                isExpanded ? 'w-[40px]' : 'w-[30px]',
              )}
              src={zoomOutIcon}
              onClick={handleZoomOut}
            />
            <BackgroundIcon
              className={cn(
                'box-border rounded-md p-[5px] hover:bg-[rgb(0,0,0,0.3)]',
                isExpanded ? 'w-[40px]' : 'w-[30px]',
              )}
              src={zoomInIcon}
              onClick={handleZoomIn}
            />
            {showExpandButton && (
              <BackgroundIcon
                className={cn(
                  'box-border rounded-md p-[5px] hover:bg-[rgb(0,0,0,0.3)]',
                  isExpanded ? 'w-[40px]' : 'w-[30px]',
                )}
                src={isExpanded ? minimizeButton : openExpandButton}
                onClick={handleExpand}
              />
            )}
          </div>
        </div>
          <ScrollMaintainingDiv
            className='relative box-border flex h-[calc(100%-50px)] flex-col gap-[20px] overflow-y-auto p-3
              shadow-[0_-20px_10px_-10px_inset_rgb(0,0,0,0.5)]'
            useScrollState={scrollState}>
            {children}
          </ScrollMaintainingDiv>
        <div
          ref={footerRef}
          className='z-10 h-fit'>
          {<Footer />}
        </div>
      </div>
    </div>
  )
}
