import { GroupSelectionStore, useGroupSelectionStore } from 'UseGroupSelectionStore'
import { AddGroupTileAuth, GroupsList } from 'components/ReviewsList'
import { ScrollBox } from 'components/ScrollBox'
import { FirebaseDb } from 'components/common/Firebase'
import { User } from 'firebase/auth'
import React, { useEffect } from 'react'
import { NavigateOptions } from 'react-router'
import { FocusSpan, showDialog } from '../components/common/Dialog'
import { NCSendGTMEvent } from 'components/common/nextJs/components/nextCompatibleGoogleTags'

type ShowAssignTeamDialogProps = {
  firebaseDb: FirebaseDb
  navigate: (url: string, opts?: NavigateOptions) => void
  onTeamAssigned: (
    assignedGroupId: string | undefined,
    groupSelectionStore: GroupSelectionStore | undefined,
  ) => Promise<string>
  addGroupAuth: AddGroupTileAuth
  createTeamOnRender?: boolean
}

const GROUP_CREATED_EVENT = { _id: 'group_created' } as const

export async function showAssignTeamDialog({
  firebaseDb,
  navigate,
  onTeamAssigned,
  addGroupAuth,
  createTeamOnRender,
}: ShowAssignTeamDialogProps) {
  NCSendGTMEvent({ event: 'assign_group_dialog_shown', category: 'group' })

  let result: string | undefined | null = undefined
  let first = true
  while (result === undefined) {
    result = await new Promise<string | undefined | null>((resolve, reject) => {
      showDialog<GroupSelectionStore | undefined>({
        title: () => <>Assign a team</>,
        user_dismissable: false,
        children: (Red, state, setState) => {
          return (
            <AssignTeamContent
              Red={Red}
              firebaseDb={firebaseDb}
              addGroupAuth={addGroupAuth}
              onGroupCreated={() => resolve(undefined)}
              navigate={navigate}
              setState={setState}
              createNewTeamOnRender={createTeamOnRender && first}
            />
          )
        },
        positiveButtonProps: {
          text: 'Assign',
          disabled: (state) => state?.selectedGroupId === undefined,
          onClicked: async (state) => {
            NCSendGTMEvent({ event: 'assign_group_dialog_assign_clicked', category: 'group' })
            const reviewId = await onTeamAssigned(state?.selectedGroupId ?? undefined, state)

            resolve(reviewId)
            return true
          },
        },
        negativeButtonProps: {
          text: 'Cancel',
          onClicked: () => {
            resolve(null)
            return true
          },
        },
      })
    })

    first = false
  }
  return result
}

function AssignTeamContent({
  firebaseDb,
  navigate,
  Red,
  setState,
  onGroupCreated,
  addGroupAuth,
  createNewTeamOnRender,
}: {
  Red: FocusSpan
  firebaseDb: FirebaseDb
  navigate: (url: string, opts?: NavigateOptions) => void
  setState: React.Dispatch<React.SetStateAction<GroupSelectionStore | undefined>>
  onGroupCreated?: (groupId: string) => void
  addGroupAuth: AddGroupTileAuth
  createNewTeamOnRender?: boolean
}) {
  const groupSelectionStore = useGroupSelectionStore({
    firebaseDb: firebaseDb,
    user: addGroupAuth.user,
    defaultSelection: { type: 'option', groupId: undefined },
  })

  useEffect(() => {
    setState(groupSelectionStore)
  }, [groupSelectionStore, setState])

  return (
    <div className='flex flex-col'>
      <div>
        <Red>You should assign this review to a team.</Red> This will let your team mates
        collaborate with you on this review.
      </div>

      <ScrollBox
        direction={'vertical'}
        className='max-h-[60svh] max-w-[80svw]'>
        <GroupsList
          firebaseDb={firebaseDb}
          addGroupTileAuth={addGroupAuth}
          groupSelectionStore={groupSelectionStore}
          showPersonal={true}
          navigate={navigate}
          onGroupCreated={onGroupCreated}
          createNewTeamOnRender={createNewTeamOnRender}
        />
      </ScrollBox>
    </div>
  )
}
