import { useTour } from '@reactour/tour'
import {
  FirebaseDb,
  useDatabaseRef,
  useNullableDatabaseRefLiveValueMemo,
} from 'components/common/Firebase'
import { useCallback, useMemo } from 'react'

export const useAchievementStore = ({
  firebaseDb,
  userId,
  groupId,
}: {
  firebaseDb: FirebaseDb
  userId: string | undefined
  groupId?: string | undefined
}) => {
  const {
    isOpen: isTourGuideOpen,
    currentStep,
    setCurrentStep,
    setIsOpen: setShowTourGuide,
    steps,
    setSteps,
    meta,
    setMeta,
  } = useTour()

  const userAchievementsRef = useDatabaseRef<{ [achievementKey: string]: boolean }>(
    firebaseDb,
    `users/${userId}/achievements`,
  )
  const groupAchievementsRef = useDatabaseRef<{ [achievementKey: string]: boolean }>(
    firebaseDb,
    `groups/${groupId}/achievements`,
  )
  const existingAchievements = useNullableDatabaseRefLiveValueMemo<{
    [achievementKey: string]: boolean
  }>(
    () => (groupId ? groupAchievementsRef : userAchievementsRef),
    [groupId, groupAchievementsRef, userAchievementsRef],
  )

  const allAchievementKeys = useMemo(() => Object.keys(allAchievements), [])

  const updateAchievementDb = useCallback(
    async (achievementKey: string, value: boolean) => {
      const ref = groupId ? groupAchievementsRef : userAchievementsRef
      if (!ref) return
      await ref.child(achievementKey).set(value)
    },
    [groupAchievementsRef, groupId, userAchievementsRef],
  )

  const updateAchievementStep = useCallback(
    (achievementKey: string | number) => {
      setCurrentStep(
        typeof achievementKey === 'string' ?
          allAchievementKeys.indexOf(achievementKey)
        : achievementKey,
      )
    },
    [allAchievementKeys, setCurrentStep],
  )

  return {
    isTourGuideOpen,
    existingAchievements,
    currentAchievementKey: allAchievementKeys[currentStep],
    steps,
    setSteps,
    meta,
    setMeta,
    setShowTourGuide,
    updateAchievementDb,
    updateAchievementStep,
  }
}

export const allAchievements = {
  inviteTeamMembers: {
    selector: '.guide-1',
    content: (
      <div className='text-center text-white'>
        <span className='text-playback-crimson'>Invite your teammates</span> to start collaborating,
        reviewing plays, and building your path to victory together.
      </div>
    ),
  },
  createImportReview: {
    selector: '.guide-4',
    highlightedSelectors: ['.guide-4-1', '.guide-4-2'],
    content: (
      <div className='text-center text-white'>
        Paste a you<span className='text-playback-crimson'>tube</span> link here to create a new
        review or Import an existing review.
      </div>
    ),
  },
}

export const tourGuideMobile = [
  {
    selector: '.mobile-guide-1',
    content: (
      <div className='text-center text-white'>
        Visit your <span className='text-playback-crimson'>team dashboard</span> here to invite your
        team mates
      </div>
    ),
  },
  {
    selector: '.mobile-guide-2',
    content: <div className='text-center text-white'>Move to team settings</div>,
  },
]
