'use client'

import { ReviewFirebaseEntry } from 'data/common'
import {
  FirebaseStatRecordEntry,
  StatRecord,
  StatRecordAlias,
  StatRecordStorage,
  findExactAlias,
  matchingTeamsRules,
} from 'data/statrecordtypes'
import { useMemo } from 'react'
import { FirebaseDb, useDatabasePathLiveValue } from '../components/common/Firebase'
import { publishNewBaseRecordAlias } from './UseStatsRecordStore'

export async function getParentStatRecordEntry({
  firebase,
  reviewId,
}: {
  firebase: FirebaseDb
  reviewId: string
}) {
  const statsRecordId = await firebase.getVal(`reviews/${reviewId}/parentStatRecord`)
  return statsRecordId ? getStatRecord({ firebase, statsRecordId }) : undefined
}

/**
 * returns the statRecord if one exists for the given review
 * @param firebase
 * @param reviewId
 */
export async function getStatRecordAlias({
  firebase,
  reviewId,
}: {
  firebase: FirebaseDb
  reviewId: string
}): Promise<StatRecordAlias | undefined> {
  const result = await firebase.getVal<ReviewFirebaseEntry['statRecordAlias']>(
    `reviews/${reviewId}/statRecordAlias`,
  )
  if (typeof result === 'string')
    return StatRecordStorage.mapFirebaseToStatRecordAlias({ allTeams: result })

  return result && StatRecordStorage.mapFirebaseToStatRecordAlias(result)
}

/**
 * returns the statRecord if one exists for the given review
 * @param firebase
 * @param reviewId
 */
export async function getReviewWatcherStatRecordId({
  firebase,
  reviewId,
}: {
  firebase: FirebaseDb
  reviewId: string
}): Promise<string> {
  const statRecordAlias = await getStatRecordAlias({ firebase, reviewId })

  const watcherStatRecordId = statRecordAlias?.watcherOnly

  if (watcherStatRecordId) return watcherStatRecordId
  const reviewRef = firebase.getRef<ReviewFirebaseEntry>(`reviews/${reviewId}`)
  const activityType = await reviewRef.childFromKey(`activityType`).getVal()
  const videoId = await reviewRef.childFromKey(`videoId`).getVal()
  const source = await reviewRef.childFromKey(`source`).getVal()
  const statsTemplateKey = (await reviewRef.childFromKey(`statsTemplateKey`).getVal()) ?? null

  if (!activityType || !videoId || !source) {
    throw new Error('Could not build WatcherLink')
  }

  const ensureStatRecordAlias =
    statRecordAlias ??
    (await publishNewBaseRecordAlias(firebase, {
      activityType,
      reviewId,
      source,
      videoId,
      statsTemplateKey,
    }))

  const watcherStatRecord = {
    activityType,
    reviewId,
    source,
    videoId,
    rules: { 0: 'stats_locked', 1: 'stats_locked' },
    statsTemplateKey,
  } satisfies StatRecord

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const newStatRecordId = firebase.getRef(`stat_records`).push(watcherStatRecord).key!

  const newStatRecordAlias = {
    allTeams: ensureStatRecordAlias.allTeams,
    watcherOnly: newStatRecordId,
    allLinks: {
      ...ensureStatRecordAlias.allLinks,
      watcherStatRecordId: watcherStatRecord.rules,
    },
  } satisfies StatRecordAlias

  await setStatRecordAlias({
    firebase,
    reviewId,
    statRecordAlias: newStatRecordAlias,
  })

  return newStatRecordId
}

/**
 * returns the statRecordId if one exists for the given review
 * @param firebase
 * @param reviewId
 */
export async function getParentStatRecordId({
  firebase,
  reviewId,
}: {
  firebase: FirebaseDb
  reviewId: string
}) {
  return await firebase.getVal(`reviews/${reviewId}/parentStatRecord`)
}

/**
 * Sets the statRecordAlias for the given review
 * @param firebase
 * @param reviewId
 * @param startRecordAlias
 */
export async function setStatRecordAlias({
  firebase,
  reviewId,
  statRecordAlias,
}: {
  firebase: FirebaseDb
  reviewId: string
  statRecordAlias: StatRecordAlias
}) {
  await firebase
    .getRef(`reviews/${reviewId}/statRecordAlias`)
    .set(StatRecordStorage.mapFirebaseFromStatRecordAlias(statRecordAlias))
}

export async function getStatRecord({
  firebase,
  statsRecordId,
}: {
  firebase: FirebaseDb
  statsRecordId: string
}): Promise<StatRecord | undefined> {
  const statRecordEntry = await firebase.getVal<FirebaseStatRecordEntry>(
    `stat_records/${statsRecordId}`,
  )
  return (
    (statRecordEntry && StatRecordStorage.mapFirebaseToStatRecord(statRecordEntry)) || undefined
  )
}

export function useParentStatRecord({
  firebase: firebase,
  reviewId,
}: {
  firebase: FirebaseDb
  reviewId: string | undefined
}): StatRecord | undefined {
  const statRecordId = useDatabasePathLiveValue<string>(
    firebase,
    `reviews/${reviewId}/parentStatRecord`,
  )
  const statRecord = useDatabasePathLiveValue<FirebaseStatRecordEntry>(
    firebase,
    `stat_records/${statRecordId}`,
  )
  return useMemo(
    () => statRecord && StatRecordStorage.mapFirebaseToStatRecord(statRecord),
    [statRecord],
  )
}

export function combineStatRecordRules(
  rules1: StatRecord['rules'],
  rules2: StatRecord['rules'],
): StatRecord['rules'] {
  const hiddenTeams = matchingTeamsRules(rules1, 'not_visible')
    .concat(matchingTeamsRules(rules2, 'not_visible'))
    .distinct()
    .reduce((acc, team) => ({ ...acc, [team]: 'hidden' }) satisfies StatRecord['rules'], {})

  const lockedTeams = matchingTeamsRules(rules1, 'visible')
    .concat(matchingTeamsRules(rules2, 'visible'))
    .distinct()
    .reduce((acc, team) => ({ ...acc, [team]: 'stats_locked' }) satisfies StatRecord['rules'], {})

  const unlockedTeams = matchingTeamsRules(rules1, 'stats_unlocked')
    .concat(matchingTeamsRules(rules2, 'stats_unlocked'))
    .distinct()
    .reduce((acc, team) => ({ ...acc, [team]: 'stats_unlocked' }) satisfies StatRecord['rules'], {})

  return Object.assign(hiddenTeams, lockedTeams, unlockedTeams)
}

export function getOppositeRules(rules: StatRecord['rules']): StatRecord['rules'] {
  return Object.entries(rules).reduce(
    (acc, [team, rule]) => ({
      ...acc,
      [team]: rule !== 'stats_unlocked' ? 'stats_unlocked' : 'hidden',
    }),
    {},
  ) as StatRecord['rules']
}

export async function getStatUpgradeInfo({
  firebase,
  reviewId,
}: {
  firebase: FirebaseDb
  reviewId: string
}): Promise<{ teamNames: string[] } | undefined> {
  const statRecord = await getParentStatRecordEntry({ firebase, reviewId })
  if (!statRecord) return undefined

  const parentStatsAlias = await getStatRecordAlias({ firebase, reviewId: statRecord.reviewId })
  if (!parentStatsAlias) return undefined
  const oppositeRules = getOppositeRules(statRecord.rules)
  const match = findExactAlias(parentStatsAlias, oppositeRules)
  if (!match) return undefined

  const reviewRef = firebase.getRef<ReviewFirebaseEntry>(`reviews/${statRecord.reviewId}`)
  const teamNames = await reviewRef.childFromKey('teamNames').getVal()
  if (!teamNames) return undefined

  return {
    teamNames: matchingTeamsRules(oppositeRules, 'stats_unlocked').mapNotNull(
      (team) => teamNames[team],
    ),
  }
}
