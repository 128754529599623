import { stringAsDatabaseKey } from 'data/common'
import {
  DivisionFilter,
  FirebaseBaseLeaderboardEntry,
  LeaderboardAggregateResults,
  LeaderboardGameResults,
  LeaderboardGraphResults,
  RoundInfo,
} from 'data/leaderboardtypes'
import { DodgeballMeasureKeys } from 'templates/dodgeball/MeasureKeys'
import { Measure } from './RankingsByDiv'
import {
  calculateRankedPlayerResults,
  calculateRankedTeamResults,
  filterResults,
  gameResultsFilters,
} from './RankingsTable'
import { FirebaseDbReference } from './common/Firebase'

type DivisionAggregateResults = NonNullable<
  NonNullable<LeaderboardAggregateResults[DodgeballMeasureKeys]>['divisions'][string]
>

export async function regenerateRankings<T extends FirebaseBaseLeaderboardEntry>(
  measure: Measure,
  results: LeaderboardGameResults,
  divisionFilters: DivisionFilter[],
  selectedRound: RoundInfo | undefined,
  leaderboardRef: FirebaseDbReference<T>,
) {
  const dbChanges = divisionFilters.reduce<Record<string, DivisionAggregateResults>>(
    (acc, division) => {
      const filteredResults = filterResults(
        results,
        selectedRound ?
          gameResultsFilters(selectedRound?.category, division.key, selectedRound?.number)
        : () => false,
      )
      const rankingsByPlayer = calculateRankedPlayerResults(filteredResults, measure)
      const rankingsByTeam = calculateRankedTeamResults(filteredResults, measure)
      const valuesByPlayer = rankingsByPlayer.map((it) => it.total?.value).filterNotNull()
      const valuesByTeam = rankingsByTeam.map((it) => it.total?.value).filterNotNull()
      acc[`${measure.key}/divisions/${division.key}`] = {
        byPlayerNameHash: rankingsByPlayer.reduce<
          NonNullable<DivisionAggregateResults['byPlayerNameHash']>
        >((acc, it) => {
          acc[stringAsDatabaseKey(it.player)] = {
            player: it.player,
            displayRank: it.displayRank,
            value: it.total?.value ?? null,
            displayValue: it.total?.displayValue ?? null,
            displayBreakdown: it.total?.displayBreakdown ?? null,
          }
          return acc
        }, {}),
        byTeamNameHash: rankingsByTeam.reduce<
          NonNullable<DivisionAggregateResults['byTeamNameHash']>
        >((acc, it) => {
          acc[stringAsDatabaseKey(it.team)] = {
            team: it.team,
            displayRank: it.displayRank,
            value: it.total?.value ?? null,
            displayValue: it.total?.displayValue ?? null,
            displayBreakdown: it.total?.displayBreakdown ?? null,
          }
          return acc
        }, {}),
        byPlayerRanking: rankingsByPlayer
          .groupByArray((it) => it.displayRank)
          .reduce<NonNullable<DivisionAggregateResults['byPlayerRanking']>>(
            (acc, [rank, results]) => {
              acc[rank] = results.map((it) => {
                return {
                  player: it.player,
                  displayRank: it.displayRank,
                  value: it.total?.value ?? null,
                  displayValue: it.total?.displayValue ?? null,
                  displayBreakdown: it.total?.displayBreakdown ?? null,
                }
              })
              return acc
            },
            {},
          ),
        byTeamRanking: rankingsByTeam
          .groupByArray((it) => it.displayRank)
          .reduce<NonNullable<DivisionAggregateResults['byTeamRanking']>>(
            (acc, [rank, results]) => {
              acc[rank] = results.map((it) => {
                return {
                  team: it.team,
                  displayRank: it.displayRank,
                  value: it.total?.value ?? null,
                  displayValue: it.total?.displayValue ?? null,
                  displayBreakdown: it.total?.displayBreakdown ?? null,
                }
              })
              return acc
            },
            {},
          ),
        valueAggregatesByPlayer: {
          average: valuesByPlayer.avgOrNull() ?? 0,
          median: valuesByPlayer.medianOrNull() ?? 0,
          maximum: valuesByPlayer.maxOrNull() ?? 0,
          minimum: valuesByPlayer.minOrNull() ?? 0,
        },
        valueAggregatesByTeam: {
          average: valuesByTeam.avgOrNull() ?? 0,
          median: valuesByTeam.medianOrNull() ?? 0,
          maximum: valuesByTeam.maxOrNull() ?? 0,
          minimum: valuesByTeam.minOrNull() ?? 0,
        },
      }
      return acc
    },
    {},
  )

  await leaderboardRef.childFromKey('aggregateResultsByPlayer').update(dbChanges)
}
