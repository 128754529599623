'use client'

import * as React from 'react'
import { PropsWithChildren, forwardRef, useCallback, useEffect, useRef } from 'react'
import { useCompositeRefObject } from './UseCompositeRefObject'
import { cn } from './common/utils/tailwindUtils'

export const ScrollBox = forwardRef<
  HTMLDivElement,
  PropsWithChildren &
    React.HTMLProps<HTMLDivElement> & {
      direction: 'horizontal' | 'vertical' | 'both'
      startPos?: 'center'
      handleScrollWheel?: boolean
    }
>(function ScrollBox(
  { children, direction, startPos, handleScrollWheel, className, ...htmlDivProps },
  forwardedRef,
) {
  const ref = useRef<HTMLDivElement | null>(null)
  const alreadyScrolledRef = useRef(false)
  const handleWheel = useCallback(
    (event: React.WheelEvent) => {
      if (ref.current) {
        if (direction === 'horizontal' && handleScrollWheel) {
          // noinspection JSSuspiciousNameCombination
          ref.current?.scrollBy({ left: event.deltaY })
          event.stopPropagation()
        }
      }
    },
    [direction, handleScrollWheel],
  )

  useEffect(() => {
    if (startPos === 'center' && ref.current && !alreadyScrolledRef.current) {
      alreadyScrolledRef.current = true
      setTimeout(() => {
        ref.current?.scrollTo({
          left: (ref.current.scrollWidth - ref.current.clientWidth) / 2,
          behavior: 'smooth',
        })
      }, 2000)
    }
  }, [startPos])

  const multiRef = useCompositeRefObject(ref, forwardedRef)
  return (
    <div
      ref={multiRef}
      {...htmlDivProps}
      className={cn(
        'scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-950',
        direction === 'horizontal' || direction === 'both' ? 'overflow-x-auto' : 'overflow-x-clip',
        direction === 'vertical' || direction === 'both' ? 'overflow-y-auto' : 'overflow-y-clip',
        className,
      )}
      onWheel={handleWheel}>
      {children}
    </div>
  )
})
