'use client'

import React, {
  CSSProperties,
  Component,
  Dispatch,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  useState,
} from 'react'

export type ScrollState = [number, number]

export function useScrollState(initialScroll?: ScrollState) {
  return useState<ScrollState>(initialScroll ?? [0, 0])
}

type ScrollMaintainingDivProps = PropsWithChildren & {
  className?: string
  // parent element needs to maintain the scroll state
  useScrollState: [ScrollState, Dispatch<SetStateAction<ScrollState>>]
  style?: CSSProperties
  scrollToKey?: string
}

export class ScrollMaintainingDiv extends Component<ScrollMaintainingDivProps> {
  containerRef: RefObject<HTMLDivElement> = React.createRef()
  scroll = { scrollTop: 0, scrollLeft: 0 }

  componentDidMount() {
    const { useScrollState } = this.props
    const [scrollPosition] = useScrollState
    this.scrollToPosition(scrollPosition)
  }

  componentWillUnmount() {
    const [, onUnmount] = this.props.useScrollState
    onUnmount([this.scroll.scrollTop, this.scroll.scrollLeft])
  }

  scrollToPosition(scrollState: ScrollState) {
    if (this.containerRef.current) {
      this.containerRef.current.scrollTop = scrollState[0]
      this.containerRef.current.scrollLeft = scrollState[1]
    }
  }

  updateCurrentScrollState() {
    if (this.containerRef.current?.scrollTop !== undefined)
      this.scroll.scrollTop = this.containerRef.current.scrollTop
    if (this.containerRef.current?.scrollLeft !== undefined)
      this.scroll.scrollLeft = this.containerRef.current.scrollLeft
  }

  render() {
    const { className, children, style } = this.props
    this.updateCurrentScrollState()
    return (
      <div
        style={style}
        className={className}
        ref={this.containerRef}>
        {children}
      </div>
    )
  }
}
