'use client'

import { ResponsiveValue } from '@chakra-ui/react'
import { icon } from '@fortawesome/fontawesome-svg-core'
import * as CSS from 'csstype'
import * as React from 'react'
import {
  CSSProperties,
  ForwardedRef,
  PropsWithChildren,
  ReactElement,
  forwardRef,
  useMemo,
} from 'react'
import { cn } from './common/utils/tailwindUtils'

export type MarkerProps = PropsWithChildren & {
  backgroundColor?: CSS.Property.Color
  displayStyle: 'down' | 'up' | 'circle'
  onContainerHoverChange?: (hover: boolean) => void
  onCircleHoverChange?: (hover: boolean) => void
  tag?: () => ReactElement | undefined
  verticalUi?: () => ReactElement | undefined
  horizontalUi?: () => ReactElement | undefined
  icon?: React.ReactNode
  tagBackgroundColor?: CSS.Property.Color
  outline?: boolean | undefined
  width?: number
  outlineColor?: CSS.Property.Color
} & Omit<React.HTMLProps<HTMLDivElement>, 'width'>

export const InteractiveMarker = forwardRef(function Marker(
  {
    backgroundColor = 'white',
    tagBackgroundColor = 'black',
    displayStyle,
    tag,
    verticalUi,
    horizontalUi,
    outline,
    outlineColor,
    draggable,

    ...props
  }: MarkerProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const variationStyles = useMemo(() => {
    const variationStyles: {
      container?: CSSProperties
      tag?: CSSProperties
      verticalUI?: CSSProperties
      horizontalUI?: CSSProperties
    } = {}
    switch (displayStyle) {
      case 'down':
        variationStyles.tag = {
          paddingBottom: '14px',
        }
        break
      case 'up':
        variationStyles.container = {
          flexDirection: 'column-reverse',
          justifyContent: 'flex-end',
        }
        variationStyles.tag = {
          paddingTop: '14px',
        }
        variationStyles.verticalUI = {
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }
        break
      case 'circle':
        variationStyles.tag = {
          paddingBottom: '12px',
        }
        break
    }
    return variationStyles
  }, [displayStyle])

  return (
    <div
      {...props}
      draggable={draggable}
      ref={ref}
      className={cn(
        'pointer-events-none flex h-[20px] w-fit -translate-x-[10px] flex-col items-center justify-end',
        props.className,
      )}
      style={{
        ...variationStyles.container,
        ...props.style,
      }}>
      {verticalUi ?
        <div
          style={{
            ...variationStyles.verticalUI,
          }}
          className='pointer-events-auto z-[-2] flex w-5 flex-col-reverse items-center justify-start py-2
            font-league-spartan text-sm font-semibold'>
          {verticalUi()}
        </div>
      : undefined}
      {tag ?
        <div
          style={{
            backgroundColor: tagBackgroundColor,
            ...variationStyles.tag,
          }}
          className='pointer-events-auto z-[-1] my-[-16px] w-[20px] rounded-full font-league-spartan text-sm text-white
            font-semibold'>
          {tag()}
        </div>
      : undefined}
      <MarkerShapeWithWidth
        backgroundColor={backgroundColor}
        displayStyle={displayStyle}
        outline={outline}
        outlineColor={outlineColor}
        width={props.width}
        icon={props.icon}>
        {horizontalUi ?
          <div
            style={{
              ...variationStyles.horizontalUI,
            }}
            className='pointer-events-auto absolute left-[calc(100%+20px)] z-[2] flex h-5 flex-col items-center justify-end
              pr-1 font-league-spartan text-sm font-semibold'>
            {horizontalUi()}
          </div>
        : undefined}
        {props.children}
      </MarkerShapeWithWidth>
    </div>
  )
})

export const MarkerShapeWithWidth = React.memo(function MarkerShapeWithWidth({
  backgroundColor,
  displayStyle,
  outline,
  outlineColor,
  children,
  width,
  icon,
}: {
  backgroundColor?: CSS.Property.Color
  displayStyle: 'down' | 'up' | 'circle'
  outline?: boolean | undefined
  outlineColor?: CSS.Property.Color
  children?: React.ReactNode
  icon?: React.ReactNode
  width?: number
}) {
  return (
    <>
      {!width && (
        <MarkerShape
          className='pointer-events-auto relative'
          backgroundColor={backgroundColor}
          displayStyle={displayStyle}
          outline={outline}
          outlineColor={outlineColor}
          icon={icon}>
          {children}
        </MarkerShape>
      )}
      {!!width && (
        <div
          className='pointer-events-auto relative flex'
          style={{
            outline:
              outline ? `${outlineColor ?? 'rgba(255, 255, 255, 1.0)'} solid 2px` : undefined,
          }}>
          <MarkerShape
            backgroundColor={backgroundColor}
            displayStyle={displayStyle}
            outline={outline}
          />
          <div
            style={{
              backgroundColor: backgroundColor,
              width: width,
            }}
            className='pointer-events-auto z-[1] flex h-[20px] flex-shrink-0 flex-grow-0 cursor-grab items-center
              justify-center transition-all'>
            <div className='h-[20px] w-[20px]'>{children}</div>
            <div className='absolute h-[20px] w-[20px]'>{icon}</div>
          </div>
          <MarkerShape
            backgroundColor={backgroundColor}
            displayStyle={displayStyle}
            outline={outline}
          />
        </div>
      )}
    </>
  )
})

export const MarkerShape = React.memo(function MarkerShape({
  backgroundColor,
  displayStyle,
  outline,
  outlineColor,
  children,
  className,
  icon,
}: {
  backgroundColor?: CSS.Property.Color
  displayStyle: 'down' | 'up' | 'circle'
  outline?: boolean | undefined
  outlineColor?: ResponsiveValue<CSS.Property.Color>
  children?: React.ReactNode
  className?: string
  icon?: React.ReactNode
}) {
  const variationStyles = useMemo(() => {
    const variationStyles: {
      point?: CSSProperties
      circle?: CSSProperties
    } = {}
    switch (displayStyle) {
      case 'down':
        variationStyles.point = {
          top: 12,
          borderTop: `12px solid ${backgroundColor}`,
        }
        break
      case 'up':
        variationStyles.point = {
          bottom: 12,
          borderBottom: `12px solid ${backgroundColor}`,
        }
        break
      case 'circle':
        variationStyles.point = {
          display: 'none',
        }
        break
    }
    return variationStyles
  }, [displayStyle, backgroundColor])
  return (
    <div className={cn('w-0', className)}>
      <div className='w-fit -translate-x-1/2'>
        <div
          style={{
            backgroundColor: backgroundColor,
            outline:
              outline ? `${outlineColor ?? 'rgba(255, 255, 255, 1.0)'} solid 2px` : undefined,
            ...variationStyles.circle,
          }}
          className='flex h-[20px] w-[20px] flex-shrink-0 flex-grow-0 cursor-grab justify-center rounded-full border-0
            border-solid border-white transition-all'>
          {children}
          {icon}
        </div>
        {displayStyle !== 'circle' && (
          <div
            style={{
              ...variationStyles.point,
            }}
            className='pointer-events-none absolute left-0 z-[-1] h-0 w-0 border-[10px] border-solid border-transparent
              content-[]'
          />
        )}
      </div>
    </div>
  )
})
