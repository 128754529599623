import { simplifyEvents, splitClashes, splitRounds } from 'data/StatsStore'
import { PlayerFirebaseEntry } from 'data/common'
import { getChildCategories, matchChildCategoryKey } from 'templates/TemplateConfig'
import { DodgeballTemplate } from 'templates/dodgeball/DodgeballTemplate'
import {
  HighlightedNoteBox,
  Measure,
  MeasurePlayerResult,
  MeasureResult,
} from '../../components/RankingsByDiv'

export const DodgeballLeaderboardMeasures: readonly Measure[] = [
  {
    // Leave this in first place
    key: 'games_played',
    title: 'Games played',
    description: 'Games played',
    mode: 'sum',
    disableTopGameRecords: true,
    hidden: true,
    highlightEventKeys: [],
    playerValuesByGame: (sortedEvents, players) => {
      return players.reduce((previousValue, player) => {
        const playerIsInGame = sortedEvents.any(
          (event) => !!event.player && event.player.id === player.id,
        )
        previousValue.push({
          player,
          value: playerIsInGame ? 1 : 0,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
  },
  {
    key: 'kills',
    title: 'Kills',
    description: 'Number of kills including catches and hits',
    mode: 'sum',
    highlightEventKeys: [
      DodgeballTemplate.Definitions.get('throw_hit').key,
      DodgeballTemplate.Definitions.get('defend_catch_success').key,
    ],
    playerValuesByGame: (sortedEvents, players) => {
      const killEvents = getChildCategories(
        DodgeballTemplate.buttonSetup,
        'KillEvent',
        true,
      ).mapProp('key')

      return players.reduce((previousValue, player) => {
        const playersEvents = sortedEvents.filter(
          (event) => !!event.player && event.player.id === player.id,
        )

        const hits = playersEvents.filter((event) => {
          const currentEventKey = event.definition_key
          return killEvents.includes(currentEventKey)
        }).length

        previousValue.push({
          player,
          value: hits,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
  },
  {
    key: 'hits',
    title: 'Hits',
    mode: 'sum',
    description: 'Number of kills resulting from throws',
    highlightEventKeys: [DodgeballTemplate.Definitions.get('throw_hit').key],
    playerValuesByGame: (sortedEvents, players) => {
      return players.reduce((previousValue, player) => {
        const playersEvents = sortedEvents.filter(
          (event) => !!event.player && event.player.id === player.id,
        )

        const hits = playersEvents.filter((event) =>
          matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('throw_hit'),
            event.definition_key,
          ),
        ).length

        previousValue.push({
          player,
          value: hits,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
  },
  {
    key: 'ontarget_throws',
    title: 'On-target throws',
    mode: 'sum',
    description: 'Number of on-target throws',
    highlightEventKeys: [DodgeballTemplate.Definitions.get('throw_ontarget').key],
    playerValuesByGame: (sortedEvents, players) => {
      return players.reduce((previousValue, player) => {
        const playersEvents = sortedEvents.filter(
          (event) => !!event.player && event.player.id === player.id,
        )

        const hits = playersEvents.filter((event) =>
          matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('throw_ontarget'),
            event.definition_key,
          ),
        ).length

        previousValue.push({
          player,
          value: hits,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
  },
  {
    key: 'hits_per_set',
    title: 'Hits per set',
    disableTopGameRecords: true,
    description: (
      <>
        Average number of hits you get per set that you play
        <br />
        You need to have played atleast 2 games and 10 sets to count on the leaderboard
      </>
    ),
    highlightEventKeys: [DodgeballTemplate.Definitions.get('throw_hit').key],
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1)
      const rounds = splitRounds(simplifiedEvents)
      return players.reduce((previousValue, player) => {
        const setsPlayed = rounds.filter((round) => {
          return round.any((event) => !!event.players?.find((it) => it.id === player.id))
        }).length
        const playersEvents = sortedEvents.filter(
          (event) => !!event.player && event.player.id === player.id,
        )

        const hits = playersEvents.filter((event) =>
          matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('throw_hit'),
            event.definition_key,
          ),
        ).length

        const value = Math.round((hits * 1000) / setsPlayed) / 1000

        previousValue.push({
          player,
          value: value,
          displayValue: `${Math.floor(value * 100) / 100}`,
          displayBreakdown: `${hits} hits / ${setsPlayed} sets played`,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },

    dependencies: ['hits', 'sets_played', 'games_played'],
    valueByLeagueResult: (result, predicate, gamesPerPlayer) => {
      if (!result['hits'] || !result['sets_played'] || !result['games_played']) return undefined
      const hits = result['hits'].filter(predicate)?.mapProp('value')?.filterNotNull()?.sum() ?? 0
      const setsPlayed =
        result['sets_played'].filter(predicate)?.mapProp('value')?.filterNotNull()?.sum() ?? 0
      const gamesPlayed =
        result['games_played'].filter(predicate)?.mapProp('value')?.filterNotNull()?.sum() ?? 0

      const value =
        (gamesPlayed >= 2 || gamesPlayed >= gamesPerPlayer) && setsPlayed >= 10 ?
          Math.round((hits * 100) / setsPlayed) / 100
        : null

      return {
        value,
        displayValue: value !== null ? `${Math.floor(value * 100) / 100}` : undefined,
        displayBreakdown: `${hits} hits / ${setsPlayed}  sets played`,
      }
    },
  },
  {
    key: 'hit_rate',
    title: 'Hit rate',
    description: (
      <>
        Number of kills as a percentage of the throws you took
        <br />
        You need to have thrown more than 20 times to count on the leaderboard
      </>
    ),
    highlightEventKeys: [DodgeballTemplate.Definitions.get('throw').key],
    playerValuesByGame: (sortedEvents, players) => {
      return players.reduce((previousValue, player) => {
        const playersEvents = sortedEvents.filter(
          (event) => !!event.player && event.player.id === player.id,
        )

        const throws = playersEvents.filter((event) =>
          matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('throw'),
            event.definition_key,
          ),
        ).length

        const hits = playersEvents.filter((event) =>
          matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('throw_hit'),
            event.definition_key,
          ),
        ).length

        const value = throws >= 20 ? Math.round((hits * 1000) / throws) / 1000 : undefined

        previousValue.push({
          player,
          value: value ?? null,
          displayValue: value !== undefined ? `${Math.floor(value * 1000) / 10}%` : undefined,
          displayBreakdown: `${hits} hits / ${throws} throws`,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },

    dependencies: ['hits', 'throws'],
    valueByLeagueResult: (result, predicate, gamesPerPlayer) => {
      if (!result['hits'] || !result['throws']) return undefined
      const playerGames =
        result['throws']?.filter(predicate)?.distinctBy((it) => it.reviewId)?.length ?? 0

      if (!playerGames || !(playerGames > 1 || playerGames >= gamesPerPlayer)) return undefined

      const hits = result['hits'].filter(predicate)?.mapProp('value')?.filterNotNull()?.sum() ?? 0
      const throws =
        result['throws'].filter(predicate)?.mapProp('value')?.filterNotNull()?.sum() ?? 0

      const value = throws >= 20 ? Math.round((hits * 1000) / throws) / 1000 : null

      return {
        value,
        displayValue: value !== null ? `${Math.floor(value * 1000) / 10}%` : undefined,
        displayBreakdown: `${hits} hits / ${throws} throws`,
      }
    },
  },
  {
    key: 'accuracy',
    title: 'Accuracy',
    description: (
      <>
        Number of on-target throws as a percentage of the throws you took
        <br />
        You need to have thrown more than 20 times to count on the leaderboard
      </>
    ),
    highlightEventKeys: [DodgeballTemplate.Definitions.get('throw').key],
    playerValuesByGame: (sortedEvents, players) => {
      return players.reduce((previousValue, player) => {
        const playersEvents = sortedEvents.filter(
          (event) => !!event.player && event.player.id === player.id,
        )

        const throws = playersEvents.filter((event) =>
          matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('throw'),
            event.definition_key,
          ),
        ).length

        const ontargets = playersEvents.filter((event) =>
          matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('throw_ontarget'),
            event.definition_key,
          ),
        ).length

        const value = throws >= 20 ? Math.round((ontargets * 1000) / throws) / 1000 : null

        previousValue.push({
          player,
          value,
          displayValue: value !== null ? `${Math.floor(value * 1000) / 10}%` : undefined,
          displayBreakdown: `${ontargets} on-target / ${throws} throws`,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },

    dependencies: ['ontarget_throws', 'throws'],
    valueByLeagueResult: (result, predicate, gamesPerPlayer) => {
      if (!result['ontarget_throws'] || !result['throws']) return undefined

      const ontargets =
        result['ontarget_throws'].filter(predicate)?.mapProp('value')?.filterNotNull()?.sum() ?? 0
      const throws =
        result['throws'].filter(predicate)?.mapProp('value')?.filterNotNull()?.sum() ?? 0

      const value = throws >= 20 ? Math.round((ontargets * 1000) / throws) / 1000 : undefined

      return {
        value: value ?? null,
        displayValue: value !== undefined ? `${Math.floor(value * 1000) / 10}%` : undefined,
        displayBreakdown: `${ontargets} on-target / ${throws} throws`,
      }
    },
  },
  {
    key: 'throws',
    title: 'Throws',
    mode: 'sum',
    description: <>Number of throws you took, regardless of the result</>,
    highlightEventKeys: [DodgeballTemplate.Definitions.get('throw').key],
    playerValuesByGame: (sortedEvents, players) => {
      return players.reduce((previousValue, player) => {
        const playersEvents = sortedEvents.filter(
          (event) => !!event.player && event.player.id === player.id,
        )

        const throws = playersEvents.filter((event) =>
          matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('throw'),
            event.definition_key,
          ),
        ).length

        previousValue.push({
          player,
          value: throws,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
  },
  {
    key: 'catches',
    title: 'Catches',
    mode: 'sum',
    description: 'Number of successful catches',
    highlightEventKeys: [DodgeballTemplate.Definitions.get('defend_catch_success').key],
    playerValuesByGame: (sortedEvents, players) => {
      return players.reduce((previousValue, player) => {
        const playersEvents = sortedEvents.filter(
          (event) => !!event.player && event.player.id === player.id,
        )

        const hits = playersEvents.filter((event) =>
          matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('defend_catch_success'),
            event.definition_key,
          ),
        ).length

        previousValue.push({
          player,
          value: hits,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
  },
  {
    key: 'sets_won',
    title: 'Sets won',
    mode: 'sum',
    hidden: true,
    description: 'Number of sets you were involved in that your team won',
    highlightEventKeys: [],
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1)
      const rounds = splitRounds(simplifiedEvents)
      return players.reduce(
        (previousValue, player) => {
          const roundsWon = rounds.filter((round) => {
            return (
              round.any(
                (event) =>
                  (event.isWin && player.team === 0) || (event.isLose && player.team === 1),
              ) && round.any((event) => !!event.players?.find((it) => it.id === player.id))
            )
          }).length
          previousValue.push({ player, value: roundsWon })
          return previousValue
        },
        [] as {
          player: Pick<PlayerFirebaseEntry, 'id' | 'name' | 'team'>
          value: number
        }[],
      )
    },
  },
  {
    key: 'set_win_rate',
    title: 'Set win rate',
    highlightEventKeys: [],
    description: (
      <>
        The probabality that your team will win the set when you are subbed on
        <br /> You need to have played more than 8 sets and 2 games to count on the leaderboard
      </>
    ),
    disableTopGameRecords: true,
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1)
      const rounds = splitRounds(simplifiedEvents)
      return players.reduce((previousValue, player) => {
        const roundsWon = rounds.filter((round) => {
          return (
            round.any(
              (event) => (event.isWin && player.team === 0) || (event.isLose && player.team === 1),
            ) && round.any((event) => !!event.players?.find((it) => it.id === player.id))
          )
        }).length

        const roundsPlayed = rounds.filter((round) => {
          return round.any((event) => !!event.players?.find((it) => it.id === player.id))
        }).length

        const value =
          roundsPlayed >= 8 ? Math.round((roundsWon * 1000) / roundsPlayed) / 1000 : undefined

        previousValue.push({
          player,
          value: value ?? null,
          displayValue: value !== undefined ? `${Math.floor(value * 1000) / 10}%` : undefined,
          displayBreakdown: `${roundsWon} sets won / ${roundsPlayed} sets played`,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
    dependencies: ['sets_won', 'sets_played'],
    valueByLeagueResult: (result, predicate, gamesPerPlayer) => {
      const sets_won_games = result['sets_won']?.filter(predicate)
      const sets_played_games = result['sets_played']?.filter(predicate)

      if (!sets_played_games?.length) return undefined

      if (!result['sets_played']) return undefined
      const sets_won = sets_won_games?.mapProp('value')?.filterNotNull()?.sum() ?? 0
      const sets_played = sets_played_games?.mapProp('value')?.filterNotNull()?.sum() ?? 0

      const value =
        (
          sets_played >= 8 &&
          (sets_played_games.length >= 2 || sets_played_games.length >= gamesPerPlayer)
        ) ?
          Math.round((sets_won * 1000) / sets_played) / 1000
        : undefined

      return {
        value: value ?? null,
        displayValue: value !== undefined ? `${Math.floor(value * 1000) / 10}%` : undefined,
        displayBreakdown: `${sets_won} sets won / ${sets_played} sets played`,
      }
    },
  },
  {
    key: 'sets_played',
    title: 'Sets played',
    mode: 'sum',
    forfun: true,
    hidden: true,
    description: 'Number of sets you were involved in',
    highlightEventKeys: [],
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1)
      const rounds = splitRounds(simplifiedEvents)
      return players.reduce(
        (previousValue, player) => {
          const roundsPlayed = rounds.filter((round) => {
            return round.any((event) => !!event.players?.find((it) => it.id === player.id))
          }).length

          previousValue.push({ player, value: roundsPlayed })
          return previousValue
        },
        [] as {
          player: Pick<PlayerFirebaseEntry, 'id' | 'name' | 'team'>
          value: number
        }[],
      )
    },
  },
  {
    key: 'sets_survived',
    title: 'Sets survived',
    description: 'Number of sets your team won and closed out while you were still alive',
    mode: 'sum',
    highlightEventKeys: [],
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1)
      const rounds = splitRounds(simplifiedEvents)
      return players.reduce(
        (previousValue, player) => {
          const roundsWon = rounds.filter((round) => {
            const playersEvents = round.filter((event) =>
              event.players?.any((it) => it.id === player.id),
            )

            if (!playersEvents.length) return false
            return (
              playersEvents.filter((event) =>
                matchChildCategoryKey(
                  DodgeballTemplate.buttonSetup,
                  DodgeballTemplate.Definitions.get('revive'),
                  event.definition_key,
                ),
              ).length -
                playersEvents.filter((event) =>
                  matchChildCategoryKey(
                    DodgeballTemplate.buttonSetup,
                    DodgeballTemplate.Definitions.get('died'),
                    event.definition_key,
                  ),
                ).length >=
              0
            )
          }).length
          previousValue.push({ player, value: roundsWon })
          return previousValue
        },
        [] as {
          player: Pick<PlayerFirebaseEntry, 'id' | 'name' | 'team'>
          value: number
        }[],
      )
    },
  },
  {
    key: 'sets_clutch_attempt',
    title: 'Attempted clutches',
    description: 'Number of sets you were the last player alive',
    mode: 'sum',
    highlightEventKeys: [],
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1).filter(
        (event) => event.activityType === 'dodgeball',
      )
      const rounds = splitRounds(simplifiedEvents).map((round) => {
        return {
          round,
          teamWon: [round.any((event) => event.isWin), round.any((event) => event.isLose)] as const,
          playersSubbed: round
            .flatMap((event) => event.players ?? [])
            .distinctBy((it) => it.id)
            .groupBy((it) => it.team),
        }
      })

      return players.reduce(
        (previousValue, player) => {
          const roundsClutchAttempted = rounds.filter(({ round, teamWon, playersSubbed }) => {
            const numberOfPlayersSubbed = playersSubbed.get(player.team ?? 0)?.length ?? 0

            const playerLastAliveEvent = round.find(
              (event) =>
                event.players?.any((it) => it.id === player.id) &&
                event.currentPlayersOut[event.team] === numberOfPlayersSubbed - 1 &&
                !event.isDeathEvent,
            )
            if (!playerLastAliveEvent) return false
            return true
          }).length
          previousValue.push({ player, value: roundsClutchAttempted })
          return previousValue
        },
        [] as {
          player: Pick<PlayerFirebaseEntry, 'id' | 'name' | 'team'>
          value: number
        }[],
      )
    },
  },
  {
    key: 'sets_clutched',
    title: 'Sets clutched',
    description: 'Number of sets you were the last player alive then your team won the set',
    mode: 'sum',
    highlightEventKeys: [],
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1).filter(
        (event) => event.activityType === 'dodgeball',
      )
      const rounds = splitRounds(simplifiedEvents).map((round) => {
        return {
          round,
          teamWon: [round.any((event) => event.isWin), round.any((event) => event.isLose)] as const,
          playersSubbed: round
            .flatMap((event) => event.players ?? [])
            .distinctBy((it) => it.id)
            .groupBy((it) => it.team),
        }
      })
      return players.reduce(
        (previousValue, player) => {
          const roundsClutched = rounds
            .filter(({ round, teamWon }) => {
              return (
                teamWon[player.team ?? 0] &&
                round.any((event) => !!event.players?.find((it) => it.id === player.id))
              )
            })
            .filter(({ round, playersSubbed }) => {
              const numberOfPlayersSubbed = playersSubbed.get(player.team ?? 0)?.length ?? 0

              const playerLastAliveEvent = round.find(
                (event) =>
                  event.players?.any((it) => it.id === player.id) &&
                  event.currentPlayersOut[event.team] === numberOfPlayersSubbed - 1 &&
                  !event.isDeathEvent,
              )
              if (!playerLastAliveEvent) return false
              return true
            }).length
          previousValue.push({ player, value: roundsClutched })
          return previousValue
        },
        [] as {
          player: Pick<PlayerFirebaseEntry, 'id' | 'name' | 'team'>
          value: number
        }[],
      )
    },
  },
  {
    key: 'sets_clutch_failed',
    title: 'False hopes',
    description: (
      <>
        Number of times you gave your team false hope.
        <br />
        <br />
        <i>ie. Number of times you were last alive, got a kill and then lost the set</i>
      </>
    ),
    mode: 'sum',
    forfun: true,
    highlightEventKeys: [],
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1).filter(
        (event) => event.activityType === 'dodgeball',
      )
      const rounds = splitRounds(simplifiedEvents).map((round) => {
        return {
          round,
          teamWon: [round.any((event) => event.isWin), round.any((event) => event.isLose)] as const,
          playersSubbed: round
            .flatMap((event) => event.players ?? [])
            .distinctBy((it) => it.id)
            .groupBy((it) => it.team),
        }
      })
      return players.reduce(
        (previousValue, player) => {
          const roundsClutchFailed = rounds
            .filter(({ round, teamWon }) => {
              return (
                !teamWon[player.team ?? 0] &&
                round.any((event) => !!event.players?.find((it) => it.id === player.id))
              )
            })
            .filter(({ round: lostRound }) => {
              const playerLastDead = lostRound.findLast(
                (event) => event.isDeathEvent && event.team === (player.team ?? 0),
              )

              if (!playerLastDead?.players?.any((it) => it.id === player.id)) return false
              return true
            })
            .filter(({ round: lostRound, playersSubbed }) => {
              const playerFirstAloneIndex = lostRound.findIndex(
                (event) =>
                  event.currentPlayersOut[player.team ?? 0] ===
                    (playersSubbed.get(player.team ?? 0)?.length ?? 0) - 1 &&
                  event.players?.any((it) => it.id === player.id),
              )

              const eventsFromPlayerFirstAlone = lostRound
                .slice(playerFirstAloneIndex)
                .filter((event) => event.players?.any((it) => it.id === player.id))

              const gotAKill = eventsFromPlayerFirstAlone.any((event) => event.isKillEvent)

              return gotAKill
            }).length
          previousValue.push({ player, value: roundsClutchFailed })
          return previousValue
        },
        [] as {
          player: Pick<PlayerFirebaseEntry, 'id' | 'name' | 'team'>
          value: number
        }[],
      )
    },
  },
  {
    key: 'sets_clutch_win_rate',
    title: 'Clutched Rate',
    description: 'Probability that you will win the set when you are the last player alive',
    // hidden: true,
    disableTopGameRecords: true,
    highlightEventKeys: [],
    dependencies: ['sets_clutch_attempt', 'sets_clutched'],
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1).filter(
        (event) => event.activityType === 'dodgeball',
      )
      const rounds = splitRounds(simplifiedEvents).map((round) => {
        return {
          round,
          teamWon: [round.any((event) => event.isWin), round.any((event) => event.isLose)] as const,
          playersSubbed: round
            .flatMap((event) => event.players ?? [])
            .distinctBy((it) => it.id)
            .groupBy((it) => it.team),
        }
      })
      return players.reduce((previousValue, player) => {
        const roundsClutchAttempted = rounds.filter(({ round, playersSubbed }) => {
          const numberOfPlayersSubbed = playersSubbed.get(player.team ?? 0)?.length ?? 0

          const playerLastAliveEvent = round.find(
            (event) =>
              event.players?.any((it) => it.id === player.id) &&
              event.currentPlayersOut[event.team] === numberOfPlayersSubbed - 1 &&
              !event.isDeathEvent,
          )
          if (!playerLastAliveEvent) return false
          return true
        })

        const roundsWon = roundsClutchAttempted.filter(({ round, teamWon }) => {
          return (
            teamWon[player.team ?? 0] &&
            round.any((event) => !!event.players?.find((it) => it.id === player.id))
          )
        })

        const value =
          roundsClutchAttempted.length ? roundsWon.length / roundsClutchAttempted.length : undefined

        previousValue.push({
          player,
          value: value ?? null,
          displayValue: value !== undefined ? `${Math.floor(value * 1000) / 10}%` : undefined,
          displayBreakdown: `${roundsWon.length} sets won / ${roundsClutchAttempted.length} sets attempted`,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
    valueByLeagueResult: (result, predicate, gamesPerPlayer) => {
      const sets_clutch_attempted =
        result['sets_clutch_attempt']
          ?.filter(predicate)
          ?.mapProp('value')
          ?.filterNotNull()
          ?.sum() ?? 0
      const sets_clutched =
        result['sets_clutched']?.filter(predicate)?.mapProp('value')?.filterNotNull()?.sum() ?? 0

      const value =
        sets_clutch_attempted > Math.min(gamesPerPlayer, 3) ?
          sets_clutched / sets_clutch_attempted
        : undefined

      return {
        value: value ?? null,
        displayValue: value !== undefined ? `${Math.floor(value * 1000) / 10}%` : undefined,
        displayBreakdown: `${sets_clutched} sets won / ${sets_clutch_attempted} sets attempted`,
      }
    },
  },
  {
    key: 'throws_survived',
    title: 'Throws defended',
    mode: 'sum',
    description: 'Number times you got thrown at and survived',
    highlightEventKeys: [DodgeballTemplate.Definitions.get('got_targeted').key],
    playerValuesByGame: (sortedEvents, players) => {
      return players.reduce(
        (previousValue, player) => {
          const playersEvents = sortedEvents.filter(
            (event) => !!event.player && event.player.id === player.id,
          )

          const gotTargetedEvents = playersEvents.filter(
            (event) =>
              (matchChildCategoryKey(
                DodgeballTemplate.buttonSetup,
                DodgeballTemplate.Definitions.get('got_targeted'),
                event.definition_key,
              ) ||
                matchChildCategoryKey(
                  DodgeballTemplate.buttonSetup,
                  DodgeballTemplate.Definitions.get('died'),
                  event.definition_key,
                )) &&
              !matchChildCategoryKey(
                DodgeballTemplate.buttonSetup,
                DodgeballTemplate.Definitions.get('died_foot-foul'),
                event.definition_key,
              ) &&
              !matchChildCategoryKey(
                DodgeballTemplate.buttonSetup,
                DodgeballTemplate.Definitions.get('died_got-caught'),
                event.definition_key,
              ),
          )
          previousValue.push({
            player,
            value:
              gotTargetedEvents.length -
              gotTargetedEvents.filter((event) =>
                matchChildCategoryKey(
                  DodgeballTemplate.buttonSetup,
                  DodgeballTemplate.Definitions.get('died'),
                  event.definition_key,
                ),
              ).length,
          })
          return previousValue
        },
        [] as {
          player: Pick<PlayerFirebaseEntry, 'id' | 'name' | 'team'>
          value: number
        }[],
      )
    },
  },
  {
    key: 'defence_rate',
    title: 'Defence rate',
    description: (
      <>
        How well you survived enemy attacks.
        <br />
        <br />
        You need to have been targeted 20 times and played atleast 2 games to count on the
        leaderboard.
        <br />
        <HighlightedNoteBox>(throws defended / got targeted)</HighlightedNoteBox>
      </>
    ),
    highlightEventKeys: [
      DodgeballTemplate.Definitions.get('got_targeted').key,
      DodgeballTemplate.Definitions.get('died').key,
    ],
    playerValuesByGame: (sortedEvents, players) => {
      return players.reduce((previousValue, player) => {
        const playersEvents = sortedEvents.filter(
          (event) => !!event.player && event.player.id === player.id,
        )
        const gotTargetedEvents = playersEvents.filter(
          (event) =>
            (matchChildCategoryKey(
              DodgeballTemplate.buttonSetup,
              DodgeballTemplate.Definitions.get('got_targeted'),
              event.definition_key,
            ) ||
              matchChildCategoryKey(
                DodgeballTemplate.buttonSetup,
                DodgeballTemplate.Definitions.get('died'),
                event.definition_key,
              )) &&
            !matchChildCategoryKey(
              DodgeballTemplate.buttonSetup,
              DodgeballTemplate.Definitions.get('died_foot-foul'),
              event.definition_key,
            ) &&
            !matchChildCategoryKey(
              DodgeballTemplate.buttonSetup,
              DodgeballTemplate.Definitions.get('died_got-caught'),
              event.definition_key,
            ),
        )

        const survived =
          gotTargetedEvents.length -
          gotTargetedEvents.filter((event) =>
            matchChildCategoryKey(
              DodgeballTemplate.buttonSetup,
              DodgeballTemplate.Definitions.get('died'),
              event.definition_key,
            ),
          ).length

        const value =
          gotTargetedEvents.length >= 20 ?
            Math.round((survived * 1000) / gotTargetedEvents.length) / 1000
          : undefined

        previousValue.push({
          player,
          value: value ?? null,
          displayValue: value !== undefined ? `${Math.floor(value * 1000) / 10}%` : undefined,
          displayBreakdown: `${survived} survived / ${gotTargetedEvents.length} times targeted`,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },

    dependencies: ['targeted', 'throws_survived'],
    valueByLeagueResult: (result, predicate, gamesPerPlayer) => {
      const got_targeted_games = result['targeted']?.filter(predicate)
      const throws_survived_games = result['throws_survived']?.filter(predicate)

      const got_targeted = got_targeted_games?.mapProp('value')?.filterNotNull()?.sum() ?? 0

      const survived = throws_survived_games?.mapProp('value')?.filterNotNull()?.sum() ?? 0
      if (
        got_targeted <= 20 ||
        !got_targeted_games?.length ||
        (got_targeted_games.length < 2 && got_targeted_games.length < gamesPerPlayer)
      )
        return undefined

      const value = Math.round((1000 * survived) / got_targeted) / 1000

      return {
        value: value ?? null,
        displayValue: `${Math.floor(value * 1000) / 10}%`,
        displayBreakdown: `${survived} survived / ${got_targeted} times targeted`,
      }
    },
  },
  {
    key: 'targeted',
    title: 'Got targeted',
    mode: 'sum',
    forfun: true,
    description: 'Number of times the other team thought you deserved to get thrown at',
    highlightEventKeys: [
      DodgeballTemplate.Definitions.get('got_targeted').key,
      DodgeballTemplate.Definitions.get('died').key,
    ],
    playerValuesByGame: (sortedEvents, players) => {
      return players.reduce((previousValue, player) => {
        const playersEvents = sortedEvents.filter(
          (event) => !!event.player && event.player.id === player.id,
        )

        const gotTargetedEvents = playersEvents.filter(
          (event) =>
            (matchChildCategoryKey(
              DodgeballTemplate.buttonSetup,
              DodgeballTemplate.Definitions.get('got_targeted'),
              event.definition_key,
            ) ||
              matchChildCategoryKey(
                DodgeballTemplate.buttonSetup,
                DodgeballTemplate.Definitions.get('died'),
                event.definition_key,
              )) &&
            !matchChildCategoryKey(
              DodgeballTemplate.buttonSetup,
              DodgeballTemplate.Definitions.get('died_foot-foul'),
              event.definition_key,
            ) &&
            !matchChildCategoryKey(
              DodgeballTemplate.buttonSetup,
              DodgeballTemplate.Definitions.get('died_got-caught'),
              event.definition_key,
            ),
        )

        previousValue.push({
          player,
          value: gotTargetedEvents.length,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
  },
  {
    key: 'value_ratio',
    title: 'Value ratio',
    description: (
      <>
        Amount of damage you do per life. <br />
        You need to have played atleast 2 games to count on the leaderboard.
        <br />
        <br />
        <HighlightedNoteBox>( kills - gotcaught + catches ) / lives</HighlightedNoteBox>
        ie. catches are double counted and you also lose a kill for getting caught
      </>
    ),
    highlightEventKeys: [
      DodgeballTemplate.Definitions.get('throw_hit').key,
      DodgeballTemplate.Definitions.get('defend_catch_success').key,
    ],
    playerValuesByGame: (sortedEvents, players) => {
      return players.reduce((previousValue, player) => {
        const playersEvents = sortedEvents.filter(
          (event) => !!event.player && event.player.id === player.id,
        )

        const kills = playersEvents.filter((event) =>
          matchChildCategoryKey(DodgeballTemplate.buttonSetup, 'KillEvent', event.definition_key),
        ).length

        const deaths = playersEvents.filter((event) =>
          matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('died'),
            event.definition_key,
          ),
        ).length

        const catches = playersEvents.filter((event) =>
          matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('defend_catch_success'),
            event.definition_key,
          ),
        ).length

        const got_caught = playersEvents.filter((event) =>
          matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('died_got-caught'),
            event.definition_key,
          ),
        ).length

        const value = Math.round((100 * (kills - got_caught + catches)) / (deaths + 1)) / 100

        previousValue.push({
          player,
          value,
          displayValue: value.toFixed(2),
          displayBreakdown: `( ${kills} kills - ${got_caught} got caught + ${catches} catches ) / ${deaths + 1} lives`,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
    dependencies: ['kills', 'deaths', 'got_caught', 'catches'],
    valueByLeagueResult: (result, predicate, gamesPerPlayer) => {
      const takeTop3 = false

      const top3Games =
        takeTop3 ?
          result['value_ratio']
            ?.filter(predicate)
            .filter((it) => it.value !== undefined)
            .orderByDesc(
              (it) =>
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                it.value!,
            )
            .slice(0, 3)
            .map((it) => it.reviewId)
        : undefined

      const playerKillResults = result['kills']
        ?.filter(predicate)
        ?.filter((it) => !takeTop3 || top3Games?.includes(it.reviewId))
      const playerDeathsResults = result['deaths']
        ?.filter(predicate)
        ?.filter((it) => !takeTop3 || top3Games?.includes(it.reviewId))
      const playerGotCaughtResults = result['got_caught']
        ?.filter(predicate)
        ?.filter((it) => !takeTop3 || top3Games?.includes(it.reviewId))
      const playerCatchesResults = result['catches']
        ?.filter(predicate)
        ?.filter((it) => !takeTop3 || top3Games?.includes(it.reviewId))

      const games = [
        ...(playerKillResults ?? []),
        ...(playerDeathsResults ?? []),
        ...(playerGotCaughtResults ?? []),
        ...(playerCatchesResults ?? []),
      ].distinctBy((it) => it.reviewId)

      if (!games?.length || !(games.length > 1 || games.length >= gamesPerPlayer)) return undefined

      const kills = playerKillResults?.mapProp('value')?.filterNotNull()?.sum() ?? 0
      const deaths = playerDeathsResults?.mapProp('value')?.filterNotNull()?.sum() ?? 0
      const got_caught = playerGotCaughtResults?.mapProp('value')?.filterNotNull()?.sum() ?? 0
      const catches = playerCatchesResults?.mapProp('value')?.filterNotNull()?.sum() ?? 0

      const value = Math.round((100 * (kills - got_caught + catches)) / (deaths + 1)) / 100

      return {
        value,
        displayValue: value.toFixed(2),
        displayBreakdown: `( ${kills} kills - ${got_caught} got caught + ${catches} catches ) / ${deaths + 1} lives`,
      }
    },
  },
  // {
  //   key: "blocks",
  //   title: "Blocks",
  //   playerValues: (events, players) =>
  //     Object.values(
  //       events.reduce(
  //         (previousValue, { definition_key, player }) => {
  //           if (
  //             matchChildCategoryKey(
  //               DodgeballTemplate.buttonSetup,
  //               DodgeballTemplate.Definitions.get("defend_block_success"),
  //               definition_key,
  //             ) &&
  //             player
  //           ) {
  //             let playerEntry = previousValue[player.id];
  //             if (!playerEntry) {
  //               playerEntry = {
  //                 player: players.find((it) => it.id === player.id) ?? {
  //                   id: player.id,
  //                   name: player.name,
  //                 },
  //                 value: 0,
  //               };
  //               previousValue[player.id] = playerEntry;
  //             }
  //             playerEntry.value = (playerEntry.value || 0) + 1;
  //           }
  //           return previousValue;
  //         },
  //         {} as {
  //           [id: string]: {
  //             player: Pick<PlayerFirebaseEntry, "id" | "name">;
  //             value: number;
  //           };
  //         },
  //       ),
  //     ),
  // } ,
  {
    key: 'foot_fouls',
    title: 'Foot fouls',
    forfun: true,
    mode: 'sum',
    description: 'Number of times you got intimate with the boundary lines',
    highlightEventKeys: [DodgeballTemplate.Definitions.get('died_foot-foul').key],
    playerValuesByGame: (events, players) =>
      Object.values(
        events.reduce(
          (previousValue, { definition_key, player }) => {
            if (
              matchChildCategoryKey(
                DodgeballTemplate.buttonSetup,
                DodgeballTemplate.Definitions.get('died_foot-foul'),
                definition_key,
              ) &&
              player
            ) {
              let playerEntry = previousValue[player.id]
              if (!playerEntry) {
                playerEntry = {
                  player: players.find((it) => it.id === player.id) ?? {
                    id: player.id,
                    name: player.name,
                    team: player.team,
                  },
                  value: 0,
                }
                previousValue[player.id] = playerEntry
              }
              playerEntry.value = (playerEntry.value || 0) + 1
            }
            return previousValue
          },
          {} as {
            [id: string]: {
              player: Pick<PlayerFirebaseEntry, 'id' | 'name' | 'team'>
              value: number
            }
          },
        ),
      ),
  },
  {
    key: 'got_caught',
    title: 'Got caught',
    mode: 'sum',
    forfun: true,
    description: (
      <>
        Number of times you held your face with both hands and walked to the sideline thinking of
        excuses why it was a good decision to throw that time.
        <br />
        <br />
        <i>ie. Number of times you threw a ball and got caught</i>
      </>
    ),
    highlightEventKeys: [DodgeballTemplate.Definitions.get('died_got-caught').key],
    playerValuesByGame: (sortedEvents, players) => {
      return players.reduce((previousValue, player) => {
        const playersEvents = sortedEvents.filter(
          (event) => !!event.player && event.player.id === player.id,
        )

        const hits = playersEvents.filter((event) =>
          matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('died_got-caught'),
            event.definition_key,
          ),
        ).length

        previousValue.push({
          player,
          value: hits,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
  },
  {
    key: 'deaths',
    title: 'Deaths',
    mode: 'sum',
    forfun: true,
    description: 'Number of times you started spectating halfway through a set',
    highlightEventKeys: [DodgeballTemplate.Definitions.get('died').key],
    playerValuesByGame: (sortedEvents, players) => {
      return players.reduce((previousValue, player) => {
        const playersEvents = sortedEvents.filter(
          (event) => !!event.player && event.player.id === player.id,
        )

        const hits = playersEvents.filter((event) =>
          matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('died'),
            event.definition_key,
          ),
        ).length

        previousValue.push({
          player,
          value: hits,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
  },
  {
    key: 'revives',
    title: 'Revives',
    forfun: true,
    mode: 'sum',
    description: 'Number of times your teammate did the dodgeball undo move in your favour',
    highlightEventKeys: [DodgeballTemplate.Definitions.get('revive').key],
    playerValuesByGame: (events, players) =>
      Object.values(
        events.reduce(
          (previousValue, { definition_key, player }) => {
            if (
              matchChildCategoryKey(
                DodgeballTemplate.buttonSetup,
                DodgeballTemplate.Definitions.get('revive'),
                definition_key,
              ) &&
              player
            ) {
              let playerEntry = previousValue[player.id]
              if (!playerEntry) {
                playerEntry = {
                  player: players.find((it) => it.id === player.id) ?? {
                    id: player.id,
                    name: player.name,
                    team: player.team,
                  },
                  value: 0,
                }
                previousValue[player.id] = playerEntry
              }
              playerEntry.value = (playerEntry.value || 0) + 1
            }
            return previousValue
          },
          {} as {
            [id: string]: MeasurePlayerResult
          },
        ),
      ),
  },
  {
    key: 'misses',
    title: 'Misses',
    forfun: true,
    mode: 'sum',
    description: (
      <>
        Number of times you intentionally failed because every failure is one step closer to
        success. <br />
        <br />
        <i>ie. Number of times you missed your target</i>
      </>
    ),
    highlightEventKeys: [DodgeballTemplate.Definitions.get('throw_miss').key],
    playerValuesByGame: (events, players) =>
      Object.values(
        events.reduce(
          (previousValue, { definition_key, player }) => {
            if (
              matchChildCategoryKey(
                DodgeballTemplate.buttonSetup,
                DodgeballTemplate.Definitions.get('throw_miss'),
                definition_key,
              ) &&
              player
            ) {
              let playerEntry = previousValue[player.id]
              if (!playerEntry) {
                playerEntry = {
                  player: players.find((it) => it.id === player.id) ?? {
                    id: player.id,
                    name: player.name,
                    team: player.team,
                  },
                  value: 0,
                }
                previousValue[player.id] = playerEntry
              }
              playerEntry.value = (playerEntry.value || 0) + 1
            }
            return previousValue
          },
          {} as {
            [id: string]: MeasurePlayerResult
          },
        ),
      ),
  },
  {
    key: 'bad_starts',
    title: 'Rough starts',
    forfun: true,
    mode: 'sum',
    description: (
      <>
        Number of times you thought about whether it was gonna be an &quot;off&quot; set. <br />
        <br />
        <i>ie. Number of sets where you missed your first throw</i>
      </>
    ),
    highlightEventKeys: [DodgeballTemplate.Definitions.get('throw_miss').key],
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1)
      const rounds = splitRounds(simplifiedEvents)
      return players.reduce((previousValue, player) => {
        const badStarts = rounds.filter((round) => {
          const playersEvents = round.filter(
            (event) => !!event.players?.find((it) => it.id === player.id),
          )

          const throws = playersEvents.filter((event) =>
            matchChildCategoryKey(
              DodgeballTemplate.buttonSetup,
              DodgeballTemplate.Definitions.get('throw'),
              event.definition_key,
            ),
          )

          const firstThrow = throws.firstOrNull()
          if (!firstThrow) return false
          return matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('throw_miss'),
            firstThrow.definition_key,
          )
        }).length
        previousValue.push({ player, value: badStarts })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
  },
  {
    key: 'opening_throw',
    title: 'Opening throws',
    hidden: true,
    mode: 'sum',
    description: (
      <>
        Number of times you took your team&apos;s first throw in an opening engagement
        <br />
        <i>
          An opening engagement is the first &quot;engagement&quot; in any set. This could be an
          opening rush or the first play of the set.
        </i>
      </>
    ),
    highlightEventKeys: [DodgeballTemplate.Definitions.get('throw').key],
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1)
      const rounds = splitRounds(simplifiedEvents)

      const firstClashes = rounds.mapNotNull((round) => splitClashes(round).firstOrNull())
      return players.reduce((previousValue, player) => {
        const openingThrows = firstClashes.filter((clash) => {
          const throwsByPlayersTeam = clash.filter(
            (event) =>
              matchChildCategoryKey(
                DodgeballTemplate.buttonSetup,
                DodgeballTemplate.Definitions.get('throw'),
                event.definition_key,
              ) && event.team === player.team,
          )

          const tookOpeningThrow = throwsByPlayersTeam
            .firstOrNull()
            ?.players?.find((it) => it.id === player.id)

          return !!tookOpeningThrow
        }).length
        previousValue.push({ player, value: openingThrows })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
  },
  {
    key: 'opening_throw_hit',
    title: 'Opening throw hits',
    mode: 'sum',
    description: (
      <>
        Number of times you took your team&apos;s first throw in an opening engagement and got a hit
        <br />
        <br />
        <i>
          An opening engagement is the first &quot;engagement&quot; in any set. This could be an
          opening rush or the first play of the set.
        </i>
      </>
    ),
    highlightEventKeys: [DodgeballTemplate.Definitions.get('throw_hit').key],
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1)
      const rounds = splitRounds(simplifiedEvents)

      const firstClashes = rounds.mapNotNull((round) => splitClashes(round).firstOrNull())

      return players.reduce((previousValue, player) => {
        const openingThrowHits = firstClashes.filter((clash) => {
          const throwsByPlayersTeam = clash.filter(
            (event) =>
              matchChildCategoryKey(
                DodgeballTemplate.buttonSetup,
                DodgeballTemplate.Definitions.get('throw'),
                event.definition_key,
              ) && event.team === player.team,
          )
          const openingThrow = throwsByPlayersTeam.firstOrNull()

          if (!openingThrow) return false

          const tookOpeningThrow = openingThrow.players?.find((it) => it.id === player.id)

          if (!tookOpeningThrow) return false

          const isOpeningThrowHit = matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('throw_hit'),
            openingThrow.definition_key,
          )
          return isOpeningThrowHit
        }).length
        previousValue.push({ player, value: openingThrowHits })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
  },
  {
    key: 'opening_throw_hit_rate',
    title: 'Opening throw hit rate',
    disableTopGameRecords: true,
    description: (
      <>
        Number of times you took your team&apos;s first throw in an opening engagement and hit.
        <br />
        <br /> You need to have taken atleast 10 opening throws and opened in more than 2 games to
        count on the leaderboard
        <br />
        <br />
        <i>
          An opening engagement is the first &quot;engagement&quot; in any set. This could be an
          opening rush or the first play of the set.
        </i>
      </>
    ),
    highlightEventKeys: [DodgeballTemplate.Definitions.get('throw_hit').key],
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1)

      const rounds = splitRounds(simplifiedEvents)
      const firstClashes = rounds.mapNotNull((round) => splitClashes(round).firstOrNull())
      return players.reduce((previousValue, player) => {
        const openingThrowHits = firstClashes.filter((clash) => {
          const throwsByPlayersTeam = clash.filter(
            (event) =>
              matchChildCategoryKey(
                DodgeballTemplate.buttonSetup,
                DodgeballTemplate.Definitions.get('throw'),
                event.definition_key,
              ) && event.team === player.team,
          )
          const openingThrow = throwsByPlayersTeam.firstOrNull()

          if (!openingThrow) return false

          const tookOpeningThrow = openingThrow.players?.find((it) => it.id === player.id)

          if (!tookOpeningThrow) return false

          const isOpeningThrowHit = matchChildCategoryKey(
            DodgeballTemplate.buttonSetup,
            DodgeballTemplate.Definitions.get('throw_hit'),
            openingThrow.definition_key,
          )
          return isOpeningThrowHit
        }).length
        const openingThrows = firstClashes.filter((clash) => {
          const throwsByPlayersTeam = clash.filter(
            (event) =>
              matchChildCategoryKey(
                DodgeballTemplate.buttonSetup,
                DodgeballTemplate.Definitions.get('throw'),
                event.definition_key,
              ) && event.team === player.team,
          )
          const openingThrow = throwsByPlayersTeam.firstOrNull()

          if (!openingThrow) return false

          const tookOpeningThrow = openingThrow.players?.find((it) => it.id === player.id)

          return tookOpeningThrow
        }).length
        const value =
          openingThrows >= 10 ?
            Math.round((1000 * openingThrowHits) / openingThrows) / 1000
          : undefined
        previousValue.push({
          player,
          value: value ?? null,
          displayValue: value !== undefined ? `${Math.floor(value * 1000) / 10}%` : undefined,
          displayBreakdown: `${openingThrowHits} hits / ${openingThrows} throws`,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
    dependencies: ['opening_throw', 'opening_throw_hit'],
    valueByLeagueResult: (result, predicate, gamesPerPlayer) => {
      const opening_throw_games = result['opening_throw']
        ?.filter(predicate)
        ?.filter((it) => it.value)
      const opening_throw_hit_games = result['opening_throw_hit']?.filter(predicate)

      const openingThrows = opening_throw_games?.mapProp('value')?.filterNotNull()?.sum() ?? 0
      const openingThrowHits =
        opening_throw_hit_games?.mapProp('value')?.filterNotNull()?.sum() ?? 0

      const value =
        (
          opening_throw_games &&
          openingThrows >= 10 &&
          (opening_throw_games?.length >= 2 || opening_throw_games?.length >= gamesPerPlayer)
        ) ?
          Math.round((1000 * openingThrowHits) / openingThrows) / 1000
        : undefined

      return {
        value: value ?? null,
        displayValue: value !== undefined ? `${Math.floor(value * 1000) / 10}%` : undefined,
        displayBreakdown: `${openingThrowHits} hits / ${openingThrows} throws`,
      }
    },
  },
  {
    key: 'opening_engagement_throw',
    title: 'Opening engagement throws',
    hidden: true,
    mode: 'sum',
    description: (
      <>
        Number of times you contributed to an opening engagement with a throw.
        <br />
        <br />
        <i>
          An opening engagement is the first &quot;engagement&quot; in any set. This could be an
          opening rush or the first play of the set.
        </i>
      </>
    ),
    highlightEventKeys: [DodgeballTemplate.Definitions.get('throw').key],
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1)
      const rounds = splitRounds(simplifiedEvents)

      const firstClashes = rounds.mapNotNull((round) => splitClashes(round).firstOrNull())

      return players.reduce((previousValue, player) => {
        const openingThrowHits = firstClashes
          .mapNotNull((clash) => {
            const throwsByPlayersTeam = clash.filter(
              (event) =>
                matchChildCategoryKey(
                  DodgeballTemplate.buttonSetup,
                  DodgeballTemplate.Definitions.get('throw'),
                  event.definition_key,
                ) && event.team === player.team,
            )

            return throwsByPlayersTeam.filter((event) =>
              event.players?.find((it) => it.id === player.id),
            ).length
          })
          .sum()

        previousValue.push({ player, value: openingThrowHits })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
  },
  {
    key: 'opening_engagement_hit',
    title: 'Opening engagement hits',
    mode: 'sum',
    description: (
      <>
        Number of times you contributed to an opening engagement and got a hit.
        <br />
        <br />
        <i>
          An opening engagement is the first &quot;engagement&quot; in any set. This could be an
          opening rush or the first play of the set.
        </i>
      </>
    ),
    highlightEventKeys: [DodgeballTemplate.Definitions.get('throw_hit').key],
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1)
      const rounds = splitRounds(simplifiedEvents)

      const firstClashes = rounds.mapNotNull((round) => splitClashes(round).firstOrNull())

      return players.reduce((previousValue, player) => {
        const openingThrowHits = firstClashes
          .mapNotNull((clash) => {
            const throwsByPlayersTeam = clash.filter(
              (event) =>
                matchChildCategoryKey(
                  DodgeballTemplate.buttonSetup,
                  DodgeballTemplate.Definitions.get('throw_hit'),
                  event.definition_key,
                ) && event.team === player.team,
            )

            return throwsByPlayersTeam.filter((event) =>
              event.players?.find((it) => it.id === player.id),
            ).length
          })
          .sum()

        previousValue.push({ player, value: openingThrowHits })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
  },
  {
    key: 'opening_engagement_hit_rate',
    title: 'Opening engagement hit rate',
    disableTopGameRecords: true,
    description: (
      <>
        Number of times you contributed to an opening engagement and got a hit as a percentage of
        the throws you took.
        <br />
        <br />
        <i>
          An opening engagement is the first &quot;engagement&quot; in any set. This could be an
          opening rush or the first play of the set.
        </i>
      </>
    ),
    highlightEventKeys: [DodgeballTemplate.Definitions.get('throw_hit').key],
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1)
      const rounds = splitRounds(simplifiedEvents)

      const firstClashes = rounds.mapNotNull((round) => splitClashes(round).firstOrNull())

      return players.reduce((previousValue, player) => {
        const openingThrowHits = firstClashes
          .mapNotNull((clash) => {
            const throwsByPlayersTeam = clash.filter(
              (event) =>
                matchChildCategoryKey(
                  DodgeballTemplate.buttonSetup,
                  DodgeballTemplate.Definitions.get('throw_hit'),
                  event.definition_key,
                ) && event.team === player.team,
            )

            return throwsByPlayersTeam.filter((event) =>
              event.players?.find((it) => it.id === player.id),
            ).length
          })
          .sum()
        const openingThrows = firstClashes
          .mapNotNull((clash) => {
            const throwsByPlayersTeam = clash.filter(
              (event) =>
                matchChildCategoryKey(
                  DodgeballTemplate.buttonSetup,
                  DodgeballTemplate.Definitions.get('throw'),
                  event.definition_key,
                ) && event.team === player.team,
            )

            return throwsByPlayersTeam.filter((event) =>
              event.players?.find((it) => it.id === player.id),
            ).length
          })
          .sum()

        const value =
          openingThrows >= 10 ?
            Math.round((1000 * openingThrowHits) / openingThrows) / 1000
          : undefined

        previousValue.push({
          player,
          value: value ?? null,
          displayValue: value !== undefined ? `${Math.floor(value * 1000) / 10}%` : undefined,
          displayBreakdown: `${openingThrowHits} hits / ${openingThrows} throws`,
        })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
    dependencies: ['opening_engagement_throw', 'opening_engagement_hit'],
    valueByLeagueResult: (result, predicate, gamesPerPlayer) => {
      const opening_throw_games = result['opening_engagement_throw']?.filter(predicate)
      const opening_throw_hit_games = result['opening_engagement_hit']?.filter(predicate)

      const openingThrows = opening_throw_games?.mapProp('value')?.filterNotNull()?.sum() ?? 0
      const openingThrowHits =
        opening_throw_hit_games?.mapProp('value')?.filterNotNull()?.sum() ?? 0

      const value =
        (
          opening_throw_games &&
          openingThrows >= 10 &&
          (opening_throw_games?.length >= 2 || opening_throw_games?.length >= gamesPerPlayer)
        ) ?
          Math.round((1000 * openingThrowHits) / openingThrows) / 1000
        : undefined

      return {
        value: value ?? null,
        displayValue: value !== undefined ? `${Math.floor(value * 1000) / 10}%` : undefined,
        displayBreakdown: `${openingThrowHits} hits / ${openingThrows} throws`,
      }
    },
  },
  {
    key: 'first_to_die',
    title: 'Lambs',
    forfun: true,
    mode: 'sum',
    description: (
      <>
        Number of times you thought it was too squishy with 6 on court so you gave your team some
        personal space.
        <br />
        <br />
        <i>ie. Number of sets where you died first on your team</i>
      </>
    ),
    highlightEventKeys: [DodgeballTemplate.Definitions.get('died').key],
    playerValuesByGame: (sortedEvents, players) => {
      const simplifiedEvents = simplifyEvents('dodgeball', sortedEvents, 0, 1)
      const rounds = splitRounds(simplifiedEvents)
      return players.reduce((previousValue, player) => {
        const diedFirsts = rounds.filter((round) => {
          return round
            .find(
              (event) =>
                matchChildCategoryKey(
                  DodgeballTemplate.buttonSetup,
                  DodgeballTemplate.Definitions.get('died'),
                  event.definition_key,
                ) &&
                event.players?.length &&
                event.team === player.team,
            )
            ?.players?.any((it) => it.id === player.id)
        }).length
        previousValue.push({ player, value: diedFirsts })
        return previousValue
      }, [] as MeasurePlayerResult[])
    },
  },
]
