'use client'

import { EventDefinition, resolveStringOrFn } from 'templates/TemplateConfig'
import { PlayerStats } from '../data/StatsStore'
import { TimelineEvent } from '../data/TimelineEvent'
import { PlayerStore } from '../ui/PlayerStore'

export function toCSVFormat(matrix: Record<string, any>[]): string {
  const header = matrix.reduce<Record<string, any>>((previousValue, currentValue) => {
    Object.keys(currentValue).forEach((key) => {
      previousValue[key] = key
    })
    return previousValue
  }, {})
  const keys = Object.keys(header)
  return [header]
    .concat(matrix)
    .map((row) => {
      return keys
        .map((key) => row[key])
        .map((i: any | undefined) => (i !== undefined ? `"${i}"` : ''))
        .join(',')
    })
    .join('\n')
}

export function eventsToMatrix(timeevents: TimelineEvent<EventDefinition>[]) {
  const data = timeevents
    .sort((a, b) => a.time - b.time)
    .map((event) => {
      const time = secondMinduteHour(event.time)
      return {
        time: `${time.h}:${time.m}:${time.s}`,
        team: event.team,
        eventName: resolveStringOrFn(event.title),
        tags: (event.tags || [resolveStringOrFn(event.title)]).join('+'),
        player: event.who?.map((w) => w.name)?.join('+') || '',
        extra: event.extra?.message || '',
        title: event.title || '',
        definitionKey: event.definition_key || '',
        fallbackDefinitionKey: event.fallbackDefinition_key || '',
        createdBy: event.createBy?.displayName || '',
        createdDate: event.createdDate || '',
        modifiedDate: event.modifiedDate || '',
        description: resolveStringOrFn(event.description) || '',
        who: event.who?.map((w) => w.name).join('; ') || '',
      }
    })
  return data
}
export function eventsToCSV(
  timeevents: TimelineEvent<EventDefinition>[],
  playerStats: { [id: string]: PlayerStats } | undefined,
  playerStore: PlayerStore,
) {
  const playerStatsAsMatrix =
    playerStats &&
    Object.entries(playerStats).map(([playerId, stats]) => {
      return { player: playerStore.getPlayer(playerId)?.name, ...stats }
    })
  return `${playerStatsAsMatrix ? toCSVFormat(playerStatsAsMatrix) + '\n\n' : ''}${toCSVFormat(eventsToMatrix(timeevents))}`
}
export function exportCSV(
  videoId: string,
  timeevents: TimelineEvent<EventDefinition>[],
  playerStats: { [id: string]: PlayerStats } | undefined,
  playerStore: PlayerStore,
  videoTitle?: string,
) {
  downloadTextFile(
    eventsToCSV(timeevents, playerStats, playerStore),
    `playback_analysis_${videoTitle && (`${videoTitle}_` || '')}${videoId}.csv`,
  )
}

export function downloadTextFile(content: string, fileName: string) {
  const element = document.createElement('a')
  element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(content))
  element.setAttribute('download', fileName)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

function secondMinduteHour(d: number) {
  const calc = {
    h: Math.floor(d / 3600),
    m: Math.floor((d % 3600) / 60),
    s: Math.floor((d % 3600) % 60),
  }
  const display = { h: `${calc.h}`, m: `${calc.m}`, s: `${calc.s}` }
  if (calc.h < 10) {
    display.h = '0' + calc.h
  }
  if (calc.m < 10) {
    display.m = '0' + calc.m
  }
  if (calc.s < 10) {
    display.s = '0' + calc.s
  }
  return display
}
