'use client'

import {
  FirebaseDb,
  useDatabaseNullableRefLiveValue,
  useDatabaseRefLiveValueMemo,
  useMappedDatabaseRefLiveValue,
  useNullableDatabasePathLiveValue,
  useNullableDatabaseRefLiveValueMemo,
} from 'components/common/Firebase'
import { UnreachableError } from 'components/common/utils/error'
import { FirebaseActivityType, ReviewFirebaseEntry } from 'data/common'
import { use } from 'i18next'
import { useCallback, useEffect, useMemo } from 'react'
import { DodgeballTemplate } from 'templates/dodgeball/DodgeballTemplate'
import {
  ActivityStatsTemplate,
  ReviewActivityType,
  mapFirebaseToActivityType,
} from 'ui/ReviewMetaStore'

export function useStatsTemplateStore(
  firebase: FirebaseDb,
  reviewId: string | undefined,
): [ActivityStatsTemplate | undefined, (templateKey: string) => Promise<void>] {
  const activityTypeResult = useMappedDatabaseRefLiveValue(
    () =>
      firebase
        .getRef<ReviewFirebaseEntry>(reviewId && `/reviews/${reviewId}`)
        ?.childFromKey('activityType'),
    [firebase, reviewId],
  )
  const templateKeyResult = useMappedDatabaseRefLiveValue(
    () =>
      firebase
        .getRef<ReviewFirebaseEntry>(reviewId && `/reviews/${reviewId}`)
        ?.childFromKey('statsTemplateKey'),
    [firebase, reviewId],
  )

  const setTemplate = useCallback(
    async (templateKey: string) =>
      firebase
        .getRef<ReviewFirebaseEntry>(reviewId && `/reviews/${reviewId}`)
        ?.childFromKey('statsTemplateKey')
        ?.set(templateKey),
    [firebase, reviewId],
  )

  return useMemo(
    () => [
      activityTypeResult.type !== 'PENDING' && templateKeyResult.type !== 'PENDING' ?
        parseStatsTemplate(
          activityTypeResult.type === 'SUCCESS' ? (activityTypeResult.value ?? null) : null,
          templateKeyResult.type === 'SUCCESS' ? (templateKeyResult.value ?? null) : null,
        )
      : undefined,
      setTemplate,
    ],
    [activityTypeResult, templateKeyResult, setTemplate],
  )
}

export function parseStatsTemplate(
  firebaseActivityType: FirebaseActivityType | null,
  templateKey: string | null,
): ActivityStatsTemplate {
  const activityType = mapFirebaseToActivityType(firebaseActivityType)
  switch (activityType) {
    case 'dodgeball':
      if (!templateKey) return { activityType: 'dodgeball', templateId: 'basic', type: 'defined' }
      if (templateKey in DodgeballTemplate.TemplateIds) {
        return {
          activityType: 'dodgeball',
          templateId: templateKey as keyof typeof DodgeballTemplate.TemplateIds,
          type: 'defined',
        }
      }
      return { activityType: 'dodgeball', templateId: templateKey, type: 'custom' }
    case 'sports_notes':
      if (!templateKey)
        return { activityType: 'sports_notes', templateId: 'default', type: 'defined' }
      return { activityType: 'sports_notes', templateId: templateKey, type: 'custom' }
    case 'general_notes':
      if (!templateKey)
        return { activityType: 'general_notes', templateId: 'default', type: 'defined' }
      return { activityType: 'general_notes', templateId: templateKey, type: 'custom' }
    default:
      throw UnreachableError(activityType)
  }
}
