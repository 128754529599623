'use client'

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import React, {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import UpArrow from '../icons/Arrow_up.png'
import HamburgerIcon from '../icons/hamburger_menu.png'
import { useCompositeRefObject } from './UseCompositeRefObject'
import { SquashingBox } from './common/SquashingBox'
import {
  NCImage,
  NodeOrImage,
  RenderableImageNode,
} from './common/nextJs/components/nextCompatibleImage'
import NCLink from './common/nextJs/components/nextCompatibleLink'
import { useElementBoundingRect } from './common/utils/reactUtils'
import { cn } from './common/utils/tailwindUtils'

export function CollapsableTopBar({
  children,
  rightFixedChildren,
  leftMainButton,
  hamburgerButtonClassName,
}: {
  hamburgerButtonClassName?: string
  children?: () => ReactNode[]
  /** Accepts ReactNode array directly instead of a function */
  rightFixedChildren?: ReactNode[]
  leftMainButton?: () => ReactNode
}) {
  const [showPopover, setShowPopover] = useState(false)
  const heightClassName = 'h-[48px]'
  const topClassName = 'top-[48px]'
  const maxHeightClassName = 'max-h-[48px]'
  const navElementClassName = '[&_.navelement]:h-[48px]'

  const [wrappingLeftChildrenRef, wrappingLeftSize] = useElementBoundingRect()
  const [wrappingRightChildrenRef, wrappedRightSize] = useElementBoundingRect()
  const [shouldShowHamburger, setshouldShowHamburger] = useState(true)

  const leftChildrenShouldWrap =
    wrappingLeftSize.scrollWidth > wrappingLeftSize.width ||
    wrappingLeftSize.scrollHeight > wrappingLeftSize.height

  const rightChildrenShouldWrap =
    wrappedRightSize.scrollWidth > wrappedRightSize.width + 100 ||
    wrappedRightSize.scrollHeight > wrappedRightSize.height

  // useEffect(() => {
  //   if (leftChildrenShouldWrap || rightChildrenShouldWrap) {
  //     setshouldShowHamburger(true)
  //   }
  // }, [leftChildrenShouldWrap, rightChildrenShouldWrap])

  const [ref, size] = useElementBoundingRect()
  useEffect(() => {
    document.documentElement.style.setProperty('--navbar-height', `${size.height}px`)
  }, [size.height])
  return (
    <div
      ref={ref}
      className={cn(
        `pointer-events-none flex w-svw shrink-0 grow-0 basis-auto flex-row justify-between shadow-md
        shadow-[rgb(0,0,0,0.5)]`,
        navElementClassName,
        heightClassName,
      )}>
      <div
        key='left-main-button'
        className={cn(
          `flex min-w-0 max-w-fit flex-shrink basis-[fit-content] flex-row flex-nowrap items-center
          justify-start`,
          maxHeightClassName,
        )}>
        {shouldShowHamburger && (
          <NavButton
            key='hamburger-button'
            className={cn('min-w-0 bg-transparent text-white', hamburgerButtonClassName)}
            onClick={() => setShowPopover((it) => !it)}
            icon={showPopover ? UpArrow : HamburgerIcon}
          />
        )}
        {leftMainButton?.()}
      </div>
      <div
        key='left-children'
        ref={wrappingLeftChildrenRef}
        className={cn(
          'flex min-w-0 max-w-fit flex-1 flex-row items-center justify-start [&>*]:min-w-0 [&>*]:max-w-full',
          leftChildrenShouldWrap ? 'flex-wrap overflow-clip' : 'flex-nowrap',
          maxHeightClassName,
        )}>
        {children?.()}
      </div>

      <div
        key='right-children'
        ref={wrappingRightChildrenRef}
        className={cn(
          `flex min-w-0 max-w-fit flex-1 basis-0 flex-row-reverse flex-nowrap items-center justify-start
          [&>*]:min-w-0 [&>*]:max-w-full`,
          rightChildrenShouldWrap ? 'overflow-clip' : '',
        )}>
        {React.Children.toArray(rightFixedChildren ?? []).toReversed()}
      </div>

      {showPopover && (
        <div
          key='popover'
          className={cn(
            'fixed left-0 z-50 shadow-2xl shadow-slate-600',
            !shouldShowHamburger && 'hidden',
            topClassName,
            showPopover ? '' : 'hidden',
          )}>
          <VerticalMenu
            heightClassName={heightClassName}
            onClose={() => setShowPopover(false)}>
            {[...(children?.() ?? []), ...(rightFixedChildren ?? [])]}
          </VerticalMenu>
        </div>
      )}
    </div>
  )
}
export const NavItemContainer = forwardRef<
  HTMLDivElement,
  {
    onClick?: () => void
    className?: string
    children?: ReactNode
    style?: CSSProperties
    showOnlyInVerticalMenu?: boolean
    isInVerticalMenu?: boolean
  }
>(function NavItemContainer(props, ref) {
  return (
    (!props.showOnlyInVerticalMenu || props.isInVerticalMenu) && (
      <div
        ref={ref}
        className={cn(
          `navelement pointer-events-auto flex h-full w-fit min-w-0 max-w-full flex-row items-center
          justify-center [&>*]:w-full [&>*]:min-w-0 [&>*]:max-w-fit`,
          props.isInVerticalMenu && 'w-full [&>*]:max-w-full',
          props.className,
        )}
        style={props.style}>
        {props.children}
      </div>
    )
  )
})
export const TextOnlyNavButton = forwardRef<
  HTMLButtonElement,
  {
    href?: string
    onClick?: () => void
    disabled?: boolean
    className?: string
    children?: ReactNode
    style?: CSSProperties
    squashedChildrenArray?: ReactNode[]
    showOnlyInVerticalMenu?: boolean
    isInVerticalMenu?: boolean
  }
>(function TextOnlyNavButton(props, ref) {
  return (
    <NavButton
      ref={ref}
      {...props}
      className={cn(
        `navelement pointer-events-auto flex-none bg-transparent px-6 text-white
        hover:bg-[#ffffff22!important]`,
        props.className,
      )}>
      {props.children}
    </NavButton>
  )
})

export const NavButton = forwardRef<
  HTMLElement,
  {
    href?: string
    onClick?: () => void
    disabled?: boolean
    role?: string
    icon?: RenderableImageNode
    className?: string
    isInVerticalMenu?: boolean
    showOnlyInVerticalMenu?: boolean
    children?: ReactNode
    style?: CSSProperties
    tabIndex?: number
    alt?: string
    squashedChildrenArray?: ReactNode[]
  }
>(function NavButton({ onClick, disabled, icon, style, alt, ...props }, ref) {
  const divRef = useRef<HTMLElement>(null)
  const height = divRef.current?.getBoundingClientRect()?.height ?? 0
  const multiRef = useCompositeRefObject(ref, divRef)

  const className = cn(
    `navelement pointer-events-auto box-border flex h-full w-fit min-w-0 max-w-full cursor-pointer
  flex-row items-center justify-center gap-2 overflow-clip whitespace-nowrap border-none px-2 py-3
  font-league-spartan text-[1em] hover:brightness-125 disabled:cursor-default`,
    props.showOnlyInVerticalMenu && !props.isInVerticalMenu && 'hidden',
    props.className,
  )

  const children = useMemo(() => {
    return (
      <>
        {React.isValidElement(icon) && icon}
        {!React.isValidElement<any>(icon) && <NCImage src={icon} />}
        {props.children && (
          <SquashingBox
            className='flex h-full w-fit min-w-0 max-w-full flex-row items-center justify-center gap-2 whitespace-nowrap
              text-[inherit]'
            squashedChildrenArray={props.squashedChildrenArray}>
            {props.children}
          </SquashingBox>
        )}
      </>
    )
  }, [icon, props.children, props.squashedChildrenArray])

  return props.href ?
      <NCLink
        href={props.href}
        ref={multiRef}
        role={props.role}
        className={className}
        aria-disabled={disabled}
        tabIndex={props.tabIndex}
        translate={'no'}
        onClick={!disabled ? onClick : undefined}
        style={{ minHeight: height, minWidth: height, ...style }}>
        {children}
      </NCLink>
    : <button
        ref={multiRef}
        role={props.role}
        className={className}
        disabled={disabled}
        tabIndex={props.tabIndex}
        translate={'no'}
        onClick={!disabled ? onClick : undefined}
        style={{ minHeight: height, minWidth: height, ...style }}>
        {children}
      </button>
})

export const VerticalMenu = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    heightClassName?: string
    onClose?: () => void
    className?: string
    isInVerticalMenu?: boolean
    showOnlyInVerticalMenu?: boolean
  }>
>(function SubMenu(
  { heightClassName, onClose, className, children, isInVerticalMenu, showOnlyInVerticalMenu },
  ref,
) {
  return (
    <div
      ref={ref}
      className={cn(
        `pointer-events-auto flex max-h-[calc(100vh-48px)] min-w-[300px] flex-col overflow-y-auto
        bg-dark-grey scrollbar-thin scrollbar-thumb-gray-500 [&>*]:pointer-events-auto [&>*]:w-full
        [&>*]:max-w-full [&>*]:justify-center`,
        showOnlyInVerticalMenu && !isInVerticalMenu && 'hidden',
        className,
      )}>
      {React.Children.map(children, (child) =>
        (
          React.isValidElement<{
            isInVerticalMenu?: boolean
          }>(child)
        ) ?
          React.cloneElement(child, { isInVerticalMenu: true })
        : child,
      )}
      {onClose && (
        <NavButton
          className={cn(
            `pointer-events-auto w-full cursor-pointer border-none bg-[#455A64] text-center font-league-spartan
            text-[1em] text-white hover:brightness-125`,
            heightClassName,
          )}
          onClick={onClose}>
          <ChevronUpIcon className='h-6 w-6' />
          Close menu
        </NavButton>
      )}
    </div>
  )
})

export function VerticalSubMenu({
  label,
  showOnlyInVerticalMenu,
  isInVerticalMenu,
  children,
  heightClassName,
}: PropsWithChildren<{
  isInVerticalMenu?: boolean
  label: string
  heightClassName?: string
  showOnlyInVerticalMenu?: boolean
}>) {
  const detailsRef = useRef<HTMLDetailsElement>(null)

  // Only run on client side

  return (
    <NavItemContainer
      isInVerticalMenu={isInVerticalMenu}
      showOnlyInVerticalMenu={showOnlyInVerticalMenu}
      className={cn('flex-none', isInVerticalMenu && 'relative !h-fit')}>
      <details
        ref={detailsRef}
        className={cn(
          'group flex w-fit min-w-0 flex-none flex-col',
          isInVerticalMenu &&
            'w-full [&>*]:pointer-events-auto [&>*]:w-full [&>*]:max-w-full [&>*]:justify-center',
        )}>
        <summary
          className={cn(
            `navelement pointer-events-auto box-border flex h-full w-fit min-w-0 max-w-full cursor-pointer
            flex-row items-center justify-center gap-2 overflow-clip whitespace-nowrap border-none px-2 py-3
            font-league-spartan text-[1em] text-white hover:brightness-125 disabled:cursor-default`,
          )}>
          <ChevronDownIcon className='group-open:hidden' />
          <ChevronUpIcon className='!hidden group-open:!block' />
          {label}
        </summary>
        {!isInVerticalMenu ?
          <VerticalMenu className='absolute top-[48px] z-10'>
            {children}
            <NavButton
              className={cn(
                `pointer-events-auto w-full cursor-pointer border-none bg-[#455A64] text-center font-league-spartan
                  text-[1em] text-white hover:brightness-125`,
                heightClassName,
              )}
              onClick={() => {
                if (detailsRef.current) {
                  detailsRef.current.open = false
                }
              }}>
              <ChevronUpIcon className='h-6 w-6' />
              Close menu
            </NavButton>
          </VerticalMenu>
        : <VerticalMenu className='bg-blue-grey/40'>{children}</VerticalMenu>}
      </details>
    </NavItemContainer>
  )
}
