'use client'

export class IndexedDBStorage {
  private dbName: string
  private storeName: string

  constructor(dbName = 'myDatabase', storeName = 'myStore') {
    this.dbName = dbName
    this.storeName = storeName
    this.init()
  }

  private async init(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1)

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = (event.target as IDBOpenDBRequest).result
        if (!db.objectStoreNames.contains(this.storeName)) {
          db.createObjectStore(this.storeName)
        }
      }

      request.onsuccess = () => {
        resolve(request.result)
      }

      request.onerror = () => {
        reject(request.error)
      }
    })
  }

  public async setItem(key: string, value: string): Promise<void> {
    const db = await this.init()
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.storeName, 'readwrite')
      const store = transaction.objectStore(this.storeName)
      const request = store.put(value, key)

      request.onsuccess = () => {
        resolve()
      }

      request.onerror = () => {
        reject(request.error)
      }
    })
  }

  public async getItem(key: string): Promise<string | null> {
    const db = await this.init()
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.storeName, 'readonly')
      const store = transaction.objectStore(this.storeName)
      const request = store.get(key)

      request.onsuccess = () => {
        resolve(request.result || null)
      }

      request.onerror = () => {
        reject(request.error)
      }
    })
  }

  public async removeItem(key: string): Promise<void> {
    const db = await this.init()
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.storeName, 'readwrite')
      const store = transaction.objectStore(this.storeName)
      const request = store.delete(key)

      request.onsuccess = () => {
        resolve()
      }

      request.onerror = () => {
        reject(request.error)
      }
    })
  }

  // Pushes a new item to the end of an array stored under the given key
  public async pushItem<T>(baseKey: string, newValue: T): Promise<void> {
    const db = await this.init()
    const transaction = db.transaction(this.storeName, 'readwrite')
    const store = transaction.objectStore(this.storeName)
    const timeStamp = new Date().getTime()
    const id = `${baseKey}-${timeStamp}-${Math.random()}`
    return new Promise((resolve, reject) => {
      const request = store.add({ id, value: newValue }, id)
      request.onsuccess = () => resolve()
      request.onerror = () => reject(request.error)
    })
  }
  // Retrieves all items stored under the given key
  public async getItems<T>(baseKey: string): Promise<T[]> {
    const db = await this.init()
    const transaction = db.transaction(this.storeName, 'readonly')
    const store = transaction.objectStore(this.storeName)
    const keyRange = IDBKeyRange.bound(`${baseKey}-`, `${baseKey}-￿`, false, false)

    return new Promise((resolve, reject) => {
      const request = store.getAll(keyRange)
      request.onsuccess = () => {
        const results = request.result.map((item) => item.value)
        resolve(results)
      }
      request.onerror = () => {
        reject(request.error)
      }
    })
  }
  // Removes all items stored under the given key
  public async removeItems(baseKey: string): Promise<void> {
    const db = await this.init()
    const transaction = db.transaction(this.storeName, 'readwrite')
    const store = transaction.objectStore(this.storeName)
    const keyRange = IDBKeyRange.bound(`${baseKey}-`, `${baseKey}-￿`, false, false)

    return new Promise((resolve, reject) => {
      const request = store.delete(keyRange)
      request.onsuccess = () => resolve()
      request.onerror = () => reject(request.error)
    })
  }
}
