'use client'

import fuzzysearch from 'fuzzysearch'
import { useDeferredValue, useEffect, useMemo, useState } from 'react'
import { GenericDisplayRankedResults, RankedPlayerMeasureResults } from './RankingsTable'
import { useQueryString } from './common/utils/QueryString'

export type LeaderboardFilterStrategy = {
  /**
   * Filters rankings based on both player and team search terms.
   * - If no search terms, returns all rankings
   * - If only team search, returns rankings matching team name
   * - If only player search, returns all rankings (player filtering done in leaderboardTableRankings)
   * - If both searches, returns rankings matching either player or team name
   */
  commonRankings: (
    rankings: GenericDisplayRankedResults[] | undefined,
  ) => GenericDisplayRankedResults[] | undefined

  /**
   * Filters rankings specifically for the leaderboard table view.
   * Applies additional player name filtering on top of commonRankings results.
   * Only shows rankings where player name matches search term.
   */
  leaderboardTableRankings: (
    rankings: GenericDisplayRankedResults[] | undefined,
  ) => GenericDisplayRankedResults[] | undefined

  /**
   * Filters rankings specifically for the profile carousel view.
   * Uses commonRankings results directly without additional filtering.
   * Shows all rankings that passed the common filters.
   */
  profileCarouselRankings: (
    rankings: GenericDisplayRankedResults[] | undefined,
  ) => GenericDisplayRankedResults[] | undefined
}

export function useLeaderboardFilterStrategy({
  byTeam,
  searchPlayerTerm,
  searchTeamsTerm,
}: {
  byTeam?: boolean
  searchPlayerTerm: string | undefined
  searchTeamsTerm: string | undefined
}): LeaderboardFilterStrategy {
  return useMemo(() => {
    return byTeam ?
        ({
          commonRankings: (rankings) =>
            rankings?.filter((it) => {
              // no search - show all results
              if (!searchPlayerTerm && !searchTeamsTerm) return true
              if (!searchPlayerTerm)
                return (
                  !searchTeamsTerm ||
                  // only team search - show only team results
                  it.reviews.any((review) =>
                    fuzzysearch(
                      searchTeamsTerm?.toLocaleLowerCase(),
                      review.teamName?.toLocaleLowerCase() ?? '',
                    ),
                  )
                )

              if (!searchTeamsTerm)
                // only player search:
                // - leaderboard will filter further by player
                // - carousel will show all results
                return true

              return (
                // both player and team search - show both player and team results
                it.reviews.any((review) =>
                  fuzzysearch(
                    searchTeamsTerm?.toLocaleLowerCase(),
                    review.teamName?.toLocaleLowerCase() ?? '',
                  ),
                )
              )
            }),
          leaderboardTableRankings: (rankings) => rankings,
          profileCarouselRankings: (rankings) => rankings,
        } satisfies LeaderboardFilterStrategy)
      : ({
          commonRankings: (rankings) =>
            rankings?.filter((it) => {
              // no search - show all results
              if (!searchPlayerTerm && !searchTeamsTerm) return true
              if (!searchPlayerTerm)
                return (
                  !searchTeamsTerm ||
                  // only team search - show only team results
                  it.reviews.any((review) =>
                    fuzzysearch(
                      searchTeamsTerm?.toLocaleLowerCase(),
                      review.teamName?.toLocaleLowerCase() ?? '',
                    ),
                  )
                )

              if (!searchTeamsTerm)
                // only player search:
                // - leaderboard will filter further by player
                // - carousel will show all results
                return true

              return (
                // both player and team search - show both player and team results
                (it.player &&
                  fuzzysearch(
                    searchPlayerTerm?.toLocaleLowerCase(),
                    it.player.toLocaleLowerCase(),
                  )) ||
                it.reviews.any((review) =>
                  fuzzysearch(
                    searchTeamsTerm?.toLocaleLowerCase(),
                    review.teamName?.toLocaleLowerCase() ?? '',
                  ),
                )
              )
            }),
          leaderboardTableRankings: (rankings) =>
            rankings?.filter(
              (it) =>
                // leaderboard filters further by player
                !searchPlayerTerm ||
                (it.player &&
                  fuzzysearch(
                    searchPlayerTerm?.toLocaleLowerCase(),
                    it.player.toLocaleLowerCase(),
                  )),
            ),
          profileCarouselRankings: (rankings) => rankings,
        } satisfies LeaderboardFilterStrategy)
  }, [byTeam, searchPlayerTerm, searchTeamsTerm])
}

export function useIsChanging(value: any): boolean {
  const previousValue = useDeferredValue(value)

  return value !== previousValue
}

export function useProfileSearchBehaviour({
  searchPlayerTerm,
  searchTeamsTerm,
  rawRankings,
}: {
  byTeam?: boolean
  searchPlayerTerm: string | undefined
  searchTeamsTerm: string | undefined
  rawRankings: GenericDisplayRankedResults[] | undefined
}) {
  const [queryPlayerProfile, setQueryPlayerProfile] = useQueryString('profile')
  const [queryTeamProfile, setQueryTeamProfile] = useQueryString('teamProfile')
  const isSearchPlayerTermChanging = useIsChanging(searchPlayerTerm)

  // Select searched player while searching
  useEffect(() => {
    if (!queryPlayerProfile) return
    if (!isSearchPlayerTermChanging) return
    if (!(searchPlayerTerm || searchTeamsTerm)) return
    if (!rawRankings?.length) return

    const searchedPlayer =
      rawRankings.find(
        (it) =>
          it.player &&
          searchPlayerTerm &&
          fuzzysearch(searchPlayerTerm.toLocaleLowerCase(), it.player.toLocaleLowerCase()),
      )?.player ?? 'No player found'

    const searchedTeam =
      rawRankings.find(
        (it) =>
          it.team &&
          searchTeamsTerm &&
          fuzzysearch(searchTeamsTerm.toLocaleLowerCase(), it.team.toLocaleLowerCase()),
      )?.team ?? 'No team found'

    setQueryPlayerProfile(searchedPlayer)
    setQueryTeamProfile(searchedTeam)
  }, [
    isSearchPlayerTermChanging,
    queryPlayerProfile,
    rawRankings,
    searchPlayerTerm,
    searchTeamsTerm,
    setQueryPlayerProfile,
    setQueryTeamProfile,
  ])

  const selectedPlayerProfile: string | undefined = useMemo(() => {
    if (!queryPlayerProfile) return undefined

    if (!rawRankings?.find((it) => it.player === queryPlayerProfile)) {
      return rawRankings?.firstOrNull()?.player ?? queryPlayerProfile
    }

    return queryPlayerProfile
  }, [queryPlayerProfile, rawRankings])
  const selectedTeamProfile: string | undefined = useMemo(() => {
    if (!queryTeamProfile) return undefined

    if (!rawRankings?.find((it) => it.team === queryTeamProfile)) {
      return rawRankings?.firstOrNull()?.team ?? queryTeamProfile
    }

    return queryTeamProfile
  }, [queryTeamProfile, rawRankings])

  return {
    selectedPlayerProfile,
    setSelectedPlayerProfile: setQueryPlayerProfile,
    selectedTeamProfile,
    setSelectedTeamProfile: setQueryTeamProfile,
  }
}
