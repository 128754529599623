'use client'

import {
  CSSProperties,
  Component,
  Dispatch,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  SetStateAction,
  createRef,
  useCallback,
  useDebugValue,
  useEffect,
  useState,
} from 'react'
import { BackgroundIcon } from './BackgroundIcon'
// import './Dialog.css'
import OutsideAlerter from './UseOutsideAlerter'
import { scrollIntoViewIfNeeded, useOnChangeEffect } from './utils/reactUtils'
import { cn } from './utils/tailwindUtils'
import { deepCopy } from './utils/typescriptUtils'

interface ButtonProps<T> {
  text: string | ((state: T) => string)
  icon?: string
  /**
   * @return true if the dialog should be dismissed
   */
  onClicked: (state: T, setState: Dispatch<SetStateAction<T>>) => boolean | Promise<boolean>
  disabled?: (state: T) => boolean
}

export interface DialogProps<T> {
  title?: string | ((state: T) => ReactNode)
  positiveButtonProps?: ButtonProps<T> | string
  negativeButtonProps?: ButtonProps<T> | string
  user_dismissable?: boolean
  onDismiss?: () => void
  children?:
    | string
    | ((
        FocusSpan: FocusSpan,
        state: T,
        setState: Dispatch<SetStateAction<T>>,
        triggerPositiveButton: (state: T) => void,
        focusPositiveButton: () => void,
        dismiss: (() => void) | undefined,
      ) => ReactNode)
}

export type FocusSpan = (props: PropsWithChildren) => ReactElement

export interface InternalDialogProps<T> {
  title?: string | ((state: T) => ReactNode)
  positiveButtonProps?: ButtonProps<T>
  negativeButtonProps?: ButtonProps<T>
  user_dismissable?: boolean
  onDismiss?: () => void
  children?: (
    FocusSpan: FocusSpan,
    state: T,
    setState: Dispatch<SetStateAction<T>>,
    triggerPositiveButton: (state: T) => void,
    focusPositiveButton: () => void,
    dismiss: (() => void) | undefined,
  ) => ReactNode
}

const HighlightedTextSpanFactory = ({
  primaryColor,
}: Pick<DialogTheming, 'primaryColor'>): FocusSpan =>
  function HighlightedTextSpan(props: PropsWithChildren) {
    return <span style={{ color: primaryColor }}>{props.children}</span>
  }

class DialogButton<T> extends Component<
  ButtonProps<T> & {
    alt: string
    autofocus?: boolean
    style?: CSSProperties
    parentState: T
    setParentState: Dispatch<SetStateAction<T>>
  }
> {
  buttonRef = createRef<HTMLDivElement>()
  state = { disabled: false }

  componentDidMount() {
    if (this.props.autofocus) {
      this.buttonRef.current?.focus()
    }
  }

  scrollIntoView = (scrollContainer: HTMLElement | null | undefined) => {
    scrollIntoViewIfNeeded(this.buttonRef.current, scrollContainer)
  }

  handleClicked = async () => {
    this.setState(
      (it) => ({ ...it, disabled: true }),
      async () => {
        try {
          await this.props.onClicked(this.props.parentState, this.props.setParentState)
        } finally {
          this.setState((it) => ({ ...it, disabled: false }))
        }
      },
    )
  }

  render() {
    const disabled = this.state.disabled || this.props.disabled?.(this.props.parentState)
    return (
      <div
        className={cn(
          `box-border flex w-fit min-w-[200px] cursor-pointer items-center justify-center rounded-[1.2em]
          p-[0.7em] disabled:opacity-60`,
          !disabled && 'hover:brightness-120',
        )}
        style={{
          ...this.props.style,
        }}
        ref={this.buttonRef}
        aria-disabled={disabled}
        onClick={!disabled ? this.handleClicked : undefined}
        tabIndex={disabled ? -1 : 0}>
        {this.props.icon && (
          <BackgroundIcon
            alt={this.props.alt}
            src={this.props.icon}
          />
        )}
        {getText(this.props.text, this.props.parentState)}
      </div>
    )
  }
}

let globalSetState: <T>(
  props: SetStateAction<
    | {
        dialogStateProps: InternalDialogProps<T>
        initialState: T | undefined
      }
    | undefined
  >,
) => void = () => {
  console.log('DialogServer not rendered')
}

export function rerenderDialog() {
  globalSetState((it) => {
    if (!it) return undefined
    const { dialogStateProps, initialState } = it
    return { dialogStateProps: deepCopy(dialogStateProps), initialState }
  })
}

export function showDialog<T>(
  props: DialogProps<T | undefined>,
  initialState?: undefined,
): Promise<boolean | undefined> & { dismiss: () => void }
export function showDialog<T extends object | string | number>(
  props: DialogProps<T>,
  initialState: T,
): Promise<boolean | undefined> & { dismiss: () => void }
export function showDialog<T extends unknown | undefined>(
  props: DialogProps<T>,
  initialState: T,
): Promise<boolean | undefined> & { dismiss: () => void } {
  const mappedProps: InternalDialogProps<T> = {
    ...props,
    negativeButtonProps:
      typeof props.negativeButtonProps === 'string' ?
        {
          text: props.negativeButtonProps,
          onClicked: () => true,
        }
      : props.negativeButtonProps,
    positiveButtonProps:
      typeof props.positiveButtonProps === 'string' ?
        {
          text: props.positiveButtonProps,
          onClicked: () => true,
        }
      : props.positiveButtonProps,
    children: typeof props.children === 'string' ? () => <>{props.children}</> : props.children,
  } satisfies InternalDialogProps<T>
  let dismiss: () => void
  const promise = new Promise<boolean | undefined>((resolve) => {
    const copiedProps: InternalDialogProps<T> = deepCopy(mappedProps)
    dismiss = () => {
      globalSetState((state) => {
        if (JSON.stringify(state?.dialogStateProps) === JSON.stringify(mappedProps)) {
          return undefined
        } else {
          return state
        }
      })
      dismiss = () => {
        // do nothing
      }
      resolve(undefined)
      mappedProps.onDismiss?.()
    }
    if (copiedProps.positiveButtonProps) {
      copiedProps.positiveButtonProps.onClicked = async (state, setState) => {
        const shouldDismiss =
          (await mappedProps.positiveButtonProps?.onClicked(state, setState)) ?? true
        if (shouldDismiss) {
          resolve(true)
          dismiss()
        }
        return shouldDismiss
      }
    }
    if (copiedProps.negativeButtonProps) {
      copiedProps.negativeButtonProps.onClicked = async (state, setState) => {
        const shouldDismiss =
          (await mappedProps.negativeButtonProps?.onClicked(state, setState)) ?? true
        if (shouldDismiss) {
          resolve(false)
          dismiss()
        }

        return shouldDismiss
      }
    }

    copiedProps.onDismiss = dismiss
    globalSetState({ dialogStateProps: copiedProps, initialState })
  })
  return Object.assign(promise, {
    dismiss: () => {
      dismiss?.()
    },
  })
}

type InternalProps = {
  dialogStateProps: InternalDialogProps<any>
  initialState: any | undefined
  style?: CSSProperties
  theme: DialogTheming
}
type DialogTheming = {
  primaryColor?: CSSProperties['backgroundColor']
  dialogContainerColor?: CSSProperties['backgroundColor']
  textColor?: CSSProperties['color']
  negativeButtonColor?: CSSProperties['backgroundColor']
}

export function DialogServer({ style, ...theme }: DialogTheming & { style?: CSSProperties }) {
  const [state, setDialogState] =
    useState<Pick<InternalProps, 'dialogStateProps' | 'initialState'>>()
  const { dialogStateProps: dialogState, initialState } = state ?? {}
  globalSetState = useCallback(
    (it: SetStateAction<Pick<InternalProps, 'dialogStateProps' | 'initialState'> | undefined>) =>
      setDialogState(it),
    [],
  )
  return (
    <>
      {dialogState && (
        <div
          style={{
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 1000000,
            pointerEvents: 'all',
            textAlign: 'left',
          }}>
          <OutsideAlerter
            onClickOutside={dialogState.user_dismissable ? dialogState.onDismiss : undefined}>
            <Dialog
              dialogStateProps={dialogState}
              initialState={initialState}
              theme={theme}
              style={{
                pointerEvents: 'auto',
                ...style,
              }}
            />
          </OutsideAlerter>
        </div>
      )}
    </>
  )
}

export function Dialog<T extends object | undefined>({
  theme: {
    primaryColor = '#DD3D4E',
    textColor = 'rgb(197,197,197)',
    dialogContainerColor = 'rgba(22,25,31)',
    negativeButtonColor = 'rgba(129,129,129,0.2)',
  },
  ...props
}: InternalProps) {
  const { negativeButtonProps, positiveButtonProps, title, children, onDismiss } =
    props.dialogStateProps
  const { style } = props
  const [state, setState] = useState<T>(props.initialState)
  useOnChangeEffect((inputs, oldInputs) => {
    if (inputs.dialogStateProps !== oldInputs?.dialogStateProps) setState(props.initialState)
  }, props)
  const [positiveButton, setPositivButton] = useState<DialogButton<T>>()
  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(null)
  const triggerPositiveButton = useCallback(
    (state: T) => {
      setState(state)
      if (!positiveButtonProps?.disabled?.(state)) {
        positiveButtonProps?.onClicked(state, setState)
      }
    },
    [positiveButtonProps],
  )
  const focusPositiveButton = useCallback(() => {
    positiveButton?.scrollIntoView(scrollContainer)
  }, [positiveButton, scrollContainer])
  useEffect(() => {
    if (scrollContainer) scrollContainer.scrollTop = 0
  }, [scrollContainer])
  return (
    <div
      ref={(node) => {
        node && setScrollContainer(node)
      }}
      className={'dialog-container'}
      style={{
        backgroundColor: dialogContainerColor,
        padding: '30px 20px',
        color: textColor,
        boxShadow: '0 0 300px 100px rgb(255,255,255,0.5)',
        minWidth: '300px',
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.4em',
        fontSize: '18px',
        borderRadius: '16px',
        fontFamily: "'LeagueSpartan', san-serif",
        overflow: 'auto',
        maxHeight: 'calc(var(--vh, 1vh) * 100)',
        boxSizing: 'border-box',
        margin: '30px',
        ...style,
      }}>
      {title && (
        <div className={'dialog-header text-4xl font-bold'}>
          {title && (typeof title === 'string' ? <>{title}</> : <>{title(state)}</>)}
        </div>
      )}
      <div
        className={'dialog-body'}
        style={{ lineHeight: '1.1em', fontVariationSettings: "'wght' 400" }}>
        {children?.(
          HighlightedTextSpanFactory({ primaryColor }),
          state,
          setState,
          triggerPositiveButton,
          focusPositiveButton,
          onDismiss,
        )}
      </div>
      <div
        className={'dialog-buttons'}
        style={{
          display: 'flex',
          fontSize: '1em',
          alignItems: 'center',
          color: '#f6f6f6',
          flexDirection: 'column',
          gap: '8px',
          fontVariationSettings: "'wght' 600",
        }}>
        {positiveButtonProps && (
          <DialogButton<T>
            {...positiveButtonProps}
            ref={(node) => {
              node && setPositivButton(node)
            }}
            alt={getText(positiveButtonProps.text, state)}
            style={{ backgroundColor: primaryColor, color: textColor }}
            parentState={state}
            setParentState={setState}
            autofocus={true}
          />
        )}
        {negativeButtonProps && (
          <DialogButton<T>
            {...negativeButtonProps}
            alt={getText(negativeButtonProps.text, state)}
            parentState={state}
            setParentState={setState}
            style={{ backgroundColor: negativeButtonColor, color: textColor }}
          />
        )}
      </div>
    </div>
  )
}

function getText<T>(text: string | ((state: T) => string), state: T) {
  return typeof text === 'string' ? text : text(state)
}
